import React, { useState, useEffect, useRef } from 'react';
import { Spin, Collapse, notification, Tree } from 'antd';
import styles from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from "redux/Dashboard/actions.js";
import { apiRequest } from 'util/services';
import { useHistory } from 'react-router-dom';

const CustomTopicFilter = ({ height }) => {

   const [hierarchyItems, setHierarchyItems] = useState([]);
   const [tempData, setTempData] = useState([]);
   const [treeItems, setTreeItems] = useState([]);
   const [showSearchBox, setShowSearchBox] = useState(false);
   const [searchValue, setSearchValue] = useState('');
   const [selectedTopicIds, setSelectedTopicIds] = useState([]);
   const [showDropdownContent, setShowDropdownContent] = useState(false);
   const { t } = useTranslation();
   const theme = useSelector(state => state.DashboardReducer.theme);
   const DashboardReducer = useSelector(state => state.DashboardReducer);
   const dispatch = useDispatch();
   const customTopicDropdownRef = useRef();
   const [viewType, setViewType] = useState('category');
   const [expandedKeys, setExpandedKeys] = useState([]);
   const [checkedKeys, setCheckedKeys] = useState([]);
   const [selectedItems, setSelectedItems] = useState([]);
   const [loadingFilters, setLoadingFilters] = useState(false)
   const [autoExpandParent, setAutoExpandParent] = useState(true);
   const onExpand = (expandedKeysValue) => {
      setExpandedKeys(expandedKeysValue);
      setAutoExpandParent(false);
   };

   const history = useHistory();
   const topicsArr = useSelector(state => state?.DashboardReducer?.searchState?.chosenTopTopic);

   useEffect(() => {
      let arr = [];
      for (let i = 0; i < topicsArr?.length; i++) {
         if (topicsArr[i].child) {
            arr.push({ id: topicsArr[i]?.id, child: topicsArr[i]?.child, parent: topicsArr[i]?.parent ? topicsArr[i]?.parent : '', type: "custom_all" });
         }
      }
      setSelectedItems(arr);
      setCheckedKeys(arr?.map(item => parseInt(item.id)));
   }, [topicsArr]);

   const selectChilds = (child, arr) => {
      for (let i = 0; i < child?.length; i++) {
         arr.push({ id: child[i].key, child: child[i].title, parent: child[i].parent_name ? child[i].parent_name : '', type: "custom_all" })
         if (child[i]?.children && child[i]?.children?.length !== 0) {
            return selectChilds(child[i].children, arr);
         }
      }
      setSelectedItems(arr.concat(selectedItems));
   }

   const removeChilds = (child, arr) => {
      for (let i = 0; i < child?.length; i++) {
         arr = arr.filter(item => item.id !== child[i].key)
         if (child[i]?.children && child[i]?.children?.length !== 0) {
            return removeChilds(child[i].children, arr);
         }
      }
      setSelectedItems(arr);
   }

   const onCheck = (checkedKeysValue, val) => {
      // setCheckedKeys(checkedKeysValue);
      // if (val.checked) {
      //    if (val?.node?.children && val?.node?.children?.length !== 0) {
      //       let arr = [];
      //       let child = val?.node?.children;
      //       for (let i = 0; i < child?.length; i++) {
      //          arr.push(child[i].key)
      //       }
      //       setCheckedKeys(checkedKeysValue);
      //       return;
      //    } else {
      //       setCheckedKeys(checkedKeysValue);
      //       return;
      //    }
      // } else {
      //    let arr = checkedKeysValue;
      //    let child = val?.node?.children;
      //    for (let i = 0; i < child?.length; i++) {
      //       arr.filter(item => item !== child[i].key)
      //    }
      //    setCheckedKeys(arr);
      // }

      setCheckedKeys(checkedKeysValue);
      if (val.checked) {
         if (val?.node?.children && val?.node?.children?.length !== 0) {
            let child = val?.node?.children;
            let arr = [];
            selectChilds(child, arr)

         } else {
            setSelectedItems([...selectedItems, { id: val?.node?.key, child: val?.node?.title, parent: val?.node?.parent_name ? val?.node?.parent_name : '', type: "custom_all" }])
         }
      } else {
         if (val?.node?.children && val?.node?.children?.length !== 0) {
            let child = val?.node?.children;
            let arr = selectedItems;
            removeChilds(child, arr);
         }
         else {
            setSelectedItems(selectedItems.filter(item => item.id !== val?.node?.key));
         }
      }
   };

   const getTopics = async () => {
      setLoadingFilters(true)
      setShowDropdownContent(true);

      const response = await apiRequest(
         'library/hierarchy',
         'POST',
         true
      );

      if (response.success) {
         setHierarchyItems(response?.success);
         setTempData(response?.success);
         renameObjectKeys(response?.success);
      } else {
         notification.error({
            message: response.error
         })
      }
      setLoadingFilters(false)


   }

   const renameObjectKeys = data => {
      data = data?.map(obj => {
         obj['key'] = obj['id'];
         obj['title'] = obj['name']
         delete obj['id'];
         delete obj['name'];
         if (obj.child?.length > 0) {
            obj['children'] = obj['child'];
            delete obj['child'];
            renameObjectKeys(obj?.children)
         } else return obj;
      })
   }

   // useEffect(() => {
   //    getTopics();
   // }, []);

   const getSearchItem = (list, str) => {
      return list.filter(obj => {
         return obj.title?.toLowerCase()?.includes(str);
      });
      // if (item.title?.toLowerCase().includes(str)) {
      //    return item;
      // } else if (item.children && item.children?.length > 0) {
      //    return getSearchItem(item.children, str, arr)
      // }

   }

   const handleSearch = (event) => {
      if (event.key === 'Enter') {
         if (searchValue?.trim()) {
            let arr = getSearchItem(hierarchyItems, searchValue?.toLowerCase());
            setHierarchyItems(arr);
         } else {
            setHierarchyItems(tempData)
         }

      }
   }
   const handleShowSearch = () => {
      setShowSearchBox(true);
   }
   const handleButtonClickSearch = () => {
      if (searchValue?.trim()) {
         let arr = getSearchItem(hierarchyItems, searchValue?.toLowerCase());
         setHierarchyItems(arr);
      } else {
         setHierarchyItems(tempData);
      }
   }

   // const handleCheckboxChange = (e) => {
   //    const { id, value } = e.target;

   //    if (selectedTopicIds?.filter(item => item.id === parseInt(id, 10))?.length !== 0) {
   //       setSelectedTopicIds(selectedTopicIds?.filter(item => parseInt(item.id, 10) !== parseInt(id, 10)));
   //    } else {
   //       setSelectedTopicIds([...selectedTopicIds, { label: value, id: parseInt(id, 10), type: "custom_all" }]);
   //    }
   // }


   const handleClearAll = () => {
      // let items = document.getElementsByClassName('check-box-topic-for-top-topics');
      // if (items?.length > 0) {
      //    for (let i = 0; i < items?.length; i++) {
      //       items[i].checked = false;
      //    }
      //    setSelectedTopicIds([]);
      // }
      setCheckedKeys([]);
      setSelectedItems([]);
   }

   const addTopics = (id, type, label) => {
      let newArr = topicsArr.filter(val => !val.parent)?.concat(selectedItems);
      dispatch({
         type: actions.UPDATE_SEARCH_STATE,
         payload: {
            chosenTopTopic: newArr,
         },
      });
   };

   const dropdownOutClick = (e) => {
      if (customTopicDropdownRef.current && !customTopicDropdownRef.current.contains(e.target)) {
         setShowDropdownContent(false)
      }
   }

   useEffect(() => {
      document.addEventListener('click', dropdownOutClick, true);
      return () => document.removeEventListener('click', dropdownOutClick, true)
   }, []);

   const handleTypeChange = (e) => {
      setViewType(e.target.value);
   }

   const handleChange = (val) => {
      console.log(val);
   }

   return (
      <div className={`${styles.selector} custom-topic-filter-mobile`} ref={customTopicDropdownRef}>
         <div className={styles.selectorContent} style={{ height: height === 'small' ? '32px' : 'auto' }} onClick={() => getTopics()}>
            <img src={"/assets/filter.svg"} alt="" />Filter
         </div>
         {showDropdownContent && <div className={styles.dropdownContent}>
            <div className={styles.categoryBlock}>
               <div className={styles.header}>
                  <p>Custom Topic Filter</p>
                  <img src={theme === 'dark' ? "/assets/x.svg" : "/assets/x_black.svg"} alt="" onClick={() => setShowDropdownContent(false)} />
               </div>
               {
                  !showSearchBox && (
                     <div className={styles.actionBtnBlock}>
                        <button className={styles.search} onClick={handleShowSearch}><img src={theme === 'dark' ? "/assets/search.svg" : "/assets/search_black.svg"} alt="" /></button>
                     </div>
                  )
               }
               {
                  showSearchBox && (
                     <div className={`${styles.searchBoxSmall} ${showSearchBox && styles.showSearch} `}>
                        <i onClick={() => {
                           setShowSearchBox(false);
                           setSearchValue("");
                           setHierarchyItems(tempData);
                        }}
                           className="fa-solid fa-arrow-left"></i>
                        <input type="text" placeholder={t("placeholder.2")} value={searchValue} onChange={e => setSearchValue(e.target.value)} onKeyPress={handleSearch} />
                        <button onClick={handleButtonClickSearch}>{t("button.5")}</button>
                     </div>
                  )
               }
               <div className={styles.btnRow}>
                  <span onClick={handleClearAll}>Clear all</span>
                  <button onClick={addTopics}>Apply</button>
               </div>
               <div className={styles.tagsWrapper}>
                  {selectedItems?.map(item => {
                     return <span key={item.id}>{item.parent ? `${item.parent} > ` : ''}{item.child}</span>
                  })}
               </div>
               <div className={styles.topicCategoryContainer}>
                  <Spin spinning={loadingFilters}>
                     {
                        loadingFilters ? <div className={styles.noTopics}></div> :
                           hierarchyItems?.length > 0 ?
                              <Tree
                                 checkable
                                 onExpand={onExpand}
                                 expandedKeys={expandedKeys}
                                 autoExpandParent={autoExpandParent}
                                 onCheck={onCheck}
                                 checkedKeys={checkedKeys}
                                 treeData={hierarchyItems}
                                 checkStrictly={false}
                              /> :
                              <div className={styles.noTopics}>
                                 <p className={styles.heading}>{t("empty_states.22")}<img src={theme === 'dark' ? "/assets/emptyStates/topic_library_icon.svg" : "/assets/emptyStates/topic_library_black.svg"} alt="" /></p>
                                 <div className={styles.secondRow}>
                                    <img src={theme === 'dark' ? "/assets/error_outline.svg" : "/assets/error_outline_black.svg"} alt="" />
                                    {t("empty_states.23")}
                                 </div>
                                 <div className={styles.imgBlock}>
                                    <div>
                                       <img src={theme === 'dark' ? "/assets/emptyStates/dashboard_custom_topic.png" : theme === 'light' ? "/assets/emptyStates/dashboard_custom_topic_light.png" : "/assets/emptyStates/dashboard_custom_topic_brand.png"} alt="" />
                                    </div>
                                 </div>
                                 <p className={styles.link} onClick={() => history.push(`/console/topicLibrary`)}>{t("empty_states.4")}</p>
                              </div>
                     }
                     {/* <Collapse className={styles.collapse} bordered={false} defaultActiveKey={['0']} accordion>
                        {hierarchyItems?.map((item, index) => (
                           <Collapse.Panel key={index + 1} showArrow={false} header={
                              <div className={styles.topicHeader}>
                                 {
                                    <input id={item.id} className={`${styles.topicCheckBox} check-box-topic-for-top-topics`} type="checkbox" onChange={handleCheckboxChange} value={item.name} onClick={e => e.stopPropagation()} />
                                 }
                                 <i className="fa-solid fa-chevron-right"></i>
                                 <span>{item.name}</span>
                              </div>
                           } collapsible={item?.child?.length > 0 ? 'header' : 'disabled'}>
                              {item.child?.length > 0 && getNestedChild(item.child)}
                           </Collapse.Panel>
                        ))}
                     </Collapse> */}
                  </Spin>
               </div>
            </div>
         </div>}
      </div>
   )
}

export default CustomTopicFilter;
