import React, { useState, useEffect, useRef } from "react";
import styles from "./style.module.scss";
import { notification, Button, Spin, Input, Menu, Dropdown } from "antd";
import { CloseOutlined, DownOutlined, PlusOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import DashboardHeader from "components/Dashboard/Header";
import { UNLOGGED } from "util/constants";
import { apiRequest } from "util/services";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import BoardCard from "components/Dashboard/BoardCard";
import LineChartWrapper from "components/Dashboard/LineChartWrapper";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bubble, Doughnut } from "react-chartjs-2";
import GaugeChart from "react-gauge-chart";
import ReviewsList from "components/Dashboard/ReviewsList";
import CreateTaskTrelloModal from "components/Dashboard/TaskModalTrello";
import CreateTaskClickupModal from "components/Dashboard/TaskModalClickup";
import CreateTaskJiraModal from "components/Dashboard/TaskModelJira";
import ScrollToReviews from "components/Dashboard/SelectedTopicInfo/ScrollToReviews";
import CustomInput from "components/General/CustomInput";
import HotTerms from "components/Dashboard/HotTerms";
import crayola from "stylesheets/crayola.json";
import { Modal, Modal as Modal1 } from "components/General/ModalFormComponents";
import NestedTabs from "components/NestedTabs";
import { useTranslation } from "react-i18next";
import TopTopics from "components/Dashboard/TopTopics";
import CustomTopicFilter from "components/Dashboard/CustomTopicFilter/index ";
import FileTypePopup from "helperComponents/FileTypePopup";
import MultiLevelDropdown from "helperComponents/MultiLevelSelect";
import BarChartWrapper from "components/Dashboard/BarChartWrapper";
import DataSelectionPopup from "components/General/DataSelectionPopup";
import { ResponsiveContainer } from "recharts";
import MLDropdown from "helperComponents/MLDropdown";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
const DashboardData = ({ match, shared, authenticated, user }) => {
  let timerating = true;
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const [allData, setAllData] = useState({});
  const [reviewsLoaded, setReviewsLoaded] = useState(false);
  const [highlights, setHighlights] = useState(null);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  if (!match.params.id && !DashboardReducer.dashboard) {
    history.push("/console/myDashboards");
  }

  let initialState = {
    currentDashboard: match.params.id || DashboardReducer.dashboard?.ID,
    restaurant: "All",
  };

  const IntentOptions = {
    cutoutPercentage: 65,
    responsive: true,
    maintainAspectRatio: false,
    onHover: (event) => {
      event.target.style.cursor = "pointer";
    },
    legend: {
      // display: false,
      color: theme === "dark" ? "white" : "black",
      position: window.innerWidth > 500 ? "right" : "top",
      labels: {
        boxWidth: 10,
        fontColor: theme === "dark" ? "white" : "black",
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    plugins: {
      datalabels: {
        display: true,
        font: {
          size: 9,
        },
        color: theme === "dark" ? "white" : "black",
        formatter: (value) => {
          return Math.round(value) + "%";
        },
      },
    },
  };

  const [localState, setState] = useState(initialState);
  const [reviewsLoading, setReviewsLoading] = useState(false);
  const [allReviews, setAllReviews] = useState([]);
  const [numReviews, setNumReviews] = useState();
  const [timeInterval, setTimeInterval] = useState(null);
  const [searchWord, setSearchWord] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [createTaskModal, setCreateTaskModal] = useState(false);
  const [insightTxt, setInsightTxt] = useState("");
  const [insightTitleTxt, setinsightTitleTxt] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const [Time_Daily_Rating, setTime_Daily_Rating] = useState(null);
  const [switchPeriod, setSwitchPeriod] = useState(false);
  const [usefulData, setUsefulData] = useState(0);
  const [rawData, setrawData] = useState(0);
  const [overalUsefulData, setOveralUsefulData] = useState(0);
  const [overalRawData, setOveralRawData] = useState(0);
  const [avgRating, setAvgRating] = useState(0);
  const [overalAvgRating, setOveralAvgRating] = useState(0);
  const [overalNps, setOveralNps] = useState(0);
  const [npScore, setnpScore] = useState(0);

  const [changeDateRange, setChangeDateRange] = useState(false);

  const [disableDateRange, setDisableDateRange] = useState([true, true]);

  const [participationPeriod, setParticipationPeriod] = useState(null);
  const [sentimentPeriod, setSentimentPeriod] = useState(null);
  const [ratingsPeriod, setratingsPeriod] = useState(null);
  const [participationPeriodLoading, setParticipationPeriodLoading] = useState(
    true
  );
  const [ratingPeriodLoading, setratingPeriodLoading] = useState(true);
  const [sentimentPeriodLoading, setSentimentPeriodLoading] = useState(true);
  const [volumeDaily, setVolumeDaily] = useState([]);
  const [sentimentDaily, setSentimentDaily] = useState([]);

  const [restaurantDoughnutData, setRestaurantDoughnutData] = useState([]);
  const [
    isRestaurantDoughnutDataLoading,
    setIsRestaurantDoughnutDataLoading,
  ] = useState(true);
  const [intentDoughnutData, setIntentDoughnutData] = useState([]);
  const [
    isIntentDoughnutDataLoading,
    setIsIntentDoughnutDataLoading,
  ] = useState(true);
  const [ratingDoughnutData, setRatingDoughnutData] = useState([]);
  const [
    isRatingDoughnutDataLoading,
    setIsRatingDoughnutDataLoading,
  ] = useState(true);
  const [sentimentDoughnutData, setSentimentDoughnutData] = useState([]);
  const [
    isSentimentDoughnutDataLoading,
    setIsSentimentDoughnutDataLoading,
  ] = useState(true);
  const [avgSentiment, setAvgSentiment] = useState(0);
  const [
    isAvgSentimentGaugeDataLoading,
    setIsAvgSentimentGaugeDataLoading,
  ] = useState(true);
  // const [facebookReplies, setFacebookReplies] = useState([]);
  const searchControllerRef = useRef();
  const userReducer = useSelector((state) => state.userReducer);

  const [platformData, setPlatformData] = useState({});
  const [
    isPlatformDoughnutDataLoading,
    setIsPlatformDoughnutDataLoading,
  ] = useState(true);
  // const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [numOfDocs, setNumOfDocs] = useState(10);
  const [pivotOptions, setPivotOptions] = useState(null);
  const [multiLevelOptions, setMultiLevelOptions] = useState(null);
  const [selectedPivots, setSelectedPivots] = useState({});
  const [filters, setFilters] = useState([]);
  const [topTopics, setTopTopics] = useState([]);
  const [hottermsData, setHottermsData] = useState({});
  const [hottermsLoading, setHottermsLoading] = useState(false);
  const [selectedTopTopics, setSelectedTopTopics] = useState([]);
  const [topTopicsLoading, setTopTopicsLoading] = useState(true);
  const [npsInfo, setNpsInfo] = useState(null);
  const [showFileTypePopup, setShowFileTypePopup] = useState(false);
  const [fileType, setFileType] = useState("csv");
  const [downloadDataLoading, setDownloadDataLoading] = useState(false);
  const [overLimitForHotterms, setOverLimitForHotterms] = useState(false);
  const downloadBtnRef = useRef();
  const { t } = useTranslation();
  const [topicRatingData, setTopicRatingData] = useState(null);
  const [topicSentimentData, setTopicSentimentData] = useState(null);
  const [topicRatingsLoading, setTopicRatingsLoading] = useState(true);
  const skipFirstLoadAPICall = useRef(true);
  const [checkedTopicIds, setCheckedTopicIds] = useState([]);
  const [selectedTopicIds, setSelectedTopicIds] = useState([]);
  const [createInsightLoading, setCreateInsightLoading] = useState(false);
  const [queryFilters, setQueryFilters] = useState({});
  const { search } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const [showPopupSequence, setShowPopupSequence] = useState(false);
  const [selectedOutcomeMetric, setSelectedOutcomeMetric] = useState(1);
  const [selectedCustomTopics, setSelectedCustomTopics] = useState([]);
  const [selectedPivotColumns, setSelectedPivotColumns] = useState([]);
  const [showDriverPopup, setShowDriverPopup] = useState(null);
  const [emptyDriverAnalysis, setEmptyDriverAnalysis] = useState(true);
  const [outcomeMetrics, setOutcomeMetrics] = useState([]);
  const [potentialDrivers, setPotentialDrivers] = useState([]);
  const [driverAnalysisData, setDriverAnalysisData] = useState([]);
  const [driverAnalysisLoading, setDriverAnalysisLoading] = useState(true);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [noDataForSelectedConfig, setNoDataForSelectedConfig] = useState(false);
  const [levelWisePivotSelections, setLevelWisePivotSelections] = useState({});
  const [zoomPlugin, setZoomPlugin] = useState({});
  const bubbleChart = useRef(null);
  const [zoomLevel, setZoomLevel] = useState(0);
  const [kdaScaleValues, setKDAScaleValues] = useState({});
  const [selectedTopicFromBars, setSelectedTopicFromBars] = useState({});
  const [actualDistributionData, setActualDistributionData] = useState({});
  const [selectedPivotsOperator, setSelectedPivotsOperator] = useState("AND");
  const [showAdditionalSearchPopup, setShowAdditionalSearchPopup] = useState(
    false
  );
  const [npsScale, setNpsScale] = useState([1, 10]);
  useEffect(() => {
    const handleResize = () => {
      setNpsScale((prev) => {
        return [prev[0], prev[1]];
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const [decisionData, setDecisionData] = useState([]);
  const [decisionDataLoading, setDecisionDataLoading] = useState(false);
  const [selectedDecisions, setSelectedDecisions] = useState({});
  const [cursors, setCursors] = useState({});
  const [cursorNext, setCursorNext] = useState("");
  const [backWardCursors, setBackwardCursors] = useState({ "-1": null });
  const handleClearAllTopTopics = () => {
    let items = document.getElementsByClassName("check-box-topic-for-filter");
    if (items?.length > 0) {
      for (let i = 0; i < items?.length; i++) {
        items[i].checked = false;
        items[i].indeterminate = false;
      }
      setSelectedTopicIds([]);
      setCheckedTopicIds([]);
    }
  };

  useEffect(() => {
    if (params) {
      const values = Array.from(params);
      const queryObj = values.reduce((acc, [key, val]) => {
        if (
          key === "keyword" ||
          key === "pivotFilter" ||
          key === "top_topics" ||
          key === "level1Selection" ||
          key === "level2Selection" ||
          key === "decisionFilter"
        ) {
          acc[key] = JSON.parse(val);
        } else {
          acc[key] = val;
        }
        return acc;
      }, {});
      setQueryFilters(queryObj);
    }
  }, [history, search]);

  const updateQParams = (attr, val) => {
    params.delete(attr);
    params.append(attr, val);
    history.push({ search: params.toString() });
  };

  useEffect(() => {
    // getSubscribtionStatus();
    if (authenticated === UNLOGGED && !shared) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reviewsLoading === false) {
      //enable date filtering
      setDisableDateRange([false, false]);
    } else {
      setDisableDateRange([true, true]);
    }
  }, [reviewsLoading]);
  useEffect(() => {
    setAllData({
      VolumeDaily: volumeDaily,
      SentimentDaily: sentimentDaily,
      RatingsDaily: Time_Daily_Rating,
    });
  }, [volumeDaily, sentimentDaily, Time_Daily_Rating]);
  useEffect(() => {
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: true,
      },
    });
    if (authenticated === UNLOGGED && !shared) {
      document.location.href = document.location.origin;
      return;
    }
    if (!localState.currentDashboard) {
      notification.error({
        message: t("notifications.42"),
      });
      history.push("/console/myDashboards");
      return;
    }
    const getDashboardID = async () => {
      const payload = {
        hashed: localState.currentDashboard,
      };
      const result = await apiRequest(
        "dashboards/share/deserialize",
        "POST",
        payload
      );
      if (result) {
        setState({ ...localState, currentDashboard: result.dashboard_id });
        checkDashboardStatus(result.dashboard_id);
      } else {
        notification.error({
          message: t("notifications.43"),
        });
        document.location.href = document.location.origin;
        return;
      }
    };
    if (shared) getDashboardID();
    else checkDashboardStatus(localState.currentDashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const runOnlyOneRef = useRef(true);
  useEffect(() => {
    if (
      DashboardReducer.dashboard &&
      searchControllerRef.current &&
      !reviewsLoading
    ) {
      if (runOnlyOneRef.current) {
        console.log(`Making search API related calls, on each filter change`);
        updateQParams("start_index", 0);
        getSearchResults(false, 0, true);
        runOnlyOneRef.current = false;
        setTopTopicsLoading(true);
        setParticipationPeriodLoading(true);
        setSentimentPeriodLoading(true);
        setratingPeriodLoading(true);
        setIsAvgSentimentGaugeDataLoading(true);
        setIsPlatformDoughnutDataLoading(true);
        setIsIntentDoughnutDataLoading(true);
        setIsRatingDoughnutDataLoading(true);
        setIsSentimentDoughnutDataLoading(true);
        setDriverAnalysisLoading(true);
        setDecisionDataLoading(true);
        return;
      }
      console.log(`Making search API related calls, on each filter change`);
      updateQParams("start_index", 0);
      setTopTopicsLoading(true);
      getSearchResults(false, 0, true);
      setParticipationPeriodLoading(true);
      setSentimentPeriodLoading(true);
      setratingPeriodLoading(true);
      setIsAvgSentimentGaugeDataLoading(true);
      setIsPlatformDoughnutDataLoading(true);
      setIsIntentDoughnutDataLoading(true);
      setIsRatingDoughnutDataLoading(true);
      setIsSentimentDoughnutDataLoading(true);
      setDriverAnalysisLoading(true);
      setDecisionDataLoading(true);
    }
  }, [queryFilters]);
  useEffect(() => {
    if (skipFirstLoadAPICall.current) {
      skipFirstLoadAPICall.current = false;
      return;
    } else {
      console.log(`Get only reviews on page size change`);
      getOnlyReviews(false, 0, true);
    }
  }, [numOfDocs]);
  useEffect(() => {
    if (DashboardReducer?.dashboard?.ID && volumeDaily.length > 0) {
      console.log(`get search results on switch period change`);
      getSearchResults(false, 1);
    }
  }, [switchPeriod]);
  useEffect(() => {
    if (!params.has("start_index")) {
      updateQParams("start_index", 0);
    }
    if (!params.has("page_offset")) {
      updateQParams("page_offset", 10);
    }
  }, []);

  const checkDashboardStatus = async (DashboardId) => {
    const result = await apiRequest(
      "dashboards/v2/get",
      "POST",
      {
        dashboard_id: parseInt(DashboardId, 10),
      },
      true
    );

    if (result.cancel || result.error) {
      setTopTopicsLoading(false);
      setParticipationPeriodLoading(false);
      setSentimentPeriodLoading(false);
      setratingPeriodLoading(false);
      setIsAvgSentimentGaugeDataLoading(false);
      setIsPlatformDoughnutDataLoading(false);
      setIsIntentDoughnutDataLoading(false);
      setIsRatingDoughnutDataLoading(false);
      setIsSentimentDoughnutDataLoading(false);
      setReviewsLoading(false);
      setIsRestaurantDoughnutDataLoading(false);
      setTopicRatingsLoading(false);
      notification.error({
        message: result.error ? result.error : "Unable to get dashboard status",
      });
      dispatch({
        type: actions.UPDATE_Dashboard,
        payload: {
          loading: false,
        },
      });
      dispatch({
        type: actions.SET_Dashboard,
        payload: {
          failureMsg: result.error,
        },
      });
    } else if (result.status.ID === -1 || result.status.ID === 5) {
      notification.info({
        message: t("notifications.44"),
        icon: (
          <InfoCircleOutlined
            style={{ color: theme === "dark" ? "white" : "black" }}
          />
        ),
      });
      history.push(`/console/myDashboards`);
    } else if (
      result.status.ID !== 4 &&
      result.status.ID !== 6 &&
      result.status.ID !== 7 &&
      !result?.dashboard?.IsDynamic
    ) {
      notification.info({
        message: t("notifications.45"),
        icon: (
          <InfoCircleOutlined
            style={{ color: theme === "dark" ? "white" : "black" }}
          />
        ),
      });
      history.push(`/console/journey/${initialState.currentDashboard}`);
    } else {
      if (
        DashboardReducer.insightBasedSearch &&
        location?.state?.searchInsight
      ) {
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            ...DashboardReducer.insightBasedSearch,
            selectedDaysRange: DashboardReducer?.insightBasedSearch
              ?.selectedDaysRange
              ? DashboardReducer.insightBasedSearch.selectedDaysRange
              : "custom",
          },
        });
        dispatch({
          type: actions.UPDATE_Dashboard,
          payload: {
            showNewReviewsLabel: true,
          },
        });
      }
      dispatch({
        type: actions.UPDATE_Dashboard,
        payload: {
          ...result,
          loading: true,
        },
      });
      if (
        !params.has("since") &&
        !params.has("until") &&
        params.get("daysRange") !== "allTime"
      ) {
        if (!result.end_date && !result.start_date) {
          updateQParams("daysRange", "allTime");
          dispatch({
            type: actions.UPDATE_SEARCH_STATE,
            payload: {
              ...result,
              selectedDaysRange: "allTime",
              dateRange: [],
            },
          });
        } else {
          // Create a dateRange array for last 7 days with the end_date
          const cd = new Date(
            DashboardReducer.dashboard?.RefreshRate
              ? new Date()
              : result.end_date
          );
          const pd = new Date(
            DashboardReducer.dashboard?.RefreshRate
              ? new Date()
              : result.end_date
          );
          cd.setDate(cd.getDate());
          pd.setDate(pd.getDate());
          pd.setDate(pd.getDate() - 1);
          const formattedCurrentDate = cd.toISOString().split("T")[0];
          const formattedPastDate = pd.toISOString().split("T")[0];
          const sinceAlreadyExists = params.get("since") === formattedPastDate;
          const untilAlreadyExists =
            params.get("until") === formattedCurrentDate;
          if (sinceAlreadyExists && formattedPastDate) {
            updateQParams("since", formattedPastDate);
          } else if (!sinceAlreadyExists && formattedPastDate) {
            updateQParams("since", formattedPastDate);
          }
          if (untilAlreadyExists && formattedCurrentDate) {
            updateQParams("until", formattedCurrentDate);
          } else if (!untilAlreadyExists && formattedCurrentDate) {
            updateQParams("until", formattedCurrentDate);
          }
          updateQParams("daysRange", "last24");
          history.push({ search: params.toString() });
          dispatch({
            type: actions.UPDATE_SEARCH_STATE,
            payload: {
              ...result,
              dateRange: [formattedPastDate, formattedCurrentDate],
            },
          });
        }
      }
      getBoardData(DashboardId);
    }
  };

  const getBoardData = async (dashboard_id) => {
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: false,
      },
    });
    // temporary solution
    const el = document.getElementById("load-reviews");
    el && el.click();
  };
  // const getFacebookReplies = async (resReviews = []) => {
  //   setReviewsLoading(true);
  //   const url = "dashboards/get_facebook_replies";
  //   const payload = {
  //     comment_ids: (resReviews.length > 0 ? resReviews : allReviews).map(
  //       ({ DocId }) => {
  //         return DocId;
  //       }
  //     ),
  //     firebase_id: DashboardReducer.dashboard.UserId,
  //   };
  //   const result = await apiRequest(url, "POST", payload, true);
  //   if (result.success) {
  //     setFacebookReplies(result.success);
  //   } else {
  //     notification.error({
  //       message: result.error,
  //     });
  //   }
  //   setReviewsLoading(false);
  // };
  const getTopTopics = async (updateIndex, flag = 0, reset) => {
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: false,
      },
    });
    const {
      sentiment,
      intent,
      since,
      until,
      rating,
      top_topics,
      topics_operator,
      platform_id,
      match_date,
      hotterm,
      nps,
      keyword,
      pivotFilter,
      pivots_operator,
      decisionFilter,
      sortByDate,
    } = queryFilters;

    let url = "dashboards/v2/search/top_topics";
    const payload = {
      start_index: reset
        ? 0
        : updateIndex
        ? updateIndex > 1
          ? updateIndex
          : startIndex + updateIndex
        : 0,
      num_docs: numOfDocs,
      dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10),
    };
    if (since && until) {
      payload.since = since;
      payload.until = until;
    }
    if (sentiment) {
      payload.sentiment = sentiment;
    }
    if (intent) {
      payload.intent = intent;
    }
    if (rating) {
      payload.rating = rating;
    }
    if (hotterm) {
      payload.hotterm = hotterm;
    }
    if (keyword && keyword.length > 0) {
      payload.keyword = keyword;
    }
    if (match_date) {
      payload.match_date = match_date;
    }
    if (platform_id) {
      payload.platform_id = platform_id;
    }
    if (nps) {
      payload.nps = nps;
    }
    if (
      pivotFilter &&
      Object.keys(pivotFilter).length > 0 &&
      Object.values(pivotFilter)?.flat().length > 0
    ) {
      payload.pivotFilter = pivotFilter;
      payload.pivots_operator = pivots_operator;
    }
    if (decisionFilter && Object.keys(decisionFilter)?.length > 0) {
      payload.decisionFilter = decisionFilter;
    }
    const selectedTpcs = {};
    for (let level = 1; level >= 1; level++) {
      if (params.has(`level${level}Selection`)) {
        const levelSelection = JSON.parse(params.get(`level${level}Selection`));
        selectedTpcs[level] = levelSelection;
      } else {
        break;
      }
    }

    const maxLevel = Math.max(...Object.keys(selectedTpcs));
    if (maxLevel > 0) {
      payload.topic_id = selectedTpcs[maxLevel]?.id;
    }
    if (top_topics && top_topics.length > 0) {
      payload.top_topics = top_topics;
      payload.topics_operator = topics_operator ? topics_operator : "AND";
    }
    if (switchPeriod && participationPeriod) {
      payload.volume_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && sentimentPeriod) {
      payload.sentiment_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && ratingsPeriod) {
      payload.ratings_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && sentimentPeriod) {
      payload.sentiment_period = sentimentPeriod.toLowerCase();
    }

    if (switchPeriod && ratingsPeriod) {
      payload.ratings_period = ratingsPeriod.toLowerCase();
    }
    // if (sortByDate) payload.sortByDate = true;
    const result = await apiRequest(
      url,
      "POST",
      payload,
      true,
      searchControllerRef.current?.signal
    );

    if (result.cancel || result?.error || result.validation_error) {
      notification.error({
        message: result.error
          ? result.error
          : "Unable to get top topics results",
      });
      setTopTopicsLoading(false);
      setParticipationPeriodLoading(false);
      setSentimentPeriodLoading(false);
      setratingPeriodLoading(false);
      setIsAvgSentimentGaugeDataLoading(false);
      setIsPlatformDoughnutDataLoading(false);
      setIsIntentDoughnutDataLoading(false);
      setIsRatingDoughnutDataLoading(false);
      setIsSentimentDoughnutDataLoading(false);
      setReviewsLoading(false);
      setIsRestaurantDoughnutDataLoading(false);
      setTopicRatingsLoading(false);
    }

    if (!result.error && !result.cancel) {
      setTopTopics(result);
    }
    setTopTopicsLoading(false);
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: false,
      },
    });
  };
  const generatePivotAnalysis = (data) => {
    if (data && Object.keys(data)?.length > 0) {
      const normalizedFilteredData = getNormalizedData(data.filtered);
      const normalizedInitialData = getNormalizedData(data.initial);
      setActualDistributionData(normalizedInitialData);
      initRestaurantDoughnutData(normalizedFilteredData, normalizedInitialData);
    }
  };
  const getNormalizedData = (data) => {
    if (data && Object.keys(data)?.length > 0) {
      const keys = Object.keys(data);
      const totalCounts = {};
      const normalizedData = {};

      keys.forEach((key) => {
        totalCounts[key] = Object.values(data[key]).reduce((a, b) => a + b, 0);
      });

      // Normalize the data
      keys.forEach((key) => {
        normalizedData[key] = {};
        for (const subKey in data[key]) {
          normalizedData[key][subKey] = {
            count: data[key][subKey],
            percentage: Math.round(
              (data[key][subKey] / totalCounts[key]) * 100
            ),
          };
        }
      });
      return normalizedData;
    }
  };
  const getHotterms = async () => {
    setHottermsLoading(true);
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: false,
      },
    });
    const ngramsArr = [1, 2, 3, 4];
    const dataObj = {};
    const promises = ngramsArr.map(async (n_words) => {
      const {
        sentiment,
        intent,
        since,
        until,
        rating,
        top_topics,
        topics_operator,
        platform_id,
        match_date,
        hotterm,
        nps,
        keyword,
        pivotFilter,
        pivots_operator,
        decisionFilter,
        sortByDate,
      } = queryFilters;

      let url = "dashboards/v2/get/hotterm";
      const payload = {
        dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10),
        n_words: n_words,
      };
      if (since && until) {
        payload.since = since;
        payload.until = until;
      }
      if (sentiment) {
        payload.sentiment = sentiment;
      }
      if (intent) {
        payload.intent = intent;
      }
      if (rating) {
        payload.rating = rating;
      }
      if (hotterm) {
        payload.hotterm = hotterm;
      }
      if (keyword && keyword.length > 0) {
        payload.keyword = keyword;
      }
      if (match_date) {
        payload.match_date = match_date;
      }
      if (platform_id) {
        payload.platform_id = platform_id;
      }
      if (nps) {
        payload.nps = nps;
      }
      if (
        pivotFilter &&
        Object.keys(pivotFilter).length > 0 &&
        Object.values(pivotFilter)?.flat().length > 0
      ) {
        payload.pivotFilter = pivotFilter;
        payload.pivots_operator = pivots_operator;
      }
      if (decisionFilter && Object.keys(decisionFilter)?.length > 0) {
        payload.decisionFilter = decisionFilter;
      }
      const selectedTpcs = {};
      for (let level = 1; level >= 1; level++) {
        if (params.has(`level${level}Selection`)) {
          const levelSelection = JSON.parse(
            params.get(`level${level}Selection`)
          );
          selectedTpcs[level] = levelSelection;
        } else {
          break;
        }
      }

      const maxLevel = Math.max(...Object.keys(selectedTpcs));
      if (maxLevel > 0) {
        payload.topic_id = selectedTpcs[maxLevel]?.id;
      }
      if (top_topics && top_topics.length > 0) {
        payload.top_topics = top_topics;
        payload.topics_operator = topics_operator ? topics_operator : "AND";
      }
      if (switchPeriod && participationPeriod) {
        payload.volume_period = switchPeriod.toLowerCase();
      }
      if (switchPeriod && sentimentPeriod) {
        payload.sentiment_period = switchPeriod.toLowerCase();
      }
      if (switchPeriod && ratingsPeriod) {
        payload.ratings_period = switchPeriod.toLowerCase();
      }
      if (switchPeriod && sentimentPeriod) {
        payload.sentiment_period = sentimentPeriod.toLowerCase();
      }

      if (switchPeriod && ratingsPeriod) {
        payload.ratings_period = ratingsPeriod.toLowerCase();
      }
      // if (sortByDate) payload.sortByDate = true;

      const result = await apiRequest(
        url,
        "POST",
        payload,
        true,
        searchControllerRef.current?.signal
      );

      if (result && !result.error && !result.cancel) {
        if (result?.data && result.data === -1) {
          setOverLimitForHotterms(true);
          return;
        } else {
          dataObj[n_words] = result;
          setOverLimitForHotterms(false);
          return;
        }
      } else {
        setHottermsLoading(false);
        dataObj[n_words] = [];
        return;
      }
    });
    await Promise.all(promises);
    setHottermsData(dataObj);
    setHottermsLoading(false);
  };

  const getSearchResults = async (updateIndex, flag = 0, reset) => {
    setReviewsLoaded(false);
    setBackwardCursors({ "-1": null });
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: false,
      },
    });
    const {
      sentiment,
      intent,
      since,
      until,
      rating,
      top_topics,
      topics_operator,
      platform_id,
      match_date,
      hotterm,
      nps,
      keyword,
      pivotFilter,
      pivots_operator,
      decisionFilter,
      sortByDate,
    } = queryFilters;

    const platformId = DashboardReducer?.dashboard?.PlatformId;
    // const isFacebookPlatform =
    //   flag === 0 && (platformId === 9 || platformId === 10);
    setTopTopicsLoading(true);
    if (flag === 0) {
      setReviewsLoading(true);
      !updateIndex && setIsRestaurantDoughnutDataLoading(true);
      if (searchControllerRef.current) {
        searchControllerRef.current.abort();
      }
      const controller = new AbortController();
      searchControllerRef.current = controller;
    }
    if (flag === 1) {
      setParticipationPeriodLoading(true);
      setSentimentPeriodLoading(true);
    }
    let url = "dashboards/v2/search";
    const payload = {
      start_index: reset
        ? 0
        : updateIndex
        ? updateIndex > 1
          ? updateIndex
          : startIndex + updateIndex
        : 0,
      num_docs: numOfDocs,
      dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10),
    };
    if (since && until) {
      payload.since = since;
      payload.until = until;
    }
    if (sentiment) {
      payload.sentiment = sentiment;
    }
    if (intent) {
      payload.intent = intent;
    }
    if (rating) {
      payload.rating = rating;
    }
    if (hotterm) {
      payload.hotterm = hotterm;
    }
    if (keyword && keyword.length > 0) {
      payload.keyword = keyword;
    }
    if (match_date) {
      payload.match_date = match_date;
    }
    if (platform_id) {
      payload.platform_id = platform_id;
    }
    if (nps) {
      payload.nps = nps;
    }
    if (
      pivotFilter &&
      Object.keys(pivotFilter).length > 0 &&
      Object.values(pivotFilter)?.flat().length > 0
    ) {
      payload.pivotFilter = pivotFilter;
      payload.pivots_operator = pivots_operator;
    }
    if (decisionFilter && Object.keys(decisionFilter)?.length > 0) {
      payload.decisionFilter = decisionFilter;
    }
    const selectedTpcs = {};
    for (let level = 1; level >= 1; level++) {
      if (params.has(`level${level}Selection`)) {
        const levelSelection = JSON.parse(params.get(`level${level}Selection`));
        selectedTpcs[level] = levelSelection;
      } else {
        break;
      }
    }

    const maxLevel = Math.max(...Object.keys(selectedTpcs));
    if (maxLevel > 0) {
      payload.topic_id = selectedTpcs[maxLevel]?.id;
    }
    if (top_topics && top_topics.length > 0) {
      payload.top_topics = top_topics;
      payload.topics_operator = topics_operator ? topics_operator : "AND";
    }
    if (switchPeriod && participationPeriod) {
      payload.volume_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && sentimentPeriod) {
      payload.sentiment_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && ratingsPeriod) {
      payload.ratings_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && sentimentPeriod) {
      payload.sentiment_period = sentimentPeriod.toLowerCase();
    }

    if (switchPeriod && ratingsPeriod) {
      payload.ratings_period = ratingsPeriod.toLowerCase();
    }
    // if (sortByDate) payload.sortByDate = true;

    // Get Top Topics and Search results simultaneously
    getOnlyReviews(updateIndex, 0, reset);
    getTopTopics();
    getHotterms();
    getTopicRating();
    getKeyDriverAnalysis();
    if ([33, 34, 35].includes(DashboardReducer?.dashboard?.PlatformId)) {
      getDecisionData();
    }
    const result = await apiRequest(
      url,
      "POST",
      payload,
      true,
      searchControllerRef.current?.signal
    );
    // let triggerFacebookReplies = false;
    if (result.cancel || result?.error || result.validation_error) {
      notification.error({
        message: result.error ? result.error : "Unable to get search results",
      });
      setTopTopicsLoading(false);
      setParticipationPeriodLoading(false);
      setSentimentPeriodLoading(false);
      setratingPeriodLoading(false);
      setIsAvgSentimentGaugeDataLoading(false);
      setIsPlatformDoughnutDataLoading(false);
      setIsIntentDoughnutDataLoading(false);
      setIsRatingDoughnutDataLoading(false);
      setIsSentimentDoughnutDataLoading(false);
      setReviewsLoading(false);
      setIsRestaurantDoughnutDataLoading(false);
      setTopicRatingsLoading(false);
    }

    if (!result.error && !result.cancel) {
      setPivotOptions(result.pivot_options ? result.pivot_options : {});
      setVolumeDaily(result.VolumeDaily || []);
      setSentimentDaily(result.SentimentDaily || []);
      setAvgSentiment(result.avgSentiment || 0);
      if (result.pivotData) {
        generatePivotAnalysis(result.pivotData);
      }
      initIntentDoughnutData((result || {}).intentPercents || []);
      initRatingDoughnutData((result || {}).ratingPercents || []);
      initSentimentDoughnutData((result || {}).sentimentPercents || []);
      if (result?.VolumeDaily?.length > 0) {
        const period = determinePeriod(result?.VolumeDaily[0]?.day);
        setratingsPeriod(period);
        setSentimentPeriod(period);
        setParticipationPeriod(period);
      }
      // triggerFacebookReplies =
      //   isFacebookPlatform && ((result || {}).docs || []).length > 0;
      // if (triggerFacebookReplies) {
      //   getFacebookReplies(result.docs);
      // }
      initPlatformDoughnutData((result || {}).PlatformCount || []);
      setTime_Daily_Rating(result?.RatingsDaily);
      setratingPeriodLoading(false);
      setNpsInfo(result?.nps_dist);
      const scale = result?.npsScale;
      if (scale) {
        setNpsScale([
          parseInt(scale.split(" to ")[0], 10),
          parseInt(scale.split(" to ")[1], 10),
        ]);
      }
      setNumReviews(result.total_num_docs);
      setrawData(result.total_num_docs || 0);
      if (result?.nps) {
        setnpScore(result?.nps || 0);
      }
      if (result?.avgRating) {
        setAvgRating(result?.avgRating || 0);
      }
    }
    // else {
    //   notification.error({
    //     message: result.error,
    //   });
    // }
    setParticipationPeriodLoading(false);
    setSentimentPeriodLoading(false);
    setratingPeriodLoading(false);
    setIsRestaurantDoughnutDataLoading(false);
    setIsIntentDoughnutDataLoading(false);
    setIsRatingDoughnutDataLoading(false);
    setIsSentimentDoughnutDataLoading(false);
    setIsAvgSentimentGaugeDataLoading(false);
    setIsPlatformDoughnutDataLoading(false);
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: false,
      },
    });
  };

  const getOnlyReviews = async (updateIndex, flag = 0, reset) => {
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: false,
      },
    });
    const values = Array.from(params);
    const queryObj = values.reduce((acc, [key, val]) => {
      if (
        key === "keyword" ||
        key === "pivotFilter" ||
        key === "top_topics" ||
        key === "level1Selection" ||
        key === "level2Selection" ||
        key === "decisionFilter"
      ) {
        acc[key] = JSON.parse(val);
      } else {
        acc[key] = val;
      }
      return acc;
    }, {});
    const {
      sentiment,
      intent,
      since,
      until,
      rating,
      top_topics,
      topics_operator,
      platform_id,
      match_date,
      hotterm,
      nps,
      keyword,
      pivotFilter,
      pivots_operator,
      decisionFilter,
      sortByDate,
      page_offset,
      start_index,
      cursor_curr,
    } = queryObj;

    const platformId = DashboardReducer?.dashboard?.PlatformId;
    // const isFacebookPlatform =
    //   flag === 0 && (platformId === 9 || platformId === 10);
    if (flag === 0) {
      setReviewsLoading(true);
      if (searchControllerRef.current) {
        searchControllerRef.current.abort();
      }
      const controller = new AbortController();
      searchControllerRef.current = controller;
    }
    let url = "dashboards/v2/get/reviews";
    const payload = {
      start_index: reset ? 0 : parseInt(start_index, 10),
      num_docs: parseInt(page_offset, 10),
      dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10),
    };
    if (cursor_curr && cursor_curr !== "null") {
      payload.cursor_next = cursor_curr;
    }
    if (since && until) {
      payload.since = since;
      payload.until = until;
    }
    if (sentiment) {
      payload.sentiment = sentiment;
    }
    if (intent) {
      payload.intent = intent;
    }
    if (rating) {
      payload.rating = rating;
    }
    if (hotterm) {
      payload.hotterm = hotterm;
    }
    if (keyword && keyword.length > 0) {
      payload.keyword = keyword;
    }
    if (match_date) {
      payload.match_date = match_date;
    }
    if (platform_id) {
      payload.platform_id = platform_id;
    }
    if (nps) {
      payload.nps = nps;
    }
    if (
      pivotFilter &&
      Object.keys(pivotFilter).length > 0 &&
      Object.values(pivotFilter)?.flat().length > 0
    ) {
      payload.pivotFilter = pivotFilter;
      payload.pivots_operator = pivots_operator;
    }
    if (decisionFilter && Object.keys(decisionFilter)?.length > 0) {
      payload.decisionFilter = decisionFilter;
    }
    const selectedTpcs = {};
    for (let level = 1; level >= 1; level++) {
      if (params.has(`level${level}Selection`)) {
        const levelSelection = JSON.parse(params.get(`level${level}Selection`));
        selectedTpcs[level] = levelSelection;
      } else {
        break;
      }
    }

    const maxLevel = Math.max(...Object.keys(selectedTpcs));
    if (maxLevel > 0) {
      payload.topic_id = selectedTpcs[maxLevel]?.id;
    }
    if (top_topics && top_topics.length > 0) {
      payload.top_topics = top_topics;
      payload.topics_operator = topics_operator ? topics_operator : "AND";
    }
    if (switchPeriod && participationPeriod) {
      payload.volume_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && sentimentPeriod) {
      payload.sentiment_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && ratingsPeriod) {
      payload.ratings_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && sentimentPeriod) {
      payload.sentiment_period = sentimentPeriod.toLowerCase();
    }

    if (switchPeriod && ratingsPeriod) {
      payload.ratings_period = ratingsPeriod.toLowerCase();
    }
    // if (sortByDate) payload.sortByDate = true;

    const result = await apiRequest(
      url,
      "POST",
      payload,
      true,
      searchControllerRef.current?.signal
    );
    // let triggerFacebookReplies = false;
    if (
      result.cancel ||
      result?.error ||
      Object.keys(result)?.length === 0 ||
      result.validation_error
    ) {
      notification.error({
        message: result.error ? result.error : "Loading reviews failed",
      });
      setTopTopicsLoading(false);
      setParticipationPeriodLoading(false);
      setSentimentPeriodLoading(false);
      setratingPeriodLoading(false);
      setIsAvgSentimentGaugeDataLoading(false);
      setIsPlatformDoughnutDataLoading(false);
      setIsIntentDoughnutDataLoading(false);
      setIsRatingDoughnutDataLoading(false);
      setIsSentimentDoughnutDataLoading(false);
      setReviewsLoading(false);
      setIsRestaurantDoughnutDataLoading(false);
      setTopicRatingsLoading(false);
      return;
    }

    if (!result.error && !result.cancel) {
      if (flag === 0) {
        setAllReviews(result.docs);
        setTimeInterval(result.timeInterval);
        setStartIndex(reset ? 0 : start_index);
      }
      setCursors({
        prev: result.cursor_before,
        next: result.cursor_next,
      });
      setBackwardCursors((prev) => {
        return {
          ...prev,
          [start_index]: result.cursor_next,
        };
      });
      const backWard_ids = {
        ...backWardCursors,
        [start_index]: result.cursor_next,
      };
      updateQParams("cursor_next", result.cursor_next);
      if (start_index === "0") {
        updateQParams("cursor_before", null);
      } else {
        const b_cursor =
          backWard_ids[(parseInt(start_index, 10) - 2).toString()];
        setBackwardCursors((prev) => {
          return {
            ...prev,
            [(parseInt(start_index, 10) - 2).toString()]:
              b_cursor !== undefined ? b_cursor : params.get("cursor_before"),
          };
        });
        updateQParams(
          "cursor_before",
          b_cursor !== undefined ? b_cursor : params.get("cursor_before")
        );
      }
    }
    setReviewsLoaded(true);
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: false,
      },
    });
  };
  function convertSentimentToPercentage(data) {
    const convertItem = (item) => {
      const { negative, neutral, positive, unspecified } = item.sentiment;
      const total =
        (negative || 0) + (neutral || 0) + (positive || 0) + (unspecified || 0);
      if (total > 0) {
        const sentiment = {
          negative: {
            percentage: parseFloat(
              (((negative || 0) / total) * 100).toFixed(2),
              10
            ),
            value: negative || 0,
          },
          neutral: {
            percentage: parseFloat(
              (((neutral || 0) / total) * 100).toFixed(2),
              10
            ),
            value: neutral || 0,
          },
          positive: {
            percentage: parseFloat(
              (((positive || 0) / total) * 100).toFixed(2),
              10
            ),
            value: positive || 0,
          },
          unspecified: {
            percentage: parseFloat(
              (((unspecified || 0) / total) * 100).toFixed(2),
              10
            ),
            value: unspecified || 0,
          },
        };

        if (item.child) {
          item.child = item.child.map(convertItem);
        }

        return {
          ...item,
          sentiment,
        };
      } else {
        return {
          ...item,
          sentiment: {
            negative: {
              percentage: 0,
              value: 0,
            },
            neutral: {
              percentage: 0,
              value: 0,
            },
            positive: {
              percentage: 0,
              value: 0,
            },
            unspecified: {
              percentage: 0,
              value: 0,
            },
          },
        };
      }
    };

    return data.map(convertItem);
  }
  const convertIntoRatingData = (data) => {
    const convertItem = (item) => {
      const rating =
        Object.keys(item.rating).length > 0
          ? Object.keys(item.rating)
              .map((x) => parseInt(x, 10))
              .reduce((acc, curr) => acc + curr, 0) /
            Object.keys(item.rating).length
          : 0;
      const reviewsCount = Object.values(item.rating)
        .map((x) => parseInt(x, 10))
        .reduce((acc, curr) => acc + curr, 0);
      if (item.child) {
        item.child = item.child.map(convertItem);
      }
      return {
        ...item,
        rating: {
          value: Math.round(rating),
          reviewsCount: reviewsCount,
        },
      };
    };
    return data.map(convertItem);
  };
  const getTopicRating = async (topicId = null) => {
    setTopicRatingsLoading(true);
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: false,
      },
    });
    const {
      sentiment,
      intent,
      since,
      until,
      rating,
      top_topics,
      topics_operator,
      platform_id,
      match_date,
      hotterm,
      nps,
      keyword,
      pivotFilter,
      pivots_operator,
      decisionFilter,
      sortByDate,
    } = queryFilters;

    let url = "dashboards/v2/get/sent-rating";
    const payload = {
      dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10),
    };
    if (since && until) {
      payload.since = since;
      payload.until = until;
    }
    if (sentiment) {
      payload.sentiment = sentiment;
    }
    if (intent) {
      payload.intent = intent;
    }
    if (rating) {
      payload.rating = rating;
    }
    if (hotterm) {
      payload.hotterm = hotterm;
    }
    if (keyword && keyword.length > 0) {
      payload.keyword = keyword;
    }
    if (match_date) {
      payload.match_date = match_date;
    }
    if (platform_id) {
      payload.platform_id = platform_id;
    }
    if (nps) {
      payload.nps = nps;
    }
    if (
      pivotFilter &&
      Object.keys(pivotFilter).length > 0 &&
      Object.values(pivotFilter)?.flat().length > 0
    ) {
      payload.pivotFilter = pivotFilter;
      payload.pivots_operator = pivots_operator;
    }
    if (decisionFilter && Object.keys(decisionFilter)?.length > 0) {
      payload.decisionFilter = decisionFilter;
    }
    const selectedTpcs = {};
    for (let level = 1; level >= 1; level++) {
      if (params.has(`level${level}Selection`)) {
        const levelSelection = JSON.parse(params.get(`level${level}Selection`));
        selectedTpcs[level] = levelSelection;
      } else {
        break;
      }
    }

    const maxLevel = Math.max(...Object.keys(selectedTpcs));
    if (maxLevel > 0) {
      payload.topic_id = selectedTpcs[maxLevel]?.id;
    }
    if (top_topics && top_topics.length > 0) {
      payload.top_topics = top_topics;
      payload.topics_operator = topics_operator ? topics_operator : "AND";
    }
    if (switchPeriod && participationPeriod) {
      payload.volume_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && sentimentPeriod) {
      payload.sentiment_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && ratingsPeriod) {
      payload.ratings_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && sentimentPeriod) {
      payload.sentiment_period = sentimentPeriod.toLowerCase();
    }

    if (switchPeriod && ratingsPeriod) {
      payload.ratings_period = ratingsPeriod.toLowerCase();
    }
    // if (sortByDate) payload.sortByDate = true;

    const result = await apiRequest(
      url,
      "POST",
      payload,
      true,
      searchControllerRef.current?.signal
    );

    if (result.cancel || result.error || result.validation_error) {
      notification.error({
        message: result?.error ? result.error : "Unable to get topics rating",
      });
      setTopicRatingsLoading(false);
    }

    if (!result.error && !result.cancel) {
      const convertedValuesIntoPercentages = convertSentimentToPercentage(
        result
      );
      const convertedRatings = convertIntoRatingData(result);
      const sortedRat = [...convertedRatings].sort((a, b) => {
        return b.rating?.value - a.rating?.value;
      });
      const sortedSent = [...convertedValuesIntoPercentages].sort((a, b) => {
        const totalA =
          a.sentiment?.negative?.value +
          a.sentiment?.neutral?.value +
          a.sentiment?.positive?.value +
          a.sentiment?.unspecified?.value;
        const totalB =
          b.sentiment?.negative?.value +
          b.sentiment?.neutral?.value +
          b.sentiment?.positive?.value +
          b.sentiment?.unspecified?.value;
        return totalB - totalA;
      });
      setTopicRatingData(sortedRat);
      setTopicSentimentData(adjustSentiments(sortedSent));
      setTopicRatingsLoading(false);
    }
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        loading: false,
      },
    });
  };
  const getHighlights = async (allReviews) => {
    const url = "dashboards/highlights";
    const payload = {
      review_ids: allReviews?.map((review) => review.date_gpid),
      dashboard_id: parseInt(DashboardReducer?.dashboard?.ID, 10),
    };
    const result = await apiRequest(url, "POST", payload, true);
    if (result?.success) {
      setHighlights(result?.success);
    } else {
      notification.error({
        message: result?.error
          ? result.error
          : "Unable to get reviews highlights",
      });
    }
    setReviewsLoading(false);
  };
  useEffect(() => {
    if (reviewsLoaded && allReviews) {
      getHighlights(allReviews);
    }
  }, [reviewsLoaded, allReviews]);
  const platformIdsANdNamesMap = {
    "1": "Twitter",
    "2": "Google Play",
    "4": "App Store",
    "5": "Şikayet Var",
    "6": "Instagram Hashtags",
    "9": "Facebook Page",
    "10": "Facebook and Instagram ads",
    "14": "Trendyol",
    "17": "Amazon",
    "18": "Reddit",
    "19": "Ekşi Sözlük",
    "27": "Instagram Account Posts",
    "29": "TikTok",
    "31": "Papara Topluluk",
    "32": "LinkedIn",
    "33": "ACS",
    "34": "Otelpuan",
    "35": "Anket",
  };
  const platformNamesAndIdsMap = {
    Twitter: "1",
    "Google Play": "2",
    "App Store": "4",
    "Şikayet Var": "5",
    "Instagram Hashtags": "6",
    "Facebook Page": "9",
    "Facebook and Instagram ads": "10",
    Trendyol: "14",
    Amazon: "17",
    Reddit: "18",
    "Ekşi Sözlük": "19",
    "Instagram Account Posts": "27",
    TikTok: "29",
    "Papara Topluluk": "31",
    LinkedIn: "32",
    ACS: "33",
    Otelpuan: "34",
    Anket: "35",
  };
  const handleDoughnutClick = (item, type) => {
    if (item && !reviewsLoading) {
      // const datasetIndex = item._datasetIndex;
      const index = item._index;

      if (type === "intent") {
        updateQParams("intent", intentDoughnutData.ids[index]);
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            chosenIntent: intentDoughnutData.ids[index],
          },
        });
      } else if (type === "sentiment") {
        let sentiment = "0.5";
        if (sentimentDoughnutData.labels[index] === "Positive")
          sentiment = "1.0";
        else if (sentimentDoughnutData.labels[index] === "Negative")
          sentiment = "0.0";
        else if (sentimentDoughnutData.labels[index] === "Mixed")
          sentiment = "33";
        updateQParams("sentiment", sentiment);
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            chosenSentiment: sentiment,
          },
        });
      } else if (type === "rating") {
        updateQParams("rating", ratingDoughnutData.labels[index].toLowerCase());
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            chosenDocRating: ratingDoughnutData.labels[index].toLowerCase(),
          },
        });
      } else if (type === "platform") {
        let platform_id = "-1";
        if (platformNamesAndIdsMap[platformData?.labels[index]]) {
          platform_id = platformNamesAndIdsMap[platformData?.labels[index]];
        }
        updateQParams("platform_id", platform_id);
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            chosenPlatformName: platformData.labels[index],
          },
        });
      }
      dispatch({
        type: actions.UPDATE_Dashboard,
        payload: {
          showNewReviewsLabel: true,
        },
      });
    }
  };

  const handleSearchWord = (tag, checked, isFromReviewsSection) => {
    searchControllerRef.current = new AbortController();
    const selectedTags = params.has("keyword")
      ? JSON.parse(params.get("keyword"))
      : [];
    if (tag && checked && selectedTags.length >= 1) {
      setShowAdditionalSearchPopup(true);
      return;
    }
    const nextSelectedTags = checked
      ? tag
        ? [...selectedTags, { label: tag, type: "search" }]
        : selectedTags
      : selectedTags.filter((t) => t.label !== tag);
    params.delete("keyword");
    params.append("keyword", JSON.stringify(nextSelectedTags));
    history.push({ search: params.toString() });
    dispatch({
      type: actions.UPDATE_SEARCH_STATE,
      payload: {
        chosenKeywords: nextSelectedTags,
      },
    });
    setSearchWord("");
    if (!isFromReviewsSection) {
      dispatch({
        type: actions.UPDATE_Dashboard,
        payload: {
          showNewReviewsLabel: true,
        },
      });
    }
  };

  const getSearchState = () => {
    const {
      chosenKeywords,
      chosenSentiment,
      chosenDate,
      chosenIntent,
      chosenHotTerm,
      chosenDocRating,
    } = DashboardReducer.searchState;
    const payload = {};
    if (chosenKeywords.length !== 0) {
      payload.chosenKeywords = chosenKeywords;
    }
    if (chosenDate) {
      payload.chosenDate = chosenDate;
    }
    if (chosenSentiment) {
      payload.chosenSentiment = chosenSentiment;
    }
    if (chosenIntent) {
      payload.chosenIntent = chosenIntent;
    }
    if (chosenHotTerm) {
      payload.chosenHotTerm = chosenHotTerm;
    }
    if (chosenDocRating) {
      payload.chosenDocRating = chosenDocRating;
    }
    return payload;
  };

  const handleCreateInsight = async () => {
    if (!insightTxt) {
      notification.error({ message: t("notifications.46") });
      return;
    }
    setCreateInsightLoading(true);
    const searchState = {
      ...DashboardReducer?.searchState,
      chosenDate: params.has("match_date") ? params.get("match_date") : "",
      chosenDocRating: params.has("rating") ? params.get("rating") : "",
      chosenHotTerm: params.has("hotterm") ? params.get("hotterm") : "",
      chosenIntent: params.has("intent") ? params.get("intent") : "",
      chosenKeywords: params.has("keyword")
        ? JSON.parse(params.get("keyword"))
        : [],
      chosenNPS: params.has("nps") ? params.get("nps") : "",
      chosenPlatformName: params.has("platform_id")
        ? platformIdsANdNamesMap[params.get("platform_id")]
        : "",
      chosenSentiment: params.has("sentiment") ? params.get("sentiment") : "",
      chosenTopTopic: params.has("top_topics")
        ? JSON.parse(params.get("top_topics"))
        : [],
      dateRange:
        params.has("since") && params.has("until")
          ? [params.get("since"), params.get("until")]
          : [],
      selectedDaysRange: params.has("daysRange")
        ? params.get("daysRange")
        : DashboardReducer?.searchState?.selectedDaysRange,
      topicsOperator:
        params.has("top_topics") && params.has("topics_operator")
          ? params.get("topics_operator")
          : "OR",
      pivotFilter: params.has("pivotFilter")
        ? JSON.parse(params.get("pivotFilter"))
        : {},
      pivots_operator:
        params.has("pivotFilter") && params.has("pivots_operator")
          ? params.get("pivots_operator")
          : "AND",
      decisionFilter: params.has("decisionFilter")
        ? JSON.parse(params.get("decisionFilter"))
        : {},
    };
    const ratingTpcs = {};
    for (let l = 1; l >= 1; l++) {
      if (params.has(`level${l}Selection`)) {
        const levelSelection = JSON.parse(params.get(`level${l}Selection`));
        ratingTpcs[`level${l}Selection`] = levelSelection;
      } else {
        break;
      }
    }
    // if(Object.keys(searchState).length === 0) {
    //   notification.error({
    //     message: "Please apply some filtering"
    //   });
    // }
    const payload = {
      dashboard_id: initialState.currentDashboard,
      insight: insightTxt,
      insight_title: insightTitleTxt,
      searchState: { ...searchState, ...ratingTpcs },
      timeInterval,
      total_num_docs: numReviews,
      reporter: userReducer.IsExpert ? 2 : 1,
    };
    const {
      sentiment,
      intent,
      since,
      until,
      rating,
      top_topics,
      topics_operator,
      platform_id,
      match_date,
      hotterm,
      nps,
      keyword,
      pivotFilter,
      pivots_operator,
      decisionFilter,
      sortByDate,
    } = queryFilters;
    const search_payload = {
      dashboard_id: parseInt(initialState.currentDashboard, 10),
      start_index: 0,
      num_docs: numOfDocs,
    };
    if (since && until) {
      search_payload.since = since;
      search_payload.until = until;
    }
    if (sentiment) {
      search_payload.sentiment = sentiment;
    }
    if (intent) {
      search_payload.intent = intent;
    }
    if (rating) {
      search_payload.rating = rating;
    }
    if (hotterm) {
      search_payload.hotterm = hotterm;
    }
    if (keyword && keyword.length > 0) {
      search_payload.keyword = keyword;
    }
    if (match_date) {
      search_payload.match_date = match_date;
    }
    if (platform_id) {
      search_payload.platform_id = platform_id;
    }
    if (nps) {
      search_payload.nps = nps;
    }
    if (
      pivotFilter &&
      Object.keys(pivotFilter).length > 0 &&
      Object.values(pivotFilter)?.flat().length > 0
    ) {
      search_payload.pivotFilter = pivotFilter;
      search_payload.pivots_operator = pivots_operator || "AND";
    }
    if (decisionFilter && Object.keys(decisionFilter)?.length > 0) {
      search_payload.decisionFilter = decisionFilter;
    }
    const selectedTpcs = {};
    for (let level = 1; level >= 1; level++) {
      if (params.has(`level${level}Selection`)) {
        const levelSelection = JSON.parse(params.get(`level${level}Selection`));
        selectedTpcs[level] = levelSelection;
      } else {
        break;
      }
    }

    const maxLevel = Math.max(...Object.keys(selectedTpcs));
    if (maxLevel > 0) {
      payload.topic_id = selectedTpcs[maxLevel]?.id;
    }
    if (top_topics && top_topics.length > 0) {
      search_payload.top_topics = top_topics;
      search_payload.topics_operator = topics_operator
        ? topics_operator
        : "AND";
    }
    if (switchPeriod && participationPeriod) {
      search_payload.volume_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && sentimentPeriod) {
      search_payload.sentiment_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && ratingsPeriod) {
      search_payload.ratings_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && sentimentPeriod) {
      search_payload.sentiment_period = sentimentPeriod.toLowerCase();
    }

    if (switchPeriod && ratingsPeriod) {
      search_payload.ratings_period = ratingsPeriod.toLowerCase();
    }
    // if (sortByDate) search_payload.sortByDate = true;
    if (Object.keys(search_payload)?.length > 0) {
      payload.search_payload = search_payload;
    }
    const result = await apiRequest(
      "dashboards/insights/create",
      "POST",
      payload,
      true
    );
    if (result.cancel || result.error || result.validation_error) {
      notification.error({
        message: result?.error ? result.error : "Failed to post Insight",
      });
    } else {
      notification.success({
        message: (
          <span>
            Insight created successfully.
            <br />
            Go to{" "}
            <a
              onClick={() => {
                history.push({
                  pathname: `/console/insights/${DashboardReducer.dashboard?.ID}`,
                });
              }}
            >
              insights page
            </a>
          </span>
        ),
      });
    }
    setCreateInsightLoading(false);
    setCreateModal(false);
    // getInsights(DashboardId);
    setInsightTxt("");
  };

  const downloadData = async () => {
    notification.success({
      message: (
        <span>
          {t("notifications.47")}.
          <br />
          Go to{" "}
          <a
            onClick={() => {
              history.push({
                pathname: `/console/report`,
              });
            }}
          >
            downloads page
          </a>
        </span>
      ),
    });
    setShowFileTypePopup(false);
    const payload = {
      firebase_id: DashboardReducer.dashboard.UserId,
      dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10),
      type: fileType,
    };

    const {
      sentiment,
      intent,
      since,
      until,
      rating,
      top_topics,
      topics_operator,
      platform_id,
      match_date,
      hotterm,
      nps,
      keyword,
      pivotFilter,
      pivots_operator,
      decisionFilter,
      sortByDate,
    } = queryFilters;

    if (since && until) {
      payload.since = since;
      payload.until = until;
    }
    if (sentiment) {
      payload.sentiment = sentiment;
    }
    if (intent) {
      payload.intent = intent;
    }
    if (rating) {
      payload.rating = rating;
    }
    if (hotterm) {
      payload.hotterm = hotterm;
    }
    if (keyword && keyword.length > 0) {
      payload.keyword = keyword;
    }
    if (match_date) {
      payload.match_date = match_date;
    }
    if (platform_id) {
      payload.platform_id = platform_id;
    }
    if (nps) {
      payload.nps = nps;
    }
    if (
      pivotFilter &&
      Object.keys(pivotFilter).length > 0 &&
      Object.values(pivotFilter)?.flat().length > 0
    ) {
      payload.pivotFilter = pivotFilter;
      payload.pivots_operator = pivots_operator;
    }
    if (decisionFilter && Object.keys(decisionFilter)?.length > 0) {
      payload.decisionFilter = decisionFilter;
    }
    const selectedTpcs = {};
    for (let level = 1; level >= 1; level++) {
      if (params.has(`level${level}Selection`)) {
        const levelSelection = JSON.parse(params.get(`level${level}Selection`));
        selectedTpcs[level] = levelSelection;
      } else {
        break;
      }
    }

    const maxLevel = Math.max(...Object.keys(selectedTpcs));
    if (maxLevel > 0) {
      payload.topic_id = selectedTpcs[maxLevel]?.id;
    }
    if (top_topics && top_topics.length > 0) {
      payload.top_topics = top_topics;
      payload.topics_operator = topics_operator ? topics_operator : "AND";
    }
    if (switchPeriod && participationPeriod) {
      payload.volume_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && sentimentPeriod) {
      payload.sentiment_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && ratingsPeriod) {
      payload.ratings_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && sentimentPeriod) {
      payload.sentiment_period = sentimentPeriod.toLowerCase();
    }

    if (switchPeriod && ratingsPeriod) {
      payload.ratings_period = ratingsPeriod.toLowerCase();
    }
    // if (sortByDate) payload.sortByDate = true;
    const result = await apiRequest(
      "dashboards/v2/get_download_link",
      "POST",
      payload,
      true,
      searchControllerRef.current?.signal
    );
  };

  const initRatingDoughnutData = async (rating_percent_obj) => {
    if (rating_percent_obj) {
      const datas = [];
      const labels = [];
      const backgroundColors = [];
      const hoverBackgroundColors = [];
      if ("0" in rating_percent_obj && rating_percent_obj["0"]) {
        datas.push(rating_percent_obj["0"]);
        labels.push("0");
        backgroundColors.push("#59009C");
        hoverBackgroundColors.push(hexToRGBA("#59009C", 0.6));
      }
      if ("1" in rating_percent_obj && rating_percent_obj["1"]) {
        datas.push(rating_percent_obj["1"]);
        labels.push("1");
        backgroundColors.push("#6E6980");
        hoverBackgroundColors.push(hexToRGBA("#6E6980", 0.6));
      }
      if ("2" in rating_percent_obj && rating_percent_obj["2"]) {
        datas.push(rating_percent_obj["2"]);
        labels.push("2");
        backgroundColors.push("#2900D5");
        hoverBackgroundColors.push(hexToRGBA("#2900D5", 0.6));
      }
      if ("3" in rating_percent_obj && rating_percent_obj["3"]) {
        datas.push(rating_percent_obj["3"]);
        labels.push("3");
        backgroundColors.push("#F774A3");
        hoverBackgroundColors.push(hexToRGBA("#F774A3", 0.6));
      }
      if ("4" in rating_percent_obj && rating_percent_obj["4"]) {
        datas.push(rating_percent_obj["4"]);
        labels.push("4");
        backgroundColors.push("#007C82");
        hoverBackgroundColors.push(hexToRGBA("#007C82", 0.6));
      }
      if ("5" in rating_percent_obj && rating_percent_obj["5"]) {
        datas.push(rating_percent_obj["5"]);
        labels.push("5");
        backgroundColors.push("#066CF8");
        hoverBackgroundColors.push(hexToRGBA("#066CF8", 0.6));
      }
      if ("6" in rating_percent_obj && rating_percent_obj["6"]) {
        datas.push(rating_percent_obj["6"]);
        labels.push("6");
        backgroundColors.push("#33C4D4");
        hoverBackgroundColors.push(hexToRGBA("#33C4D4", 0.6));
      }
      if ("7" in rating_percent_obj && rating_percent_obj["7"]) {
        datas.push(rating_percent_obj["7"]);
        labels.push("7");
        backgroundColors.push("#B71288");
        hoverBackgroundColors.push(hexToRGBA("#B71288", 0.6));
      }
      if ("8" in rating_percent_obj && rating_percent_obj["8"]) {
        datas.push(rating_percent_obj["8"]);
        labels.push("8");
        backgroundColors.push("#E070EF");
        hoverBackgroundColors.push(hexToRGBA("#E070EF", 0.6));
      }
      if ("9" in rating_percent_obj && rating_percent_obj["9"]) {
        datas.push(rating_percent_obj["9"]);
        labels.push("9");
        backgroundColors.push("#836DDE");
        hoverBackgroundColors.push(hexToRGBA("#836DDE", 0, 6));
      }
      if ("10" in rating_percent_obj && rating_percent_obj["10"]) {
        datas.push(rating_percent_obj["10"]);
        labels.push("10");
        backgroundColors.push("#1c8220");
        hoverBackgroundColors.push("rgba(255, 0, 0, 0.5)");
      }

      const ratingDoughnutData = {
        maintainAspectRatio: false,
        responsive: false,
        text: "Rating",
        labels: labels,
        datasets: [
          {
            data: datas,
            backgroundColor: backgroundColors,
            hoverBackgroundColor: hoverBackgroundColors,
          },
        ],
      };
      setRatingDoughnutData(ratingDoughnutData);
    }
  };
  const intentMapping = {
    "-1": "Unspecified",
    "1": "Appraisal",
    "2": "Complaint",
    "3": "Suggestion",
    "4": "Remark",
    "5": "Marketing & Spam",
    "6": "Information",
    "7": "Request",
    "8": "Comparison",
    "33": "Mixed",
    others: "Others",
  };
  const intentColors = {
    "-1": "#6E6980",
    "1": "#671D9E",
    "2": "#DE7070",
    "3": "#46ABBF",
    "4": "#0027FF",
    "5": "#CCCCCC",
    "6": "#46ABBF",
    "7": "#FF0C32",
    "8": "#D6336B",
    "33": "#C4DFE6",
    others: "#DE46BF",
    // "#0C3F54",
    // "#C9A479",
    // "#9BC3DE",
    // "#140A80",
    // "#E7EDEF"
  };

  const initIntentDoughnutData = async (intent_percent_obj) => {
    setIsIntentDoughnutDataLoading(true);
    if (intent_percent_obj && Object.keys(intent_percent_obj).length > 0) {
      const datas = [];
      const labels = [];
      const backgroundColors = [];
      const hoverBackgroundColors = [];
      Object.keys(intent_percent_obj).map((key) => {
        datas.push(intent_percent_obj[key]);
        labels.push(intentMapping[key]);
        backgroundColors.push(hexToRGBA(intentColors[key], 0.8));
        hoverBackgroundColors.push(hexToRGBA(intentColors[key], 0.5));
      });
      const intentDoughnutData = {
        maintainAspectRatio: false,
        responsive: true,
        text: "intent",
        labels: labels,
        ids: Object.keys(intent_percent_obj),
        datasets: [
          {
            data: datas,
            backgroundColor: backgroundColors,
            hoverBackgroundColor: hoverBackgroundColors,
          },
        ],
      };
      setIntentDoughnutData(intentDoughnutData);
    }
  };

  const colors = [
    "#4400AA",
    "#FF5280",
    "#9E1684",
    "#4582C4",
    "#5C2A70",
    "#D41C1C",
    "#D2B5F2",
    "#56DEC8",
    "#87050D",
    "#836DDE",
    "#FF056D",
    "#BD02FF",
    "#6E6980",
    "#671D9E",
  ];
  const hexToRGBA = (hex, opacity) => {
    hex = hex?.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };
  const RGBAToHex = (rgba) => {
    const rgbaValues = rgba.match(/\d+/g);
    const r = ("0" + parseInt(rgbaValues[0]).toString(16)).slice(-2);
    const g = ("0" + parseInt(rgbaValues[1]).toString(16)).slice(-2);
    const b = ("0" + parseInt(rgbaValues[2]).toString(16)).slice(-2);
    return `#${r}${g}${b}`;
  };
  const restaurantRef = useRef({});
  useEffect(() => {
    for (let i = 0; i >= 0; i++) {
      if (restaurantRef.current[i]?.chartInstance) {
        restaurantRef.current[i].chartInstance["filtersApplied"] =
          filters.length > 0;
        restaurantRef.current[0].chartInstance.update();
      } else {
        break;
      }
    }
  }, [filters, isRestaurantDoughnutDataLoading, reviewsLoading]);
  const [restaurantColorMap, setRestaurantColorMap] = useState({});
  const initRestaurantDoughnutData = async (filteredData, actualData) => {
    if (
      filteredData &&
      Object.keys(filteredData)?.length > 0 &&
      actualData &&
      Object.keys(actualData)?.length > 0
    ) {
      let doughnutDataArray = Object.keys(filteredData).map((key) => {
        const restaurant_obj = filteredData[key];
        const act_obj = actualData[key];
        if (restaurant_obj) {
          const restaurant_arr = [];
          const act_arr = [];
          let total = 0;
          const counts = [];
          Object.keys(restaurant_obj).map((key) => {
            const value = restaurant_obj[key];
            total += value;
            restaurant_arr.push([key, value]);
          });
          restaurant_arr.sort(function(a, b) {
            return b[1].percentage - a[1].percentage;
          });
          const data = [];
          const act_data = [];
          const act_counts = [];
          const labels = [];
          const backgroundColor = [];
          const hoverBackgroundColor = [];
          const threshold = 7;
          const k = Math.min(threshold, restaurant_arr.length);
          const totalCount = restaurant_arr.reduce((acc, curr) => {
            return acc + curr[1].count;
          }, 0);
          let x = 0;
          let y = 0;
          for (let i = 0; i < k; i++) {
            let color = "";
            if (i === k - 1 && k === threshold) {
              if (restaurantColorMap["Others"]) {
                color = restaurantColorMap["Others"];
              } else {
                color = colors[i % colors.length];
                setRestaurantColorMap((prev) => {
                  return { ...prev, ["Others"]: color };
                });
              }
              backgroundColor.push(hexToRGBA(color, 1.0));
              hoverBackgroundColor.push(hexToRGBA(color, 0.6));
              data.push(100 - x);
              labels.push("Others");
              counts.push(totalCount - y);
            } else {
              const label = restaurant_arr[i][0];
              if (restaurantColorMap[label]) {
                color = restaurantColorMap[label];
              } else {
                color = colors[i % colors.length];
                setRestaurantColorMap((prev) => {
                  return { ...prev, [label]: color };
                });
              }
              backgroundColor.push(hexToRGBA(color, 1.0));
              hoverBackgroundColor.push(hexToRGBA(color, 0.6));
              x += restaurant_arr[i][1].percentage;
              y += restaurant_arr[i][1].count;
              data.push(restaurant_arr[i][1].percentage);
              labels.push(label);
              counts.push(restaurant_arr[i][1].count);
            }
          }
          let abc = 0;
          let xyz = 0;
          labels.map((label, i) => {
            const totalActualCount = Object.values(act_obj).reduce(
              (total, curr) => total + curr.count,
              0
            );
            if (i === 6 && label === "Others") {
              act_counts.push(totalActualCount - xyz);
              act_data.push(100 - abc);
            } else {
              abc += act_obj[label].percentage;
              xyz += act_obj[label].count;
              act_data.push(act_obj[label].percentage);
              act_counts.push(act_obj[label].count);
            }
          });
          const doughnutData = {
            datasets: [
              {
                backgroundColor: backgroundColor,
                data: data,
                actualData: act_data,
                actualCounts: act_counts,
                hoverBackgroundColor: hoverBackgroundColor,
                counts: counts,
              },
            ],
            labels: labels,
            maintainAspectRatio: false,
            responsive: true,
            text: "restaurant",
            doughnutLabel: key,
          };
          return doughnutData;
        }
      });
      setRestaurantDoughnutData(doughnutDataArray);
    }
  };
  const initDecisionChartsData = (data) => {
    if (data?.length > 0) {
      const dataArr = data.map((decision) => {
        const labels =
          decision.decision === "publish"
            ? ["Do not Publish", "Publish"]
            : decision.decision === "opencase"
            ? ["Do not open case", "Open case"]
            : ["Do not take action", "Take action"];
        const distribution = decision.dist;
        if (distribution.True + distribution.False === 0) {
          return {
            labels,
            datasets: [
              {
                data: [0, 0],
                counts: [0, 0],
              },
            ],
            doughnutLabel: decision.decision,
          };
        }
        const truePerc =
          (distribution.True / (distribution.True + distribution.False)) * 100;
        const falsePerc =
          (distribution.False / (distribution.True + distribution.False)) * 100;
        const datasets = [
          {
            data: [falsePerc.toFixed(1), truePerc.toFixed(1)],
            backgroundColor:
              decision.decision === "publish"
                ? [hexToRGBA("#3A6D8C", 1), hexToRGBA("#41B3A2", 1)]
                : decision.decision === "opencase"
                ? [hexToRGBA("#D91656", 1), hexToRGBA("704264", 1)]
                : [hexToRGBA("#304463", 1), hexToRGBA("#758694", 1)],
            hoverBackgroundColor:
              decision.decision === "publish"
                ? [hexToRGBA("#3A6D8C", 0.8), hexToRGBA("#41B3A2", 0.8)]
                : decision.decision === "opencase"
                ? [hexToRGBA("#D91656", 0.8), hexToRGBA("#704264", 0.8)]
                : [hexToRGBA("#304463", 0.8), hexToRGBA("#758694", 0.8)],
            counts: [distribution.False, distribution.True],
          },
        ];
        return {
          labels,
          datasets,
          text: "decision",
          doughnutLabel: decision.decision,
        };
      });
      const sumOfDataValues = dataArr.map((item) =>
        item.datasets[0].data.reduce((acc, curr) => acc + parseInt(curr, 10), 0)
      );
      if (sumOfDataValues.every((sum) => sum === 0)) {
        setDecisionData([]);
      } else {
        setDecisionData(dataArr);
      }
    } else {
      setDecisionData([]);
    }
  };

  const initSentimentDoughnutData = (sentiment_percent_obj = {}) => {
    if (sentiment_percent_obj) {
      const datas = [];
      const labels = [];
      const backgroundColors = [];
      const hoverBackgroundColors = [];
      if (
        "positive" in sentiment_percent_obj &&
        sentiment_percent_obj["positive"]
      ) {
        datas.push(sentiment_percent_obj["positive"]);
        labels.push("Positive");
        backgroundColors.push("rgba(16, 200, 0, 1)");
        hoverBackgroundColors.push("rgba(0, 255, 0, 0.5)");
      }
      if (
        "neutral" in sentiment_percent_obj &&
        sentiment_percent_obj["neutral"]
      ) {
        datas.push(sentiment_percent_obj["neutral"]);
        labels.push("Neutral");
        backgroundColors.push("rgb(255, 255, 0)");
        hoverBackgroundColors.push("rgba(255, 255, 0, 0.5)");
      }
      if (
        "negative" in sentiment_percent_obj &&
        sentiment_percent_obj["negative"]
      ) {
        datas.push(sentiment_percent_obj["negative"]);
        labels.push("Negative");
        backgroundColors.push("rgba(220, 37, 37, 1)");
        hoverBackgroundColors.push("rgba(255, 0, 0, 0.5)");
      }
      if (
        "unspecified" in sentiment_percent_obj &&
        sentiment_percent_obj["unspecified"] > 1
      ) {
        datas.push(sentiment_percent_obj["unspecified"]);
        labels.push("Unspecified");
        backgroundColors.push("rgb(255,140,0)");
        hoverBackgroundColors.push("rgb(255,140,0)");
      }
      if (
        "mixed" in sentiment_percent_obj &&
        sentiment_percent_obj["mixed"] > 1
      ) {
        datas.push(sentiment_percent_obj["mixed"]);
        labels.push("Mixed");
        backgroundColors.push(hexToRGBA("#FB6542", 1));
        hoverBackgroundColors.push(hexToRGBA("#FB6542", 0.7));
      }
      const sentimentdoughnutData = {
        maintainAspectRatio: false,
        responsive: false,
        text: "Sentiment",
        labels: labels,
        datasets: [
          {
            data: datas,
            backgroundColor: backgroundColors,
            hoverBackgroundColor: hoverBackgroundColors,
          },
        ],
      };
      setSentimentDoughnutData(sentimentdoughnutData);
    }
  };
  const initPlatformDoughnutData = (platform_obj = {}) => {
    const datas = [];
    const labels = [];
    const backgroundColors = [];
    const hoverBackgroundColors = [];
    if ("1" in platform_obj && platform_obj["1"]) {
      datas.push(platform_obj["1"]);
      labels.push("Twitter");
      backgroundColors.push("#E070EF");
      hoverBackgroundColors.push("#E070EF");
    }
    if ("2" in platform_obj && platform_obj["2"]) {
      datas.push(platform_obj["2"]);
      labels.push("Google Play");
      backgroundColors.push("rgba(254,71,128, 0.7)");
      hoverBackgroundColors.push("rgba(254,71,128, 0.5)");
    }
    if ("4" in platform_obj && platform_obj["4"]) {
      datas.push(platform_obj["4"]);
      labels.push("App Store");
      backgroundColors.push("rgba(61,133,198, 0.7)");
      hoverBackgroundColors.push("rgba(61,133,198, 0.5)");
    }
    if ("6" in platform_obj && platform_obj["6"]) {
      datas.push(platform_obj["6"]);
      labels.push("Instagram Hashtags");
      backgroundColors.push("#C018D6");
      hoverBackgroundColors.push("#C018D6");
    }
    if ("9" in platform_obj && platform_obj["9"]) {
      datas.push(platform_obj["9"]);
      labels.push("Facebook Page");
      backgroundColors.push("#C018D6");
      hoverBackgroundColors.push("#C018D6");
    }
    if ("10" in platform_obj && platform_obj["10"]) {
      datas.push(platform_obj["10"]);
      labels.push("Facebook and Instagram Ads");
      backgroundColors.push("#C018D6");
      hoverBackgroundColors.push("#C018D6");
    }
    if ("14" in platform_obj && platform_obj["14"]) {
      datas.push(platform_obj["14"]);
      labels.push("Trendyol");
      backgroundColors.push("#1B031F");
      hoverBackgroundColors.push("#1B031F");
    }
    if ("17" in platform_obj && platform_obj["17"]) {
      datas.push(platform_obj["17"]);
      labels.push("Amazon");
      backgroundColors.push("#891199");
      hoverBackgroundColors.push("#891199");
    }
    if ("5" in platform_obj && platform_obj["5"]) {
      datas.push(platform_obj["5"]);
      labels.push("Şikayet Var");
      backgroundColors.push("#652CB3");
      hoverBackgroundColors.push("#652CB3");
    }
    if ("18" in platform_obj && platform_obj["18"]) {
      datas.push(platform_obj["18"]);
      labels.push("Reddit");
      backgroundColors.push("#d896ff");
      hoverBackgroundColors.push("#d896ff");
    }
    if ("19" in platform_obj && platform_obj["19"]) {
      datas.push(platform_obj["19"]);
      labels.push("Ekşi Sözlük");
      backgroundColors.push("#800080");
      hoverBackgroundColors.push("#800080");
    }
    if ("27" in platform_obj && platform_obj["27"]) {
      datas.push(platform_obj["27"]);
      labels.push("Instagram Account Posts");
      backgroundColors.push("#962fbf");
      hoverBackgroundColors.push("#962fbf");
    }
    if ("29" in platform_obj && platform_obj["29"]) {
      datas.push(platform_obj["29"]);
      labels.push("TikTok");
      backgroundColors.push("#000000");
      hoverBackgroundColors.push("#962fbf");
    }
    if ("31" in platform_obj && platform_obj["31"]) {
      datas.push(platform_obj["31"]);
      labels.push("Papara Topluluk");
      backgroundColors.push("#800080");
      hoverBackgroundColors.push("#800080");
    }
    if ("32" in platform_obj && platform_obj["32"]) {
      datas.push(platform_obj["32"]);
      labels.push("LinkedIn");
      backgroundColors.push("#891199");
      hoverBackgroundColors.push("#891199");
    }
    if ("33" in platform_obj && platform_obj["33"]) {
      datas.push(platform_obj["33"]);
      labels.push("ACS");
      backgroundColors.push("#C018D6");
      hoverBackgroundColors.push("#C018D6");
    }
    if ("34" in platform_obj && platform_obj["34"]) {
      datas.push(platform_obj["34"]);
      labels.push("Otelpuan");
      backgroundColors.push("#1B031F");
      hoverBackgroundColors.push("#1B031F");
    }
    if ("35" in platform_obj && platform_obj["35"]) {
      datas.push(platform_obj["35"]);
      labels.push("Anket");
      backgroundColors.push("#891199");
      hoverBackgroundColors.push("#891199");
    }
    const platformData = {
      maintainAspectRatio: false,
      responsive: true,
      text: "Platform",
      labels: labels,
      datasets: [
        {
          data: datas,
          backgroundColor: backgroundColors,
          hoverBackgroundColor: hoverBackgroundColors,
        },
      ],
    };
    setPlatformData(platformData);
  };

  const generateDateString = (date, period) => {
    let x;
    if (period === "Daily" || period === "Weekly") {
      x =
        date.getFullYear() +
        "-" +
        (String(date.getMonth() + 1).length === 1 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (String(date.getDate()).length === 1 ? "0" : "") +
        date.getDate();
    } else if (period === "Monthly") {
      x =
        date.getFullYear() +
        "-" +
        (String(date.getMonth() + 1).length === 1 ? "0" : "") +
        (date.getMonth() + 1);
    } else {
      x = String(date.getFullYear());
    }
    return x;
  };

  const determinePeriod = (val) => {
    try {
      let identifier = (val.match(/-/g) || []).length;
      return identifier === 0
        ? "Yearly"
        : identifier === 1
        ? "Monthly"
        : "Daily";
    } catch (e) {
      console.error("Error", e);
      return "Monthly";
    }
  };

  const handleTimePeriodOptions = (val, flag) => {
    if (!reviewsLoading) {
      setParticipationPeriod(val);
      setSentimentPeriod(val);
      setratingPeriodLoading(true);
      setratingsPeriod(val);
      setSwitchPeriod(val);
    }
  };

  const timePeriodOptions = (flag) => {
    const itemStyle = {
      color: theme === "dark" ? "#fff" : "#000",
      backgroundColor: theme === "dark" ? "#1C193B" : "#ffffff",
    };
    return (
      <Menu
        style={{ backgroundColor: theme === "dark" ? "#1C193B" : "#ffffff" }}
        onClick={(e) => handleTimePeriodOptions(e.key, flag)}
      >
        <Menu.Item key="Daily" style={itemStyle}>
          {t("selected_dashboard_page.1")}
        </Menu.Item>
        {/* <Menu.Item key="Weekly" style={itemStyle}>
          Weekly
        </Menu.Item> */}
        <Menu.Item key="Monthly" style={itemStyle}>
          {t("selected_dashboard_page.2")}
        </Menu.Item>
        <Menu.Item key="Yearly" style={itemStyle}>
          {t("selected_dashboard_page.3")}
        </Menu.Item>
      </Menu>
    );
  };
  const handleResetTopicFilters = () => {
    for (let l = 1; l >= 1; l++) {
      if (params.has(`level${l}Selection`)) {
        params.delete(`level${l}Selection`);
        history.push({ search: params.toString() });
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            [`selectedL${l}Topic`]: null,
          },
        });
      } else {
        break;
      }
    }
  };
  const handleResetFilter = () => {
    handleResetTopicFilters();
    handleResetPivotFilters();
    handleClearAllTopTopics();
    [
      "sentiment",
      "intent",
      "since",
      "until",
      "daysRange",
      "rating",
      "top_topics",
      "topics_operator",
      "platform_id",
      "match_date",
      "hotterm",
      "nps",
      "keyword",
      "pivotFilter",
      "decisionFilter",
      "cursor_next",
      "cursor_curr",
      "cursor_before",
    ].forEach((item) => params.delete(item));
    params.append("daysRange", "allTime");
    history.push({ search: params.toString() });
    dispatch({
      type: actions.UPDATE_SEARCH_STATE,
      payload: {
        chosenKeywords: [],
        chosenSentiment: "",
        chosenDate: "",
        chosenFetchType: "topic",
        sortByDate: false,
        chosenIntent: "",
        chosenHotTerm: "",
        chosenDocRating: "",
        showNewReviewsLabel: false,
        chosenRestaurantName: "",
        chosenPlatformName: "",
        chosenTopTopic: [],
        topicsOperator: "AND",
        chosenNPS: "",
        dateRange: [],
        pivotFilter: {},
        pivots_operator: null,
        selectedL1Topic: "",
        selectedL2Topic: "",
        level1OfSelectedTopic: "",
        level2OfSelectedTopic: "",
      },
    });
    dispatch({
      type: actions.SELECT_TOPIC,
      payload: {
        selected_topic: undefined,
        insightBasedSearch: {},
        trends: {},
      },
    });
  };

  const handleNPSFilter = (label) => {
    params.delete("nps");
    params.append("nps", label);
    history.push({ search: params.toString() });
    dispatch({
      type: actions.UPDATE_SEARCH_STATE,
      payload: {
        chosenNPS: label,
      },
    });
  };

  const handleShowFileType = () => {
    setShowFileTypePopup(true);
  };

  useEffect(() => {
    if (
      selectedTopicFromBars &&
      Object.keys(selectedTopicFromBars).length > 0
    ) {
      Object.keys(selectedTopicFromBars).forEach((key) => {
        const item = selectedTopicFromBars[key];
        if (item?.id) {
          updateQParams(`level${item.level}Selection`, JSON.stringify(item));
          dispatch({
            type: actions.UPDATE_SEARCH_STATE,
            payload: {
              [`selectedL${item.level}Topic`]: item.id,
            },
          });
        }
      });
    }
  }, [selectedTopicFromBars]);

  useEffect(() => {
    if (
      params.has("level1Selection") &&
      ((topicRatingData &&
        topicRatingData.length > 0 &&
        !topicRatingData.map((t) => t?.rating?.value).every((t) => t === 0)) ||
        (topicSentimentData && topicSentimentData.length > 0))
    ) {
      for (let l = 1; l >= 1; l++) {
        if (params.has(`level${l}Selection`)) {
          const levelSelection = JSON.parse(params.get(`level${l}Selection`));
          setSelectedTopicFromBars((prev) => {
            return {
              ...prev,
              [levelSelection?.id]: levelSelection,
            };
          });
          dispatch({
            type: actions.UPDATE_SEARCH_STATE,
            payload: {
              [`selectedL${l}Topic`]: levelSelection?.id,
            },
          });
        } else {
          break;
        }
      }
    }
  }, [topicSentimentData]);

  useEffect(() => {
    if (pivotOptions) {
      const options = {
        level1: [],
        level2: {},
      };

      Object.keys(pivotOptions).forEach((key) => {
        // Level 1 options
        options.level1.push({
          id: key?.toString(),
          label: key?.toString(),
          value: key?.toString(),
        });

        // Construct level2 options
        options.level2[key] = pivotOptions[key].map((option) => ({
          id: option?.toString(),
          label: option?.toString(),
          value: option?.toString(),
        }));
      });
      setMultiLevelOptions(options);
    }
  }, [pivotOptions]);

  const handlePivotDoughnutClick = (key, value) => {
    const pvtFilter = params.get("pivotFilter")
      ? JSON.parse(params.get("pivotFilter"))
      : {};
    if (
      pvtFilter &&
      Object.keys(pvtFilter).length > 0 &&
      Object.values(pvtFilter).flat().length > 0
    ) {
      if (Object.keys(pvtFilter).includes(key)) {
        return;
      }
      setSelectedPivots((prevSeletedPivots) => {
        updateQParams(
          "pivotFilter",
          JSON.stringify({ ...prevSeletedPivots, [key]: [value] })
        );
        updateQParams("pivots_operator", "AND");
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            pivotFilter: { ...prevSeletedPivots, [key]: [value] },
          },
        });
        return { ...prevSeletedPivots, [key]: [value] };
      });
      setLevelWisePivotSelections((prev) => ({
        ...prev,
        [key]: { ...prev[key], [value]: {} },
      }));
    } else {
      updateQParams("pivotFilter", JSON.stringify({ [key]: [value] }));
      updateQParams("pivots_operator", "AND");
      setSelectedPivots({ [key]: [value] });
      setLevelWisePivotSelections({
        [key]: { [value]: {} },
      });
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          pivotFilter: { [key]: [value] },
        },
      });
    }
  };
  const handleDecisionDoughnutClick = (key, value) => {
    const decFilter = params.get("decisionFilter")
      ? JSON.parse(params.get("decisionFilter"))
      : {};
    if (
      decFilter &&
      Object.keys(decFilter).length > 0 &&
      Object.values(decFilter).flat().length > 0
    ) {
      if (Object.keys(decFilter).includes(key)) {
        return;
      }
      setSelectedDecisions((prevSelectedDecisions) => {
        updateQParams(
          "decisionFilter",
          JSON.stringify({ ...prevSelectedDecisions, [key]: value })
        );
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            decisionFilter: { ...prevSelectedDecisions, [key]: value },
          },
        });
        return { ...prevSelectedDecisions, [key]: value };
      });
    } else {
      updateQParams("decisionFilter", JSON.stringify({ [key]: value }));
      setSelectedDecisions({ [key]: value });
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          decisionFilter: { [key]: value },
        },
      });
    }
  };
  const getSelectedPivotsFromLevelWisePivotSelections = (
    levelWisePivotSelections,
    multiLevelOptions
  ) => {
    const level1Map = multiLevelOptions.level1.reduce((acc, item) => {
      acc[item.id] = item.label;
      return acc;
    }, {});

    const level2Map = Object.keys(multiLevelOptions.level2).reduce(
      (acc, level1Id) => {
        acc[level1Id] = multiLevelOptions.level2[level1Id].reduce(
          (subAcc, item) => {
            subAcc[item.id] = item.label;
            return subAcc;
          },
          {}
        );
        return acc;
      },
      {}
    );

    const requiredFormat = Object.keys(levelWisePivotSelections).reduce(
      (acc, level1Id) => {
        const level1Label = level1Map[level1Id];
        if (level1Label) {
          acc[level1Label] = Object.keys(
            levelWisePivotSelections[level1Id]
          ).map((level2Id) => level2Map[level1Id][level2Id]);
        }
        return acc;
      },
      {}
    );
    return requiredFormat;
  };

  useEffect(() => {
    if (
      levelWisePivotSelections &&
      Object.keys(levelWisePivotSelections).length > 0 &&
      multiLevelOptions &&
      Object.keys(multiLevelOptions?.level1)?.length > 0
    ) {
      const selectedPvts = getSelectedPivotsFromLevelWisePivotSelections(
        levelWisePivotSelections,
        multiLevelOptions
      );
      if (selectedPvts && Object.keys(selectedPvts).length > 0) {
        setSelectedPivots(selectedPvts);
      }
    }
  }, [levelWisePivotSelections]);

  useEffect(() => {
    if (params.get("pivots_operator")) {
      setSelectedPivotsOperator(params.get("pivots_operator"));
    }
  }, [queryFilters]);
  const handleApplyPivotFilters = (operator = "AND") => {
    if (selectedPivots) {
      updateQParams("pivotFilter", JSON.stringify(selectedPivots));
      updateQParams("pivots_operator", operator);
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: { pivotFilter: selectedPivots, pivots_operator: operator },
      });
    }
  };

  const handleResetPivotFilters = () => {
    params.delete("pivotFilter");
    params.delete("pivots_operator");
    history.push({ search: params.toString() });
    dispatch({
      type: actions.UPDATE_SEARCH_STATE,
      payload: { pivotFilter: {}, pivots_operator: null },
    });
    setSelectedPivots({});
    setLevelWisePivotSelections({});
  };

  useEffect(() => {
    if (
      params.get("pivotFilter") &&
      multiLevelOptions &&
      Object.keys(multiLevelOptions).length > 0
    ) {
      const pvtFilters = Object.entries(
        JSON.parse(params.get("pivotFilter"))
      ).flatMap(([key, values]) => {
        return values.map((value) => ({ key: key, value: value }));
      });
      if (pvtFilters && pvtFilters.length > 0) {
        pvtFilters.map((filter) => {
          setLevelWisePivotSelections((prev) => ({
            ...prev,
            [filter.key]: { ...prev[filter.key], [filter.value]: {} },
          }));
        });
      }
    }
  }, [multiLevelOptions]);

  const getOutcomeMetricsList = async () => {
    setTableDataLoading(true);
    const result = await apiRequest(
      "drivers/outcome_metrics",
      "POST",
      { dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10) },
      true
    );
    if (result?.success) {
      const metrics = result.success?.map((item) => ({
        key: parseInt(item.key, 10),
        col1: item.name,
      }));
      setOutcomeMetrics(metrics);
    } else {
      notification.error({
        message: result?.error
          ? result.error
          : "Failed to get outcome metrics list",
      });
      setSelectedOutcomeMetric(-1);
    }
    setTableDataLoading(false);
  };

  const getPotentialDriversList = async () => {
    setTableDataLoading(true);
    const result = await apiRequest(
      "drivers/potential_drivers",
      "POST",
      { dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10) },
      true
    );
    if (result?.success) {
      const metrics = result.success?.map((item) => {
        if (item.key) {
          return {
            key: item.key,
            col1: item.name,
            col2: item.type === 1 ? "Custom Topic" : "Pivot",
          };
        }
      });
      // setPotentialDrivers(metrics);
    } else {
      notification.error({
        message: result?.error ? result.error : "Failed to get drivers list",
      });
    }
    setTableDataLoading(false);
  };

  const getKeyDriversConfig = async () => {
    setDriverAnalysisLoading(true);
    const result = await apiRequest(
      "drivers/get_config",
      "POST",
      { dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10) },
      true
    );
    if (result?.success) {
      const selectedConfig = result.success;
      const selectedDriversInConfig = selectedConfig.potential_drivers.map(
        (conf) => ({
          key: conf.type === 1 ? parseInt(conf.key, 10) : conf.key,
          col2: conf.type === 1 ? "Custom Topic" : "Pivot",
        })
      );
      setShowPopupSequence(true);
      getOutcomeMetricsList();
      setSelectedOutcomeMetric(selectedConfig.outcome_metric);
      setSelectedCustomTopics(
        selectedDriversInConfig.filter((item) => item.col2 === "Custom Topic")
      );
      setSelectedPivotColumns(
        selectedDriversInConfig.filter((item) => item.col2 === "Pivot")
      );
    } else {
      notification.error({
        message: result?.error ? result.error : "Couldn't get the saved config",
      });
    }
    setDriverAnalysisLoading(false);
  };
  const saveKeyDriverConfig = async () => {
    const payload = {
      dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10),
      outcome_metric: selectedOutcomeMetric,
      potential_drivers: [
        ...selectedCustomTopics,
        ...selectedPivotColumns,
      ]?.map((item) => ({
        key: item.key,
        type: item.col2 === "Custom Topic" ? 1 : 2,
      })),
    };
    const result = await apiRequest(
      "drivers/save_config",
      "POST",
      payload,
      true
    );
    if (result?.success) {
      console.log("Config saved!");
    } else {
      notification.error({
        message: result?.error ? result.error : "Failed to save configuration",
      });
    }
  };

  const getKeyDriverAnalysis = async () => {
    setDriverAnalysisLoading(true);
    const analysis_payload =
      selectedOutcomeMetric !== -1 &&
      [...selectedCustomTopics, ...selectedPivotColumns]?.length > 0
        ? {
            outcome_metric: selectedOutcomeMetric,
            potential_drivers: [
              ...selectedCustomTopics,
              ...selectedPivotColumns,
            ]?.map((item) => ({
              key: item.key,
              type: item.col2 === "Custom Topic" ? 1 : 2,
            })),
          }
        : {};
    const {
      sentiment,
      intent,
      since,
      until,
      rating,
      top_topics,
      topics_operator,
      platform_id,
      match_date,
      hotterm,
      nps,
      keyword,
      pivotFilter,
      pivots_operator,
      decisionFilter,
      sortByDate,
    } = queryFilters;
    const payload = {
      ...analysis_payload,
      dashboard_id: parseInt(initialState.currentDashboard, 10),
      start_index: 0,
      num_docs: numOfDocs,
    };
    if (since && until) {
      payload.since = since;
      payload.until = until;
    }
    if (sentiment) {
      payload.sentiment = sentiment;
    }
    if (intent) {
      payload.intent = intent;
    }
    if (rating) {
      payload.rating = rating;
    }
    if (hotterm) {
      payload.hotterm = hotterm;
    }
    if (keyword && keyword.length > 0) {
      payload.keyword = keyword;
    }
    if (match_date) {
      payload.match_date = match_date;
    }
    if (platform_id) {
      payload.platform_id = platform_id;
    }
    if (nps) {
      payload.nps = nps;
    }
    if (
      pivotFilter &&
      Object.keys(pivotFilter).length > 0 &&
      Object.values(pivotFilter)?.flat().length > 0
    ) {
      payload.pivotFilter = pivotFilter;
      payload.pivots_operator = pivots_operator;
    }
    if (decisionFilter && Object.keys(decisionFilter)?.length > 0) {
      payload.decisionFilter = decisionFilter;
    }
    const selectedTpcs = {};
    for (let level = 1; level >= 1; level++) {
      if (params.has(`level${level}Selection`)) {
        const levelSelection = JSON.parse(params.get(`level${level}Selection`));
        selectedTpcs[level] = levelSelection;
      } else {
        break;
      }
    }

    const maxLevel = Math.max(...Object.keys(selectedTpcs));
    if (maxLevel > 0) {
      payload.topic_id = selectedTpcs[maxLevel]?.id;
    }
    if (top_topics && top_topics.length > 0) {
      payload.top_topics = top_topics;
      payload.topics_operator = topics_operator ? topics_operator : "AND";
    }
    if (switchPeriod && participationPeriod) {
      payload.volume_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && sentimentPeriod) {
      payload.sentiment_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && ratingsPeriod) {
      payload.ratings_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && sentimentPeriod) {
      payload.sentiment_period = sentimentPeriod.toLowerCase();
    }

    if (switchPeriod && ratingsPeriod) {
      payload.ratings_period = ratingsPeriod.toLowerCase();
    }
    // if (sortByDate) payload.sortByDate = true;
    const result = await apiRequest("drivers/analysis", "POST", payload, true);
    if (result?.success) {
      const responseData = result.success;
      if (responseData === -1 || responseData.length === 0) {
        setNoDataForSelectedConfig(true);
        setEmptyDriverAnalysis(false);
        setDriverAnalysisLoading(false);
        return;
      } else if (responseData === "No config found") {
        setNoDataForSelectedConfig(false);
        setEmptyDriverAnalysis(true);
        setDriverAnalysisLoading(false);
        return;
      } else {
        setDriverAnalysisData(responseData);
        setNoDataForSelectedConfig(false);
        setEmptyDriverAnalysis(false);
        setDriverAnalysisLoading(false);
      }
    } else {
      notification.error({
        message: result?.error
          ? result.error
          : "Failed to get driver analysis data",
      });
      setEmptyDriverAnalysis(true);
      setDriverAnalysisLoading(false);
    }
  };
  const deleteDriverAnalysis = async () => {
    setDriverAnalysisLoading(true);
    const payload = {
      dashboard_id: parseInt(DashboardReducer.dashboard?.ID, 10),
    };
    const result = await apiRequest("drivers/delete", "POST", payload, true);
    if (result?.success) {
      notification.success({
        message: "Configuration deleted!",
      });
      setEmptyDriverAnalysis(true);
      setSelectedOutcomeMetric(1);
      setSelectedCustomTopics([]);
      setSelectedPivotColumns([]);
    } else {
      notification.error({
        message: result?.error ? result.error : "Error deleting configuration!",
      });
    }
    setDriverAnalysisLoading(false);
  };

  const convertIntoBubbleData = (scatterData) => {
    const maxSize = Math.max(...scatterData.map((d) => d.size));
    return {
      datasets: scatterData?.map((d) => ({
        data: [{ x: d.x, y: d.y, r: d.r }],
        backgroundColor: hexToRGBA(
          d.fill,
          // Opacity based on the size
          Math.max(0.4, (1 - d.size / maxSize).toFixed(2))
        ),
        // Min 0.4 opacity
        borderColor: d.fill,
        label: d.label,
        size: d.size,
      })),
    };
  };

  const handleZoomInOut = (zoom) => {
    if (bubbleChart.current?.chartInstance) {
      let ticksX =
        bubbleChart.current.chartInstance.options.scales.xAxes[0].ticks;
      let ticksY =
        bubbleChart.current.chartInstance.options.scales.yAxes[0].ticks;

      var newMin = ticksX.min + zoomLevel;
      var newMax = ticksX.max - zoomLevel;

      ticksX.min = parseInt(newMin, 10);
      ticksX.max = parseInt(newMax, 10);
      ticksY.min = parseInt(newMin, 10);
      ticksY.max = parseInt(newMax, 10);
      bubbleChart.current.chartInstance.update();
    }
  };

  const resetZoom = () => {
    let ticksX =
      bubbleChart.current.chartInstance.options.scales.xAxes[0].ticks;
    let ticksY =
      bubbleChart.current.chartInstance.options.scales.yAxes[0].ticks;
    ticksX.min = 0;
    ticksX.max = 100;
    ticksY.min = 0;
    ticksY.max = 100;
    bubbleChart.current.chartInstance.update();
    setZoomLevel(0);

    let opt = { ...zoomPlugin };
    opt.zoom.zoom.enabled = false;
    opt.zoom.pan.enabled = false;
    setZoomPlugin(opt);
  };
  const onceRef = useRef(true);
  useEffect(() => {
    if (onceRef.current) {
      let zoomPlug = {
        zoom: {
          pan: {
            enabled: false,
            mode: "xy",
            speed: 5,
            threshold: 5,
            rangeMin: {
              x: -25,
              y: -25,
            },
            rangeMax: {
              x: 150,
              y: 150,
            },
          },
          zoom: {
            enabled: false,
            mode: "xy",
            onZoom: (text) => console.log(text),
            rangeMin: {
              x: 0,
              y: 0,
            },
            rangeMax: {
              x: 100,
              y: 100,
            },
          },
        },
      };
      setZoomPlugin(zoomPlug);

      onceRef.current = false;
    }
  }, []);
  const handleZoomState = (value) => {
    if (
      (zoomLevel === -50 && value === -5) ||
      (zoomLevel === 25 && value === 5)
    ) {
      return;
    } else {
      let opt = { ...zoomPlugin };
      opt.zoom.zoom.enabled = true;
      opt.zoom.pan.enabled = true;

      setZoomPlugin(opt);
      setZoomLevel((prev) => prev + value);
    }
  };
  const removeOthersFromTopTopics = (topics) => {
    const removeOthersNested = (topics, isParent = false) => {
      return topics.filter((topic) => {
        if (isParent) {
          if (topic?.id === null || topic?.id > 0) {
            return topic;
          }
        } else {
          if (topic?.id > 0 && topic.lib_id > 0) {
            return topic;
          }
        }
      });
    };
    return removeOthersNested(topics, true);
  };
  const convertToTreeOptions = (topicsTree) => {
    const convert = (tree) => {
      return tree.map((topic) => {
        const child =
          topic?.child && topic?.child?.length > 0
            ? convert(topic.child.filter((t) => t.lib_id > 0))
            : null;
        const col2 = "Custom Topic";
        const obj = {
          key: topic?.lib_id,
          col1: topic?.name,
          col2,
        };
        if (child) {
          obj.child = child;
        }
        return obj;
      });
    };
    return convert(topicsTree);
  };

  useEffect(() => {
    if (topTopics && topTopics?.tree && topTopics?.tree?.length > 0) {
      const treeWithoutOthers = removeOthersFromTopTopics(topTopics.tree);
      if (treeWithoutOthers && treeWithoutOthers.length > 0) {
        const topics = convertToTreeOptions(treeWithoutOthers);
        if (pivotOptions && Object.keys(pivotOptions).length > 0) {
          const pivots = Object.keys(pivotOptions);
          setPotentialDrivers([
            ...topics,
            ...pivots?.map((pvt, index) => ({
              key: `${pvt}_${index}`,
              col1: pvt,
              col2: "Pivot",
            })),
          ]);
        } else {
          setPotentialDrivers(topics);
        }
      }
    } else if (pivotOptions && Object.keys(pivotOptions).length > 0) {
      const pivots = Object.keys(pivotOptions);
      setPotentialDrivers(
        pivots?.map((pvt, index) => ({
          key: `${pvt}_${index}`,
          col1: pvt,
          col2: "Pivot",
        }))
      );
    }
  }, [pivotOptions, topTopics, showDriverPopup]);

  useEffect(() => {
    handleZoomInOut();
  }, [zoomLevel]);

  useEffect(() => {
    if (driverAnalysisData) {
      const bubbleData = convertIntoBubbleData(driverAnalysisData);
      const bubbleDataSets = bubbleData?.datasets;
      const xValues = bubbleDataSets?.map((item) => item?.data[0]?.x);
      const yValues = bubbleDataSets?.map((item) => item?.data[0]?.y);
      const filteredValues = {
        x: xValues?.filter((v) => {
          if (v) {
            return v;
          }
        }),
        y: yValues?.filter((v) => {
          if (v) {
            return v;
          }
        }),
      };
      setKDAScaleValues(filteredValues);
    }
  }, [driverAnalysisData]);
  const removeParentFromChildren = (parent) => {
    if (parent && parent !== "" && parent.includes(">")) {
      const substrs = parent.split(">").slice(-1)[0];
      return substrs;
    } else {
      return parent;
    }
  };
  const roundSentiments = (sentiment) => {
    const keys = Object.keys(sentiment);

    // Round values and calculate new total
    let roundedSentiment = {};
    let roundedTotal = 0;
    keys.forEach((key) => {
      const roundedPercentage = Math.round(sentiment[key].percentage * 10) / 10;
      roundedSentiment[key] = {
        ...sentiment[key],
        percentage: roundedPercentage,
      };
      roundedTotal += roundedPercentage;
    });

    // Adjust if the total exceeds 100
    while (roundedTotal > 100) {
      let maxKey = keys[0];
      keys.forEach((key) => {
        if (
          roundedSentiment[key].percentage > roundedSentiment[maxKey].percentage
        ) {
          maxKey = key;
        }
      });

      roundedSentiment[maxKey].percentage -= 0.1;
      roundedTotal -= 0.1;
    }

    return roundedSentiment;
  };

  const adjustSentiments = (data) => {
    return data.map((item) => {
      if (item.sentiment) {
        item.sentiment = roundSentiments(item.sentiment);
      }
      if (item.child && item.child.length > 0) {
        item.child = adjustSentiments(item.child);
      }
      return item;
    });
  };
  const formatCountsForDoughnuts = (count) => {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(1) + "M";
    } else if (count >= 1000) {
      return (count / 1000).toFixed(1) + "K";
    } else {
      return count;
    }
  };
  const hasAlphabet = (str) => {
    return /[A-Za-z]/.test(str);
  };
  const getDecisionData = async () => {
    const {
      sentiment,
      intent,
      since,
      until,
      rating,
      top_topics,
      topics_operator,
      platform_id,
      match_date,
      hotterm,
      nps,
      keyword,
      pivotFilter,
      pivots_operator,
      decisionFilter,
      sortByDate,
    } = queryFilters;
    const payload = {
      dashboard_id: parseInt(initialState.currentDashboard, 10),
      start_index: 0,
      num_docs: numOfDocs,
    };
    if (since && until) {
      payload.since = since;
      payload.until = until;
    }
    if (sentiment) {
      payload.sentiment = sentiment;
    }
    if (intent) {
      payload.intent = intent;
    }
    if (rating) {
      payload.rating = rating;
    }
    if (hotterm) {
      payload.hotterm = hotterm;
    }
    if (keyword && keyword.length > 0) {
      payload.keyword = keyword;
    }
    if (match_date) {
      payload.match_date = match_date;
    }
    if (platform_id) {
      payload.platform_id = platform_id;
    }
    if (nps) {
      payload.nps = nps;
    }
    if (
      pivotFilter &&
      Object.keys(pivotFilter).length > 0 &&
      Object.values(pivotFilter)?.flat().length > 0
    ) {
      payload.pivotFilter = pivotFilter;
      payload.pivots_operator = pivots_operator;
    }
    if (decisionFilter && Object.keys(decisionFilter)?.length > 0) {
      payload.decisionFilter = decisionFilter;
    }
    const selectedTpcs = {};
    for (let level = 1; level >= 1; level++) {
      if (params.has(`level${level}Selection`)) {
        const levelSelection = JSON.parse(params.get(`level${level}Selection`));
        selectedTpcs[level] = levelSelection;
      } else {
        break;
      }
    }

    const maxLevel = Math.max(...Object.keys(selectedTpcs));
    if (maxLevel > 0) {
      payload.topic_id = selectedTpcs[maxLevel]?.id;
    }
    if (top_topics && top_topics.length > 0) {
      payload.top_topics = top_topics;
      payload.topics_operator = topics_operator ? topics_operator : "AND";
    }
    if (switchPeriod && participationPeriod) {
      payload.volume_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && sentimentPeriod) {
      payload.sentiment_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && ratingsPeriod) {
      payload.ratings_period = switchPeriod.toLowerCase();
    }
    if (switchPeriod && sentimentPeriod) {
      payload.sentiment_period = sentimentPeriod.toLowerCase();
    }

    if (switchPeriod && ratingsPeriod) {
      payload.ratings_period = ratingsPeriod.toLowerCase();
    }
    // if (sortByDate) payload.sortByDate = true;
    const result = await apiRequest(
      "dashboards/decision_distribution",
      "POST",
      payload,
      true
    );
    if (result?.success) {
      initDecisionChartsData(result?.success);
    } else {
      notification.error({
        message: result?.error
          ? result.error
          : "Failed to get decision analysis data",
      });
    }
    setDecisionDataLoading(false);
  };

  return (
    <div className={styles.mainWindow}>
      {/* <ScrollToReviews /> */}
      <DashboardHeader
        generalDataSrc
        shared={shared}
        currentDashboardHash={match.params.id}
        disableDateBtn={
          reviewsLoading === null ||
          reviewsLoading ||
          isPlatformDoughnutDataLoading
            ? true
            : false
        }
        setChangeDateRange={setChangeDateRange}
      />
      {!shared && <NestedTabs />}
      {filters.length > 0 && (
        <div className={styles.searchFilters}>
          <div className={styles.filterFirstRow}>{filters}</div>
          <div
            className={styles.reset}
            onClick={() => {
              !reviewsLoading && handleResetFilter();
            }}
          >
            {t("selected_dashboard_page.5")}
          </div>
        </div>
      )}
      <Spin spinning={DashboardReducer.loading}>
        <div className={styles.dropdownFilter}>
          {multiLevelOptions &&
            Object.keys(multiLevelOptions?.level1)?.length > 0 &&
            !reviewsLoading && (
              <MLDropdown
                title={
                  <span className={styles.pivotFilters}>
                    Filter Pivots <DownOutlined />
                  </span>
                }
                level1Pos={"right"}
                subLevelPos={"right-top"}
                levelWiseOptions={multiLevelOptions}
                levelWiseSelections={levelWisePivotSelections}
                setLevelWiseSelections={setLevelWisePivotSelections}
                reset={handleResetPivotFilters}
                apply={(val) => handleApplyPivotFilters(val)}
                disabled={reviewsLoading}
                selectedFilterType={selectedPivotsOperator}
                setSelectedFilterType={setSelectedPivotsOperator}
              />
            )}
        </div>
        <div className={styles.analysisContainer}>
          {
            <BoardCard
              title={
                <span className={styles.KDATitle}>
                  <span>
                    {filters.length > 0
                      ? t("selected_dashboard_page.73")
                      : t("selected_dashboard_page.7")}
                  </span>
                  <img
                    className={styles.message_img}
                    src={
                      theme === "dark"
                        ? "/assets/info1.svg"
                        : "/assets/info1_black.svg"
                    }
                  />
                </span>
              }
            >
              <Spin spinning={isSentimentDoughnutDataLoading}>
                <div className={styles.dataWrapper}>
                  <span>{rawData}</span>
                </div>
              </Spin>
            </BoardCard>
          }
          {avgRating !== 0 && (
            <BoardCard
              title={
                <span className={styles.KDATitle}>
                  <span>{t("selected_dashboard_page.8")}</span>
                  <img
                    className={styles.message_img}
                    src={
                      theme === "dark"
                        ? "/assets/info1.svg"
                        : "/assets/info1_black.svg"
                    }
                  />
                </span>
              }
            >
              <Spin spinning={isSentimentDoughnutDataLoading}>
                <div className={styles.dataWrapper}>
                  <span>{avgRating}</span>
                </div>
              </Spin>
            </BoardCard>
          )}
        </div>
        {
          <div className={styles.analysisContainer}>
            <BoardCard
              title={
                <span className={styles.KDATitle}>
                  <span>{`${t("selected_dashboard_page.60")}🔥`}</span>
                  <img
                    className={styles.message_img}
                    src={
                      theme === "dark"
                        ? "/assets/info1.svg"
                        : "/assets/info1_black.svg"
                    }
                  />
                </span>
              }
            >
              <div className={styles.container}>
                <HotTerms
                  allNgrams={hottermsData}
                  loading={hottermsLoading}
                  setLoading={setHottermsLoading}
                  overLimit={overLimitForHotterms}
                />
              </div>
            </BoardCard>
            <BoardCard
              title={
                <span className={styles.KDATitle}>
                  <span>{t("others.42")}</span>
                  <img
                    className={styles.message_img}
                    src={
                      theme === "dark"
                        ? "/assets/info1.svg"
                        : "/assets/info1_black.svg"
                    }
                  />
                </span>
              }
            >
              <Spin spinning={topTopicsLoading}>
                <div className={styles.container}>
                  <TopTopics
                    data={topTopics}
                    topTopicsLoading={topTopicsLoading}
                    disableTopTopicsBtn={
                      reviewsLoading || isPlatformDoughnutDataLoading
                    }
                    selectedTopTopics={selectedTopTopics}
                    setSelectedTopTopics={setSelectedTopTopics}
                    checkedTopicIds={checkedTopicIds}
                    setCheckedTopicIds={setCheckedTopicIds}
                    selectedTopicIds={selectedTopicIds}
                    setSelectedTopicIds={setSelectedTopicIds}
                    boardType="dashboard"
                    handleClearAll={handleClearAllTopTopics}
                  />
                </div>
              </Spin>
            </BoardCard>
          </div>
        }
        {
          <div>
            <BoardCard
              title={
                <span className={styles.KDATitle}>
                  <span>Key Driver Analysis</span>
                  <img
                    className={styles.message_img}
                    src={
                      theme === "dark"
                        ? "/assets/info1.svg"
                        : "/assets/info1_black.svg"
                    }
                    onClick={() => {
                      const win = window.open(
                        "https://pivony.notion.site/Key-drivers-analysis-KDA-94a067596db74855b74ffc2c511bef4d#510a8b02c3514c7baf3e4ae9ca75e183",
                        "_blank"
                      );
                      win.focus();
                    }}
                  />
                </span>
              }
              extra={
                <div className={styles.keyDriverActions}>
                  {!emptyDriverAnalysis && (
                    <>
                      <img
                        className={styles.message_img}
                        src={
                          theme === "dark"
                            ? "/assets/edit_icon.svg"
                            : "/assets/edit_icon_black.svg"
                        }
                        onClick={() => {
                          !driverAnalysisLoading && getKeyDriversConfig();
                        }}
                        width={20}
                        height={20}
                      />
                      <img
                        className={styles.message_img}
                        src={
                          theme === "dark"
                            ? "/assets/delete_icon.svg"
                            : "/assets/delete_icon_black.svg"
                        }
                        onClick={() => {
                          if (!driverAnalysisLoading) {
                            setDriverAnalysisData([]);
                            deleteDriverAnalysis();
                          }
                        }}
                        width={20}
                        height={20}
                      />
                    </>
                  )}
                </div>
              }
            >
              <Spin spinning={driverAnalysisLoading}>
                {emptyDriverAnalysis ? (
                  <div
                    className={styles.emptyDriverAnalysis}
                    onClick={() => {
                      setShowPopupSequence(true);
                      getOutcomeMetricsList();
                    }}
                  >
                    <span className={styles.displayMsg}>
                      <PlusOutlined
                        style={{
                          fontSize: "14px",
                          width: "14px",
                          height: "14px",
                        }}
                      />{" "}
                      Start by selecting the Outcome Metric and Potential
                      Drivers
                    </span>
                  </div>
                ) : (
                  <div className={styles.bubbleWrapper}>
                    <ResponsiveContainer
                      width={"90%"}
                      style={
                        window.innerWidth > 949 ? { marginLeft: "5%" } : {}
                      }
                      height={
                        window.innerWidth > 499
                          ? noDataForSelectedConfig
                            ? 125
                            : 600
                          : noDataForSelectedConfig
                          ? 120
                          : 400
                      }
                    >
                      {noDataForSelectedConfig ? (
                        <div className={styles.noDataForSelectedConfig}>
                          <span className={styles.emptyMsg}>
                            No data available
                          </span>
                          <span
                            className={styles.redirectBtn}
                            onClick={() => {
                              getKeyDriversConfig();
                            }}
                          >
                            Edit Configuration
                          </span>
                        </div>
                      ) : (
                        <Bubble
                          ref={bubbleChart}
                          data={convertIntoBubbleData(driverAnalysisData)}
                          options={{
                            scales: {
                              xAxes: [
                                {
                                  ticks: {
                                    min: 0,
                                    max:
                                      Object.keys(kdaScaleValues).length > 0
                                        ? Math.ceil(
                                            (Math.max(...kdaScaleValues.x) +
                                              10) /
                                              10
                                          ) *
                                            10 >
                                          100
                                          ? 100
                                          : Math.ceil(
                                              (Math.max(...kdaScaleValues.x) +
                                                10) /
                                                10
                                            ) * 10
                                        : 100,
                                    stepSize: 10,
                                    padding: 20,
                                    fontColor:
                                      theme === "dark" ? "white" : "black",
                                    callback: function(value) {
                                      return Math.round(value);
                                    },
                                  },
                                  gridLines: {
                                    drawOnChartArea: false,
                                    color: "#808080",
                                  },
                                  scaleLabel: {
                                    display: true,
                                    labelString: "Performance (%)",
                                    fontColor:
                                      theme === "dark" ? "white" : "black",
                                  },
                                },
                              ],
                              yAxes: [
                                {
                                  ticks: {
                                    min: 0,
                                    max:
                                      Object.keys(kdaScaleValues).length > 0
                                        ? Math.ceil(
                                            Math.max(...kdaScaleValues.y) / 10
                                          ) *
                                            10 >
                                          100
                                          ? 100
                                          : Math.ceil(
                                              Math.max(...kdaScaleValues.y) / 10
                                            ) * 10
                                        : 100,
                                    padding: 20,
                                    stepSize: 10,
                                    fontColor:
                                      theme === "dark" ? "white" : "black",
                                    callback: function(value) {
                                      return Math.round(value);
                                    },
                                  },
                                  gridLines: {
                                    drawOnChartArea: false,
                                    color: "#808080",
                                  },
                                  scaleLabel: {
                                    display: true,
                                    labelString: "Importance (%)",
                                    fontColor:
                                      theme === "dark" ? "white" : "black",
                                  },
                                },
                              ],
                            },
                            legend: {
                              display: false,
                              labels: {
                                usePointStyle: true,
                                fontColor: theme === "dark" ? "white" : "black",
                                pointStyle: "circle",
                                padding: 20,
                                boxWidth: 30,
                              },
                              borderRadius: 15,
                              position: "top",
                            },
                            layout: {
                              padding: {
                                top: 10,
                                right: window.innerWidth > 499 ? 80 : 20,
                              },
                            },
                            tooltips: {
                              enabled: false,
                              custom: function(tooltipModel) {
                                // Tooltip Element
                                var tooltipEl = document.getElementById(
                                  "chartjs-tooltip"
                                );

                                // Create element on first render
                                if (!tooltipEl) {
                                  tooltipEl = document.createElement("div");
                                  tooltipEl.id = "chartjs-tooltip";
                                  tooltipEl.innerHTML = "<div></div>";
                                  document.body.appendChild(tooltipEl);
                                }

                                // Hide if no tooltip
                                if (tooltipModel.opacity === 0) {
                                  if (tooltipEl) {
                                    tooltipEl.remove();
                                  }
                                  return;
                                }

                                // Set caret Position
                                tooltipEl.classList.remove(
                                  "above",
                                  "below",
                                  "no-transform"
                                );
                                if (tooltipModel.yAlign) {
                                  tooltipEl.classList.add(tooltipModel.yAlign);
                                } else {
                                  tooltipEl.classList.add("no-transform");
                                }

                                function getBody(bodyItem) {
                                  return bodyItem.lines;
                                }

                                // Set Text
                                if (tooltipModel.body) {
                                  const bubbleData = convertIntoBubbleData(
                                    driverAnalysisData
                                  );

                                  let innerHtml =
                                    "<div class=" + styles.driversTooltip + ">";
                                  let dataset = {};
                                  let dataItem = {};
                                  tooltipModel.dataPoints.forEach(
                                    (dataPoint) => {
                                      const datasetIndex =
                                        dataPoint.datasetIndex;
                                      const index = dataPoint.index;
                                      dataset =
                                        bubbleData.datasets[datasetIndex];
                                      dataItem = dataset.data[index];
                                    }
                                  );

                                  // Add the common tooltip values
                                  innerHtml += `
                                  <div class=${styles.topicLabelInTooltip}>
                                      <span class=${
                                        styles.fillColor
                                      } style="background-color: ${
                                    dataset.backgroundColor
                                  }"></span>
                                      <span class=${
                                        styles.topicLabel
                                      }>${removeParentFromChildren(
                                    dataset.label
                                  )}</span>
                                    </div>
                                  <p class=${
                                    styles.tooltipValues
                                  }>Performance: ${dataItem.x}</p>
                                  <p class=${
                                    styles.tooltipValues
                                  }>Importance: ${dataItem.y}</p>
                                  <p class=${
                                    styles.tooltipValues
                                  }>Sample size: ${dataset.size}</p>
                                `;

                                  innerHtml += "</div>";
                                  const tooltipRoot = tooltipEl.querySelector(
                                    "div"
                                  );
                                  tooltipRoot.innerHTML = innerHtml;
                                }

                                // `this` will be the overall tooltip
                                var position = this._chart.canvas.getBoundingClientRect();

                                // Display, position, and set styles for font
                                tooltipEl.style.opacity = 1;
                                tooltipEl.style.position = "absolute";
                                tooltipEl.style.left =
                                  position.left +
                                  window.pageXOffset +
                                  tooltipModel.caretX +
                                  "px";
                                tooltipEl.style.top =
                                  position.top +
                                  window.pageYOffset +
                                  tooltipModel.caretY +
                                  "px";
                                tooltipEl.style.fontFamily =
                                  tooltipModel._bodyFontFamily;
                                tooltipEl.style.fontSize =
                                  tooltipModel.bodyFontSize + "px";
                                tooltipEl.style.fontStyle =
                                  tooltipModel._bodyFontStyle;
                                tooltipEl.style.padding =
                                  tooltipModel.yPadding +
                                  "px " +
                                  tooltipModel.xPadding +
                                  "px";
                                tooltipEl.style.pointerEvents = "none";
                              },
                            },
                            plugins: {
                              ...zoomPlugin,
                              datalabels: {
                                display: (ctx) => {
                                  const chart = ctx.chart;
                                  const datasets = chart.config.data.datasets;
                                  const overlappingPoints = datasets?.filter(
                                    (dataset) =>
                                      dataset?.data[0]?.x ===
                                        ctx?.dataset?.data[0]?.x &&
                                      dataset?.data[0]?.y ===
                                        ctx?.dataset?.data[0]?.y
                                  );
                                  return overlappingPoints?.length === 1;
                                },
                                align: "center",
                                anchor: "center",
                                color: theme === "dark" ? "white" : "black",
                                formatter: function(val, context) {
                                  return removeParentFromChildren(
                                    context.dataset.label
                                  );
                                },
                              },
                            },
                          }}
                        />
                      )}
                    </ResponsiveContainer>
                    {!noDataForSelectedConfig && (
                      <div className={styles.zoomInfoBox}>
                        <img
                          onClick={resetZoom}
                          src={
                            theme === "dark"
                              ? "/assets/refresh.svg"
                              : "/assets/refresh_black.svg"
                          }
                          alt=""
                        />
                        <i
                          className="fa-solid fa-minus"
                          onClick={() => handleZoomState(-5)}
                        ></i>
                        <span className={styles.zoomPercent}>
                          {100 + zoomLevel}
                        </span>
                        <i
                          className="fa-solid fa-plus"
                          onClick={() => handleZoomState(5)}
                        ></i>
                      </div>
                    )}
                  </div>
                )}
              </Spin>
            </BoardCard>
          </div>
        }
        {/* <div className={styles.numericDataContainer}>
          {DashboardReducer.boardData.UniqueUsers && DashboardReducer.dashboard?.PlatformId !== 2 &&
            DashboardReducer.boardData.UniqueUsers !== 0 &&
            DashboardReducer?.dashboard?.PlatformId !== 11 && (
                <BoardCard title="Total Users">
                  <span>{DashboardReducer.boardData.UniqueUsers || 0}</span>
                </BoardCard>
              )}
          {npScore !== 0 && <BoardCard title={"NPS"}>
            <Spin spinning={isSentimentDoughnutDataLoading}>
              <div className={styles.dataWrapper}>
                <span>{npScore}</span>
                <span>of {overalNps}</span>
              </div>
            </Spin>
            <span className={styles.indicator}></span>
          </BoardCard>
          }
        </div>
        <div className={styles.analysisContainer}></div> */}
        {npsInfo && Object.keys(npsInfo).length !== 0 && (
          <div className={styles.analysisContainer}>
            <BoardCard
              title={
                <span className={styles.KDATitle}>
                  <span>{npsScale[1] === 5 ? "VoC Score" : "NPS Score"}</span>
                  <img
                    className={styles.message_img}
                    src={
                      theme === "dark"
                        ? "/assets/info1.svg"
                        : "/assets/info1_black.svg"
                    }
                  />
                </span>
              }
              id="nps-score"
            >
              <Spin spinning={isAvgSentimentGaugeDataLoading}>
                <div className={styles.npsChart}>
                  <GaugeChart
                    animate={false}
                    className={styles.sentimentGauge}
                    id="gauge-chart2"
                    nrOfLevels={3}
                    needleColor={
                      theme === "dark" ? "rgb(70, 74, 79)" : "#a6a6a6"
                    }
                    needleBaseColor={
                      theme === "dark" ? "rgb(70, 74, 79)" : "#a6a6a6"
                    }
                    textColor={theme === "dark" ? "white" : "black"}
                    percent={
                      npsScale[1] === 5
                        ? (avgRating - 1) / 4
                        : npsInfo?.nps < 0
                        ? 0.5 - Math.abs(npsInfo?.nps) / 2
                        : 0.5 + npsInfo?.nps / 2
                    }
                    width={200}
                    colors={["#AA2B0E", "#FFA800", "#00B07B"]}
                    formatTextValue={() => {
                      return npsScale[1] === 5
                        ? parseFloat(avgRating)
                        : parseFloat(npsInfo?.nps * 100).toFixed(0);
                    }}
                  />
                </div>
                <div className={styles.npsBarTitle}>All</div>
                <div className={styles.npsBar}>
                  {npsInfo?.detractors > 0 && (
                    <div className={styles.barPercent}>
                      <span
                        onClick={() => handleNPSFilter("detractors")}
                        className={styles.color0}
                        style={{ width: `${npsInfo.detractors * 2}px` }}
                      ></span>
                      <span className={styles.percent}>
                        {npsInfo?.detractors}%
                      </span>
                    </div>
                  )}
                  {npsInfo?.passives > 0 && (
                    <div className={styles.barPercent}>
                      <span
                        onClick={() => handleNPSFilter("neutrals")}
                        className={styles.color2}
                        style={{ width: `${npsInfo.passives * 2}px` }}
                      ></span>
                      <span className={styles.percent}>
                        {npsInfo?.passives}%
                      </span>
                    </div>
                  )}
                  {npsInfo?.promoters > 0 && (
                    <div className={styles.barPercent}>
                      <span
                        onClick={() => handleNPSFilter("promoters")}
                        className={styles.color3}
                        style={{ width: `${npsInfo.promoters * 2}px` }}
                      ></span>
                      <span className={styles.percent}>
                        {npsInfo?.promoters}%
                      </span>
                    </div>
                  )}
                </div>
                {npsInfo?.detractors > 0 && (
                  <div className={styles.npsBarTitle}>Detractors</div>
                )}
                <div className={styles.npsBar}>
                  {npsInfo?.deep_detractors > 0 && (
                    <div className={styles.barPercent}>
                      <span
                        onClick={() => handleNPSFilter("deep detractors")}
                        className={styles.color01}
                        style={{ width: `${npsInfo.deep_detractors * 2}px` }}
                      ></span>
                      <span className={styles.percent}>
                        {npsInfo?.deep_detractors}%
                      </span>
                    </div>
                  )}
                  {npsInfo?.detractors2 > 0 && (
                    <div className={styles.barPercent}>
                      <span
                        onClick={() => handleNPSFilter("other detractors")}
                        className={styles.color02}
                        style={{ width: `${npsInfo.detractors2 * 2}px` }}
                      ></span>
                      <span className={styles.percent}>
                        {npsInfo?.detractors2}%
                      </span>
                    </div>
                  )}
                </div>
                <div className={styles.npsLabels}>
                  <div className={styles.labelItem0}>
                    <span></span>
                    {`Detractors[${npsScale[1] === 5 ? `1-3` : `0-6`}]`}
                  </div>
                  <div className={styles.labelItem01}>
                    <span></span>
                    {`Deep detractors[${npsScale[1] === 5 ? `1-2` : `0-3`}]`}
                  </div>
                  <div className={styles.labelItem02}>
                    <span></span>
                    {`Other detractors[${npsScale[1] === 5 ? `3` : `4-6`}]`}
                  </div>
                  <div className={styles.labelItem1}>
                    <span></span>
                    {`Neutrals[${npsScale[1] === 5 ? 4 : `7-8`}]`}
                  </div>
                  <div className={styles.labelItem2}>
                    <span></span>
                    {`Promoters[${npsScale[1] === 5 ? 5 : `9-10`}]`}
                  </div>
                </div>
              </Spin>
            </BoardCard>
          </div>
        )}
        <div className={styles.numericDataContainer}>
          {(DashboardReducer.boardData.hasOwnProperty("BotAccounts") && (
            <BoardCard
              title={
                <span className={styles.KDATitle}>
                  <span>{t("selected_dashboard_page.9")}</span>
                  <img
                    className={styles.message_img}
                    src={
                      theme === "dark"
                        ? "/assets/info1.svg"
                        : "/assets/info1_black.svg"
                    }
                  />
                </span>
              }
            >
              <span>{DashboardReducer?.boardData?.BotAccounts || 0}</span>
            </BoardCard>
          )) || <></>}
        </div>
        <div className={styles.ac}>
          {(platformData?.datasets &&
            (DashboardReducer?.dashboard?.PlatformId === 11 ||
              DashboardReducer?.dashboard?.PlatformId === 12 ||
              DashboardReducer?.dashboard?.PlatformId === 13) && (
              <BoardCard
                title={
                  <span className={styles.KDATitle}>
                    <span>Platforms</span>
                    <img
                      className={styles.message_img}
                      src={
                        theme === "dark"
                          ? "/assets/info1.svg"
                          : "/assets/info1_black.svg"
                      }
                    />
                  </span>
                }
                id="Platform-Analysis"
              >
                <div className={styles.doughnutWrapper}>
                  <Spin spinning={isPlatformDoughnutDataLoading}>
                    <Doughnut
                      data={
                        Object.keys(platformData).length > 0
                          ? platformData
                          : localState.platformData
                      }
                      plugins={ChartDataLabels}
                      width={200}
                      options={{
                        ...IntentOptions,
                        onClick: function(e) {
                          var point = this.getElementAtEvent(e)[0];
                          handleDoughnutClick(point, "platform");
                        },
                        legend: {
                          display: true,
                          labels: {
                            usePointStyle: true,
                            fontColor: theme === "dark" ? "white" : "black",
                            pointStyle: "circle",
                            padding: 20,
                            boxWidth: 8,
                          },
                          borderRadius: 12,
                          position: "right",
                        },
                        plugins: {
                          datalabels: {
                            anchor: "center",
                            align: "center",
                            color: "white",
                            font: {
                              size: 9,
                            },
                            formatter: function(value, context) {
                              if (
                                context.dataset.data[context.dataIndex] < 2 ||
                                isNaN(context.dataset.data[context.dataIndex])
                              ) {
                                return "";
                              }
                              return Math.round(value) + "%";
                            },
                          },
                        },
                      }}
                    />
                  </Spin>
                </div>
              </BoardCard>
            )) || <></>}
          {intentDoughnutData?.length !== 0 && (
            <BoardCard
              title={
                <span className={styles.KDATitle}>
                  <span>{t("selected_dashboard_page.12")}</span>
                  <img
                    className={styles.message_img}
                    src={
                      theme === "dark"
                        ? "/assets/info1.svg"
                        : "/assets/info1_black.svg"
                    }
                  />
                </span>
              }
              id="Intent-Analysis"
            >
              <div className={styles.doughnutWrapper}>
                <Spin spinning={isIntentDoughnutDataLoading}>
                  <Doughnut
                    data={intentDoughnutData}
                    plugins={ChartDataLabels}
                    width={200}
                    options={{
                      ...IntentOptions,
                      onClick: function(e) {
                        var point = this.getElementAtEvent(e)[0];
                        handleDoughnutClick(point, "intent");
                      },
                      legend: {
                        display:
                          window.innerWidth < 599 &&
                          intentDoughnutData?.ids?.length > 4
                            ? false
                            : true,
                        labels: {
                          usePointStyle: true,
                          fontColor: theme === "dark" ? "white" : "black",
                          pointStyle: "circle",
                          padding: 20,
                          boxWidth: 8,
                        },
                        borderRadius: 20,
                        position: "right",
                      },
                      plugins: {
                        datalabels: {
                          anchor: "center",
                          align: "center",
                          color: "white",
                          font: {
                            size: 9,
                          },
                          formatter: function(value, context) {
                            if (
                              context.dataset.data[context.dataIndex] < 2 ||
                              isNaN(context.dataset.data[context.dataIndex])
                            ) {
                              return "";
                            }
                            return Math.round(value) + "%";
                          },
                        },
                      },
                    }}
                  />
                </Spin>
              </div>
            </BoardCard>
          )}
        </div>
        <div className={styles.ac}>
          {avgSentiment ? (
            <BoardCard
              title={
                <span className={styles.KDATitle}>
                  <span>{t("selected_dashboard_page.10")}</span>
                  <img
                    className={styles.message_img}
                    src={
                      theme === "dark"
                        ? "/assets/info1.svg"
                        : "/assets/info1_black.svg"
                    }
                  />
                </span>
              }
              id="Overall-Sentiment-Analysis"
            >
              <Spin spinning={isAvgSentimentGaugeDataLoading}>
                <GaugeChart
                  className={styles.sentimentGauge}
                  id="gauge-chart1"
                  nrOfLevels={3}
                  needleColor={theme === "dark" ? "rgb(70, 74, 79)" : "#a6a6a6"}
                  needleBaseColor={
                    theme === "dark" ? "rgb(70, 74, 79)" : "#a6a6a6"
                  }
                  percent={avgSentiment || 0}
                  formatTextValue={(text) => {
                    return text;
                  }}
                  textColor={theme === "dark" ? "white" : "black"}
                  width={200}
                  colors={[
                    "rgba(220, 37, 37, 1)",
                    "rgb(255, 255, 0)",
                    "rgba(16, 200, 0, 1)",
                  ]}
                />
              </Spin>
            </BoardCard>
          ) : (
            <></>
          )}
          {(sentimentDoughnutData?.datasets && (
            <BoardCard
              title={
                <span className={styles.KDATitle}>
                  <span>{t("selected_dashboard_page.11")}</span>
                  <img
                    className={styles.message_img}
                    src={
                      theme === "dark"
                        ? "/assets/info1.svg"
                        : "/assets/info1_black.svg"
                    }
                  />
                </span>
              }
              id="Detailed-Sentiment-Analysis"
            >
              <div className={styles.doughnutWrapper}>
                <Spin spinning={isSentimentDoughnutDataLoading}>
                  <Doughnut
                    data={sentimentDoughnutData}
                    plugins={ChartDataLabels}
                    width={200}
                    options={{
                      ...IntentOptions,
                      onClick: function(e) {
                        var point = this.getElementAtEvent(e)[0];
                        handleDoughnutClick(point, "sentiment");
                      },
                      legend: {
                        display: true,
                        labels: {
                          usePointStyle: true,
                          fontColor: theme === "dark" ? "white" : "black",
                          pointStyle: "circle",
                          padding: 20,
                          boxWidth: 8,
                        },
                        borderRadius: 20,
                        position: "right",
                      },
                      plugins: {
                        datalabels: {
                          anchor: "center",
                          align: "center",
                          color: "white",
                          font: {
                            size: 9,
                          },
                          formatter: function(value, context) {
                            if (
                              context.dataset.data[context.dataIndex] < 2 ||
                              isNaN(context.dataset.data[context.dataIndex])
                            ) {
                              return "";
                            }
                            return Math.round(value) + "%";
                          },
                          color: function(context) {
                            const bgColor =
                              context.dataset.backgroundColor[
                                context.dataIndex
                              ];
                            if (bgColor == "rgb(255, 255, 0)") {
                              return "black";
                            } else {
                              return "white";
                            }
                          },
                        },
                      },
                    }}
                  />
                </Spin>
              </div>
            </BoardCard>
          )) || <></>}
          {ratingDoughnutData && ratingDoughnutData["labels"]?.length > 0 && (
            <BoardCard
              title={
                <span className={styles.KDATitle}>
                  <span>{t("selected_dashboard_page.13")}</span>
                  <img
                    className={styles.message_img}
                    src={
                      theme === "dark"
                        ? "/assets/info1.svg"
                        : "/assets/info1_black.svg"
                    }
                  />
                </span>
              }
              id="Rating-Analysis"
            >
              <div className={styles.doughnutWrapper}>
                <Spin spinning={isRatingDoughnutDataLoading}>
                  <Doughnut
                    data={ratingDoughnutData}
                    plugins={ChartDataLabels}
                    width={200}
                    options={{
                      ...IntentOptions,
                      onClick: function(e) {
                        var point = this.getElementAtEvent(e)[0];
                        handleDoughnutClick(point, "rating");
                      },
                      legend: {
                        display: true,
                        labels: {
                          usePointStyle: true,
                          fontColor: theme === "dark" ? "white" : "black",
                          pointStyle: "circle",
                          padding: 20,
                          boxWidth: 8,
                        },
                        borderRadius: 20,
                        position: "right",
                      },
                      plugins: {
                        datalabels: {
                          anchor: "center",
                          align: "center",
                          color: "white",
                          font: {
                            size: 9,
                          },
                          formatter: function(value, context) {
                            if (
                              context.dataset.data[context.dataIndex] < 3 ||
                              isNaN(context.dataset.data[context.dataIndex])
                            ) {
                              return "";
                            }
                            return Math.round(value) + "%";
                          },
                        },
                      },
                    }}
                  />
                </Spin>
              </div>
            </BoardCard>
          )}
          {volumeDaily && volumeDaily.length > 0 && (
            <BoardCard
              title={
                <span className={styles.KDATitle}>
                  <span>{t("selected_dashboard_page.15")}</span>
                  <img
                    className={styles.message_img}
                    src={
                      theme === "dark"
                        ? "/assets/info1.svg"
                        : "/assets/info1_black.svg"
                    }
                  />
                </span>
              }
              id="Time-based-Participation"
              extra={
                <span>
                  <Dropdown
                    trigger={["click"]}
                    overlay={() => timePeriodOptions("participation")}
                  >
                    <Button
                      style={{
                        color: theme === "dark" ? "#fff" : "#000",
                        backgroundColor: theme === "dark" ? "#1C193B" : "#fff",
                        border: 0,
                      }}
                    >
                      {participationPeriod ||
                        (volumeDaily &&
                          volumeDaily.length > 0 &&
                          determinePeriod(volumeDaily[0].day)) ||
                        "Daily"}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </span>
              }
            >
              <Spin spinning={participationPeriodLoading}>
                <LineChartWrapper
                  id="Time-based Participation"
                  period={participationPeriod}
                  VolumeDaily={
                    participationPeriodLoading
                      ? []
                      : volumeDaily.length
                      ? volumeDaily
                      : []
                  }
                  allData={allData}
                  clickable={reviewsLoading ? false : true}
                />
              </Spin>
            </BoardCard>
          )}
          {sentimentDaily && sentimentDaily.length > 0 && (
            <BoardCard
              title={
                <span className={styles.KDATitle}>
                  <span>{t("selected_dashboard_page.16")}</span>
                  <img
                    className={styles.message_img}
                    src={
                      theme === "dark"
                        ? "/assets/info1.svg"
                        : "/assets/info1_black.svg"
                    }
                  />
                </span>
              }
              id="Time-based-Sentiment"
              extra={
                <span>
                  <Dropdown
                    trigger={["click"]}
                    overlay={() => timePeriodOptions("sentiment")}
                  >
                    <Button
                      style={{
                        color: theme === "dark" ? "#fff" : "#000",
                        backgroundColor: theme === "dark" ? "#1C193B" : "#fff",
                        border: 0,
                      }}
                    >
                      {sentimentPeriod ||
                        (sentimentDaily &&
                          sentimentDaily.length > 0 &&
                          determinePeriod(sentimentDaily[0].day)) ||
                        "Daily"}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </span>
              }
            >
              <Spin spinning={sentimentPeriodLoading}>
                <LineChartWrapper
                  period={participationPeriod}
                  SentimentDaily={
                    sentimentPeriodLoading
                      ? []
                      : sentimentDaily.length
                      ? sentimentDaily
                      : []
                  }
                  allData={allData}
                  clickable={reviewsLoading ? false : true}
                />
              </Spin>
            </BoardCard>
          )}

          {Time_Daily_Rating &&
            Object.keys(Time_Daily_Rating).length > 0 &&
            timerating && (
              <BoardCard
                title={
                  <span className={styles.KDATitle}>
                    <span>{t("selected_dashboard_page.14")}</span>
                    <img
                      className={styles.message_img}
                      src={
                        theme === "dark"
                          ? "/assets/info1.svg"
                          : "/assets/info1_black.svg"
                      }
                    />
                  </span>
                }
                id="Time-based Ratings"
                extra={
                  <span>
                    <Dropdown
                      trigger={["click"]}
                      overlay={() => timePeriodOptions("Time-based_Ratings")}
                    >
                      <Button
                        style={{
                          color: theme === "dark" ? "#fff" : "#000",
                          backgroundColor:
                            theme === "dark" ? "#1C193B" : "#fff",
                          border: 0,
                        }}
                      >
                        {ratingsPeriod || "Daily"}
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </span>
                }
              >
                <Spin spinning={ratingPeriodLoading}>
                  <LineChartWrapper
                    id="Time-based Participation"
                    Time_Rating={timerating}
                    Time_Daily_Rating={Time_Daily_Rating}
                    period={ratingsPeriod}
                    allData={allData}
                  />
                </Spin>
              </BoardCard>
            )}
        </div>
        <div className={styles.ac}>
          {topicRatingData &&
            topicRatingData.length > 0 &&
            !topicRatingData
              .map((t) => t?.rating?.value)
              .every((t) => t === 0) && (
              <BoardCard
                title={
                  <span className={styles.KDATitle}>
                    <span>{t("selected_dashboard_page.84")}</span>
                    <img
                      className={styles.message_img}
                      src={
                        theme === "dark"
                          ? "/assets/info1.svg"
                          : "/assets/info1_black.svg"
                      }
                    />
                  </span>
                }
                id="Topics Rating"
                extra={
                  <div className={styles.subTopicCards}>
                    {selectedTopicFromBars &&
                      Object.keys(selectedTopicFromBars).length > 0 && (
                        <div className={styles.selections}>
                          {Object.values(selectedTopicFromBars).map((topic) => (
                            <span
                              key={topic.id}
                              className={styles.level1Label}
                              title={topic.label}
                              onClick={() => {
                                const topics = Object.values(
                                  selectedTopicFromBars
                                ).filter((t) => t.level < topic.level);
                                setSelectedTopicFromBars(topics);
                                for (
                                  let level = topic.level;
                                  level >= 1;
                                  level++
                                ) {
                                  if (params.has(`level${level}Selection`)) {
                                    params.delete(`level${level}Selection`);
                                    history.push({
                                      search: params.toString(),
                                    });
                                    dispatch({
                                      type: actions.UPDATE_SEARCH_STATE,
                                      payload: {
                                        [`selectedL${level}Topic`]: null,
                                      },
                                    });
                                  } else {
                                    break;
                                  }
                                }
                              }}
                            >
                              <span>
                                {removeParentFromChildren(topic.label)}
                              </span>
                              <CloseOutlined />
                            </span>
                          ))}
                        </div>
                      )}
                  </div>
                }
              >
                <ResponsiveContainer
                  width={"90%"}
                  height={"100%"}
                  style={{
                    marginLeft: "5%",
                  }}
                >
                  <Spin spinning={topicRatingsLoading}>
                    <BarChartWrapper
                      data={topicRatingData}
                      selectedTopics={selectedTopicFromBars}
                      setSelectedTopics={setSelectedTopicFromBars}
                      metric={"rating"}
                    />
                  </Spin>
                </ResponsiveContainer>
              </BoardCard>
            )}
          {topicSentimentData && topicSentimentData.length > 0 && (
            <BoardCard
              title={
                <span className={styles.KDATitle}>
                  <span>{t("selected_dashboard_page.86")}</span>
                  <img
                    className={styles.message_img}
                    src={
                      theme === "dark"
                        ? "/assets/info1.svg"
                        : "/assets/info1_black.svg"
                    }
                  />
                </span>
              }
              id="Topics Sentiment"
              extra={
                <div className={styles.subTopicCards}>
                  {selectedTopicFromBars &&
                    Object.keys(selectedTopicFromBars).length > 0 && (
                      <div className={styles.selections}>
                        {Object.values(selectedTopicFromBars).map((topic) => (
                          <span
                            key={topic.id}
                            className={styles.level1Label}
                            title={topic.label}
                            onClick={() => {
                              const topics = Object.values(
                                selectedTopicFromBars
                              ).filter((t) => t.level < topic.level);
                              setSelectedTopicFromBars(topics);
                              for (
                                let level = topic.level;
                                level >= 1;
                                level++
                              ) {
                                if (params.has(`level${level}Selection`)) {
                                  params.delete(`level${level}Selection`);
                                  history.push({
                                    search: params.toString(),
                                  });
                                  dispatch({
                                    type: actions.UPDATE_SEARCH_STATE,
                                    payload: {
                                      [`selectedL${level}Topic`]: null,
                                    },
                                  });
                                } else {
                                  break;
                                }
                              }
                            }}
                          >
                            <span>{removeParentFromChildren(topic.label)}</span>
                            <CloseOutlined />
                          </span>
                        ))}
                      </div>
                    )}
                </div>
              }
            >
              <ResponsiveContainer
                width={"90%"}
                height={"100%"}
                style={{
                  marginLeft: "5%",
                }}
              >
                <Spin spinning={topicRatingsLoading}>
                  <BarChartWrapper
                    data={topicSentimentData}
                    selectedTopics={selectedTopicFromBars}
                    setSelectedTopics={setSelectedTopicFromBars}
                    metric={"sentiment"}
                    stacked={true}
                  />
                </Spin>
              </ResponsiveContainer>
            </BoardCard>
          )}
        </div>
        {decisionData?.length > 0 &&
          [33, 34, 35].includes(DashboardReducer?.dashboard?.PlatformId) && (
            <div
              className={`${styles.analysisContainer} ${styles.pivotDoughnuts}`}
            >
              <BoardCard
                title={
                  <span className={styles.KDATitle}>
                    <span>{"Decision Analysis"}</span>
                    <img
                      className={styles.message_img}
                      src={
                        theme === "dark"
                          ? "/assets/info1.svg"
                          : "/assets/info1_black.svg"
                      }
                    />
                  </span>
                }
                id="Decision-Analysis"
              >
                <div className={styles.dnutWrapper}>
                  <Spin spinning={decisionDataLoading}>
                    <div className={`${styles.doughnutContainer}`}>
                      {decisionData.map((decsData) => (
                        <div className={styles.doughnut}>
                          <span className={styles.doughnutLabel}>
                            {decsData.doughnutLabel === "publish"
                              ? "Publish"
                              : decsData.doughnutLabel === "opencase"
                              ? "Open Case"
                              : "Take Action"}
                          </span>
                          {decsData.datasets[0].data.reduce(
                            (acc, curr) => acc + parseInt(curr, 10),
                            0
                          ) > 0 ? (
                            <Doughnut
                              data={decsData}
                              width={230}
                              formatTextValue={(value) => value + "%"}
                              options={{
                                ...IntentOptions,
                                cutoutPercentage: 45,
                                onClick: function(e) {
                                  var point = this.getElementAtEvent(e)[0];
                                  if (point) {
                                    const key = decsData.doughnutLabel;
                                    const value =
                                      point._index === 0 ? "False" : "True";
                                    handleDecisionDoughnutClick(key, value);
                                  }
                                },
                                legend: false,
                                layout: {
                                  padding: {
                                    left: 40,
                                    right: 40,
                                    top: 0,
                                    bottom: 0,
                                  },
                                },
                                tooltips: {
                                  enabled: false,
                                  custom: function(tooltipModel) {
                                    // Tooltip Element
                                    var tooltipEl = document.getElementById(
                                      "chartjs-tooltip"
                                    );

                                    // Create element on first render
                                    if (!tooltipEl) {
                                      tooltipEl = document.createElement("div");
                                      tooltipEl.id = "chartjs-tooltip";
                                      tooltipEl.innerHTML = "<div></div>";
                                      document.body.appendChild(tooltipEl);
                                    }

                                    // Hide if no tooltip
                                    if (tooltipModel.opacity === 0) {
                                      if (tooltipEl) {
                                        tooltipEl.remove();
                                      }
                                      return;
                                    }

                                    // Set caret Position
                                    tooltipEl.classList.remove(
                                      "above",
                                      "below",
                                      "no-transform"
                                    );
                                    if (tooltipModel.yAlign) {
                                      tooltipEl.classList.add(
                                        tooltipModel.yAlign
                                      );
                                    } else {
                                      tooltipEl.classList.add("no-transform");
                                    }

                                    function getBody(bodyItem) {
                                      return bodyItem.lines;
                                    }

                                    // Set Text
                                    if (tooltipModel.body) {
                                      let innerHtml =
                                        "<div class=" +
                                        styles.driversTooltip +
                                        ">";
                                      const index =
                                        tooltipModel.dataPoints[0].index;
                                      const datasetLabel =
                                        decsData.labels[index];
                                      const filteredPercentage =
                                        decsData.datasets[0].data[index];
                                      const filteredCount =
                                        decsData.datasets[0].counts[index];
                                      innerHtml += `
                                    <div class=${styles.topicLabelInTooltip}>
                                      <span class=${styles.fillColor} style="background-color: ${decsData.datasets[0].backgroundColor[index]}"></span>
                                      <span class=${styles.topicLabel}>${datasetLabel}</span>
                                    </div>`;
                                      innerHtml += `
                                        <p class=${styles.tooltipValues}><b>${filteredPercentage}% (${filteredCount})</b></p>
                                      `;
                                      innerHtml += "</div>";
                                      const tooltipRoot = tooltipEl.querySelector(
                                        "div"
                                      );
                                      tooltipRoot.innerHTML = innerHtml;
                                    }
                                    // this will be the overall tooltip
                                    var position = this._chart.canvas.getBoundingClientRect();

                                    // Display, position, and set styles for font
                                    tooltipEl.style.opacity = 1;
                                    tooltipEl.style.position = "absolute";
                                    tooltipEl.style.left =
                                      position.left +
                                      window.pageXOffset +
                                      tooltipModel.caretX +
                                      "px";
                                    tooltipEl.style.top =
                                      position.top +
                                      window.pageYOffset +
                                      tooltipModel.caretY +
                                      "px";
                                    tooltipEl.style.fontFamily =
                                      tooltipModel._bodyFontFamily;
                                    tooltipEl.style.fontSize =
                                      tooltipModel.bodyFontSize + "px";
                                    tooltipEl.style.fontStyle =
                                      tooltipModel._bodyFontStyle;
                                    tooltipEl.style.padding =
                                      tooltipModel.yPadding +
                                      "px " +
                                      tooltipModel.xPadding +
                                      "px";
                                    tooltipEl.style.pointerEvents = "none";
                                  },
                                },
                                plugins: {
                                  datalabels: {
                                    color: (context) => {
                                      const bgColor =
                                        context.dataset.backgroundColor[
                                          context.dataIndex
                                        ];
                                      if (
                                        RGBAToHex(bgColor) === "#d2b5f2" ||
                                        RGBAToHex(bgColor) === "#56dec8" ||
                                        RGBAToHex(bgColor) === "#ffbfff" ||
                                        RGBAToHex(bgColor) === "#cccccc" ||
                                        RGBAToHex(bgColor) === "#9bc3de" ||
                                        RGBAToHex(bgColor) === "#e7edef"
                                      ) {
                                        return "#4400AA";
                                      } else {
                                        return "white";
                                      }
                                    },
                                    font: {
                                      size: 9,
                                    },
                                    formatter: function(value, context) {
                                      if (
                                        context.dataset.data[
                                          context.dataIndex
                                        ] == 0 ||
                                        isNaN(
                                          context.dataset.data[
                                            context.dataIndex
                                          ]
                                        )
                                      ) {
                                        return "";
                                      }
                                      const formattedCount = formatCountsForDoughnuts(
                                        context.dataset.counts[
                                          context.dataIndex
                                        ]
                                      );
                                      const lineBreak = "\n";
                                      const spaceBreak = "\t";
                                      return context.dataset.data[
                                        context.dataIndex
                                      ] < 2
                                        ? ""
                                        : hasAlphabet(formattedCount)
                                        ? `${spaceBreak}${" "}${value +
                                            "%"}${lineBreak}(${formattedCount})`
                                        : `${" "}${value +
                                            "%"}${lineBreak}(${formattedCount})`;
                                    },
                                  },
                                },
                              }}
                            />
                          ) : (
                            <div className={styles.emptyDoughnut}>
                              <Doughnut
                                data={decsData}
                                options={{
                                  ...IntentOptions,
                                  cutoutPercentage: 45,
                                  legend: false,
                                  layout: {
                                    padding: {
                                      left: 40,
                                      right: 40,
                                      top: 0,
                                      bottom: 0,
                                    },
                                  },
                                  plugins: {
                                    datalabels: {
                                      font: {
                                        size: 9,
                                      },
                                      formatter: function(value, context) {
                                        if (
                                          context.dataset.data[
                                            context.dataIndex
                                          ] == 0 ||
                                          isNaN(
                                            context.dataset.data[
                                              context.dataIndex
                                            ]
                                          )
                                        ) {
                                          return "";
                                        }
                                      },
                                    },
                                  },
                                }}
                              />
                              <span className={styles.noDataDoughnutMsg}>
                                No Data
                              </span>
                            </div>
                          )}
                          {decsData.datasets[0].data.reduce(
                            (acc, curr) => acc + parseInt(curr),
                            0
                          ) > 0 && (
                            <>
                              <div className={styles.pivotsLegendSingle}>
                                <div
                                  title={decsData.labels[0]}
                                  className={styles.topicLabelInTooltip}
                                >
                                  <span
                                    className={styles.fillColor}
                                    style={{
                                      backgroundColor:
                                        decsData.doughnutLabel === "publish"
                                          ? hexToRGBA("#3A6D8C", 1)
                                          : decsData.doughnutLabel ===
                                            "opencase"
                                          ? hexToRGBA("#D91656", 1)
                                          : hexToRGBA("#304463", 1),
                                    }}
                                  ></span>
                                  <span className={styles.topicLabel}>
                                    {decsData.labels[0]}
                                  </span>
                                </div>
                              </div>
                              <div className={styles.pivotsLegendSingle}>
                                <div
                                  title={decsData.labels[1]}
                                  className={styles.topicLabelInTooltip}
                                >
                                  <span
                                    className={styles.fillColor}
                                    style={{
                                      backgroundColor:
                                        decsData.doughnutLabel === "publish"
                                          ? hexToRGBA("#41B3A2", 1)
                                          : decsData.doughnutLabel ===
                                            "opencase"
                                          ? hexToRGBA("704264", 1)
                                          : hexToRGBA("#758694", 1),
                                    }}
                                  ></span>
                                  <span className={styles.topicLabel}>
                                    {decsData.labels[1]}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </Spin>
                </div>
              </BoardCard>
            </div>
          )}
        {restaurantDoughnutData && restaurantDoughnutData.length > 0 && (
          <div
            className={`${styles.analysisContainer} ${styles.pivotDoughnuts}`}
          >
            <BoardCard
              title={
                <span className={styles.KDATitle}>
                  <span>{t("selected_dashboard_page.17")}</span>
                  <img
                    className={styles.message_img}
                    src={
                      theme === "dark"
                        ? "/assets/info1.svg"
                        : "/assets/info1_black.svg"
                    }
                  />
                </span>
              }
              id="Detailed-Pivot-Analysis"
            >
              <div
                className={
                  filters.length > 0
                    ? styles.doughnutWrapper
                    : styles.dnutWrapper
                }
              >
                <Spin spinning={isRestaurantDoughnutDataLoading}>
                  <div className={styles.doughnutContainer}>
                    {restaurantDoughnutData.map((restaurentData, index) => (
                      <div className={styles.doughnut}>
                        <span className={styles.doughnutLabel}>
                          {restaurentData.doughnutLabel}
                        </span>
                        <Doughnut
                          data={restaurentData}
                          width={230}
                          ref={(ref) => (restaurantRef.current[index] = ref)}
                          formatTextValue={(value) => value + "%"}
                          plugins={[
                            ChartDataLabels,
                            {
                              afterDatasetsDraw: function(chart) {
                                if (chart.filtersApplied) {
                                  var ctx = chart.chart.ctx;
                                  var datasets = chart.chart.data.datasets;
                                  datasets.forEach(function(dataset, i) {
                                    var meta = chart.chart.getDatasetMeta(i);
                                    meta.data.forEach(function(elem, index) {
                                      ctx.save();
                                      var outerRadius =
                                        elem._model.outerRadius + 10;
                                      var startAngle = elem._model.startAngle;
                                      var endAngle = elem._model.endAngle;

                                      var halfAngle =
                                        (endAngle - startAngle) / 4;
                                      var halfStartAngle = startAngle;
                                      var halfEndAngle =
                                        startAngle + halfAngle * 2;
                                      var halfMidAngle =
                                        (halfStartAngle + halfEndAngle) / 2;
                                      var x =
                                        elem._model.x +
                                        Math.cos(halfMidAngle) *
                                          (outerRadius + 15);
                                      var y =
                                        elem._model.y +
                                        Math.sin(halfMidAngle) *
                                          (outerRadius + 15);
                                      const filteredPercentage =
                                        dataset.data[index];
                                      const actualPercentage =
                                        dataset.actualData[index];
                                      var diffValue =
                                        filteredPercentage - actualPercentage;
                                      if (
                                        dataset.data[index] > 3 &&
                                        diffValue !== 0
                                      ) {
                                        // Draw outer ring
                                        ctx.beginPath();
                                        ctx.strokeStyle =
                                          elem._model.backgroundColor;
                                        ctx.lineWidth = 3;
                                        ctx.arc(
                                          elem._model.x,
                                          elem._model.y,
                                          outerRadius,
                                          halfStartAngle,
                                          halfEndAngle
                                        );
                                        ctx.stroke();

                                        // Draw number
                                        ctx.font = "bold 12px Poppins";

                                        ctx.textAlign = "center";
                                        ctx.textBaseline = "middle";
                                        ctx.fillStyle =
                                          theme === "dark" ? "white" : "black";
                                        ctx.fillText(
                                          diffValue > 0
                                            ? "+" + diffValue
                                            : diffValue < 0
                                            ? diffValue
                                            : 0,
                                          x,
                                          y
                                        );
                                        ctx.restore();
                                      }
                                    });
                                  });
                                }
                              },
                            },
                          ]}
                          options={{
                            ...IntentOptions,
                            cutoutPercentage: 45,
                            onClick: function(e) {
                              var point = this.getElementAtEvent(e)[0];
                              if (point) {
                                const key = restaurentData.doughnutLabel;
                                const value =
                                  restaurentData.labels[point._index];
                                if (point._index === 6 && value === "Others") {
                                  return;
                                }
                                handlePivotDoughnutClick(key, value);
                              }
                            },
                            legend: false,
                            layout: {
                              padding: {
                                left: 40,
                                right: 40,
                                top: 0,
                                bottom: 0,
                              },
                            },
                            tooltips: {
                              enabled: false,
                              custom: function(tooltipModel) {
                                // Tooltip Element
                                var tooltipEl = document.getElementById(
                                  "chartjs-tooltip"
                                );

                                // Create element on first render
                                if (!tooltipEl) {
                                  tooltipEl = document.createElement("div");
                                  tooltipEl.id = "chartjs-tooltip";
                                  tooltipEl.innerHTML = "<div></div>";
                                  document.body.appendChild(tooltipEl);
                                }

                                // Hide if no tooltip
                                if (tooltipModel.opacity === 0) {
                                  if (tooltipEl) {
                                    tooltipEl.remove();
                                  }
                                  return;
                                }

                                // Set caret Position
                                tooltipEl.classList.remove(
                                  "above",
                                  "below",
                                  "no-transform"
                                );
                                if (tooltipModel.yAlign) {
                                  tooltipEl.classList.add(tooltipModel.yAlign);
                                } else {
                                  tooltipEl.classList.add("no-transform");
                                }

                                function getBody(bodyItem) {
                                  return bodyItem.lines;
                                }

                                // Set Text
                                if (tooltipModel.body) {
                                  let innerHtml =
                                    "<div class=" + styles.driversTooltip + ">";
                                  const index =
                                    tooltipModel.dataPoints[0].index;
                                  const datasetLabel =
                                    restaurentData.labels[index];
                                  const filteredPercentage =
                                    restaurentData.datasets[0].data[index];
                                  const filteredCount =
                                    restaurentData.datasets[0].counts[index];
                                  const actualPercentage =
                                    restaurentData.datasets[0].actualData[
                                      index
                                    ];
                                  const actualCount =
                                    restaurentData.datasets[0].actualCounts[
                                      index
                                    ];
                                  if (
                                    filteredCount >= 0 &&
                                    filteredPercentage >= 0 &&
                                    actualPercentage >= 0 &&
                                    actualCount >= 0
                                  ) {
                                    innerHtml += `
                                    <div class=${styles.topicLabelInTooltip}>
                                      <span class=${styles.fillColor} style="background-color: ${restaurentData.datasets[0].backgroundColor[index]}"></span>
                                      <span class=${styles.topicLabel}>${datasetLabel}</span>
                                    </div>`;
                                    if (filters?.length > 0) {
                                      innerHtml += `
                                        <p class=${styles.tooltipValues}>Filtered Data: ${filteredPercentage}% (${filteredCount})</p>
                                        <p class=${styles.tooltipValues}>Actual Data: ${actualPercentage}% (${actualCount})</p>
                                      `;
                                    } else {
                                      innerHtml += `
                                        <p class=${styles.tooltipValues}>Actual Data: ${actualPercentage}% (${actualCount})</p>
                                      `;
                                    }
                                  }
                                  innerHtml += "</div>";
                                  const tooltipRoot = tooltipEl.querySelector(
                                    "div"
                                  );
                                  tooltipRoot.innerHTML = innerHtml;
                                }
                                // this will be the overall tooltip
                                var position = this._chart.canvas.getBoundingClientRect();

                                // Display, position, and set styles for font
                                tooltipEl.style.opacity = 1;
                                tooltipEl.style.position = "absolute";
                                tooltipEl.style.left =
                                  position.left +
                                  window.pageXOffset +
                                  tooltipModel.caretX +
                                  "px";
                                tooltipEl.style.top =
                                  position.top +
                                  window.pageYOffset +
                                  tooltipModel.caretY +
                                  "px";
                                tooltipEl.style.fontFamily =
                                  tooltipModel._bodyFontFamily;
                                tooltipEl.style.fontSize =
                                  tooltipModel.bodyFontSize + "px";
                                tooltipEl.style.fontStyle =
                                  tooltipModel._bodyFontStyle;
                                tooltipEl.style.padding =
                                  tooltipModel.yPadding +
                                  "px " +
                                  tooltipModel.xPadding +
                                  "px";
                                tooltipEl.style.pointerEvents = "none";
                              },
                            },
                            plugins: {
                              datalabels: {
                                color: (context) => {
                                  const bgColor =
                                    context.dataset.backgroundColor[
                                      context.dataIndex
                                    ];
                                  if (
                                    RGBAToHex(bgColor) === "#d2b5f2" ||
                                    RGBAToHex(bgColor) === "#56dec8" ||
                                    RGBAToHex(bgColor) === "#ffbfff" ||
                                    RGBAToHex(bgColor) === "#cccccc" ||
                                    RGBAToHex(bgColor) === "#9bc3de" ||
                                    RGBAToHex(bgColor) === "#e7edef"
                                  ) {
                                    return "#4400AA";
                                  } else {
                                    return "white";
                                  }
                                },
                                font: {
                                  size: 9,
                                },
                                formatter: function(value, context) {
                                  if (
                                    context.dataset.data[context.dataIndex] ==
                                      0 ||
                                    isNaN(
                                      context.dataset.data[context.dataIndex]
                                    )
                                  ) {
                                    return "";
                                  }
                                  const formattedCount = formatCountsForDoughnuts(
                                    context.dataset.counts[context.dataIndex]
                                  );
                                  const lineBreak = "\n";
                                  const spaceBreak = "\t";
                                  return context.dataset.data[
                                    context.dataIndex
                                  ] < 2
                                    ? ""
                                    : hasAlphabet(formattedCount)
                                    ? `${spaceBreak}${" "}${value +
                                        "%"}${lineBreak}(${formattedCount})`
                                    : `${" "}${value +
                                        "%"}${lineBreak}(${formattedCount})`;
                                },
                              },
                            },
                          }}
                        />
                        <div
                          className={
                            restaurentData.labels.length === 1
                              ? styles.pivotsLegendSingle
                              : styles.pivotsLegend
                          }
                        >
                          {restaurentData.labels.map((label) => (
                            <div
                              title={label}
                              className={styles.topicLabelInTooltip}
                            >
                              <span
                                className={styles.fillColor}
                                style={{
                                  backgroundColor: hexToRGBA(
                                    restaurantColorMap[label],
                                    1.0
                                  ),
                                }}
                              ></span>
                              <span className={styles.topicLabel}>{label}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                  {restaurantDoughnutData?.length > 0 && filters.length > 0 && (
                    <div className={styles.legendInfo}>
                      <b>Note: </b> The outer ring represents the percentage
                      variance between the Normal Distribution and the
                      Distribution after applying filters
                    </div>
                  )}
                </Spin>
              </div>
            </BoardCard>
          </div>
        )}
        <div className={styles.chartReviewsContainer}>
          <BoardCard
            title={
              numReviews
                ? `${t("selected_dashboard_page.18")}: ${numReviews}`
                : t("selected_dashboard_page.18")
            }
            theme={theme}
            extra={
              !shared && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {![65, 66, 67, 68, 69, 70].includes(
                    userReducer?.PackageId
                  ) && (
                    <div className={styles.shareBtn}>
                      <span
                        type="primary"
                        style={{ borderRadius: "8px" }}
                        disabled={[74, 75, 66, 65, 67].includes(
                          userReducer?.PackageId
                        )}
                        className={styles.downloadBtn}
                        onClick={handleShowFileType}
                        ref={downloadBtnRef}
                      >
                        <img
                          className={styles.downloadReviewIcon}
                          src={
                            theme === "dark"
                              ? "/assets/download.svg"
                              : "/assets/download_black.svg"
                          }
                          alt=""
                        />
                        <span className={styles.downloadReviewText}>
                          {t("button.25")}
                        </span>
                      </span>
                    </div>
                  )}
                  {/* <Checkbox
                    className={styles.sortByDateCheckbox}
                    style={{ color: theme === 'dark' ? "white" : 'black' }}
                    checked={DashboardReducer.searchState.sortByDate}
                    onChange={(e) => {
                      dispatch({
                        type: actions.UPDATE_Dashboard,
                        payload: {
                          searchState: {
                            ...DashboardReducer.searchState,
                            sortByDate: e.target.checked,
                          },
                        },
                      });
                    }}
                  >
                    {t("selected_dashboard_page.19")}
                  </Checkbox> */}
                </div>
              )
            }
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <CustomInput
                // label="Search"
                value={searchWord}
                placeholder={t("placeholder.13")}
                onOk={() => handleSearchWord(searchWord, true, true)}
                onChange={setSearchWord}
                style={{ margin: "20px auto" }}
                extra={
                  <Button
                    type="primary"
                    onClick={() => handleSearchWord(searchWord, true, true)}
                    disabled={reviewsLoading ? true : false}
                  >
                    {t("button.5")}
                  </Button>
                }
              />
              <ReviewsList
                reviewsLoading={reviewsLoading}
                allReviews={allReviews}
                numReviews={numReviews}
                startIndex={startIndex}
                setStartIndex={setStartIndex}
                getAllReviews={getSearchResults}
                getOnlyReviews={getOnlyReviews}
                setNumOfDocs={setNumOfDocs}
                numOfDocs={numOfDocs}
                handleTagSelection={handleSearchWord}
                user={user}
                setReviewsLoading={setReviewsLoading}
                getHighlights={getHighlights}
                highlights={highlights}
                shared={shared}
                searchControllerRef={searchControllerRef}
                currentDashboardHash={initialState.currentDashboard}
                boardDataSrc
                setCreateModal={setCreateModal}
                setCreateTaskModal={() => {
                  if (
                    userReducer.trello_status === "active" ||
                    userReducer.clickup_status === "active" ||
                    userReducer.jira_status === "active"
                  ) {
                    setCreateTaskModal(true);
                  } else {
                    notification.info({
                      message: t("notifications.48"),
                      icon: (
                        <InfoCircleOutlined
                          style={{
                            color: theme === "dark" ? "white" : "black",
                          }}
                        />
                      ),
                    });
                  }
                }}
                // facebookReplies={facebookReplies}
                // getFacebookReplies={getFacebookReplies}
                setFilters={setFilters}
                filters={filters}
                resetPivotFilters={handleResetPivotFilters}
                resetTopicFilters={handleResetTopicFilters}
                selectedPivots={selectedPivots}
                pivotOptions={pivotOptions}
                multiLevelOptions={multiLevelOptions}
                setSelectedPivots={setSelectedPivots}
                setSelectedDecisions={setSelectedDecisions}
                levelWisePivotSelections={levelWisePivotSelections}
                setLevelWisePivotSelections={setLevelWisePivotSelections}
                topicRatingsLoading={topicRatingsLoading}
                topTopics={topTopics}
                handleClearAllTopTopics={handleClearAllTopTopics}
                checkedTopicIds={checkedTopicIds}
                setCheckedTopicIds={setCheckedTopicIds}
                selectedTopicIds={selectedTopicIds}
                setSelectedTopicIds={setSelectedTopicIds}
                selectedTopicFromBars={selectedTopicFromBars}
                setSelectedTopicFromBars={setSelectedTopicFromBars}
                npsScale={npsScale}
                cursors={cursors}
                setCursorNext={setCursorNext}
                backWardCursors={backWardCursors}
              />
            </div>
          </BoardCard>
        </div>
      </Spin>
      <Modal1
        showModal={createModal}
        onClose={() => {
          setCreateModal(false);
          setInsightTxt("");
          setinsightTitleTxt("");
        }}
        onSave={handleCreateInsight}
        showSaveButton={true}
        titleAsset=""
        title={
          <span className={styles.insightTitle}>
            {t("selected_dashboard_page.65")}{" "}
            <img
              className={styles.message_img}
              src={
                theme === "dark"
                  ? "/assets/info1.svg"
                  : "/assets/info1_black.svg"
              }
              style={{ cursor: "pointer" }}
              width={20}
              height={20}
              onClick={() => {
                const win = window.open(
                  "https://pivony.notion.site/Insight-Creation-160faa1a90cc4191baf11fb62c88d808",
                  "_blank"
                );
                win.focus();
              }}
            />
          </span>
        }
        widthofmodal="420px"
        loadingSaveClick={createInsightLoading}
      >
        <div className={styles.titles}>
          <Input.TextArea
            style={{
              height: "2px",
              marginBottom: "7px",
              width: "400px",
              background: "transparent",
              color: theme === "dark" ? "white" : "black",
              borderColor: "#6f6c99",
              borderRadius: "10px",
            }}
            placeholder={t("placeholder.14")}
            // onKeyPress={(e) => keyPressed(e, handleCreateInsight)}
            onChange={(e) => setinsightTitleTxt(e.target.value)}
            value={insightTitleTxt}
          />
        </div>
        <div className={styles.titles}>
          <Input.TextArea
            style={{
              height: "130px",
              width: "400px",
              background: "transparent",
              color: theme === "dark" ? "white" : "black",
              borderColor: "#6f6c99",
              borderRadius: "10px",
            }}
            placeholder={t("selected_dashboard_page.15")}
            // onKeyPress={(e) => keyPressed(e, handleCreateInsight)}
            onChange={(e) => setInsightTxt(e.target.value)}
            value={insightTxt}
          />
        </div>
      </Modal1>
      {userReducer?.trello_status === "active" && (
        <CreateTaskTrelloModal
          userReducer={userReducer}
          user={user}
          showModal={createTaskModal}
          onClose={() => setCreateTaskModal(false)}
          dashboardId={DashboardReducer?.dashboard?.ID}
          getSearchState={getSearchState}
          timeInterval={timeInterval}
          total_num_docs={numReviews}
          reporter={userReducer.IsExpert ? 2 : 1}
        />
      )}
      {userReducer?.jira_status === "active" && (
        <CreateTaskJiraModal
          userReducer={userReducer}
          user={user}
          showModal={createTaskModal}
          onClose={() => setCreateTaskModal(false)}
          dashboardId={DashboardReducer?.dashboard?.ID}
          getSearchState={getSearchState}
          timeInterval={timeInterval}
          total_num_docs={numReviews}
          reporter={userReducer.IsExpert ? 2 : 1}
        />
      )}
      {userReducer?.clickup_status === "active" && (
        <CreateTaskClickupModal
          userReducer={userReducer}
          user={user}
          showModal={createTaskModal}
          onClose={() => setCreateTaskModal(false)}
          dashboardId={DashboardReducer?.dashboard?.ID}
          getSearchState={getSearchState}
          timeInterval={timeInterval}
          total_num_docs={numReviews}
          reporter={userReducer.IsExpert ? 2 : 1}
        />
      )}
      {showPopupSequence && (
        <DataSelectionPopup
          open={showPopupSequence}
          onClose={() => {
            setShowPopupSequence(false);
            setSelectedOutcomeMetric(1);
            setSelectedCustomTopics([]);
            setSelectedPivotColumns([]);
            setOutcomeMetrics([]);
          }}
          onSave={() => {
            setShowPopupSequence(false);
            setShowDriverPopup(true);
          }}
          dataType={"Outcome Metric"}
          description={"Choose the metric you want to check the analysis"}
          options={outcomeMetrics}
          columnHeaders={[{ title: "Outcome Metric", align: "left" }]}
          selectedItems={selectedOutcomeMetric}
          setSelectedItems={setSelectedOutcomeMetric}
          resetPopup={() => {
            setShowPopupSequence(false);
            setSelectedOutcomeMetric(1);
            setSelectedCustomTopics([]);
            setSelectedPivotColumns([]);
            setOutcomeMetrics([]);
          }}
          popupWidth="400px"
          selectionType={"single"}
          saveButtonText={"Next: Select Potential Drivers"}
          dataLoading={tableDataLoading}
        />
      )}
      {showDriverPopup && (
        <DataSelectionPopup
          open={showDriverPopup}
          onClose={() => {
            setShowDriverPopup(false);
            setSelectedOutcomeMetric(1);
            setSelectedCustomTopics([]);
            setSelectedPivotColumns([]);
            setPotentialDrivers([]);
          }}
          dataType={"Potential Drivers"}
          description={"Choose the potential drivers to analyze the data"}
          options={potentialDrivers}
          columnHeaders={[{ title: "Potential Driver", align: "left" }]}
          saveButtonText={"Analyze"}
          onSave={() => {
            if ([...selectedCustomTopics, ...selectedPivotColumns].length < 2) {
              notification.warning({
                message: "Please select at least two drivers",
              });
              return;
            }
            // if ([...selectedCustomTopics, ...selectedPivotColumns] > 10) {
            //   notification.warning({
            //     message: "Too much data for the analysis",
            //     description: "Max 10 topics can be selected.",
            //   });
            //   return;
            // }
            setEmptyDriverAnalysis(false);
            setShowDriverPopup(false);
            /*Call the generate scattered chart API*/
            saveKeyDriverConfig();
            getKeyDriverAnalysis();
          }}
          selectedCustomTopics={selectedCustomTopics}
          setSelectedCustomTopics={setSelectedCustomTopics}
          selectedPivotColumns={selectedPivotColumns}
          setSelectedPivotColumns={setSelectedPivotColumns}
          resetPopup={() => {
            setShowDriverPopup(false);
            setSelectedOutcomeMetric(1);
            setSelectedCustomTopics([]);
            setSelectedPivotColumns([]);
            setOutcomeMetrics([]);
            setPotentialDrivers([]);
          }}
          selectionType={"multiple"}
          dataLoading={tableDataLoading}
          emptyDataContent={
            <div className={styles.emptyDataContent}>
              <span className={styles.emptyMsg}>
                Please run some topics on the dashboard
              </span>
              <span
                className={styles.redirectBtn}
                onClick={() => {
                  history.push(`/console/topicLibrary`);
                }}
              >
                Go to Topic Library
              </span>
            </div>
          }
        />
      )}
      {showFileTypePopup && (
        <FileTypePopup
          loading={downloadDataLoading}
          setShowFileTypePopup={setShowFileTypePopup}
          downloadData={downloadData}
          fileType={fileType}
          setFileType={setFileType}
        />
      )}
      {showAdditionalSearchPopup && (
        <Modal
          showModal={showAdditionalSearchPopup}
          onClose={() => {
            setShowAdditionalSearchPopup(false);
          }}
          onDiscard={() => {
            setShowAdditionalSearchPopup(false);
            updateQParams(
              "keyword",
              JSON.stringify([{ label: searchWord, type: "search" }])
            );
            dispatch({
              type: actions.UPDATE_SEARCH_STATE,
              payload: {
                chosenKeywords: [{ label: searchWord, type: "search" }],
              },
            });
            setSearchWord("");
          }}
          onSave={() => {
            setShowAdditionalSearchPopup(false);
            const selectedTags = params.has("keyword")
              ? JSON.parse(params.get("keyword"))
              : [];
            const nextSelectedTags = [
              ...selectedTags,
              { label: searchWord, type: "search" },
            ];
            params.delete("keyword");
            params.append("keyword", JSON.stringify(nextSelectedTags));
            history.push({ search: params.toString() });
            dispatch({
              type: actions.UPDATE_SEARCH_STATE,
              payload: {
                chosenKeywords: nextSelectedTags,
              },
            });
            setSearchWord("");
          }}
          showSaveButton={true}
          showCancelButton={true}
          textonButton={"Yes, Continue"}
          cancelButtonText={"No, Discard"}
          titleAsset=""
          title={"Do a combined text search?"}
          widthofmodal="460px"
        >
          <p>Do you want to keep the previous text search?</p>
        </Modal>
      )}
    </div>
  );
};

export default DashboardData;
