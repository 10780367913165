import React from "react";
import { Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import { scrollToElement } from "util/common";
import styles from "./style.module.scss";
import { useTranslation } from 'react-i18next';

const ScrollToReviews = () => {
  const showBtn = useSelector(
    (state) => state.DashboardReducer.showNewReviewsLabel
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const hideBtn = () => {
    scrollToElement("ReviewsList");
    dispatch({
      type: actions.UPDATE_Dashboard,
      payload: {
        showNewReviewsLabel: false,
      },
    });
  };

  return (
    <div className={styles.scrollBtn}>
      <Button
        icon={<i className="fa fa-arrow-down" />}
        style={{ display: showBtn ? "block" : "none", opacity: showBtn ? 1 : 0 }}
        onClick={hideBtn}
      >
        {t("selected_dashboard_page.4")}
      </Button>
    </div>
  );
};

export default ScrollToReviews;
