import React, { useState, useEffect, useRef } from "react";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js";
import styles from "./style.module.scss";
import { Line } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import CreationItem from "components/NewDashboard/CreationItem";
import { apiRequest } from "util/services";
import { useHistory, useLocation } from "react-router-dom";
import { notification, Spin } from "antd";
import { hexToRgbA } from "util/common";

const LineChartWrapper = ({
  Time_Rating,
  Time_Daily_Rating,
  VolumeDaily,
  SentimentDaily,
  clickable,
  height,
  data,
  allData,
  hideLegend,
  TopicId,
  topicsType,
  DashboardId,
  period,
  max_y,
}) => {
  const dispatch = useDispatch();
  const [localState, setState] = useState({ data: {} });
  const [loading, setLoading] = useState(false);
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const theme = DashboardReducer.theme;

  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const [queryFilters, setQueryFilters] = useState({});

  useEffect(() => {
    if (params) {
      const values = Array.from(params);
      const queryObj = values.reduce((acc, [key, val]) => {
        if (
          key === "keyword" ||
          key === "pivotFilter" ||
          key === "top_topics" ||
          key === "level1Selection" ||
          key === "level2Selection" ||
          key === "level1OfSelected" ||
          key === "level2OfSelected"
        ) {
          acc[key] = JSON.parse(val);
        } else {
          acc[key] = val;
        }
        return acc;
      }, {});
      setQueryFilters(queryObj);
    }
  }, [history, search]);
  const customizeGraphData = (dataArr, valueKeyName) => {
    if (!dataArr || dataArr.length === 0) return { labels: [], data: [] };
    const sortedArr = dataArr?.sort(
      (a, b) => new Date(a.day) - new Date(b.day)
    );

    if (
      sortedArr[0][valueKeyName] === undefined &&
      valueKeyName === "num_of_docs"
    ) {
      valueKeyName = "num_of_tweet";
    }
    return {
      labels: sortedArr.map((item) => item.day),
      data: sortedArr.map((item) => item[valueKeyName]),
    };
  };
  const allVolData = data?.map((d) => customizeGraphData(d, "num_of_docs"));
  const allPosSentData = data?.map((d) => customizeGraphData(d, "num_of_pos"));
  const datesArr = VolumeDaily
    ? allVolData?.map((volData) => volData.labels)
    : allPosSentData?.map((posSentData) => posSentData.labels);
  const dateRange = DashboardReducer.searchState.dateRange;
  let dateArray = [];
  let uniqueAllDates = [];
  if (params.get("since") && params.get("until")) {
    const startDate = new Date(params.get("since"));
    const endDate = new Date(params.get("until"));
    let currentDate = startDate;
    while (currentDate <= endDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    const allDates = dateArray?.map((date) => {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;

      if (
        period &&
        (period.toLowerCase() === "daily" || period.toLowerCase() === "default")
      ) {
        return `${year}-${month}-${day}`;
      } else if (period && period.toLowerCase() === "monthly") {
        return `${year}-${month}`;
      } else if (period && period.toLowerCase() === "yearly") {
        return `${year}`;
      } else {
        return `${year}-${month}-${day}`;
      }
    });
    uniqueAllDates = [...new Set(allDates)];
  } else {
    if (data) {
      const datesUnion = [...new Set(datesArr.flat())];
      uniqueAllDates = datesUnion;
    }
    if (allData) {
      const sentD = allData?.SentimentDaily;
      const volD = allData?.VolumeDaily;
      const ratD = allData?.RatingsDaily
        ? Object.values(allData.RatingsDaily)
        : [];
      const rUnion = [
        ...new Set(ratD[0]?.map((obj) => Object.keys(obj)).flat()),
      ];
      const dtArr = [];
      if (volD) {
        dtArr.push(customizeGraphData(volD, "num_of_docs").labels);
      }
      if (sentD) {
        dtArr.push(customizeGraphData(sentD, "num_of_pos").labels);
      }
      if (ratD) {
        dtArr.push(rUnion);
      }
      uniqueAllDates = [...new Set(dtArr.flat())];
    }
  }
  // Sort the Array by date
  uniqueAllDates.sort((a, b) => {
    return new Date(a) - new Date(b);
  });
  // When there's only one date in the array
  if (uniqueAllDates && uniqueAllDates.length === 1 && uniqueAllDates[0]) {
    let dateStr = uniqueAllDates.pop();
    let date = new Date(dateStr);
    let prevDate = "";
    let nextDate = "";

    if (isNaN(date.getTime())) {
      console.error(`Invalid date: ${dateStr}`);
    } else {
      if (period && period.toLowerCase() === "monthly") {
        date.setMonth(date.getMonth() - 1);
        prevDate = date.toISOString().slice(0, 7);
        date.setMonth(date.getMonth() + 2);
        nextDate = date.toISOString().slice(0, 7);
      } else if (period && period.toLowerCase() === "yearly") {
        date.setFullYear(date.getFullYear() - 1);
        prevDate = date.toISOString().slice(0, 4);
        date.setFullYear(date.getFullYear() + 2);
        nextDate = date.toISOString().slice(0, 4);
      } else {
        date.setDate(date.getDate() - 1);
        prevDate = date.toISOString().split("T")[0];
        date.setDate(date.getDate() + 2);
        nextDate = date.toISOString().split("T")[0];
      }
      uniqueAllDates = [prevDate, dateStr, nextDate];
    }
  }
  const updateGraphData = (volumetric, sentimental) => {
    let data = {};
    if (volumetric) {
      const volData = customizeGraphData(volumetric, "num_of_docs");
      if (uniqueAllDates && uniqueAllDates?.length > 0 && uniqueAllDates[0]) {
        for (let i = 0; i < uniqueAllDates?.length; i++) {
          let index = volData.labels.indexOf(uniqueAllDates[i]);
          if (index === -1) {
            volData.labels.splice(i, 0, uniqueAllDates[i]);
            volData.data.splice(i, 0, 0);
          }
        }
      }

      data = {
        labels: volData.labels,
        datasets: [
          {
            label: "Volumetric data",
            lineTension: 0.5,
            borderColor: "rgba(33, 172, 158, 0.8)",
            borderWidth: 4,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: "white",
            data: volData.data,
            fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
          },
        ],
      };
    } else if (sentimental) {
      let posSentData = customizeGraphData(sentimental, "num_of_pos");
      let negSentData = customizeGraphData(sentimental, "num_of_neg");
      if (uniqueAllDates && uniqueAllDates?.length > 0 && uniqueAllDates[0]) {
        for (let i = 0; i < uniqueAllDates?.length; i++) {
          let pIndex = posSentData.labels.indexOf(uniqueAllDates[i]);
          if (pIndex === -1) {
            posSentData.labels.splice(i, 0, uniqueAllDates[i]);
            posSentData.data.splice(i, 0, 0);
          }
          let nIndex = negSentData.labels.indexOf(uniqueAllDates[i]);
          if (nIndex === -1) {
            negSentData.labels.splice(i, 0, uniqueAllDates[i]);
            negSentData.data.splice(i, 0, 0);
          }
        }
      }
      data = {
        labels: posSentData.labels,
        datasets: [
          {
            label: "Positive sentiment",
            lineTension: 0.5,
            data: posSentData.data,
            borderColor: "rgba(16, 200, 0, 1)",
            borderWidth: 4,
            pointHoverRadius: 6,
            backgroundColor: "transparent",
            pointHoverBackgroundColor: "white",
          },
          {
            label: "Negative sentiment",
            lineTension: 0.5,
            data: negSentData.data,
            borderColor: "rgba(220, 37, 37, 1)",
            borderWidth: 4,
            pointHoverRadius: 6,
            backgroundColor: "transparent",
            pointHoverBackgroundColor: "white",
          },
        ],
      };
    }
    setState({ ...localState, data });
    setLoading(false);
  };
  useEffect(() => {
    if (VolumeDaily) {
      updateGraphData(VolumeDaily);
    } else if (SentimentDaily) {
      updateGraphData(null, SentimentDaily);
    }
  }, [VolumeDaily, SentimentDaily]);

  const handlePointClick = (item, type) => {
    if (item && item[0] && clickable) {
      const datasetIndex = item[0]._datasetIndex;
      const index = item[0]._index;
      params.delete("match_date");
      params.append("match_date", localState.data.labels[index]);
      params.delete("since");
      params.delete("until");
      params.delete("daysRange");
      params.append("daysRange", "allTime");
      if (type === "volumetric") {
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            chosenDate: localState.data.labels[index],
            dateRange: [],
          },
        });
      } else {
        let sentiment = "0.5";
        if (datasetIndex === 0) sentiment = "1.0";
        else if (datasetIndex === 1) sentiment = "0.0";
        params.delete("sentiment");
        params.append("sentiment", sentiment);
        dispatch({
          type: actions.UPDATE_SEARCH_STATE,
          payload: {
            chosenDate: localState.data.labels[index],
            chosenSentiment: sentiment,
            dateRange: [],
          },
        });
      }
      history.push({ search: params.toString() });
    }
  };

  const changeView = async () => {
    const payload = {
      dashboard_id: DashboardId,
      topic_id: TopicId,
      topic_type: topicsType,
      period: localState.view,
    };
    const result = await apiRequest(
      "dashboards/change/graph_view",
      "POST",
      payload,
      true
    );
    if (result.error) {
      notification.error({
        message: result.error,
      });
    } else if (VolumeDaily) {
      updateGraphData(result.VolumeDaily);
    } else {
      updateGraphData(null, result.SentimentDaily);
    }
  };

  useEffect(() => {
    if (localState.view && localState.view !== "default") {
      setLoading(true);
      changeView();
    }
  }, [localState.view]);
  let a = [];
  let b = [];
  let z = [];
  let y = [];

  const Google = [];
  const Apple = [];
  let result = [];
  let labels = [];
  let RatingsDaily = Time_Daily_Rating;

  const Filtering = (arr) => {
    return arr.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  };

  // if (Time_Daily_Rating) {

  //   let uniquelabel = []
  //   for (let i = 0; i < RatingsDaily["2"]?.length; i++) {
  //     if (!RatingsDaily["4"]) {
  //       uniquelabel.push(Object.keys(RatingsDaily["2"][i]));
  //     }
  //     Google.push((RatingsDaily["2"][i]))
  //   }

  //   for (let i = 0; i < RatingsDaily["4"]?.length; i++) {

  //     uniquelabel.push(Object.keys(RatingsDaily["4"][i]));
  //     Apple.push((RatingsDaily["4"][i]))

  //   }
  //   result = Filtering(uniquelabel);
  //   result.sort((date1, date2) => date1 - date2);

  //   function getAvgRating(value, index, array) {
  //     return Object.values(value)[0]
  //   }
  //   const a = Google.map(getAvgRating);
  //   const b = Apple.map(getAvgRating);

  //   z = RatingsDaily["2"] ? a : [];
  //   y = RatingsDaily["4"] ? b : [];
  // }

  // labels = result

  const getData = (canvas) => {
    const ctx = canvas.getContext("2d");
    let gradientBG1 = ctx.createLinearGradient(0, 0, 0, canvas.height);
    let gradientLine1 = ctx.createLinearGradient(0, 0, canvas.width, 0);

    let gradientBG2 = ctx.createLinearGradient(0, 0, 0, canvas.height);
    let gradientLine2 = ctx.createLinearGradient(0, 0, canvas.width, 0);

    gradientBG1.addColorStop(0, "rgba(65, 242, 255, 0.3)");
    gradientBG1.addColorStop(1, "rgba(75, 215, 230, 0)");
    gradientBG1.addColorStop(0.6, "rgba(75, 215, 230, 0)");

    gradientLine1.addColorStop(0, "rgba(65, 242, 255)");
    gradientLine1.addColorStop(1, "rgba(65, 242, 255)");
    gradientLine1.addColorStop(0.8, "rgba(7, 202, 217)");

    gradientBG2.addColorStop(0, "rgba(239, 98, 68, 0.3)");
    gradientBG2.addColorStop(1, "rgba(239, 98, 68, 0)");
    gradientBG2.addColorStop(0.6, "rgba(239, 98, 68, 0)");

    gradientLine2.addColorStop(0, "rgba(239, 98, 68)");
    gradientLine2.addColorStop(1, "rgba(239, 98, 68)");
    gradientLine2.addColorStop(0.5, "rgba(252, 23, 229)");
    // if (Time_Daily_Rating) {
    if (
      Time_Daily_Rating["14"] ||
      Time_Daily_Rating["17"] ||
      Time_Daily_Rating["None"]
    ) {
      let tempData = [];
      let tempLabel = [];
      let num = Time_Daily_Rating["14"]
        ? 14
        : Time_Daily_Rating["17"]
        ? 17
        : "None";
      Time_Daily_Rating[num].map((item) => {
        for (const key in item) {
          tempData.push(item[key]);
          tempLabel.push(key);
        }
      });
      if (uniqueAllDates && uniqueAllDates?.length > 0 && uniqueAllDates[0]) {
        for (let i = 0; i < uniqueAllDates?.length; i++) {
          let index = tempLabel.indexOf(uniqueAllDates[i]);
          if (index === -1) {
            tempLabel.splice(i, 0, uniqueAllDates[i]);
            tempData.splice(i, 0, 0);
          }
        }
      }
      return {
        labels: tempLabel,
        datasets: [
          {
            label:
              num === 14 ? "Trendyol" : num === "None" ? "Custom" : "Amazon",
            data: tempData,
            backgroundColor: gradientBG1,
            lineTension: 0.5,
            borderColor: gradientLine1,
            borderWidth: 4,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: "white",
          },
        ],
      };
    } else if (Time_Daily_Rating["2"] || Time_Daily_Rating["4"]) {
      let tempGoogleData = [];
      let tempGoogleLabel = [];
      let tempAppleData = [];
      let tempAppleLabel = [];
      Time_Daily_Rating["2"] &&
        Time_Daily_Rating["2"].map((item) => {
          for (const key in item) {
            tempGoogleData.push(item[key]);
            tempGoogleLabel.push(key);
          }
        });
      Time_Daily_Rating["4"] &&
        Time_Daily_Rating["4"].map((item) => {
          for (const key in item) {
            tempAppleData.push(item[key]);
            tempAppleLabel.push(key);
          }
        });
      if (uniqueAllDates && uniqueAllDates?.length > 0 && uniqueAllDates[0]) {
        for (let i = 0; i < uniqueAllDates?.length; i++) {
          let gIndex = tempGoogleLabel.indexOf(uniqueAllDates[i]);
          if (gIndex === -1) {
            tempGoogleLabel.splice(i, 0, uniqueAllDates[i]);
            tempGoogleData.splice(i, 0, 0);
          }
          let aIndex = tempAppleLabel.indexOf(uniqueAllDates[i]);
          if (aIndex === -1) {
            tempAppleLabel.splice(i, 0, uniqueAllDates[i]);
            tempAppleData.splice(i, 0, 0);
          }
        }
      }
      return {
        labels: [...new Set(tempGoogleLabel.concat(tempAppleLabel))],
        datasets: [
          {
            label: "Google play",
            lineTension: 0.5,
            data: tempGoogleData,
            borderColor: "#9054A5",
            borderWidth: 4,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: "white",
            backgroundColor: hexToRgbA("#9054A5", 0.5),
          },
          {
            label: "Apple store",
            lineTension: 0.5,
            data: tempAppleData,
            borderColor: gradientLine2,
            borderWidth: 4,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: "white",
            backgroundColor: gradientBG2,
          },
        ],
      };
    } else if (
      Time_Daily_Rating["33"] ||
      Time_Daily_Rating["34"] ||
      Time_Daily_Rating["35"]
    ) {
      let tempData = [];
      let tempLabel = [];
      let num = Time_Daily_Rating["33"]
        ? 33
        : Time_Daily_Rating["34"]
        ? 34
        : 35;
      Time_Daily_Rating[num].map((item) => {
        for (const key in item) {
          tempData.push(item[key]);
          tempLabel.push(key);
        }
      });
      if (uniqueAllDates && uniqueAllDates?.length > 0 && uniqueAllDates[0]) {
        for (let i = 0; i < uniqueAllDates?.length; i++) {
          let index = tempLabel.indexOf(uniqueAllDates[i]);
          if (index === -1) {
            tempLabel.splice(i, 0, uniqueAllDates[i]);
            tempData.splice(i, 0, 0);
          }
        }
      }
      return {
        labels: tempLabel,
        datasets: [
          {
            label: num === 33 ? "ACS" : num === 34 ? "Otelpuan" : "Anket",
            data: tempData,
            backgroundColor: hexToRgbA("#9054A5", 0.5),
            lineTension: 0.5,
            borderColor: "#9054A5",
            borderWidth: 4,
            pointHoverRadius: 6,
            pointHoverBackgroundColor: "white",
          },
        ],
      };
    } else return [];
    // } else {
    //   return [];
    // }
  };

  // const getLabel = () => {
  //   if (Time_Daily_Rating) {
  //     let temp = Time_Daily_Rating[14].map(item => {
  //       for (const key in item) {
  //         return key;
  //       }
  //     })
  //     return temp
  //   } else return [];
  // }

  // const data2 = {
  //   labels: getLabel(),
  //   datasets: [
  //     {
  //       data: getData(),
  //       lineTension: 0.5,
  //       borderColor: "rgba(75,192,192,1)",
  //       borderWidth: 1
  //     }
  //   ],
  // };
  const tooltipLine = {
    id: "tooltipLine",
    beforeDraw: (chart) => {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const ctx = chart.ctx;
        ctx.save();
        const activePoint = chart.tooltip._active[0];
        ctx.beginPath();
        ctx.setLineDash([4, 6]);
        ctx.moveTo(activePoint?._view?.x, chart.chartArea.top);
        ctx.lineTo(activePoint?._view?.x, chart.chartArea.bottom);
        ctx.lineWidth = 2;
        ctx.strokeStyle = "rgba(155, 155, 155)";
        ctx.stroke();
        ctx.restore();
      }
    },
  };

  return (
    <>
      {!Time_Rating ? (
        <div className={styles.container}>
          {TopicId && (
            <CreationItem
              item={{
                valueType: "dropdown",
                acceptedValues: ["daily", "weekly", "monthly", "yearly"],
                placeholder: "Select the chart view",
                key: "view",
              }}
              localState={localState}
              setState={setState}
              defaultValue={"monthly"}
              value={localState.view}
            />
          )}
          <Spin spinning={loading}>
            <div className={styles.chartAreaWrapper}>
              <Line
                data={localState.data}
                plugins={tooltipLine}
                height={height ? height : 220}
                options={{
                  color: "rgba(75,192,192,1)",
                  responsive: true,
                  maintainAspectRatio: false,
                  onClick: function(e) {
                    if (!clickable) return;
                    var point = this.getElementAtEvent(e)[0];
                    handlePointClick(
                      [point],
                      VolumeDaily ? "volumetric" : "sentiment"
                    );
                  },
                  scales: {
                    xAxes: [
                      {
                        gridLines: {
                          drawOnChartArea: false,
                          drawTicks: false,
                          color: "#808080",
                        },
                        ticks: {
                          padding: 20,
                          fontColor: theme === "dark" ? "white" : "black",
                        },
                      },
                    ],
                    yAxes: [
                      {
                        afterDataLimits: (axis) => {
                          axis.max += 1;
                        },
                        ticks: {
                          beginAtZero: true,
                          userCallback: function(label, index, labels) {
                            // when the floored value is the same as the value we have a whole number
                            if (Math.floor(label) === label) {
                              return label;
                            }
                          },

                          suggestedMax: max_y,
                          maxTicksLimit: 4,
                          padding: 20,
                          fontColor: theme === "dark" ? "white" : "black",
                        },
                        gridLines: {
                          drawOnChartArea: false,
                          drawTicks: false,
                          color: "#808080",
                        },
                      },
                    ],
                  },
                  legend: {
                    display: !hideLegend,
                  },
                  elements: {
                    point: {
                      radius: 0,
                      hitRadius: 3,
                    },
                  },
                  hover: {
                    mode: "point",
                    intersect: true,
                  },
                  interaction: {
                    mode: "index",
                    intersect: false,
                  },
                  layout: {
                    padding: {
                      top: 20,
                    },
                  },
                  tooltips: {
                    backgroundColor: "rgba(0,0,0,0.5)",
                    mode: "label",
                    intersect: false,
                  },
                  plugins: {
                    datalabels: {
                      labels: {
                        title: null,
                      },
                    },
                  },
                }}
              />
            </div>
          </Spin>
        </div>
      ) : (
        <div className={styles.container}>
          {TopicId && (
            <CreationItem
              item={{
                valueType: "dropdown",
                acceptedValues: ["daily", "weekly", "monthly", "yearly"],
                placeholder: "Select the chart view",
                key: "view",
              }}
              localState={localState}
              setState={setState}
              defaultValue={"monthly"}
              value={localState.view}
            />
          )}
          <Spin spinning={loading}>
            <div className={styles.chartAreaWrapper}>
              {Time_Daily_Rating && (
                <Line
                  data={getData}
                  height={height ? height : 220}
                  plugins={tooltipLine}
                  options={{
                    color: "rgba(75,192,192,1)",
                    responsive: true,
                    maintainAspectRatio: false,
                    onClick: function(e) {
                      if (!clickable) return;
                      var point = this.getElementAtEvent(e)[0];
                      handlePointClick([point]);
                    },
                    // onHover: (context) => {
                    //   context.chart.update()
                    // },
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            drawOnChartArea: false,
                            drawTicks: false,
                            color: "#808080",
                          },
                          ticks: {
                            display: true,
                            padding: 20,
                            fontColor: theme === "dark" ? "white" : "black",
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            drawOnChartArea: false,
                            drawTicks: false,
                            color: "#808080",
                          },
                          ticks: {
                            beginAtZero: true,
                            stepSize: 2,
                            padding: 20,
                            fontColor: theme === "dark" ? "white" : "black",
                          },
                        },
                      ],
                    },
                    hover: {
                      mode: "point",
                      intersect: true,
                    },
                    legend: {
                      display: true,
                      labels: {
                        lineWidth: 2,
                      },
                    },
                    elements: {
                      point: {
                        radius: 0,
                        hitRadius: 3,
                      },
                      line: {
                        fill: "bottom",
                      },
                    },
                    plugins: {
                      datalabels: {
                        labels: {
                          title: null,
                        },
                      },
                    },
                    interaction: {
                      mode: "point",
                    },
                    tooltips: {
                      backgroundColor: "rgba(0,0,0,0.5)",
                      mode: "label",
                      intersect: false,
                    },
                  }}
                />
              )}
            </div>
          </Spin>
        </div>
      )}
    </>
  );
};

export default LineChartWrapper;
