import React from "react";
import "./stylesheets/App.scss";
import firebase from "./FirebaseConfig.js";
import { Router, Route, Switch } from "react-router-dom";
import Loading from "./helperComponents/Loading";
import { LOGGED, UNKNOWN, UNLOGGED } from "./util/constants";
import { routes } from "./util/router.js";
import IndexLayout from "./Layouts";
import TopMenu from "./helperComponents/TopMenu";
import { apiRequest, signout } from "./util/services";
import $ from "jquery";
import { setWithExpiry } from "util/common";
import { SET_USER } from "redux/user/actions.js";
import { connect } from "react-redux";
import mixpanel from "mixpanel-browser";
import ChartsContainer from "components/GlobalExecutiveDashboard/ChartsContainer";
import { getParams } from "util/common.js";
import history from "./history";
import Login from "authentication/Login";
import ReviewsContainer from "components/GlobalExecutiveDashboard/ChartsContainer/reviewsPresentation";
import ResetPassword from "authentication/Verification/ResetPassword";
import PageNotFound from "pages/PageNotFound";

import * as actions from "redux/Dashboard/actions.js";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      authenticated: UNKNOWN,
      params: "",
      redirectURL: getParams(document.location.href).redirectURL,
      user: {},
    };
  }

  getUserTheme() {
    if (localStorage.getItem("theme") === null) {
      apiRequest("users/theme", "POST", true)
        .then((res) => {
          if (res.success) {
            this.props.changeTheme(res.success);
          }
        })
        .catch((error) => console.log(error));
    } else {
      this.props.changeTheme(window.localStorage.getItem("theme"));
    }
  }
  navigate() {
    if (window.location.pathname == "/") {
      document.location.href = this.state.redirectURL
        ? this.state.redirectURL
        : document.location.origin + "/console/myDashboards";
    }
  }

  UNSAFE_componentWillMount() {
    const analytics = firebase.analytics();

    firebase.auth().onAuthStateChanged(async (res) => {
      if (res) {
        firebase
          .database()
          .ref(`/users/${res.uid}`)
          .on("value", async (snapshot) => {
            let user = snapshot.val();
            if (user) {
              user.uid = snapshot.key;
              analytics.setUserId(user.uid);
              // below check is to verify if the user has a plan or not, if no plan redirect to signup page
              if (
                user &&
                (!user.emailVerified || user.plan === undefined) &&
                window.location.pathname !== "/verification"
              ) {
                history.push("/signup");
                this.setState({
                  loading: false,
                  authenticated: LOGGED,
                  user,
                });
                return;
              }
              if (user.mandatoryLogout) {
                await firebase
                  .database()
                  .ref(`/users/${user.uid}/mandatoryLogout`)
                  .set(false);
                signout();
              }
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                userId: user.uid,
              });
              user.currency = "usd";
              user.currencySymbol = "$";

              const token = await apiRequest("users/token", "POST", {
                user_id: user.uid,
              });

              if (token.error) {
                // document.location.href = document.location.origin + '/login';
                signout(false);
                try {
                  apiRequest(
                    "users/client_log",
                    "POST",
                    { error: "Unable to generate new token - BE server" },
                    true
                  );
                } catch (er) {
                  console.log(er);
                }
              }

              if (firebase.auth().currentUser) {
                firebase
                  .auth()
                  .currentUser.getIdToken(true)
                  .then((token) => {})
                  .catch((err) => {
                    signout(false);
                    try {
                      apiRequest(
                        "users/client_log",
                        "POST",
                        { error: "Unable to generate new token" },
                        true
                      );
                    } catch (er) {
                      console.log(er);
                    }
                    // window.location.href = window.location.origin + '/login';
                    sessionStorage.setItem("loginFailed", true);
                  });
              }

              const remember_me_checked = window.localStorage.getItem(
                "remember_me"
              );

              setWithExpiry(
                "jwt_token",
                token.access_token,
                remember_me_checked === "true" ? 1440 : 3
              );

              $.ajax("https://get.geojs.io/v1/ip/country.json").then(
                (response) => {
                  user.country = response.name;
                  if (response.name === "Turkey") {
                    user.currency = "tl";
                    user.currencySymbol = "₺";
                  }
                  this.setState({
                    loading: false,
                    authenticated: LOGGED,
                    user,
                  });
                },

                (data, status) => {
                  this.setState({
                    loading: false,
                    authenticated: LOGGED,
                    user,
                  });
                }
              );
              this.getUserTheme();
              const subscription = await apiRequest(
                "subscriptions/all",
                "POST",
                {
                  user_id: user.uid,
                }
              );

              if (subscription) {
                this.props.subscribe(subscription);
                // i18n.changeLanguage(subscription[0].language);
              }

              this.setState({
                loading: false,
                authenticated: LOGGED,
                user,
              });
              if (window.location.pathname == "/login") {
                history.push("/console/myDashboards");
              }

              // this.navigate()
            } else {
              console.log("out");
            }
          });
      } else {
        if (res?.error) {
          try {
            apiRequest(
              "users/client_log",
              "POST",
              { error: res.error, message: "Login Failed" },
              true
            );
          } catch (er) {
            console.log(er);
          }

          console.log(res.error);
        }
        this.setState({ loading: false, authenticated: UNLOGGED });
      }
    });
  }

  render() {
    let { authenticated, user, loading } = this.state;
    let { theme } = this.props;
    let params = getParams(document.location.href);

    document.documentElement.style.setProperty(
      "--body",
      theme === "dark" ? "#100d31" : "#ffffff"
    );
    document.documentElement.style.setProperty(
      "--font",
      theme === "dark" ? "#ffffff" : theme === "light" ? "#000000" : "#4A4D4E"
    );

    document.documentElement.style.setProperty(
      "--border",
      theme === "dark" ? "#262250" : theme === "light" ? "#E8E0F4" : "#f4f4f4"
    );

    document.documentElement.style.setProperty(
      "--active",
      theme === "brand" ? "#4A4D4E" : "#4400aa"
    );
    document.documentElement.style.setProperty(
      "--secondary",
      theme === "dark" ? "#1c193b" : theme === "light" ? "#E8E0F4" : "#f4f4f4"
    );

    document.documentElement.style.setProperty(
      "--line",
      theme === "dark" ? "#262250" : "#00000024"
    );
    document.documentElement.style.setProperty(
      "--popup",
      theme === "dark" ? "#262250" : "#ffffff"
    );

    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      const getScrollBtn = document.querySelector("#scroll-to-top");
      if (scrolled > 600) {
        getScrollBtn.style.bottom = "127px";
      } else if (scrolled <= 600) {
        getScrollBtn.style.bottom = "-48px";
      }
    };

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    window.addEventListener("scroll", toggleVisible);

    if (loading) return <Loading />;
    return (
      <div className="App">
        <Router history={history}>
          {(params.mode && params.mode == "resetPassword") ||
          params.email ||
          (params.id && params.period) ? (
            ""
          ) : (
            <TopMenu authenticated={authenticated} user={user} theme={theme} />
          )}
          <Switch>
            <Route
              exact
              path="/login"
              render={(props) => (
                <IndexLayout authenticated={LOGGED} user={user} theme={theme}>
                  <Login
                    authenticated={LOGGED}
                    user={user}
                    theme={theme}
                    {...props}
                  />
                </IndexLayout>
              )}
            />
            <Route
              path="/global_executive/presentation"
              render={() => (
                <ChartsContainer authenticated={authenticated} user={user} />
              )}
            />
            <Route
              path="/global_executive/reviews"
              render={() => (
                <ReviewsContainer authenticated={authenticated} user={user} />
              )}
            />
            <Route
              exact
              path="/reset-password"
              render={(props) => (
                <ResetPassword
                  authenticated={LOGGED}
                  user={user}
                  theme={theme}
                  {...props}
                />
              )}
            />
            {/* Route for paths with valid integer id */}
            <Route
              exact
              path={[
                "/console/Dashboard/:id",
                "/console/customTopics/:id",
                "/console/DashboardData/:id",
                "/console/geolocation/:id",
                "/console/insights/:id",
                "/console/journey/:id",
                "/share/Dashboard/:id",
                "/share/DashboardData/:id",
                "/share/insights/:id",
              ]}
              render={({ match }) => {
                const { id } = match.params;
                // Check if id is a valid integer
                if (/^\d+$/.test(id)) {
                  const matchingRoute = routes.find(
                    (route) => route.path === match.path
                  );
                  if (matchingRoute) {
                    let DynamicComponent = matchingRoute.type;
                    return (
                      <IndexLayout
                        authenticated={authenticated}
                        user={user}
                        theme={theme}
                      >
                        <DynamicComponent
                          authenticated={authenticated}
                          user={user}
                          theme={theme}
                          match={match}
                        />
                      </IndexLayout>
                    );
                  }
                } else {
                  // Display PageNotFound if id is not a valid integer
                  return (
                    <PageNotFound
                      authenticated={LOGGED}
                      user={user}
                      theme={theme}
                    />
                  );
                }
              }}
            />
            {routes.map((route) => (
              <Route
                exact
                path={route.path}
                key={route.path}
                render={(props) => (
                  <IndexLayout
                    authenticated={authenticated}
                    user={user}
                    theme={theme}
                  >
                    <route.type
                      authenticated={authenticated}
                      user={user}
                      theme={theme}
                      {...props}
                      {...route.props}
                    />
                  </IndexLayout>
                )}
              />
            ))}
            {/* PageNotFound route */}
            <Route
              render={() => (
                <PageNotFound
                  authenticated={LOGGED}
                  user={user}
                  theme={theme}
                />
              )}
            />
          </Switch>
        </Router>
        <div id="scroll-to-top" onClick={scrollToTop}>
          <i className="fa-solid fa-arrow-up"></i>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeTheme: (color) => {
      return dispatch({
        type: actions.THEME_MODE,
        payload: {
          theme: color,
        },
      });
    },
    subscribe: (subscription) => {
      return dispatch({
        type: SET_USER,
        payload: subscription[0],
      });
    },
  };
};

const mapStateToProps = (state) => {
  return {
    theme: state.DashboardReducer.theme,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
