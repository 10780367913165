import React, { useState, useEffect, useRef } from "react";
import styles from "./style.module.scss";
import { UNLOGGED } from "util/constants";
import { useHistory } from "react-router-dom";
import "./chartsStyle.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTv } from "@fortawesome/free-solid-svg-icons";
import ChartsContainer from "components/GlobalExecutiveDashboard/ChartsContainer";
import { apiRequest } from "util/services";
import ShareButtonPopup from "helperComponents/ShareButtonPopup";
import { Select, notification, Radio, Button, Divider } from "antd";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const GlobalExecutiveDashboard = ({ authenticated, user, match, theme }) => {
  const history = useHistory();
  const [showOption, setShowOption] = useState(false);
  const [showBelowChartIndex, setShowBelowChartIndex] = useState(0);
  const [selectedTeamItem, setSelectedTeamItem] = useState("");
  const [selectedTeamName, setSelectedTeamName] = useState("");
  const [teamList, setTeamList] = useState([]);
  const [shareList, setShareList] = useState([]);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [presentationTitle, setPresentationTitle] = useState(
    "Global Executive Dashboard"
  );
  const [showTitlePopup, setShowTitlePopup] = useState(false);
  const [showDataPopup, setShowDataPopup] = useState(false);
  const [showDataLabel, setShowDataLabel] = useState(false);

  const [chartPeriod, setChartPeriod] = useState("M");
  const [periodPref, setPeriodPref] = useState("M");
  const [settingsLoading, setSettingsLoading] = useState(false);

  const [allowShare, setAllowShare] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [presentationType, setPresentationType] = useState("trend");
  const dataSettingsBtn = useRef();
  const { t } = useTranslation();
  if (authenticated === UNLOGGED) {
    history.push("/");
  }

  const handlePresentationMode = () => {
    setShowTitlePopup(true);
  };

  const gotoPresentation = () => {
    if (presentationType === "trend") {
      const open = window.open(
        `/global_executive/presentation/?id=${selectedTeamItem}&period=${chartPeriod}`,
        "_blank"
      );
      open.title = presentationTitle;
      open.showDataLabel = showDataLabel;
      setShowTitlePopup(false);
      setPresentationTitle("");
    } else {
      const open = window.open(
        `/global_executive/reviews?id=${selectedTeamItem}&period=${chartPeriod}&review=${true}`,
        "_blank"
      );
      open.title = presentationTitle;
      open.showDataLabel = showDataLabel;
      setShowTitlePopup(false);
      setPresentationTitle("");
    }
  };

  const handleTeamFilter = (value) => {
    setDataLoading(true);
    setSelectedTeamName(value);
    for (let i = 0; i < teamList?.length; i++) {
      if (value === teamList[i].team_name) {
        setSelectedTeamItem(teamList[i].team_id);
        setAllowShare(teamList[i].owned);
      }
    }
  };

  const getCollaborators = async () => {
    await getSettingsData();
    const result = await apiRequest(
      "executive_summary/kpi_views",
      "POST",
      true
    );
    if (result.success) {
      setTeamList(result?.success);
      setSelectedTeamItem(result?.success[0]?.team_id);
      setSelectedTeamName(result?.success[0]?.team_name);
      setAllowShare(result?.success[0]?.owned);
    }
  };

  useEffect(() => {
    getCollaborators();
  }, []);

  const getTeams = async () => {
    const payload = {
      team_id: selectedTeamItem,
    };
    const result = await apiRequest(
      "executive_summary/teams",
      "POST",
      payload,
      true
    );
    if (result.success) {
      setShareList(result?.success);
    }
  };

  const handleShareTeam = async (selectedItems) => {
    const payload = {
      shared_with: selectedItems,
      team_id: selectedTeamItem,
    };
    const result = await apiRequest(
      "executive_summary/share",
      "POST",
      payload,
      true
    );
    if (result.success) {
      notification.success({
        message: t("notifications.20"),
      });
      setShowSharePopup(false);
      getTeams();
    }
    if (result.error) {
      notification.error({
        message: result.error,
      });
    }
  };

  const outClick = (e) => {
    if (
      dataSettingsBtn.current &&
      !dataSettingsBtn.current.contains(e.target)
    ) {
      setShowDataPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", outClick, true);
    return () => document.removeEventListener("click", outClick, true);
  }, []);

  const handleShowDataLabel = (e) => {
    if (e.target.checked) {
      setShowDataLabel(true);
    } else setShowDataLabel(false);
  };

  const getSettingsData = async () => {
    setSettingsLoading(true);
    let payload = {};
    const result = await apiRequest(
      "executive_summary/settings",
      "POST",
      payload,
      true
    );
    if (result?.success) {
      setChartPeriod(result.success?.chartPeriod);
      setPeriodPref(result.success?.chartPeriod);
      if (result.success?.showNumbers) {
        setShowDataLabel(true);
      }
    }
    setSettingsLoading(false);
  };
  const UpdateSettingsData = async (period, showNumbers) => {
    let payload = {
      showNumbers: showNumbers,
      chartPeriod: period,
    };
    const result = await apiRequest(
      "executive_summary/edit_settings",
      "POST",
      payload,
      true
    );
    if (result?.success) {
    }
  };

  const ApplyChartPeriod = (value) => {
    if (value === "5D") {
      setChartPeriod("5D");
    } else if (value === "W") {
      setChartPeriod("W");
    } else {
      setChartPeriod("30D");
    }
    UpdateSettingsData(value, showDataLabel);
    setShowDataPopup(false);
  };
  useEffect(() => {
    if (selectedTeamItem) getTeams();
  }, [selectedTeamItem]);

  return (
    <div
      className={`mainWindow ${
        theme === "light" ? "light-mode" : theme === "brand" ? "brand-mode" : ""
      }`}
      onClick={() => {
        setShowOption(false);
        setShowBelowChartIndex("");
      }}
    >
      <div className={styles.top}>
        <div className={styles.title}>
          <div className={styles.text}>
            <h5 style={{ color: theme === "dark" ? "#fff" : "#000" }}>
              {t("dashboard_sidebar.2")}
            </h5>
            <span
              className={styles.divider}
              style={{ color: theme === "dark" ? "#fff" : "#000" }}
            >
              /
            </span>
            <Select
              value={selectedTeamName}
              onChange={handleTeamFilter}
              disabled={dataLoading}
              style={{ width: 200 }}
              showSearch
              optionFilterProp="title"
            >
              {teamList?.map((item) => (
                <Option
                  value={item.team_name}
                  key={item.team_id}
                  title={item.team_name}
                >
                  {item.team_name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="present-mode-block" ref={dataSettingsBtn}>
          <button
            className={styles.dataSettingsBtn}
            disabled={dataLoading ? true : false}
            onClick={() => {
              setShowDataPopup(true);
            }}
          >
            <img
              src={
                theme === "dark"
                  ? "/assets/settings.svg"
                  : "/assets/settings_black.svg"
              }
              alt=""
            />
          </button>
          {showDataPopup && (
            <div className={styles.dataPopup}>
              <p>{t("team_kpi_view.21")}</p>
              <span className={styles.subHead}>{t("team_kpi_view.32")}</span>
              <div className={styles.checkBox}>
                <input
                  id="showDataLabel"
                  type="checkbox"
                  onChange={handleShowDataLabel}
                  checked={showDataLabel}
                />
                <label htmlFor="showDataLabel">{t("others.9")}</label>
              </div>
              <hr style={{ color: theme === "dark" ? "white" : "black" }} />
              <span className={styles.subHead}>{t("team_kpi_view.33")}</span>
              <div className={styles.settingPrefrences}>
                <Radio.Group
                  value={periodPref}
                  onChange={(e) => {
                    setPeriodPref(e.target.value);
                  }}
                >
                  <Radio value="5D" id="5D">
                    {t("team_kpi_view.34")}
                  </Radio>
                  <Radio value="W" id="W">
                    {t("team_kpi_view.35")}
                  </Radio>
                  <Radio value="30D" id="30D">
                    {t("team_kpi_view.36")}
                  </Radio>
                </Radio.Group>
                <Button
                  className={styles.applyButton}
                  onClick={() => {
                    ApplyChartPeriod(periodPref);
                  }}
                >
                  {t("team_kpi_view.30")}
                </Button>
              </div>
            </div>
          )}
          {allowShare && !dataLoading && (
            <ShareButtonPopup
              setShowSharePopup={setShowSharePopup}
              showSharePopup={showSharePopup && allowShare}
              headingText={t("others.10")}
              handleShare={handleShareTeam}
              shareList={shareList}
            />
          )}
          <button
            type="button"
            disabled={dataLoading ? true : false}
            className="present-btn"
            onClick={handlePresentationMode}
          >
            <FontAwesomeIcon icon={faTv} />
            <span style={{ color: theme === "dark" ? "" : "#fff" }}>
              {t("team_kpi_view.2")}
            </span>
          </button>
        </div>
      </div>

      <ChartsContainer
        mode="default"
        showReviews={true}
        showOption={showOption}
        setShowOption={setShowOption}
        showBelowChartIndex={showBelowChartIndex}
        setShowBelowChartIndex={setShowBelowChartIndex}
        authenticated={authenticated}
        selectedTeamItem={selectedTeamItem}
        showDataLabel={showDataLabel}
        chartPeriod={chartPeriod}
        setDataLoading={setDataLoading}
      />

      {showTitlePopup && (
        <div
          className={styles.presentationTitleModal}
          onClick={() => setShowTitlePopup(false)}
        >
          <div className={styles.centerWrapper}>
            <div
              className={styles.modalContent}
              onClick={(e) => e.stopPropagation()}
            >
              <p>{t("team_kpi_view.3")}</p>
              <input
                type="text"
                value={presentationTitle}
                onChange={(e) => setPresentationTitle(e.target.value)}
              />
              <Radio.Group
                value={presentationType}
                className={styles.viewTypeWrapper}
                onChange={(e) => {
                  setPresentationType(e.target.value);
                }}
              >
                <Radio value="trend">
                  <span className={styles.fontColor}>
                    Display with Trend charts
                  </span>
                </Radio>
                <Radio value="reviews">
                  <span className={styles.fontColor}>
                    Display with Top reviews
                  </span>
                </Radio>
              </Radio.Group>
              <div className={styles.proceedBtn}>
                <button onClick={gotoPresentation}>{t("button.6")}</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobalExecutiveDashboard;
