import Landing from "../Landing";
import Verification from '../authentication/Verification';
import Login from '../authentication/Login';
import CustomerJourney from "pages/CustomerJourney/customerJourney";
import Signup from '../authentication/Signup';
import Dashboard from "pages/Dashboard"
import IndustryDashboard from "pages/IndustryDashboard"
import CustomDashboard from "pages/CustomDashboard"
import SharedDashboard from "pages/SharedDashboard"
import DashboardData from "pages/DashboardData"
import NewDashboard from "pages/NewDashboard";
import AllDashboards from "pages/AllDashboards";
import Journey from "pages/Journey";
import Audience from "pages/Audience";
import Report from "pages/Report";
import EmailMarketing from "pages/EmailMarketing";
import FollowedTopics from "pages/FollowedTopics"
import Insights from "pages/Insights";
import SharedInsights from "pages/SharedInsights";
import SpecialSignup from "authentication/SpecialSignup";
import Geolocation from "pages/Geolocation";
import AppSumo from "LandingComponents/AppSumo";
import AppSumoBlackFriday from "LandingComponents/AppSumoBlackFriday";
import InvitedUsers from "LandingComponents/InvitedUsers";
import Details from "pages/Profile/details";
import Preferences from "pages/Profile/preferences";
import Team from "pages/Profile/team";
import Organizations from "pages/Profile/organization"
import security from "pages/Profile/security";
import Plan from "pages/Profile/plan";
import Integrations from "pages/Profile/integrations";
import InviteTeam from "pages/Profile/team/invite";
import mfa from "pages/Profile/security/mfa";
import GlobalExecutiveDashboard from 'pages/GlobalExecutiveDashboard';
import TopicLibrary from 'pages/TopicLibrary';
import Engage from 'pages/Engage';
import ReportsDownloads from 'pages/ReportsDownloads';
import Tags from 'pages/Tags';
import Alerts from 'pages/Alerts';
import Survey from "pages/SurveyRepository";


export const routes = [
    {
        path:"/",
        type:Landing
    },
    {
        path: '/verification',
        type: Verification
    },
    {
        path: '/console',
        type: AllDashboards
    },
    {
        path: '/console/myDashboards',
        type: AllDashboards
    },
    {
        path: '/console/source',
        type: NewDashboard
    },
    {
        path: '/console/report',
        type: ReportsDownloads
    },
    {
        path: '/console/reportt',
        type: Report
    },
    {
        path: '/console/Dashboard/:id',
        type: Dashboard
    },
    {
        path: '/console/industryTopics',
        type: IndustryDashboard
    },
    {
        path: '/console/customTopics/:id',
        type: CustomDashboard
    },
    {
        path: '/console/DashboardData/:id',
        type: DashboardData
    },
    // {
    //     path: '/console/geolocation/:id',
    //     type: Geolocation
    // },
    {
        path: '/console/global_executive',
        type: GlobalExecutiveDashboard,
    },
    {
        path: '/console/topicLibrary',
        type: TopicLibrary
    },
    {
        path: '/console/topicLibrary/custom_topics',
        type: TopicLibrary
    },
    {
        path: '/console/topicLibrary/industry_topics',
        type: TopicLibrary
    },
    {
        path: '/console/tags',
        type: Tags
    },
    {
        path: '/console/alerts',
        type: Alerts
    },
    {
        path: '/console/survey',
        type: Survey
    },
    {
        path: '/console/journeys',
        type: CustomerJourney
    },
    {
        path: '/share/Dashboard/:id',
        type: SharedDashboard,
        props: {
            shared: true
        }
    },
    {
        path: '/share/DashboardData/:id',
        type: DashboardData,
        props: {
            shared: true
        }
    },
    {
        path: '/share/insights/:id',
        type: SharedInsights,
        props: {
            shared: true
        }
    },


    {
        path: '/console/journey/:id',
        type: Journey
    },
    {
        path: '/console/insights/:id',
        type: Insights
    },
    {
        path: '/console/engage',
        type: Engage
    },
    {
        path: '/console/engage/audience',
        type: Engage
    },
    {
        path: '/console/engage/emailMarketing',
        type: Engage
    },
    {
        path: '/console/followed',
        type: FollowedTopics
    },
    {
        path: '/login',
        type: Login
    },
    {
        path: '/signup',
        type: Signup
    },
    {
        path: '/checkout',
        type: Signup
    },
    {
        path: '/checkout/special',
        type: SpecialSignup
    },
    {
        path: '/checkout/app_sumo',
        type: AppSumo
    },
    {
        path: '/settings',
        type: Details
    },
    {
        path: '/settings/details',
        type: Details
    },
    {
        path: '/settings/preferences',
        type: Preferences
    },
    {
        path: '/settings/users',
        type: Team
    },
    {
        path: '/settings/team/invite/:id?',
        type: InviteTeam
    },
    {
        path: '/settings/security',
        type: security
    },
    {
        path: '/settings/security/enable/2fa',
        type: mfa
    },
    {
        path: '/settings/teams',
        type: Organizations
    },
    {
        path: '/settings/plan',
        type: Plan
    },
    {
        path: '/settings/integrations',
        type: Integrations
    },
    {
        path: '/sentiment_intent_analyzer',
        type: AppSumoBlackFriday
    },
    {
        path: '/users/:org_name/user_auth',
        type: InvitedUsers
    },
]