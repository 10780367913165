import React from 'react';
import Representation from './Representation';

const UploadCSV = ({ handleFiles, progress, fileName, setColumnNames, spinning }) => {
  return (
    <Representation
      fileName={fileName}
      progress={progress}
      spinning={spinning}
      setColumnNames={setColumnNames}
      handleFileChange={handleFiles}
    />
  );
}

export default UploadCSV;