import React, { useEffect, useState } from "react";
import { HorizontalBar, Doughnut, Line } from "react-chartjs-2";
import styles from "./style.module.scss";
import { Button } from "antd";
import { useSelector } from "react-redux";
const SubCategoryChart = ({
  chartData,
  chartType,
  selectedCategoryForSubcategories,
  barColorMap,
  doughnutColorMap,
  dateRange,
  setSelectedGroupForReviews,
}) => {
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < 950 ? true : false
  );
  const [toggleSelection, setToggleSelection] = useState("Daily");
  const [activeBtn, setActiveBtn] = useState("Daily");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 950) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileView]);
  const selectedCategoryId = selectedCategoryForSubcategories?.id;
  const childrenOfSelectedCategory = chartData?.map(
    (dashboardData, index) =>
      dashboardData.comparisonData?.find(
        (item) => item.categoryId === selectedCategoryId
      )?.children
  );
  const [selectedTopic, setSelectedTopic] = useState(null);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const dashboardIds = chartData?.map((dashboard) => dashboard.dashboardId);
  const dashboardLabels = chartData?.map((dashboard) => dashboard.dashboard);
  const subCategoryLabels = childrenOfSelectedCategory?.map((child) =>
    child?.map((subCategoryData) => subCategoryData.subCategory)
  )[0];
  const subCategoryIds = childrenOfSelectedCategory?.map((child) =>
    child?.map((subCategoryData) => subCategoryData.subCategoryId)
  )[0];
  const percentages = childrenOfSelectedCategory?.map((subCategoryData) =>
    subCategoryData?.map((data) => Math.round(data.percentage))
  );
  const labels = childrenOfSelectedCategory?.map((child) =>
    child?.map((subCategoryData) => subCategoryData.subCategory)
  );
  const ids = childrenOfSelectedCategory?.map((child) =>
    child?.map((subCategoryData) => subCategoryData.subCategoryId)
  );
  const labelsForLegend = chartData?.map(
    (dashboardData) => dashboardData.dashboard
  );
  const counts = childrenOfSelectedCategory?.map((subCategoryData) =>
    subCategoryData?.map((data) => data.count)
  );
  const round = (value, precision) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };
  const formatCountsForDoughnuts = (count) => {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(1) + "M";
    } else if (count >= 1000) {
      return (count / 1000).toFixed(1) + "K";
    } else {
      return count;
    }
  };
  const hasAlphabet = (str) => {
    return /[A-Za-z]/.test(str);
  };
  // Define a function that takes the labels, ids, and counts arrays as parameters
  function rearrangeCounts(labels, ids, counts) {
    // Create an empty array to store the rearranged counts
    let rearrangedCounts = [];
    if (labels?.length > 0 && ids?.length > 0 && counts?.length > 0) {
      // Loop through each subarray of labels, ids, and counts
      for (let i = 0; i < labels?.length; i++) {
        // Create an empty object to map each label to its corresponding id and count
        let labelMap = {};
        // Loop through each label, id, and count in the subarrays
        for (let j = 0; j < labels[i].length; j++) {
          // Assign the id and count as a value to the label as a key
          labelMap[labels[i][j]] = [ids[i][j], counts[i][j]];
        }
        // Create an empty array to store the rearranged subarray of counts
        let rearrangedSubarray = [];
        // Loop through the first subarray of labels as a reference
        for (let k = 0; k < labels[0].length; k++) {
          // Get the count value from the labelMap object using the reference label as a key
          if (labelMap[labels[0][k]]?.length > 0) {
            let countValue = labelMap[labels[0][k]][1];
            // Push the count value to the rearranged subarray
            rearrangedSubarray.push(countValue);
          }
        }
        // Push the rearranged subarray to the rearranged counts array
        rearrangedCounts.push(rearrangedSubarray);
      }
    }
    // Return the rearranged counts array
    return rearrangedCounts;
  }
  const rearrangedCounts = rearrangeCounts(labels, ids, counts);
  const dashboardData = labelsForLegend?.map((_, index) => ({
    data: {
      labels: labels[index],
      datasets: [
        {
          data: percentages[index],
          backgroundColor: ids[index]?.map((id) => barColorMap[id]),
          barThickness: isMobileView && subCategoryIds?.length > 5 ? 10 : 13,
        },
      ],
    },
  }));
  const totalReviews = chartData?.map(
    (dashboardData) => dashboardData.total_reviews
  );
  const calculateNormalizedPercentages = (arr, total) => {
    if (arr?.length > 0) {
      const percentages = arr[0]?.map((_, i) => {
        const numerator = arr?.map((row, rowIndex) => {
          if (total[rowIndex] !== 0) {
            return row[i] / total[rowIndex];
          } else {
            return 0;
          }
        });
        const denominator = arr.reduce((acc, row, rowIndex) => {
          if (total[rowIndex] !== 0) {
            return acc + row[i] / total[rowIndex];
          } else {
            return acc;
          }
        }, 0);

        return numerator?.map((numerator) =>
          Math.floor(
            (numerator / (denominator === 0 ? 1 : denominator)) * 100
          ).toFixed(0)
        );
      });
      return percentages;
    }
  };
  const doughnutPercentages = calculateNormalizedPercentages(
    rearrangedCounts,
    totalReviews
  );
  useEffect(() => {
    if (selectedTopic) {
      if (selectedTopic.id) {
        const clickedTopicData = {
          label: dashboardLabels,
          bgColor: "rgb(116, 45, 224)",
          id: dashboardIds.map((item) => parseInt(item)),
          topicId: parseInt(selectedTopic.id),
          topicLabel: selectedTopic.label,
          clickIsFrom: "topics",
        };
        if (parseInt(selectedTopic.id) === -1) {
          clickedTopicData.othersParent = parseInt(
            selectedCategoryForSubcategories.id
          );
        }
        setSelectedGroupForReviews(clickedTopicData);
      }
    }
  }, [selectedTopic]);
  const doughnutDataArray = [];
  const options = {
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 100,
          },
          display: false,
          gridLines: {
            display: true,
            drawOnChartArea: false,
            drawTicks: false,
            color:
              theme === "dark"
                ? "rgba(128, 128, 128, 0.4)"
                : "rgba(0, 0, 0, 0.2)",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontSize: isMobileView ? 10 : 12,
            fontColor: theme === "dark" ? "white" : "black",
            autoSkip: false,
            maxTicksLimit: 10,
            padding: 10,
            max: 100,
            callback: function(value) {
              if (value.length > 15) {
                return value.substr(0, 12) + "...";
              } else {
                return value;
              }
            },
          },
          gridLines: {
            display: true,
            drawOnChartArea: false,
            drawTicks: false,
            color:
              theme === "dark"
                ? "rgba(128, 128, 128, 0.4)"
                : "rgba(0, 0, 0, 0.2)",
          },
        },
      ],
    },
    maintainAspectRatio: isMobileView ? true : false,
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: "center",
        align: function(context) {
          return isMobileView
            ? context.dataset.data[context.dataIndex] <= 5
              ? "end"
              : "center"
            : context.dataset.data[context.dataIndex] <= 2
            ? "end"
            : "center";
        },
        font: {
          size: isMobileView ? 9 : 12,
        },
        formatter: function(value, context) {
          if (
            (context.dataset.data[context.dataIndex] >= 0 &&
              context.dataset.data[context.dataIndex] < 1) ||
            isNaN(context.dataset.data[context.dataIndex])
          ) {
            return "";
          }
          return Math.round(value) + "%";
        },
        color: (context) => {
          const datasetIndex = context.datasetIndex;
          const labelIndex = context.dataIndex;
          const bgColor =
            context.chart?.config?.data?.datasets[datasetIndex]
              ?.backgroundColor[labelIndex];
          if (isMobileView) {
            if (context.dataset.data[context.dataIndex] <= 5) {
              return theme === "dark" ? "white" : "black";
            }
          } else {
            if (context.dataset.data[context.dataIndex] <= 2) {
              return theme === "dark" ? "white" : "black";
            }
          }
          if (
            bgColor === "#D2B5F2" ||
            bgColor === "#56DEC8" ||
            bgColor === "#FFBFFF" ||
            bgColor === "#CCCCCC" ||
            bgColor === "#9BC3DE" ||
            bgColor === "#E7EDEF"
          ) {
            return "#4400AA";
          } else {
            return "white";
          }
        },
      },
    },
  };
  const subCategoriesData = subCategoryIds?.map((_, subCategoryIndex) =>
    doughnutPercentages?.map((array, arrayIndex) => array[subCategoryIndex])
  );
  const adjustChartHeight = () => {
    if (isMobileView) {
      if (subCategoryIds && subCategoryIds?.length > 0) {
        switch (true) {
          case subCategoryIds?.length < 5:
            return 80;
          case subCategoryIds?.length >= 5 && subCategoryIds?.length < 10:
            return 150;
          case subCategoryIds?.length >= 10 && subCategoryIds?.length < 15:
            return 240;
          case subCategoryIds?.length >= 15 && subCategoryIds?.length < 20:
            return 300;
          default:
            return 400;
        }
      }
    } else {
      if (subCategoryIds && subCategoryIds?.length > 0) {
        switch (true) {
          case subCategoryIds?.length < 5:
            return 75;
          case subCategoryIds?.length >= 5 && subCategoryIds?.length < 10:
            return 160;
          case subCategoryIds?.length >= 10 && subCategoryIds?.length < 15:
            return 310;
          case subCategoryIds?.length >= 15 && subCategoryIds?.length < 20:
            return 420;
          default:
            return 540;
        }
      }
    }
  };
  const renderChart = () => {
    if (chartType === "C1") {
      return (
        <div className={styles.subChart}>
          {dashboardData.map((dashboard, index) => (
            <div className={styles.chartNlabel}>
              <span className={styles.label}>{labelsForLegend[index]}</span>
              <HorizontalBar
                data={dashboard.data}
                options={options}
                height={adjustChartHeight()}
              />
            </div>
          ))}
        </div>
      );
    } else if (chartType === "C2") {
      const doughnutCharts = [];
      const zeroDoughnuts = [];
      for (let i = 0; i < subCategoriesData?.length; i++) {
        let sum = doughnutPercentages[i].reduce((acc, val) => acc + val, 0);
        const doughnutChartData = {
          labels: chartData?.map((dashboardData) => dashboardData.dashboard),
          datasets: [
            {
              id: dashboardIds,
              data: doughnutPercentages[i],
              backgroundColor: dashboardIds?.map((id) => doughnutColorMap[id]),
              keyId: subCategoryIds[i],
              keyLabel: subCategoryLabels[i],
            },
          ],
        };
        const handleDoughnutClick = (activeElement) => {
          if (activeElement) {
            const clickedDataset = doughnutChartData.datasets[0];
            const selectedDoughnutData = {
              label: [activeElement._model.label],
              bgColor: activeElement._model.backgroundColor,
              id: [parseInt(clickedDataset.id[activeElement._index])],
              topicId: parseInt(clickedDataset.keyId),
              topicLabel: clickedDataset.keyLabel,
              clickIsFrom: "groups",
            };
            if (parseInt(clickedDataset.keyId) === -1) {
              selectedDoughnutData.othersParent = parseInt(
                selectedCategoryForSubcategories.id
              );
            }
            setSelectedGroupForReviews(selectedDoughnutData);
          }
        };
        doughnutDataArray.push(doughnutChartData);
        const doughnutChartOptions = {
          responsive: true,
          plugins: {
            datalabels: {
              anchor: "center",
              align: "center",
              formatter: function(value, context) {
                if (
                  (context.dataset.data[context.dataIndex] >= 0 &&
                    context.dataset.data[context.dataIndex] < 1) ||
                  isNaN(context.dataset.data[context.dataIndex])
                ) {
                  return "";
                }
                const itemData = childrenOfSelectedCategory[
                  context.dataIndex
                ].find((item) => item.subCategoryId === context.dataset.keyId);
                const formattedCount = formatCountsForDoughnuts(itemData.count);
                const lineBreak = "\n";
                const spaceBreak = "\t";
                return context.dataset.data[context.dataIndex] <= 3
                  ? value + "%"
                  : hasAlphabet(formattedCount)
                  ? `${spaceBreak}${" "}${value +
                      "%"}${lineBreak}(${formattedCount})`
                  : `${" "}${value + "%"}${lineBreak}(${formattedCount})`;
              },
              color: (context) => {
                const bgColor =
                  context.dataset.backgroundColor[context.dataIndex];

                if (
                  bgColor === "#D2B5F2" ||
                  bgColor === "#56DEC8" ||
                  bgColor === "#FFBFFF" ||
                  bgColor === "#CCCCCC" ||
                  bgColor === "#9BC3DE" ||
                  bgColor === "#E7EDEF"
                ) {
                  return "#4400AA";
                } else {
                  return "white";
                }
              },
            },
          },
          cutoutPercentage: 45,
          maintainAspectRatio: false,
          outerRadius: 75,
          cornerRadius: 7,
          reponsive: true,
          aspectRatio: 1,
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
          legend: {
            display: false,
            position: "bottom",
            labels: {
              fontColor: theme === "dark" ? "white" : "black",
            },
          },
          onClick: function(e) {
            var point = this.getElementAtEvent(e)[0];
            if (point) {
              handleDoughnutClick(point);
            }
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                const itemData = childrenOfSelectedCategory[
                  tooltipItem.index
                ].find((item) => item.subCategoryId === data.datasets[0].keyId);
                return `${data.labels[tooltipItem.index]}: ${itemData.count}`;
              },
            },
          },
        };
        if (Number(sum) === 0) {
          zeroDoughnuts.push(
            <div key={`category-${i + 1}`} className={styles.chartWithLabel}>
              <div className={styles.doughnut}>
                <Doughnut
                  data={doughnutChartData}
                  options={doughnutChartOptions}
                  width={230}
                />
                <span className={styles.noDataDoughnutMsg}>No Data</span>
              </div>
              <h6 className={styles.categoryName} title={subCategoryLabels[i]}>
                {subCategoryLabels[i]}
              </h6>
            </div>
          );
        } else {
          doughnutCharts.push(
            <div key={`category-${i + 1}`} className={styles.chartWithLabel}>
              <div className={styles.doughnut}>
                <Doughnut
                  data={doughnutChartData}
                  options={doughnutChartOptions}
                  width={230}
                />
              </div>
              <h6
                title={subCategoryLabels[i]}
                className={styles.categoryName}
                onClick={() =>
                  setSelectedTopic({
                    id: subCategoryIds[i],
                    label: subCategoryLabels[i],
                  })
                }
              >
                {subCategoryLabels[i]}
              </h6>
            </div>
          );
        }
      }
      return [...doughnutCharts, ...zeroDoughnuts];
    } else if (chartType === "C3") {
      const indexOfMax = ids.reduce(
        (maxIndex, currentArray, currentIndex, array) => {
          return currentArray.length > array[maxIndex].length
            ? currentIndex
            : maxIndex;
        },
        0
      );
      const subIds = ids[indexOfMax];
      const subLabels = labels[indexOfMax];
      let dateArray = [];
      let uniqueAllDates = [];
      if (dateRange && dateRange.length > 0) {
        const startDate = new Date(dateRange[0]);
        const endDate = new Date(dateRange[1]);
        let currentDate = startDate;
        while (currentDate <= endDate) {
          dateArray.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }
        const allDates = dateArray.map((date) => {
          let year = date.getFullYear();
          let month =
            toggleSelection === "Monthly"
              ? date.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                })
              : date.getMonth() + 1;
          let day = date.getDate();

          if (toggleSelection === "Daily") {
            day = day < 10 ? "0" + day : day;
            month = month < 10 ? "0" + month : month;
            return `${day}-${month}-${year}`;
          } else if (toggleSelection === "Monthly") {
            return month.replace(/ /g, "' ");
          } else if (toggleSelection === "Yearly") {
            return `${year}`;
          }
        });
        uniqueAllDates = [...new Set(allDates)];
      } else {
        let allTimeDates = subIds?.map((id) => {
          const datesForOneChart = [];
          const foundData = childrenOfSelectedCategory?.map((c) =>
            c.find((d) => d.subCategoryId === id)
          );

          for (let i = 0; i < childrenOfSelectedCategory?.length; i++) {
            if (foundData && foundData[i]) {
              const timeSelection = toggleSelection.toLowerCase();
              for (
                let j = 0;
                j < foundData[i].dataByTime[timeSelection].length;
                j++
              ) {
                const timeData = foundData[i].dataByTime[timeSelection][j];
                datesForOneChart.push(timeData.date);
              }
            }
          }
          return datesForOneChart;
        });
        uniqueAllDates = Array.from(new Set([].concat(...allTimeDates)));
      }

      // Check if the array has only one element and it is not undefined
      if (uniqueAllDates && uniqueAllDates.length === 1 && uniqueAllDates[0]) {
        // Parse the element as a date object
        let date = new Date(uniqueAllDates[0]);
        // Create a new array to store the dates
        let dates = [];
        // Get the previous date by subtracting one day
        let prevDate = new Date(date);
        prevDate.setDate(prevDate.getDate() - 1);
        // Get the next date by adding one day
        let nextDate = new Date(date);
        nextDate.setDate(nextDate.getDate() + 1);
        // Format the dates according to the toggleSelection
        if (toggleSelection === "Daily") {
          // Use the same format as the original code
          let prevDay =
            prevDate.getDate() < 10
              ? "0" + prevDate.getDate()
              : prevDate.getDate();
          let prevMonth =
            prevDate.getMonth() + 1 < 10
              ? "0" + (prevDate.getMonth() + 1)
              : prevDate.getMonth() + 1;
          let prevYear = prevDate.getFullYear();
          let nextDay =
            nextDate.getDate() < 10
              ? "0" + nextDate.getDate()
              : nextDate.getDate();
          let nextMonth =
            nextDate.getMonth() + 1 < 10
              ? "0" + (nextDate.getMonth() + 1)
              : nextDate.getMonth() + 1;
          let nextYear = nextDate.getFullYear();
          // Push the formatted dates to the array
          dates.push(`${prevDay}-${prevMonth}-${prevYear}`);
          dates.push(uniqueAllDates[0]);
          dates.push(`${nextDay}-${nextMonth}-${nextYear}`);
        } else if (toggleSelection === "Monthly") {
          // Use the same format as the original code
          let prevMonth = new Date(date); // Create a new date object for the previous month
          prevMonth.setMonth(prevMonth.getMonth() - 1); // Subtract one from the month value
          prevMonth = prevMonth
            .toLocaleDateString("en-US", { year: "numeric", month: "short" })
            .replace(/ /g, "' ");
          let nextMonth = new Date(date); // Create a new date object for the next month
          nextMonth.setMonth(nextMonth.getMonth() + 1); // Add one to the month value
          nextMonth = nextMonth
            .toLocaleDateString("en-US", { year: "numeric", month: "short" })
            .replace(/ /g, "' ");
          // Push the formatted dates to the array
          dates.push(prevMonth);
          dates.push(uniqueAllDates[0]);
          dates.push(nextMonth);
        } else if (toggleSelection === "Yearly") {
          // Use the same format as the original code
          let prevYear = new Date(date); // Create a new date object for the previous year
          prevYear.setFullYear(prevYear.getFullYear() - 1); // Subtract one from the year value
          prevYear = prevYear.getFullYear();
          let nextYear = new Date(date); // Create a new date object for the next year
          nextYear.setFullYear(nextYear.getFullYear() + 1); // Add one to the year value
          nextYear = nextYear.getFullYear();
          // Push the formatted dates to the array
          dates.push(`${prevYear}`);
          dates.push(uniqueAllDates[0]);
          dates.push(`${nextYear}`);
        }
        uniqueAllDates = dates;
      }
      const lineChartsData = subIds?.map((id) => {
        const dataForOneChart = { labels: uniqueAllDates, datasets: [] };
        const foundData = childrenOfSelectedCategory?.map((c) =>
          c.find((d) => d.subCategoryId === id)
        );

        for (let i = 0; i < childrenOfSelectedCategory?.length; i++) {
          if (foundData && foundData[i]) {
            const dashboardDataset = {
              label: dashboardLabels[i],
              data: new Array(uniqueAllDates.length).fill(0),
              backgroundColor: doughnutColorMap[dashboardIds[i]],
              fill: false,
              borderColor: doughnutColorMap[dashboardIds[i]],
              lineTension: 0.5,
              pointRadius: 0,
              pointHoverRadius: isMobileView ? 1 : 2,
            };

            const timeSelection = toggleSelection.toLowerCase();
            for (
              let j = 0;
              j < foundData[i].dataByTime[timeSelection].length;
              j++
            ) {
              const timeData = foundData[i].dataByTime[timeSelection][j];
              const index = dataForOneChart.labels.indexOf(timeData.date);
              if (index !== -1) {
                dashboardDataset.data[index] = timeData.count;
              }
            }

            dataForOneChart.datasets.push(dashboardDataset);
          }
        }

        return dataForOneChart;
      });
      const updatedCategoryData = lineChartsData.reduce(
        (result, chartData, index) => {
          const subCategoryName = subLabels[index];
          const subCategoryId = subIds[index];
          if (
            chartData?.datasets
              ?.map((d) => d.data)
              .flat()
              .every((element) => element === 0)
          ) {
            result.emptyCharts.push({
              chartData,
              subCategoryName,
              subCategoryId,
            });
          } else {
            result.nonEmptyCharts.push({
              chartData,
              subCategoryName,
              subCategoryId,
            });
          }

          return result;
        },
        { emptyCharts: [], nonEmptyCharts: [] }
      );

      const finalCategoryData = [
        ...updatedCategoryData.nonEmptyCharts,
        ...updatedCategoryData.emptyCharts,
      ];
      const tooltipLine = {
        id: "tooltipLine",
        beforeDraw: (chart) => {
          if (chart.tooltip._active && chart.tooltip._active.length) {
            const ctx = chart.ctx;
            ctx.save();
            const activePoint = chart.tooltip._active[0];
            ctx.beginPath();
            ctx.setLineDash([4, 6]);
            ctx.moveTo(activePoint?._view?.x, chart.chartArea.top);
            ctx.lineTo(activePoint?._view?.x, chart.chartArea.bottom);
            ctx.lineWidth = 2;
            ctx.strokeStyle = "rgba(155, 155, 155)";
            ctx.stroke();
            ctx.restore();
          }
        },
      };
      const lineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: "point",
          intersect: true,
        },
        interaction: {
          mode: "point",
        },
        tooltips: {
          backgroundColor: "rgba(0,0,0,0.5)",
          mode: "label",
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                color: theme === "dark" ? "#262250" : "rgba(0, 0, 0, 0.2)",
                display: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                fontColor: theme === "dark" ? "white" : "black",
                padding: 15,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Reviews Count",
                fontColor: theme === "dark" ? "white" : "black",
              },
              ticks: {
                beginAtZero: true,
                display: true,
                padding: 15,
                fontColor: theme === "dark" ? "white" : "black",
              },
              gridLines: {
                display: true,
                color: theme === "dark" ? "#262250" : "rgba(0, 0, 0, 0.2)",
                drawTicks: false,
                drawOnChartArea: false,
              },
            },
          ],
        },
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
            fontColor: theme === "dark" ? "white" : "black",
            pointStyle: "circle",
            padding: 20,
          },
          borderRadius: 20,
          position: "bottom",
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      };
      const emptyLineOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
                color:
                  theme === "dark"
                    ? "rgba(255, 255, 255, 0.6)"
                    : "rgba(0, 0, 0, 0.6)",
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                fontColor: theme === "dark" ? "white" : "black",
                padding: 20,
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Reviews Count",
                fontColor: theme === "dark" ? "white" : "black",
              },
              ticks: {
                display: false,
                beginAtZero: true,
                padding: 20,
                fontColor: theme === "dark" ? "white" : "black",
                fontSize: isMobileView ? 10 : 12,
              },
              gridLines: {
                color:
                  theme === "dark"
                    ? "rgba(255, 255, 255, 0.6)"
                    : "rgba(0, 0, 0, 0.6)",
                display: true,
                drawOnChartArea: false,
                drawTicks: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      };
      const handleToggleSelection = (selection) => {
        setToggleSelection(selection);
        setActiveBtn(selection);
      };
      return (
        <div className={styles.chartContainer}>
          <div className={styles.toggleBtnContainer}>
            <Button
              className={
                activeBtn === "Daily"
                  ? styles.activeToggleBtn
                  : styles.toggleBtn
              }
              onClick={() => handleToggleSelection("Daily")}
            >
              Daily
            </Button>
            <Button
              className={
                activeBtn === "Monthly"
                  ? styles.activeToggleBtn
                  : styles.toggleBtn
              }
              onClick={() => handleToggleSelection("Monthly")}
            >
              Monthly
            </Button>
            <Button
              className={
                activeBtn === "Yearly"
                  ? styles.activeToggleBtn
                  : styles.toggleBtn
              }
              onClick={() => handleToggleSelection("Yearly")}
            >
              Yearly
            </Button>
          </div>
          <div className={styles.lineChartContainer}>
            {finalCategoryData?.map(
              ({ chartData, subCategoryName, subCategoryId }, index) => (
                <div>
                  {!chartData?.datasets
                    ?.map((d) => d.data)
                    .flat()
                    .every((element) => element === 0) ? (
                    <div className={styles.chartWithLabel}>
                      <span
                        className={styles.categoryName}
                        title={subCategoryName}
                        onClick={() =>
                          setSelectedTopic({
                            id: subCategoryId,
                            label: subCategoryName,
                          })
                        }
                      >
                        {subCategoryName}
                      </span>
                      <div className={styles.lineChart}>
                        <Line
                          data={{
                            ...chartData,
                            datasets: chartData.datasets.map((d) => ({
                              ...d,
                              hidden: d.data.every((x) => x === 0),
                            })),
                          }}
                          options={{
                            ...lineOptions,
                            scales: {
                              xAxes: [
                                {
                                  display: true,
                                  gridLines: {
                                    color:
                                      theme === "dark"
                                        ? "rgba(128, 128, 128, 0.4)"
                                        : "rgba(0, 0, 0, 0.2)",
                                    display: true,
                                    drawOnChartArea: false,
                                    drawTicks: false,
                                  },
                                  ticks: {
                                    fontColor:
                                      theme === "dark" ? "white" : "black",
                                    padding: 15,
                                  },
                                },
                              ],
                              yAxes: [
                                {
                                  display: true,
                                  scaleLabel: {
                                    display: true,
                                    labelString: "Reviews Count",
                                    fontColor:
                                      theme === "dark" ? "white" : "black",
                                  },
                                  ticks: {
                                    beginAtZero: true,
                                    display: true,
                                    padding: 15,
                                    fontColor:
                                      theme === "dark" ? "white" : "black",
                                    suggestedMax:
                                      Math.max(
                                        ...chartData?.datasets
                                          ?.map((d) => d.data)
                                          .flat()
                                      ) + 5,
                                  },
                                  gridLines: {
                                    display: true,
                                    color:
                                      theme === "dark"
                                        ? "rgba(128, 128, 128, 0.4)"
                                        : "rgba(0, 0, 0, 0.2)",
                                    drawTicks: false,
                                    drawOnChartArea: false,
                                  },
                                },
                              ],
                            },
                          }}
                          plugins={tooltipLine}
                        />
                      </div>
                      <div className={styles.lineChartLegend}>
                        {chartData.datasets.map((d, index) => (
                          <div
                            key={`legend-${index}`}
                            style={{
                              textDecoration: d.data.every((x) => x === 0)
                                ? "line-through"
                                : "none",
                            }}
                          >
                            <span
                              className={styles.legendColor}
                              style={{ backgroundColor: d.backgroundColor }}
                            />
                            <span className={styles.legendLabel}>
                              {d.label}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.chartWithLabel}>
                      <span
                        title={subCategoryName}
                        className={styles.categoryName}
                      >
                        {subCategoryName}
                      </span>
                      <div className={styles.emptyLineChart}>
                        <Line
                          data={{
                            ...chartData,
                            datasets: chartData.datasets.map((d) => ({
                              ...d,
                              hidden: true,
                            })),
                          }}
                          options={emptyLineOptions}
                        />
                        <span className={styles.noDataMsg}>
                          No Data Available
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      );
    }
  };

  const renderLegend = (data) => {
    if (chartType === "C2") {
      return data[0]?.labels?.map((label, index) => (
        <div key={index}>
          <span
            className={styles.legendColor}
            style={{
              backgroundColor: data[0]?.datasets[0]?.backgroundColor[index],
            }}
          />
          <span className={styles.legendLabel}>{label}</span>
        </div>
      ));
    }
    return (
      <div className={styles.legendC}>
        {data[0]?.data?.labels?.map((label, index) => (
          <div key={`legend-${index}`} className={styles.legendDisplay}>
            <span
              className={styles.legendColor}
              style={{
                backgroundColor:
                  data[0]?.data?.datasets[0]?.backgroundColor[index],
              }}
            />
            <span className={styles.legendLabel}>{label}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      {chartType === "C1" ? (
        renderChart()
      ) : (
        <div className={styles.doughnutChart}>{renderChart()}</div>
      )}
      <div className={styles.Legend}>
        {chartType === "C1"
          ? renderLegend(dashboardData)
          : renderLegend(doughnutDataArray)}
      </div>
    </>
  );
};
export default SubCategoryChart;
