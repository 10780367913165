import React, { useState, useRef, useEffect } from "react";
import styles from "./style.module.scss";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localeData from "dayjs/plugin/localeData";
import { DatePicker } from "antd";
import { Radio, Button, Space } from "antd";
import Icon from "helperComponents/Icon";
import { useSelector } from "react-redux";
dayjs.extend(relativeTime);

const { RangePicker } = DatePicker;

const DateFilterPopUp = ({
  onChange,
  defaultStartDate,
  defaultEndDate,
  searchPeriod,
  disabled,
  selectedDaysRange,
  close,
}) => {
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const dateFilterPopupRef = useRef();
  const setSelectedDate = (date) => {
    setDateRange(date);
  };
  const applyDateFilter = () => {
    if (dateRange.length == 0) {
      onChange([], dayRangeId);
    } else {
      let dateString = [
        dayjs(dateRange[0]).format("YYYY-MM-DD"),
        dayjs(dateRange[1]).format("YYYY-MM-DD"),
      ];
      onChange(dateString, dayRangeId);
    }
  };

  const isDynamic = () => {
    if (DashboardReducer?.dashboard?.RefreshRate) {
      return true;
    } else return false;
  };
  const getDateRange = (range, type) => {
    if (isDynamic()) {
      return [dayjs().add(range, type), dayjs()];
    } else {
      let end_date = DashboardReducer?.end_date;
      if (end_date?.length) {
        return [dayjs(end_date).add(range, type), dayjs(end_date)];
      } else {
        return [
          dayjs(searchPeriod[1]).add(range, type),
          dayjs(searchPeriod[1]),
        ];
      }
    }
  };

  const rangePresets = [
    {
      id: "last24",
      label: "Last 24 hrs",
      value: getDateRange(-24, "h"),
    },
    {
      id: "last7d",
      label: "Last 7 Days",
      value: getDateRange(-6, "d"),
    },
    {
      id: "last14d",
      label: "Last 14 Days",
      value: getDateRange(-13, "d"),
    },
    {
      id: "last30d",
      label: "Last 30 Days",
      value: getDateRange(-29, "d"),
    },
    {
      id: "last90d",
      label: "Last 90 Days",
      value: getDateRange(-89, "d"),
    },
  ];
  const getDaysRange = (daysId) => {
    let date = [];
    if (rangePresets.length) {
      rangePresets.map((info) => {
        if (info.id == daysId) {
          date = info.value;
        }
      });
    }
    return date;
  };
  const [dateRange, setDateRange] = useState(
    selectedDaysRange !== "custom"
      ? getDaysRange(selectedDaysRange)
      : [
          searchPeriod[0] ? dayjs(searchPeriod[0], "YYYY-MM-DD") : "",
          searchPeriod[1] ? dayjs(searchPeriod[1], "YYYY-MM-DD") : "",
        ]
  );
  const [dayRangeId, setDayRangeId] = useState(selectedDaysRange);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const dateClicked = event.target.closest(
        ".ant-dropdown, .ant-picker, .ant-picker-header, .ant-picker-body"
      );
      if (!dateClicked) {
        if (
          dateFilterPopupRef.current &&
          !dateFilterPopupRef.current.contains(event.target)
        ) {
          close();
        }
      }
    };

    // Add event listener when click event occurs
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateFilterPopupRef]);
  return (
    <div ref={dateFilterPopupRef} className={styles.dateFilterPopup}>
      <div className={styles.header}>
        <div className={styles.title}>Date Filter</div>
        <div onClick={close}>
          <Icon name={"close"} />
        </div>
      </div>
      <div className={styles.quickDates}>
        <Radio.Group className={styles.radioWrapper} value={dayRangeId}>
          <Space direction="vertical">
            {rangePresets &&
              rangePresets.map((val) => {
                return (
                  <Radio
                    value={val.id}
                    key={val.id}
                    checked={true}
                    onClick={() => {
                      setDayRangeId(val.id);
                      setSelectedDate(val.value);
                    }}
                  >
                    {val.label}
                  </Radio>
                );
              })}
            <Radio
              value="custom"
              onClick={() => {
                setDayRangeId("custom");
                setSelectedDate(dateRange);
              }}
            >
              Custom
            </Radio>
            <Radio
              value="allTime"
              onClick={() => {
                setDayRangeId("allTime");
                setSelectedDate([]);
              }}
            >
              All Time
            </Radio>
          </Space>
        </Radio.Group>
      </div>
      <div className={styles.dateRangeView}>
        <div className={styles.datePicker}>
          <RangePicker
            open={true}
            style={{ width: 0, height: 0, padding: 0, margin: 0 }}
            value={[dayjs(dateRange[0]), dayjs(dateRange[1])]}
            onCalendarChange={(dates, dateStrings) => {
              setDayRangeId("custom");
              setSelectedDate(dateStrings);
            }}
            placeholder={"topLeft"}
            className={styles.dateRange}
            popupClassName={styles.dashboardDateFilter}
          />
        </div>
        <div className={styles.footer}>
          <Button className={styles.cancelButton} onClick={close}>
            Cancel
          </Button>
          <Button className={styles.applyButton} onClick={applyDateFilter}>
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DateFilterPopUp;
