import React, { useState, useEffect } from "react";
import { Button, Space, Radio } from "antd";
import { Modal } from "components/General/ModalFormComponents";
import DataSelection from "./DataSelection";
import styles from "./style.module.scss";
import NestedDataSelection from "./NestedDataSelection";

const DataSelectionPopup = ({
  open,
  onClose,
  options,
  columnHeaders,
  onSave,
  dataType,
  selectedItems,
  title,
  setSelectedItems,
  cancelButtonText,
  saveButtonText,
  resetPopup,
  popupWidth,
  description,
  selectionType = "multiple",
  emptyDataContent,
  dataLoading,
  selectedCustomTopics,
  setSelectedCustomTopics,
  selectedPivotColumns,
  setSelectedPivotColumns,
}) => {
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < 950 ? true : false
  );
  const [selectedRadio, setSelectedRadio] = useState("");

  useEffect(() => {
    if (dataType === "Potential Drivers") {
      if (options?.filter((item) => item.col2 === "Custom Topic")?.length > 0) {
        setSelectedRadio("custom");
      } else if (options?.filter((item) => item.col2 === "Pivot")?.length > 0) {
        setSelectedRadio("pivot");
      } else {
        setSelectedRadio("custom");
      }
    }
  }, [dataType]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 950);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileView]);

  return (
    <Modal
      showModal={open}
      onClose={onClose}
      onSave={onSave}
      showSaveButton={false}
      showCancelButton={false}
      hideCloseIcon={true}
      titleAsset={""}
      widthofmodal={
        isMobileView
          ? popupWidth
            ? popupWidth
            : "25rem"
          : popupWidth
          ? popupWidth
          : "500px"
      }
      className={styles.PopupSequence}
      title={
        <div className={styles.popupHeading}>
          {title ? title : `Select ${dataType}`}
        </div>
      }
      description={description}
    >
      {dataType === "Potential Drivers" &&
        (options?.filter((item) => item.col2 === "Custom Topic")?.length > 0 ||
          options?.filter((item) => item.col2 === "Pivot")?.length > 0) && (
          <div
            className={`${styles.radioContainer} ${
              options?.filter((item) => item.col2 === "Custom Topic").length ===
                0 ||
              options?.filter((item) => item.col2 === "Pivot").length === 0
                ? `${styles.singleOption}`
                : ""
            }`}
          >
            {options?.filter((item) => item.col2 === "Custom Topic")?.length >
              0 && (
              <span
                className={
                  selectedRadio === "custom" ? styles.activeBtn : styles.btn
                }
                onClick={() => setSelectedRadio("custom")}
              >
                Custom Topics{" "}
                {selectedCustomTopics?.length > 0 &&
                  `(${selectedCustomTopics?.length})`}
              </span>
            )}
            {options?.filter((item) => item.col2 === "Pivot")?.length > 0 && (
              <span
                className={
                  selectedRadio === "pivot" ? styles.activeBtn : styles.btn
                }
                onClick={() => setSelectedRadio("pivot")}
              >
                Pivot Columns{" "}
                {selectedPivotColumns.length > 0 &&
                  `(${selectedPivotColumns.length})`}
              </span>
            )}
          </div>
        )}
      <div className={styles.Popup}>
        {dataType === "Outcome Metric" && (
          <DataSelection
            options={options}
            selectedOptions={selectedItems}
            setSelectedOptions={setSelectedItems}
            resetPopup={resetPopup}
            columnHeaders={columnHeaders}
            selectionType={selectionType}
            dataLoading={dataLoading}
            emptyDataContent={emptyDataContent}
          />
        )}
        {dataType === "Potential Drivers" && (
          <>
            {selectedRadio === "custom" && (
              <NestedDataSelection
                options={options.filter((item) => item.col2 === "Custom Topic")}
                selectedOptions={selectedCustomTopics}
                setSelectedOptions={setSelectedCustomTopics}
                resetPopup={resetPopup}
                columnHeaders={columnHeaders}
                selectionType={selectionType}
                dataLoading={dataLoading}
                emptyDataContent={emptyDataContent}
              />
            )}
            {selectedRadio === "pivot" && (
              <DataSelection
                options={options.filter((item) => item.col2 === "Pivot")}
                selectedOptions={selectedPivotColumns}
                setSelectedOptions={setSelectedPivotColumns}
                resetPopup={resetPopup}
                columnHeaders={columnHeaders}
                selectionType={selectionType}
                dataLoading={dataLoading}
                emptyDataContent={emptyDataContent}
              />
            )}
          </>
        )}
      </div>
      <div className={styles.footer}>
        <Button className={styles.footerBtn} onClick={onClose}>
          {cancelButtonText ? cancelButtonText : "Cancel"}
        </Button>
        <Button
          className={styles.footerBtn}
          type="primary"
          onClick={onSave}
          disabled={
            selectionType === "single"
              ? selectedItems === -1
              : selectedItems?.length === 0
          }
        >
          {saveButtonText ? saveButtonText : "Save"}
        </Button>
      </div>
    </Modal>
  );
};

export default DataSelectionPopup;
