import React from "react";
import styles from "./style.module.scss";
import { Checkbox} from "antd";
import { apiRequest } from "util/services";

const CustomGroupCheckbox = ({
  options,
  value,
  defaultValue,
  dashboard_id,
  changeSelection,
  owner,
}) => {
  const shareDashboard = async (checkedValues,dashboard_id) => {
    const payload = {
      team_ids_viewers : checkedValues.join(','),
      dashboard_id: dashboard_id,
    }
    const result = await apiRequest(
      "dashboards/viewability",
      "POST",
      payload,
      true
    )}
  const onCheckboxChange  = (checkedValues) => {
    changeSelection(checkedValues)
  };
  return (
      <div className={styles.customGroupCheckboxContainer}>
        {owner !== null &&(
         <div>
         <Checkbox defaultChecked={true} disabled>{owner} (Owner)</Checkbox>
         </div>
        )
        }
    <Checkbox.Group  options={options.filter(item => item !== owner)} value={value} onChange={onCheckboxChange}/>
    </div>
  );
};

export default CustomGroupCheckbox;

