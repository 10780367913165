import { countryList, lang } from "util/constants";

const steps = {
  1: {
    simple: {
      step1: [
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          title: "Any Keyword",
          subtitle:
            "Reviews contain any of these keywords. Example: In case dog and cat are selected as any keywords, tweets collected will either contain the keyword dog, cat or both.",
          valueType: "multiInput",
          key: "any_keywords",
        },
        {
          title: "All Keywords",
          subtitle:
            "Reviews contain all of these keywords. Example: In case dog and cat are selected as any keywords, tweets collected will strictly contain the keyword dog and cat in the same tweet.",
          valueType: "multiInput",
          key: "all_keywords",
        },
        {
          title: "Banned Accounts",
          subtitle: "Reviews from these Twitter accounts will not be displayed",
          valueType: "multiInput",
          key: "banned_accounts",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          title: "Any Keyword",
          subtitle: "Reviews contain any of these keywords",
          valueType: "multiInput",
          key: "any_keywords",
        },
        {
          title: "All Keywords",
          subtitle: "Reviews contain all of these keywords",
          valueType: "multiInput",
          key: "all_keywords",
        },
        {
          title: "Banned Accounts",
          subtitle: "Reviews from these accounts will not be displayed",
          valueType: "multiInput",
          key: "banned_accounts",
        },
      ],
      step3: [
        {
          title: "Algorithm",
          subtitle: "Select the training algorithm to generate topics",
          valueType: "radio",
          acceptedValues: [
            { text: "Short-Text", key: 4 },
            { text: "Long-Text", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Number Of Iterations",
          subtitle: "Select the number of iterations in training",
          valueType: "numeric",
          placeholder: "Enter number of iterations",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alpha",
          subtitle: "Select the alpha value in training",
          valueType: "input",
          placeholder: "Enter alpha value",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Select the beta value in training",
          valueType: "numeric",
          placeholder: "Enter beta value",
          key: "beta",
        },
      ],
    },
  },
  2: {
    simple: {
      step1: [
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
        {
          title: "Country",
          subtitle: "Filter reviews by countries",
          valueType: "dropdownMulti",
          acceptedValues: countryList,
          placeholder: "Select your countries",
          key: "countries",
        },
      ],
      step2: [
        {
          title: "App Url",
          subtitle: "Type the application url found at the store",
          valueType: "input",
          placeholder: "Enter application URL",
          key: "app_url",
          required: true,
        },
        {
          title: "Number Of Topics to Discover",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Start Date",
          subtitle: "Reviews posted since",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "Reviews posted until",
          valueType: "date",
          key: "end_date",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
        {
          title: "Country",
          subtitle: "Filter reviews by countries",
          valueType: "dropdownMulti",
          acceptedValues: countryList,
          placeholder: "Select your countries",
          key: "countries",
        },
      ],
      step2: [
        {
          title: "App Url",
          subtitle: "Type the application url found at the store",
          valueType: "input",
          placeholder: "Enter application URL",
          key: "app_url",
          required: true,
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Start Date",
          subtitle: "Start date of the fetched documents",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "End date of the fetched documents",
          valueType: "date",
          key: "end_date",
        },
      ],
      step3: [
        {
          title: "Algorithm",
          subtitle: "Select the training algorithm to generate topics",
          valueType: "radio",
          acceptedValues: [
            { text: "Short-Text", key: 4 },
            { text: "Long-Text", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Number Of Iterations",
          subtitle: "Select the number of iterations in training",
          valueType: "numeric",
          placeholder: "Enter number of iterations",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alpha",
          subtitle: "Select the alpha value in training",
          valueType: "input",
          placeholder: "Enter alpha value",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Select the beta value in training",
          valueType: "numeric",
          placeholder: "Enter beta value",
          key: "beta",
        },
      ],
    },
  },
  3: {
    simple: {
      step1: [
        {
          title: "Dashboard Name",
          subtitle: "Type your dashboard Name",
          valueType: "input",
          placeholder: "Enter dashboard name",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
          required: false,
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          valueType: "customerFile",
          placeholder: "Choose a file or upload",
          key: "customerDataFileS3Link",
          required: true,
        },
      ],
      step3: [
        {
          title: "Does your file contain headers",
          subtitle:
            "Your file needs to have column names in order to proceed to the next steps",
          valueType: "radio",
          acceptedValues: [
            { text: "No", key: "false" },
            { text: "Yes", key: "true" },
          ],
          key: "headersQuestion",
          required: true,
          disableIfNo: true,
        },
      ],
      step4: [
        {
          title: "Feedback Column Name",
          subtitle:
            "Type the feedback column name in the uploaded/selected file",
          valueType: "dropdown",
          placeholder: "Enter name",
          key: "customerFeedbackTextIndex",
          required: true,
        },
      ],
      step5: [
        {
          title: "TicketID Column Name",
          subtitle:
            "Please specify the column name that contains the unique ID for each review in the uploaded/selected file",
          valueType: "dropdown",
          placeholder: "Enter name",
          key: "customerUserIdIndex",
          skipAll: true,
        },
      ],
      // step6: [
      //   {
      //     title: "Email Column Name",
      //     subtitle: "Type the email column name in the uploaded/selected file",
      //     valueType: "dropdown",
      //     placeholder: "Enter name",
      //     key: "customerUserEmailndex",
      //     skipAll: true,
      //   },
      // ],
      step6: [
        {
          title: "Date Column Name",
          subtitle: "Date format should be YYYY-MM-DD or YYYY-MM-DD HH:MM:SS",
          valueType: "dropdown",
          placeholder: "Enter name",
          key: "customerDateIndex",
          skipAll: true,
        },
      ],
      // step8: [
      //   {
      //     title: "Phone Column Name",
      //     subtitle: "Type the phone column name in the uploaded/selected file",
      //     valueType: "dropdown",
      //     placeholder: "Enter name",
      //     key: "customerUserPhoneIndex",
      //     skipAll: true,
      //   },
      // ],
      step7: [
        {
          title: "Rating Column Name",
          subtitle: "Type the rating column name in the uploaded/selected file",
          valueType: "dropdown",
          placeholder: "Enter name",
          key: "customerRatingIndex",
          skipAll: true,
        },
      ],
      step8: [
        {
          title: "Pivot Column",
          subtitle:
            "Select pivot values to create aggregated calculations based on review attributes like customer tenure, segment, seller name, channel name, and more.",
          valueType: "multiselect",
          placeholder: "Select column names (Upto 10)",
          key: "filtercolIndex",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dashboard Name",
          subtitle: "Type your dashboard Name",
          valueType: "input",
          placeholder: "Enter dashboard name",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
          required: true,
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          title: "Algorithm",
          subtitle: "Select the training algorithm to generate topics",
          valueType: "radio",
          acceptedValues: [
            { text: "Short-Text", key: 4 },
            { text: "Long-Text", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Number Of Iterations",
          subtitle: "Select the number of iterations in training",
          valueType: "numeric",
          placeholder: "Enter number of iterations",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alpha",
          subtitle: "Select the alpha value in training",
          valueType: "input",
          placeholder: "Enter alpha value",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Select the beta value in training",
          valueType: "numeric",
          placeholder: "Enter beta value",
          key: "beta",
        },
      ],
      step3: [
        {
          valueType: "customerFile",
          placeholder: "Choose a file or upload",
          key: "customerDataFileS3Link",
          required: true,
        },
      ],
      step4: [
        {
          title: "Does your file contain headers",
          subtitle:
            "Your file needs to have column names in order to proceed to the next steps",
          valueType: "radio",
          acceptedValues: [
            { text: "No", key: "false" },
            { text: "Yes", key: "true" },
          ],
          key: "headersQuestion",
          required: true,
          disableIfNo: true,
        },
      ],
      step5: [
        {
          title: "Feedback Column Name",
          subtitle:
            "Type the feedback column name in the uploaded/selected file",
          valueType: "dropdown",
          placeholder: "Enter name",
          key: "customerFeedbackTextIndex",
          required: true,
        },
      ],
      step6: [
        {
          title: "TicketID Column Name",
          subtitle:
            "Please specify the column name that contains the unique ID for each review in the uploaded/selected file",
          valueType: "dropdown",
          placeholder: "Enter name",
          key: "customerUserIdIndex",
          skipAll: true,
        },
      ],
      // step7: [
      //   {
      //     title: "Email Column Name",
      //     subtitle: "Type the email column name in the uploaded/selected file",
      //     valueType: "dropdown",
      //     placeholder: "Enter name",
      //     key: "customerUserEmailndex",
      //     skipAll: true,
      //   },
      // ],
      step7: [
        {
          title: "Date Column Name",
          subtitle: "Date format should be YYYY-MM-DD or YYYY-MM-DD HH:MM:SS",
          valueType: "dropdown",
          placeholder: "Enter name",
          key: "customerDateIndex",
          skipAll: true,
        },
      ],
      // step9: [
      //   {
      //     title: "Phone Column Name",
      //     subtitle: "Type the phone column name in the uploaded/selected file",
      //     valueType: "dropdown",
      //     placeholder: "Enter name",
      //     key: "customerUserPhoneIndex",
      //     skipAll: true,
      //   },
      // ],
      step8: [
        {
          title: "Rating Column Name",
          subtitle: "Type the rating column name in the uploaded/selected file",
          valueType: "dropdown",
          placeholder: "Enter name",
          key: "customerRatingIndex",
          skipAll: true,
        },
      ],
      step9: [
        {
          title: "Pivot Column",
          subtitle:
            "If you want to filter your data based on Mentor Names, restaurant names or other criteria add its column Name",
          valueType: "multiselect",
          placeholder: "Enter name of the column",
          key: "filtercolIndex",
        },
      ],
    },
  },
  4: {
    simple: {
      step1: [
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
        {
          title: "Country",
          subtitle: "Filter reviews by countries",
          valueType: "dropdownMulti",
          acceptedValues: countryList,
          placeholder: "Select your countries",
          key: "countries",
        },
      ],
      step2: [
        {
          title: "App Url",
          subtitle: "Type the application url found at the store",
          valueType: "input",
          placeholder: "Enter application URL",
          key: "app_url",
          required: true,
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Start Date",
          subtitle: "Start date of the fetched documents",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "End date of the fetched documents",
          valueType: "date",
          key: "end_date",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
        {
          title: "Country",
          subtitle: "Filter reviews by countries",
          valueType: "dropdownMulti",
          acceptedValues: countryList,
          placeholder: "Select your countries",
          key: "countries",
        },
      ],
      step2: [
        {
          title: "App Url",
          subtitle: "Type the application url found at the store",
          valueType: "input",
          placeholder: "Enter application URL",
          key: "app_url",
          required: true,
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Start Date",
          subtitle: "Start date of the fetched documents",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "End date of the fetched documents",
          valueType: "date",
          key: "end_date",
        },
      ],
      step3: [
        {
          title: "Algorithm",
          subtitle: "Select the training algorithm to generate topics",
          valueType: "radio",
          acceptedValues: [
            { text: "Short-Text", key: 4 },
            { text: "Long-Text", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Number Of Iterations",
          subtitle: "Select the number of iterations in training",
          valueType: "numeric",
          placeholder: "Enter number of iterations",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alpha",
          subtitle: "Select the alpha value in training",
          valueType: "input",
          placeholder: "Enter alpha value",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Select the beta value in training",
          valueType: "numeric",
          placeholder: "Enter beta value",
          key: "beta",
        },
      ],
    },
  },
  5: {
    simple: {
      step1: [
        {
          title: "Brand Name",
          subtitle: "Type the brand name",
          valueType: "input",
          placeholder: "Enter the brand name",
          key: "brand",
          required: true,
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Start Date",
          subtitle: "Start date of the fetched documents",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "End date of the fetched documents",
          valueType: "date",
          key: "end_date",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Brand Name",
          subtitle: "Type the brand name",
          valueType: "input",
          placeholder: "Enter the brand name",
          key: "brand",
          required: true,
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Start Date",
          subtitle: "Start date of the fetched documents",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "End date of the fetched documents",
          valueType: "date",
          key: "end_date",
        },
      ],
      step2: [
        {
          title: "Algorithm",
          subtitle: "Select the training algorithm to generate topics",
          valueType: "radio",
          acceptedValues: [
            { text: "Short-Text", key: 4 },
            { text: "Long-Text", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Number Of Iterations",
          subtitle: "Select the number of iterations in training",
          valueType: "numeric",
          placeholder: "Enter number of iterations",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alpha",
          subtitle: "Select the alpha value in training",
          valueType: "input",
          placeholder: "Enter alpha value",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Select the beta value in training",
          valueType: "numeric",
          placeholder: "Enter beta value",
          key: "beta",
        },
      ],
    },
  },
  6: {
    simple: {
      step1: [
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
      ],
      step2: [
        {
          title: "HashTags",
          subtitle: "Reviews contain any of these hashtags",
          valueType: "multiInput",
          key: "hashtags",
          required: true,
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
      ],
      step2: [
        {
          title: "HashTag",
          subtitle: "Type the hashtag you are looking for",
          valueType: "input",
          placeholder: "Enter the hashtag",
          key: "hashtag",
          required: true,
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
      ],
      step3: [
        {
          title: "Algorithm",
          subtitle: "Select the training algorithm to generate topics",
          valueType: "radio",
          acceptedValues: [
            { text: "Short-Text", key: 4 },
            { text: "Long-Text", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Number Of Iterations",
          subtitle: "Select the number of iterations in training",
          valueType: "numeric",
          placeholder: "Enter number of iterations",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alpha",
          subtitle: "Select the alpha value in training",
          valueType: "input",
          placeholder: "Enter alpha value",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Select the beta value in training",
          valueType: "numeric",
          placeholder: "Enter beta value",
          key: "beta",
        },
      ],
    },
  },
  7: {
    simple: {
      step1: [
        {
          title: "Dashboard Name",
          subtitle: "Type your dashboard Name",
          valueType: "input",
          placeholder: "Enter dashboard name",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Analysis type",
          subtitle: "Get tailored analysis based on your main goal",
          valueType: "dropdown",
          defaultValue: "Tickets Content Analysis",
          acceptedValues: [
            "Tickets Content Analysis",
            "Customer Satisfaction Post-Survey Analysis (CSAT)",
          ],
          placeholder: "Select your analysis purpose",
          key: "zendesk_analysis_target",
          required: true,
        },
      ],
      step2: [
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Start Date",
          subtitle: "Reviews posted since",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "Reviews posted until",
          valueType: "date",
          key: "end_date",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dashboard Name",
          subtitle: "Type your dashboard Name",
          valueType: "input",
          placeholder: "Enter dashboard name",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Analysis type",
          subtitle: "Get tailored analysis based on your main goal",
          valueType: "dropdown",
          defaultValue: "Tickets Content Analysis",
          acceptedValues: [
            "Tickets Content Analysis",
            "Customer Satisfaction Post-Survey Analysis (CSAT)",
          ],
          placeholder: "Select your analysis purpose",
          key: "zendesk_analysis_target",
          required: true,
        },
      ],
      step2: [
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Start Date",
          subtitle: "Reviews posted since",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "Reviews posted until",
          valueType: "date",
          key: "end_date",
        },
      ],
      step3: [
        {
          title: "Algorithm",
          subtitle: "Select the training algorithm to generate topics",
          valueType: "radio",
          acceptedValues: [
            { text: "Short-Text", key: 4 },
            { text: "Long-Text", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Number Of Iterations",
          subtitle: "Select the number of iterations in training",
          valueType: "numeric",
          placeholder: "Enter number of iterations",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alpha",
          subtitle: "Select the alpha value in training",
          valueType: "input",
          placeholder: "Enter alpha value",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Select the beta value in training",
          valueType: "numeric",
          placeholder: "Enter beta value",
          key: "beta",
        },
      ],
    },
  },
  8: {
    simple: {
      step1: [
        {
          title: "Dashboard Name",
          subtitle: "Type your dashboard Name",
          valueType: "input",
          placeholder: "Enter dashboard name",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Start Date",
          subtitle: "Reviews posted since",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "Reviews posted until",
          valueType: "date",
          key: "end_date",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dashboard Name",
          subtitle: "Type your dashboard Name",
          valueType: "input",
          placeholder: "Enter dashboard name",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Start Date",
          subtitle: "Reviews posted since",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "Reviews posted until",
          valueType: "date",
          key: "end_date",
        },
      ],
      step2: [
        {
          title: "Algorithm",
          subtitle: "Select the training algorithm to generate topics",
          valueType: "radio",
          acceptedValues: [
            { text: "Short-Text", key: 4 },
            { text: "Long-Text", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Number Of Iterations",
          subtitle: "Select the number of iterations in training",
          valueType: "numeric",
          placeholder: "Enter number of iterations",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alpha",
          subtitle: "Select the alpha value in training",
          valueType: "input",
          placeholder: "Enter alpha value",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Select the beta value in training",
          valueType: "numeric",
          placeholder: "Enter beta value",
          key: "beta",
        },
      ],
    },
  },
  9: {
    simple: {
      step1: [
        {
          title: "Dashboard Name",
          subtitle: "Type your dashboard Name",
          valueType: "input",
          placeholder: "Enter dashboard name",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          title: "Page Name",
          subtitle: "Name of the Facebook Page",
          valueType: "dropdown_2",
          acceptedValues: [],
          placeholder: "Select your page",
          key: "page_id",
          required: true,
        },
        {
          title: "Start Date",
          subtitle: "Reviews posted since",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "Reviews posted until",
          valueType: "date",
          key: "end_date",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dashboard Name",
          subtitle: "Type your dashboard Name",
          valueType: "input",
          placeholder: "Enter dashboard name",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          title: "Page Name",
          subtitle: "Name of the Facebook Page",
          valueType: "dropdown_2",
          acceptedValues: [],
          placeholder: "Select your page",
          key: "page_id",
          required: true,
        },
        {
          title: "Start Date",
          subtitle: "Reviews posted since",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "Reviews posted until",
          valueType: "date",
          key: "end_date",
        },
      ],
      step3: [
        {
          title: "Algorithm",
          subtitle: "Select the training algorithm to generate topics",
          valueType: "radio",
          acceptedValues: [
            { text: "Short-Text", key: 4 },
            { text: "Long-Text", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Number Of Iterations",
          subtitle: "Select the number of iterations in training",
          valueType: "numeric",
          placeholder: "Enter number of iterations",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alpha",
          subtitle: "Select the alpha value in training",
          valueType: "input",
          placeholder: "Enter alpha value",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Select the beta value in training",
          valueType: "numeric",
          placeholder: "Enter beta value",
          key: "beta",
        },
      ],
    },
  },
  10: {
    simple: {
      step1: [
        {
          title: "Dashboard Name",
          subtitle: "Type your dashboard Name",
          valueType: "input",
          placeholder: "Enter dashboard name",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          title: "Ad Account",
          subtitle: "Id of the Facebook Ad Account",
          valueType: "dropdown_2",
          acceptedValues: [],
          placeholder: "Select your ad account",
          key: "ad_account_id",
          required: true,
        },
        {
          title: "Start Date",
          subtitle: "Reviews posted since",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "Reviews posted until",
          valueType: "date",
          key: "end_date",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dashboard Name",
          subtitle: "Type your dashboard Name",
          valueType: "input",
          placeholder: "Enter dashboard name",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          title: "Ad Account",
          subtitle: "Id of the Facebook Ad Account",
          valueType: "dropdown_2",
          acceptedValues: [],
          placeholder: "Select your ad account",
          key: "ad_account_id",
          required: true,
        },
        {
          title: "Start Date",
          subtitle: "Reviews posted since",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "Reviews posted until",
          valueType: "date",
          key: "end_date",
        },
      ],
      step3: [
        {
          title: "Algorithm",
          subtitle: "Select the training algorithm to generate topics",
          valueType: "radio",
          acceptedValues: [
            { text: "Short-Text", key: 4 },
            { text: "Long-Text", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Number Of Iterations",
          subtitle: "Select the number of iterations in training",
          valueType: "numeric",
          placeholder: "Enter number of iterations",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alpha",
          subtitle: "Select the alpha value in training",
          valueType: "input",
          placeholder: "Enter alpha value",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Select the beta value in training",
          valueType: "numeric",
          placeholder: "Enter beta value",
          key: "beta",
        },
      ],
    },
  },
  11: {
    simple: {
      step1: [
        {
          title: "Dashboard Name",
          subtitle: "Type your dashboard Name",
          valueType: "input",
          placeholder: "Enter dashboard name",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
          required: true,
        },
        {
          title: "Country",
          subtitle: "Filter reviews by countries",
          valueType: "dropdownMulti",
          acceptedValues: countryList,
          placeholder: "Select your countries",
          key: "countries",
        },
        {
          title: "Platforms",
          subtitle: "Choose between Google Play, App Store or both.",
          valueType: "dropdownMulti",
          acceptedValues: ["Google Play", "App Store"],
          placeholder: "Select your platforms",
          key: "platforms",
          required: true,
        },
      ],
      step2: [
        {
          title: "Google Play Application Url",
          subtitle: "Type the application url found at the google play",
          valueType: "input",
          placeholder: "Enter application URL",
          key: "google_play_app_url",
          // required: true,
        },
        {
          title: "App Store Application Url",
          subtitle: "Type the application url found at the app store",
          valueType: "input",
          placeholder: "Enter application URL",
          key: "app_store_app_url",
          // required: true,
        },
        {
          title: "Number Of Topics to Discover",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Start Date",
          subtitle: "Reviews posted since",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "Reviews posted until",
          valueType: "date",
          key: "end_date",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dashboard Name",
          subtitle: "Type your dashboard Name",
          valueType: "input",
          placeholder: "Enter dashboard name",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
          required: true,
        },
        {
          title: "Country",
          subtitle: "Filter reviews by countries",
          valueType: "dropdownMulti",
          acceptedValues: countryList,
          placeholder: "Select your countries",
          key: "countries",
        },
        {
          title: "Platforms",
          subtitle: "Choose between Google Play, App Store or both.",
          valueType: "dropdownMulti",
          acceptedValues: ["Google Play", "App Store"],
          placeholder: "Select your platforms",
          key: "platforms",
        },
      ],
      step2: [
        {
          title: "Google Play Application Url",
          subtitle: "Type the application url found at the google play",
          valueType: "input",
          placeholder: "Enter application URL",
          key: "google_play_app_url",
          // required: true,
        },
        {
          title: "App Store Application Url",
          subtitle: "Type the application url found at the app store",
          valueType: "input",
          placeholder: "Enter application URL",
          key: "app_store_app_url",
          // required: true,
        },
        {
          title: "Number Of Topics",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
        {
          title: "Start Date",
          subtitle: "Start date of the fetched documents",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "End date of the fetched documents",
          valueType: "date",
          key: "end_date",
        },
      ],
      step3: [
        {
          title: "Algorithm",
          subtitle: "Select the training algorithm to generate topics",
          valueType: "radio",
          acceptedValues: [
            { text: "Short-Text", key: 4 },
            { text: "Long-Text", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Number Of Iterations",
          subtitle: "Select the number of iterations in training",
          valueType: "numeric",
          placeholder: "Enter number of iterations",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alpha",
          subtitle: "Select the alpha value in training",
          valueType: "input",
          placeholder: "Enter alpha value",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Select the beta value in training",
          valueType: "numeric",
          placeholder: "Enter beta value",
          key: "beta",
        },
      ],
    },
  },
  13: {
    simple: {
      step1: [
        {
          title: "Dashboard Name",
          subtitle: "Type your dashboard Name",
          valueType: "input",
          placeholder: "Enter dashboard name",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
          required: true,
        },
        {
          title: "Number Of Topics to Discover",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          title: "Category Link",
          subtitle: "Copy and paste the category urls you want to analyze",
          valueType: "multiInput",
          placeholder: "Enter product URLs and press enter for each link",
          key: "category_links",
          required: true,
        },
        {
          title: "Product Link",
          subtitle: "Copy and paste the product urls you want to analyze",
          valueType: "multiInput",
          placeholder: "Enter product URLs and press enter for each link",
          key: "product_links",
          required: true,
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dashboard Name",
          subtitle: "Type your dashboard Name",
          valueType: "input",
          placeholder: "Enter dashboard name",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
          required: true,
        },
        {
          title: "Number Of Topics to Discover",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
      ],
      step2: [
        {
          title: "Category Link",
          subtitle: "Copy and paste the category urls you want to analyze",
          valueType: "multiInput",
          placeholder: "Enter product URLs and press enter for each link",
          key: "category_links",
          required: true,
        },
        {
          title: "Product Link",
          subtitle: "Copy and paste the product urls you want to analyze",
          valueType: "multiInput",
          placeholder: "Enter product URLs and press enter for each link",
          key: "product_links",
          required: true,
        },
      ],
      step3: [
        {
          title: "Algorithm",
          subtitle: "Select the training algorithm to generate topics",
          valueType: "radio",
          acceptedValues: [
            { text: "Short-Text", key: 4 },
            { text: "Long-Text", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Number Of Iterations",
          subtitle: "Select the number of iterations in training",
          valueType: "numeric",
          placeholder: "Enter number of iterations",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alpha",
          subtitle: "Select the alpha value in training",
          valueType: "input",
          placeholder: "Enter alpha value",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Select the beta value in training",
          valueType: "numeric",
          placeholder: "Enter beta value",
          key: "beta",
        },
      ],
    },
  },
  12: {
    simple: {
      step1: [
        {
          title: "Dashboard Name",
          subtitle: "Type your dashboard Name",
          valueType: "input",
          placeholder: "Enter dashboard name",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
        {
          title: "Start Date",
          subtitle: "Reviews posted since",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "Reviews posted until",
          valueType: "date",
          key: "end_date",
        },
      ],
      step2: [
        {
          title: "Platforms",
          subtitle: "Choose between Social Media Platforms",
          valueType: "dropdownMulti",
          acceptedValues: [
            "Instagram Account Posts",
            "Ekşi Sözlük",
            "Facebook Page",
            "Şikayet Var",
            "Twitter",
            "TikTok",
            "Forums/Community",
            "LinkedIn Posts",
          ],
          placeholder: "Select your platforms",
          key: "platforms",
          required: true,
        },
        {
          title: "Number Of Topics to Discover",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
      ],
      step3: [
        {
          title: "Instagram HashTags",
          subtitle: "Reviews contain any of these hashtags",
          valueType: "multiInput",
          key: "hashtags",
        },
      ],
    },
    advanced: {
      step1: [
        {
          title: "Dashboard Name",
          subtitle: "Type your dashboard Name",
          valueType: "input",
          placeholder: "Enter dashboard name",
          key: "dashboard_name",
          required: true,
        },
        {
          title: "Language",
          subtitle: "Language of reviews that will be collected",
          valueType: "dropdown",
          defaultValue: "Turkish",
          acceptedValues: lang,
          placeholder: "Select your language",
          key: "language",
        },
        {
          title: "Start Date",
          subtitle: "Reviews posted since",
          valueType: "date",
          key: "start_date",
        },
        {
          title: "End Date",
          subtitle: "Reviews posted until",
          valueType: "date",
          key: "end_date",
        },
      ],
      step2: [
        {
          title: "Platforms",
          subtitle: "Choose between Social Media Platforms",
          valueType: "dropdownMulti",
          acceptedValues: [
            "Instagram Account Posts",
            "Ekşi Sözlük",
            "Facebook Page",
            "Şikayet Var",
            "Twitter",
            "TikTok",
            "Forums/Community",
            "LinkedIn Posts",
          ],
          placeholder: "Select your platforms",
          key: "platforms",
          required: true,
        },
        {
          title: "Number Of Topics to Discover",
          subtitle: "Specify the maximum number of topics to be discovered",
          valueType: "numeric",
          placeholder: "Enter number of topics (Optional)",
          key: "num_topics",
          required: false,
        },
      ],
      step3: [
        {
          title: "Instagram HashTags",
          subtitle: "Reviews contain any of these hashtags",
          valueType: "multiInput",
          key: "hashtags",
        },
      ],
      step4: [
        {
          title: "Algorithm",
          subtitle: "Select the training algorithm to generate topics",
          valueType: "radio",
          acceptedValues: [
            { text: "Short-Text", key: 4 },
            { text: "Long-Text", key: 3 },
          ],
          key: "algorithm_id",
        },
        {
          title: "Number Of Iterations",
          subtitle: "Select the number of iterations in training",
          valueType: "numeric",
          placeholder: "Enter number of iterations",
          defaultValue: 100,
          key: "num_iters",
        },
        {
          title: "Alpha",
          subtitle: "Select the alpha value in training",
          valueType: "input",
          placeholder: "Enter alpha value",
          key: "alpha",
        },
        {
          title: "Beta",
          subtitle: "Select the beta value in training",
          valueType: "numeric",
          placeholder: "Enter beta value",
          key: "beta",
        },
      ],
    },
  },
};

export default steps;

/*
types = [radio, dropdown, dropdownMulti, input, numeric, date, ]
*/
