import React, { useEffect, useState, useRef } from "react";
import styles from "./style.module.scss";
import { Spin, notification, Button, Select } from "antd";
import AllDashboardsHeader from "components/AllDashboards/AllDashboardsHeader";
import { apiRequest } from "util/services";
import { UNLOGGED } from "util/constants";
import { useHistory } from "react-router-dom";
import DashboardItem from "components/AllDashboards/DashboardItem";
import { Modal } from "components/General/ModalFormComponents";
import GroupsMangaer from "components/Groups/index";
import * as actions from "redux/popup/actions.js";
import { useDispatch, useSelector } from "react-redux";
import Icon from "helperComponents/Icon";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import CustomInput from "components/General/CustomInput";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const NewDashboard = ({ authenticated, user }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [localState, setState] = useState({
    activePlatform: 0,
    selectedUserId: user?.uid,
    group_id: null,
  });
  const [loading, setLoading] = useState(true);
  const [platforms, setPlatforms] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState();
  const [Dashboards, setDashboards] = useState([]);
  const [showGroups, setShowGroups] = useState(false);
  const [groups, setGroups] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [groupColor, setGroupColor] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  const extraFilterRef = useRef();

  const [isOnlyPage, setIsOnlyPage] = useState(true);
  const [searchWord, setSearchWord] = useState("");
  const [userList, setUserList] = useState(null);
  const [sortValue, setSortValue] = useState("newest");
  const [showExtraFilterOption, setShowExtraFilterOption] = useState(false);
  const [savingGroup, setSavingGroup] = useState(false);
  const [selectedPerPage, setSelectedPerPage] = useState(10);
  const [dashboardPageNumber, setDashboardPageNumber] = useState(1);
  const [totalDashboards, setTotalDashboards] = useState();
  const [numOfDashboards, setNumOfDashboards] = useState(10);
  const [selectedDashboards, setSelectedDashboards] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [pinnedCount, setPinnedCount] = useState(0);
  const { t } = useTranslation();
  const [disableSave, setDisableSave] = useState(false);
  const userReducer = useSelector((state) => state.userReducer);
  const theme = useSelector((state) => state.DashboardReducer.theme);

  useEffect(() => {
    if (authenticated === UNLOGGED) {
      history.push("/");
      return;
    }
    getAllPlatforms();
    dispatch({
      type: actions.POPUP_FIRST_TIME,
    });
  }, []);

  useEffect(() => {
    fetchAllGroups();
  }, []);

  useEffect(() => {
    getAllDashboards();
  }, [
    startIndex,
    localState.activePlatform,
    localState.selectedUserId,
    localState.group_id,
    sortValue,
    numOfDashboards,
  ]);

  useEffect(() => {
    if (userReducer.IsExpert) {
      const getUserList = async () => {
        const result = await apiRequest(
          "users/ExpertGetUsersList",
          "POST",
          {
            expert_id: user.uid,
          },
          true
        );
        if (result && !result.error) {
          setUserList(result);
        }
      };
      getUserList();
    }
  }, [userReducer.IsExpert]);

  const getAllPlatforms = async () => {
    const result = await apiRequest(
      "users/getAllowedPlatformsOfUser",
      "POST",
      {
        firebase_id: user.uid,
      },
      true
    );
    if (!result.error) {
      setPlatforms(result.platforms);
    }
  };
  const filterOutClick = (e) => {
    if (extraFilterRef.current && !extraFilterRef.current.contains(e.target)) {
      setShowExtraFilterOption(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", filterOutClick, true);
    return () => document.removeEventListener("click", filterOutClick, true);
  }, []);
  const addOrRemoveSelected = (dashboard_id) => {
    let flag = selectedDashboards.includes(dashboard_id);

    if (flag) {
      let filteredSelected = selectedDashboards.filter(
        (item) => item !== dashboard_id
      );
      setSelectedDashboards(filteredSelected);
    } else {
      setSelectedDashboards([...selectedDashboards, dashboard_id]);
    }
  };

  const fetchAllGroups = async () => {
    const result = await apiRequest("dashboards/groups", "POST", {}, true);
    if (!result.error) {
      setGroups(result.success);
    } else {
      notification.error({
        message: result.error,
      });
    }
  };

  const sortByPinnedDashboards = (dashboards) => {
    let pinned = [];
    let unpinned = [];
    if (dashboards?.length) {
      dashboards.map((info) => {
        if (info?.pined) {
          pinned.push(info);
        } else {
          unpinned.push({ ...info });
        }
      });
    }
    setPinnedCount(pinned.length);
    return [...pinned, ...unpinned];
  };

  const getAllDashboards = async (reset) => {
    // if (startIndex === lastIndex) return
    setLoading(true);
    const payload = {
      start_index: startIndex,
      num_dashboards: numOfDashboards,
      sort: sortValue,
    };

    if (searchWord && !reset) {
      payload.search = searchWord;
    }
    if (localState?.selectedUserId) {
      payload.user_id = localState?.selectedUserId;
    }

    if (localState.activePlatform !== 0)
      payload.platform_id = localState.activePlatform;
    if (localState.group_id) payload.group_id = localState.group_id;

    const result = await apiRequest("dashboards/all", "POST", payload, true);
    if (result && result?.dashboards?.length !== 0) {
      setDashboards(sortByPinnedDashboards(result?.dashboards));
      setTotalDashboards(result?.num_dashboard);
      if (startIndex === 0 && result?.dashboards?.length === numOfDashboards) {
        setIsOnlyPage(false);
      }
      if (startIndex === 0 && result?.dashboards?.length < numOfDashboards) {
        setIsOnlyPage(true);
      }
    } else if (result && result?.dashboards?.length === 0 && startIndex !== 0) {
      notification.info({
        message: t("notifications.1"),
        icon: (
          <InfoCircleOutlined
            style={{ color: theme === "dark" ? "white" : "black" }}
          />
        ),
      });
      setLastIndex(startIndex - 1);
      setStartIndex(startIndex - 1);
    } else if (result.error) {
      notification.error({
        message: result.error,
      });
    } else if (result?.dashboards?.length === 0) {
      setDashboards([]);
      setTotalDashboards(0);
    }
    setLoading(false);
  };

  const handleUserPage = (e) => {
    if (e.which === 13) {
      if (!dashboardPageNumber) {
        notification.info({
          message: t("notifications.2"),
          icon: (
            <InfoCircleOutlined
              style={{ color: theme === "dark" ? "white" : "black" }}
            />
          ),
        });
      } else if (
        dashboardPageNumber > 0 &&
        dashboardPageNumber <= Math.ceil(totalDashboards / selectedPerPage) &&
        /^\d+$/.test(dashboardPageNumber)
      ) {
        setStartIndex(dashboardPageNumber - 1);
        e.target.blur();
      } else {
        notification.info({
          message: t("notifications.2"),
          icon: (
            <InfoCircleOutlined
              style={{ color: theme === "dark" ? "white" : "black" }}
            />
          ),
        });
      }
    }
  };

  const handlePageChange = (value) => {
    setSelectedPerPage(value);
    setNumOfDashboards(value);
    setStartIndex(0);
    setDashboardPageNumber(1);
  };
  const handleGroupDashboard = async () => {
    setSavingGroup(true);
    if (!selectedGroup && searchQuery === "") {
      const payload = {
        dashboard_ids: selectedDashboards,
      };
      const result = await apiRequest("dashboards/ungrouping", "POST", payload);
      if (!result) {
        notification.error({
          message: t("others.1"),
        });
      } else {
        getAllDashboards();
        setSelectedDashboards([]);
        setSelectedGroup(null);
        setShowGroups(false);
        notification.success({
          message: "Removed Group from Dashboard Successfuly",
        });
      }
      setSavingGroup(false);
      return;
    }
    const payload = {
      dashboard_ids: selectedDashboards,
      group_name: selectedGroup?.group_name
        ? selectedGroup.group_name
        : searchQuery,
      group_color: selectedGroup?.group_color
        ? selectedGroup?.group_color
        : groupColor,
    };
    if (selectedGroup && selectedGroup.group_id) {
      payload.group_id = selectedGroup.group_id;
    }
    const result = await apiRequest("dashboards/grouping", "POST", payload);
    if (!result || result.error || result.validation_error) {
      notification.error({
        message: "Grouping failed.",
      });
    } else {
      getAllDashboards();
      setSelectedGroup(null);
      setIsEdit(null);
      setSearchQuery("");
      setShowGroups(false);
      setSavingGroup(false);
      notification.success({
        message: "Added Group to Dashboard Successfuly",
      });
      setSelectedDashboards([]);
    }
  };
  const selectAllDashboards = () => {
    let all = [];
    Dashboards.map((item) => {
      all.push(item.ID);
    });
    setSelectedDashboards(all);
  };

  const DashboardsItems = Dashboards?.map((Dashboard) => {
    return (
      <DashboardItem
        item={Dashboard}
        selectedDashboards={selectedDashboards}
        selected={selectedDashboards.includes(Dashboard.ID)}
        setDashboards={setDashboards}
        pinnedCount={pinnedCount}
        Dashboards={Dashboards}
        addOrRemoveSelected={addOrRemoveSelected}
        hideActions={selectedDashboards.length ? true : false}
        getAllDashboards={getAllDashboards}
        IsExpert={userReducer.IsExpert}
      />
    );
  });

  return (
    <div className={styles.mainWindow}>
      <div>
        <Modal
          showModal={showGroups}
          onClose={() => {
            setSelectedGroup(null);
            setSearchQuery(null);
            setShowGroups(false);
            setIsEdit(false);
          }}
          onSave={handleGroupDashboard}
          showSaveButton={true}
          hideCloseIcon={true}
          textonButton={"Save"}
          disabled={savingGroup || disableSave ? true : false}
          showCancelButton={true}
          titleAsset=""
          title={
            <div>
              {" "}
              <Icon name={"tag_group"} />
              Add or Remove Group
            </div>
          }
          widthofmodal="450px"
        >
          <GroupsMangaer
            setGroup={setSelectedGroup}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            groupColor={groupColor}
            setGroupColor={setGroupColor}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        </Modal>
        <AllDashboardsHeader
          platforms={platforms}
          localState={localState}
          setState={setState}
          setStartIndex={setStartIndex}
          setLastIndex={setLastIndex}
          groups={groups}
          userList={userList}
          user={user}
          getAllDashboards={getAllDashboards}
          startIndex={startIndex}
          setSearchWord={setSearchWord}
          searchWord={searchWord}
          setSortValue={setSortValue}
          sortValue={sortValue}
          setDashboardPageNumber={setDashboardPageNumber}
        />
        <Spin spinning={loading}>
          <div className={styles.content} id="all_Dashboards_container">
            {selectedDashboards && selectedDashboards.length ? (
              <div className={styles.headerActions}>
                <div className={styles.extraFilterRow}>
                  <div
                    className={styles.firstItem}
                    onClick={() => setShowExtraFilterOption(true)}
                  >
                    <div className={styles.wrapper}>
                      <input
                        type="checkbox"
                        checked={selectedDashboards?.length > 0 ? true : false}
                        onChange={() => {}}
                        className={`${styles.topicCheckBox} ${
                          selectedDashboards?.length !== Dashboards.length
                            ? styles.fewSelectedState
                            : undefined
                        }`}
                        onClick={(e) => e.stopPropagation()}
                      />
                      <i className="fa-sharp fa-solid fa-caret-down"></i>
                    </div>
                    {t("label.11")} ({selectedDashboards.length})
                    {showExtraFilterOption && (
                      <div
                        className={styles.extraFilterOption}
                        ref={extraFilterRef}
                      >
                        <span
                          onClick={() => {
                            setSelectedDashboards([]);
                          }}
                        >
                          {t("topic_library.25")}
                        </span>
                        <span onClick={selectAllDashboards}>
                          {t("topic_library.26")}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.actions}>
                  <div
                    className={styles.button}
                    onClick={() => {
                      setShowGroups(true);
                    }}
                  >
                    <Icon name={"tag_group"} /> <p>Add Group</p>
                  </div>
                  <div
                    className={styles.button}
                    onClick={() => {
                      handleGroupDashboard();
                    }}
                  >
                    <Icon name={"trash"} /> <p>Remove Group</p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {DashboardsItems.length !== 0 && (
              <DashboardItem
                item={{}}
                hideActions={selectedDashboards.length ? true : false}
                header
              />
            )}
            {DashboardsItems.length === 0 && !loading ? (
              <div className={styles.noBoards}>
                <p className={styles.heading}>
                  {t("empty_states.5")}{" "}
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/emptyStates/all_dashboards_icon.svg"
                        : "/assets/emptyStates/all_dashboards_icon_black.svg"
                    }
                    alt=""
                  />
                </p>
                <div className={styles.secondRow}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/error_outline.svg"
                        : "/assets/error_outline_black.svg"
                    }
                    alt=""
                  />
                  {t("empty_states.6")}
                </div>
                <p className={styles.secondHeading}>
                  {t("empty_states.7")}{" "}
                  <img src={"/assets/double_down_arrow.svg"} alt="" />
                </p>
                <div className={styles.imgBlock}>
                  <div>
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/emptyStates/all_dashboards1.png"
                          : theme === "light"
                          ? "/assets/emptyStates/all_dashboards1_light.png"
                          : "/assets/emptyStates/all_dashboards1_brand.png"
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      src={
                        theme === "dark"
                          ? "/assets/emptyStates/all_dashboards2.png"
                          : theme === "light"
                          ? "/assets/emptyStates/all_dashboards2_light.png"
                          : "/assets/emptyStates/all_dashboards2_brand.png"
                      }
                      alt=""
                    />
                  </div>
                </div>
              </div>
            ) : (
              DashboardsItems
            )}
          </div>
        </Spin>
      </div>
      {Dashboards.length !== 0 && (
        <div className={styles.paginationWrapper}>
          <div className={styles.innerWrapper}>
            {
              <div className={styles.paginationBox}>
                <Button
                  disabled={startIndex === 0}
                  onClick={() => {
                    parseInt(dashboardPageNumber, 10) === startIndex + 1 &&
                      setStartIndex((prev) => prev - 1);
                    parseInt(dashboardPageNumber, 10) === startIndex + 1 &&
                      setDashboardPageNumber((prev) => parseInt(prev, 10) - 1);
                  }}
                >
                  <i className="fa fa-angle-left" />
                </Button>
                <span className={styles.pagination}>
                  <input
                    className={styles.pageIndex}
                    onKeyPress={handleUserPage}
                    type="text"
                    value={dashboardPageNumber}
                    onChange={(e) => setDashboardPageNumber(e.target.value)}
                  />
                  {totalDashboards
                    ? ` / ${Math.ceil(totalDashboards / selectedPerPage)}`
                    : ""}
                </span>
                <Button
                  disabled={
                    Dashboards.length === 0 ||
                    (totalDashboards &&
                      startIndex + 1 ===
                        Math.ceil(totalDashboards / selectedPerPage)) ||
                    !dashboardPageNumber
                  }
                  onClick={() => {
                    parseInt(dashboardPageNumber, 10) === startIndex + 1 &&
                      setStartIndex((prev) => prev + 1);
                    parseInt(dashboardPageNumber, 10) === startIndex + 1 &&
                      setDashboardPageNumber((prev) => parseInt(prev, 10) + 1);
                  }}
                >
                  <i className="fa fa-angle-right" />
                </Button>
              </div>
            }

            {Dashboards?.length > 0 && (
              <Select
                className="review-page-number-selector"
                value={selectedPerPage + ` / ${t("label.10")}`}
                onChange={handlePageChange}
                style={{ backgroundColor: "transparent" }}
              >
                <Option value={10}>10</Option>
                <Option
                  disabled={totalDashboards <= 10 ? true : false}
                  value={25}
                >
                  25
                </Option>
                <Option
                  disabled={totalDashboards <= 25 ? true : false}
                  value={50}
                >
                  50
                </Option>
                <Option
                  disabled={totalDashboards <= 50 ? true : false}
                  value={100}
                >
                  100
                </Option>
              </Select>
            )}
          </div>
          {totalDashboards > 0 && (
            <div className={styles.dashboardsTotal}>
              {selectedPerPage * startIndex +
                Dashboards.filter((item) => {
                  if (!item.pined) {
                    return item;
                  }
                })?.length +
                " of " +
                totalDashboards}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NewDashboard;
