import React from 'react';
import { useDropzone } from 'react-dropzone';
import Representation from './Representation';
import { getFileHeaderFromXLSX } from './fileReader';
import { useSelector } from 'react-redux';

const DropZoneWrapper = ({
  handleFileChange,
  progress,
  spinning,
  setColumnNames
}) => {

  const theme = useSelector(state => state.DashboardReducer.theme);

  const onFileChange = async (acceptedFiles) => {
    const headers = await getFileHeaderFromXLSX(acceptedFiles[0])
    setColumnNames(headers);
    handleFileChange(acceptedFiles);
  }
  const params = {
    accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    onDrop: onFileChange,
    maxFiles: 1,
    multiple: false
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone(params);
  return (
    <Representation
      spinning={spinning}
      progress={progress}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      isDragActive={isDragActive}
      theme={theme}
    />
  );
}

export default DropZoneWrapper;