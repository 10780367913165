import React from "react";
import { Select } from "antd";
import styles from "./style.module.scss";
const { Option } = Select;

const SingleSelect = ({
  options,
  reset,
  placeholder,
  defaultValue,
  selectedOptions,
  setSelectedOptions,
  disableMenu,
}) => {
  const handleSelection = (id) => {
    const selected = options.find((option) => option.id === id);
    setSelectedOptions({
      id: selected?.id,
      label: selected?.label,
      img: selected?.img ? selected?.img : "",
      value: selected?.value,
    });
  };
  return (
    <div className={styles.filters}>
      {reset ? (
        <Select
          onChange={handleSelection}
          placeholder={placeholder}
          value={selectedOptions ? selectedOptions.value : defaultValue}
          listHeight={160}
          disabled={disableMenu}
          showSearch
          optionFilterProp="title"
        >
          {options?.map((option) => (
            <Option key={option.id} value={option.value} title={option.label}>
              {option.img && <img src={option.img} width={22} height={22} />}{" "}
              <span className={styles.optionLabel}>{option.label}</span>
            </Option>
          ))}
        </Select>
      ) : (
        <Select
          onChange={handleSelection}
          placeholder={placeholder}
          value={selectedOptions ? selectedOptions.value : defaultValue}
          listHeight={160}
          disabled={disableMenu}
          showSearch
          optionFilterProp="title"
        >
          {options?.map((option) => (
            <Option key={option.id} value={option.value} title={option.label}>
              {option.img && <img src={option.img} width={22} height={22} />}{" "}
              <span className={styles.optionLabel}>{option.label}</span>
            </Option>
          ))}
        </Select>
      )}
    </div>
  );
};

export default SingleSelect;
