import React, { useState, useEffect, useRef } from "react";
import DropdownSelect from "../helperComponents/DropdownSelect/DropdownSelect";
import styles from "./style.module.scss";
import DateFilterPopUp from "../helperComponents/DateFilter(OnleftSide)";
import ThreeDotsMenu from "../helperComponents/ThreeDotsMenu/ThreeDotsMenu";
import ComparisonChart from "../helperComponents/Charts/ComparisonChart/ComparisonChart";
import dayjs from "dayjs";
import { Button, Input, notification, Drawer, Spin } from "antd";
import {
  CalendarFilled,
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  RightOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import MultiSelectDropdown from "../helperComponents/MultiSelectDropdown/MultiSelectDropdown";
import SubCategoryChart from "../helperComponents/Charts/SubCategoryChart/SubCategoryChart";
import OverallAverage from "../helperComponents/OverallAverage/OverallAverage";
import { apiRequest } from "util/services";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "redux/Dashboard/actions.js";
import { Modal, Modal as Modal1 } from "components/General/ModalFormComponents";
import BoardCard from "components/Dashboard/BoardCard";
import CustomInput from "components/General/CustomInput";
import ReviewsList from "../helperComponents/ReviewsList";
import { useTranslation } from "react-i18next";
import MultiSelect from "helperComponents/MultiSelect";
import { useHistory } from "react-router-dom";
import { convertDateIntoYYYYMMDD } from "util/common";
const AnalysisBoard = ({
  data,
  dataLoaded,
  setDataLoaded,
  categories,
  availablePlatforms,
  fetchData,
  clearComparisonView,
  isLoading,
  setIsLoading,
  comparisonName,
  setComparisonName,
  match,
  user,
  defaultSelectedCategories,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [showDateFilterPopup, setShowDateFilterPopup] = useState(false);
  const [dateRange, setDateRange] = useState("last7d");
  const [selectedDaysRange, setSelectedDaysRange] = useState("last7d");
  const [selectedFilter, setSelectedFilter] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [subCategoryClicked, setSubCategoryClicked] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [resetFiltersClicked, setResetFiltersClicked] = useState(false);
  const [
    selectedCategoryForSubcategories,
    setSelectedCategoryForSubcategories,
  ] = useState(null);
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [mobileFiltersReset, setMobileFiltersReset] = useState(false);
  const [selectedGroupForReviews, setSelectedGroupForReviews] = useState(null);
  const [reviewsLoading, setReviewsLoading] = useState(false);
  const [allReviews, setAllReviews] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [numOfDocs, setNumOfDocs] = useState(10);
  const [numReviews, setNumReviews] = useState(0);
  const [searchWord, setSearchWord] = useState("");
  const [resetReviews, setResetReviews] = useState(false);
  const DashboardReducer = useSelector((state) => state.DashboardReducer);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const stopInitSearchRef = useRef(true);
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [facebookReplies, setFacebookReplies] = useState([]);
  const [selectedChartType, setSelectedChartType] = useState(
    params.get("selected_chart_type") ? params.get("selected_chart_type") : "C2"
  );
  const typeRef = useRef(params.get("selected_chart_type"));
  const subCategoryRef = useRef(params.has("selected_subCategory"));
  const updateQParams = (attr, val) => {
    params.delete(attr);
    params.append(attr, val);
    history.push({ search: params.toString() });
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 949 && showMobileFilters) {
        setShowMobileFilters(!showMobileFilters);
      }
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showMobileFilters]);
  const skipOnceRef = useRef(true);
  const resetFilters = () => {
    [
      "selected_sentiment",
      "selected_platforms",
      "selected_categories",
      "selected_chart_type",
      "since",
      "until",
      "daysRange",
      "decisionFilter",
    ].forEach((item) => params.delete(item));
    const untilDate = new Date();
    const sinceDate = new Date(untilDate.getTime() - 6 * 24 * 60 * 60 * 1000);
    params.append("since", convertDateIntoYYYYMMDD(sinceDate));
    params.append("until", convertDateIntoYYYYMMDD(untilDate));
    params.append("daysRange", "last7d");
    params.append(
      "selected_categories",
      JSON.stringify(defaultSelectedCategories?.map((item) => item.categoryId))
    );
    params.append("selected_chart_type", "C2");
    history.push({ search: params.toString() });
    setSelectedOptions([]);
    setSelectedPlatforms([]);
    setShowDateFilterPopup(false);
    setSelectedDaysRange("last7d");
    setDateRange("last7d");
    setSelectedChartType("C2");
    setSelectedCategories([]);
    setSubCategoryClicked(false);
  };
  const clearComparison = async () => {
    setIsLoading(true);
    const apiResponse = await apiRequest(
      "industry_analysis/clear",
      "GET",
      null,
      true
    );
    if (apiResponse?.success) {
      clearComparisonView(true);
      [
        "selected_sentiment",
        "selected_platforms",
        "selected_categories",
        "selected_chart_type",
        "since",
        "until",
        "daysRange",
        "decisionFilter",
      ].forEach((item) => params.delete(item));
      history.push({ search: params.toString() });
      setIsLoading(false);
    } else {
      notification.error({
        message: `${apiResponse?.error}`,
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (selectedFilter == 1) {
      setResetFiltersClicked(true);
      resetFilters();
    } else if (selectedFilter == 2) {
      // editSourcesConfiguration()
      console.log("Alter Configurations");
    } else if (selectedFilter == 3) {
      clearComparison();
    }
    setSelectedFilter(0);
    setResetFiltersClicked(selectedFilter == 1);
  }, [selectedFilter]);
  const rangePresets = {
    last24: {
      label: "Last 24 hours",
    },
    last7d: {
      label: "Last 7 Days",
    },
    last14d: {
      label: "Last 14 Days ",
    },
    last30d: {
      label: "Last 30 Days ",
    },
    last90d: {
      label: "Last 90 Days",
    },
    custom: {
      label: "Custom",
    },
    allTime: {
      label: "All Time",
    },
  };
  const dateFilterRef = useRef(null);
  useEffect(() => {
    if (selectedCategories?.length > 0) {
      setDataLoaded(false);
    } else if (selectedOptions?.length > 0) {
      setDataLoaded(false);
    } else if (selectedPlatforms?.length > 0) {
      setDataLoaded(false);
    } else if (DashboardReducer.searchState?.dateRange?.length == 0) {
      setDataLoaded(false);
    }
  }, [dataLoaded]);
  useEffect(() => {
    if (selectedChartType === "C2" && showMobileFilters) {
      setShowMobileFilters(false);
    } else if (selectedChartType === "C1" && showMobileFilters) {
      setShowMobileFilters(false);
    } else if (selectedChartType === "C3" && showMobileFilters) {
      setShowMobileFilters(false);
    }
  }, [selectedChartType]);
  useEffect(() => {
    let payload = {
      filters: {},
    };
    if (!params.has("selected_categories")) {
      return;
    }
    if (skipOnceRef.current) {
      skipOnceRef.current = false;
      return;
    }
    if (!showMobileFilters && !resetFiltersClicked) {
      if (typeRef?.current !== params.get("selected_chart_type")) {
        typeRef.current = params.get("selected_chart_type");
        return;
      }
      if (subCategoryRef?.current !== params.has("selected_subCategory")) {
        subCategoryRef.current = params.has("selected_subCategory");
        return;
      }
    }
    if (showMobileFilters && !mobileFiltersReset) {
      if (typeRef?.current !== params.get("selected_chart_type")) {
        typeRef.current = params.get("selected_chart_type");
        return;
      }
      if (subCategoryRef?.current !== params.has("selected_subCategory")) {
        subCategoryRef.current = params.has("selected_subCategory");
        return;
      }
    }
    if (!showMobileFilters) {
      if (params.get("selected_categories")) {
        payload.filters["categories"] = JSON.parse(
          params.get("selected_categories")
        );
      } else {
        payload.filters["selected_categories"] = defaultSelectedCategories?.map(
          (item) => item.categoryId
        );
      }
      if (params.get("selected_sentiment")) {
        payload.filters["sentiment"] = JSON.parse(
          params.get("selected_sentiment")
        );
      } else {
        payload.filters["sentiment"] = [];
      }
      if (params.get("selected_platforms")) {
        payload.filters["stream"] = JSON.parse(
          params.get("selected_platforms")
        );
      } else {
        payload.filters["stream"] = [];
      }
      if (params.get("since") && params.get("until")) {
        payload.filters["date"] = [params.get("since"), params.get("until")];
      } else {
        payload.filters["date"] = [];
      }
      fetchData(payload);
    }
  }, [history, window.location.search]);
  const handleMobileFiltersApply = () => {
    let payload = {
      filters: {},
    };
    setShowMobileFilters(false);
    if (params.get("selected_categories")) {
      payload.filters["categories"] = JSON.parse(
        params.get("selected_categories")
      );
    } else {
      payload.filters["selected_categories"] = defaultSelectedCategories?.map(
        (item) => item.categoryId
      );
    }
    if (params.get("selected_sentiment")) {
      payload.filters["sentiment"] = JSON.parse(
        params.get("selected_sentiment")
      );
    } else {
      payload.filters["sentiment"] = [];
    }
    if (params.get("selected_platforms")) {
      payload.filters["stream"] = JSON.parse(params.get("selected_platforms"));
    } else {
      payload.filters["stream"] = [];
    }
    if (params.get("since") && params.get("until")) {
      payload.filters["date"] = [params.get("since"), params.get("until")];
    } else {
      payload.filters["date"] = [];
    }
    fetchData(payload);
  };
  const handleMobileFiltersReset = () => {
    let payload = {
      filters: {},
    };
    resetFilters();
    if (
      !(
        DashboardReducer.searchState?.dateRange?.length == 0 &&
        selectedDaysRange !== "allTime"
      ) ||
      selectedOptions?.length > 0 ||
      selectedPlatforms?.length > 0 ||
      selectedCategories?.length > 0
    ) {
      setShowMobileFilters(false);
      payload = {
        filters: {
          date: dateRange,
          sentiment: [],
          stream: [],
          categories: defaultSelectedCategories?.map(
            (category) => category.categoryId
          ),
        },
      };
      fetchData(payload);
      return;
    }
    return;
  };
  const saveComparisonName = async (name) => {
    const apiResponse = await apiRequest(
      "industry_analysis/rename",
      "POST",
      { name: name },
      true
    );
    if (apiResponse?.success) {
      return;
    } else {
      notification.error({
        message: `${apiResponse?.error}`,
      });
      setIsLoading(false);
    }
  };
  const handleEditComparisonName = () => {
    setIsEditMode(!isEditMode);
  };
  const handleSaveComparisonName = () => {
    setIsEditMode(false);
    if (comparisonName.length > 40) {
      setIsEditMode(true);
      notification.warning({
        message: "Comparison name too long.",
      });
    } else if (comparisonName.length === 0) {
      setIsEditMode(true);
      notification.error({
        message: "Comparison name can't be empty.",
      });
    }
    saveComparisonName(comparisonName);
  };
  const handleComparisonNameChange = (event) => {
    setComparisonName(event.target.value);
  };

  const handleSubCategoryClosed = () => {
    params.delete("selected_subCategory");
    history.push({ search: params.toString() });
    // subCategoryRef.current = false;
    setSubCategoryClicked(false);
  };
  const options = [
    {
      id: "positive",
      value: "positive",
      label: "Positive",
    },
    {
      id: "neutral",
      value: "neutral",
      label: "Neutral",
    },
    {
      id: "negative",
      value: "negative",
      label: "Negative",
    },
  ];
  const platforms = availablePlatforms?.map((platform) => ({
    id: platform.id,
    label: platform.name,
    value: platform.id,
  }));
  const threeDotsFilters = [
    {
      id: 1,
      label: "Reset Filters",
      value: "1",
    },
    // {
    //   id:2,
    //   label:'Edit Sources',
    //   value:'2'
    // },
    {
      id: 3,
      label: "Clear Comparison",
      value: "3",
    },
  ];
  const chartData = data?.chartData;
  const dashboardLabels = chartData?.map(
    (dashboardData) => dashboardData?.dashboard
  );
  const dashboardIds = chartData?.map(
    (dashboardData) => dashboardData?.dashboardId
  );
  const categoryLabels = categories?.map((category) => category.category);
  const categoryIds = categories?.map((category) => category.categoryId);
  const categoriesForDropdown = categoryIds?.map((categoryId, index) => ({
    id: categoryId,
    label: categoryLabels[index],
    value: categoryId,
  }));
  //Data for overall average.
  const averageData = data?.averageData;

  const categoryAverage =
    averageData?.length > 0 ? averageData[0]?.categoryAverage : undefined;
  const categoryNames = categoryAverage?.map((category) => category.category);
  const categoryIdsForAvg = categoryAverage?.map(
    (category) => category.categoryId
  );
  const categoryPercentages = categoryAverage?.map(
    (category) => category.percentage
  );
  const bgColors = [
    "#4400AA",
    "#FF5280",
    "#9E1684",
    "#D2B5F2",
    "#4582C4",
    "#5C2A70",
    "#D41C1C",
    "#56DEC8",
    "#87050D",
    "#836DDE",
    "#FF056D",
    "#BD02FF",
    "#6E6980",
    "#671D9E",
    "#DE7070",
    "#FFBFFF",
    "#0027FF",
    "#CCCCCC",
    "#46ABBF",
    "#FF0C32",
    "#D6336B",
    "#0C3F54",
    "#C9A479",
    "#9BC3DE",
    "#140A80",
    "#E7EDEF",
  ];
  const categoryIdColorMap = {};
  if (categoryIdsForAvg?.length > 0) {
    categoryIdsForAvg.flat().forEach((id, index) => {
      categoryIdColorMap[id] = bgColors[index % bgColors.length];
    });
  }
  const categoriesData = categoryIdsForAvg?.map((id, index) => ({
    id: id,
    name: categoryNames?.length > 0 ? categoryNames[index] : "",
    percentage:
      categoryPercentages?.length > 0 ? categoryPercentages[index] : 0,
    color: categoryIdColorMap[id],
  }));
  const selectedCategoryId = JSON.parse(params.get("selected_subCategory"))?.id;
  const subCategoryAverage = categoryAverage?.find(
    (item) => item.categoryId === selectedCategoryId
  )?.subCategoryAverage;
  const subCategoryNames = subCategoryAverage?.map(
    (subCategory) => subCategory.subCategory
  );
  const subCategoryIdsForAvg = subCategoryAverage?.map(
    (subCategory) => subCategory.subCategoryId
  );
  const subCategoryPercentages = subCategoryAverage?.map(
    (subCategory) => subCategory.percentage
  );
  const subCategoryIdColorMap = {};
  if (subCategoryIdsForAvg?.length > 0) {
    subCategoryIdsForAvg.flat().forEach((id, index) => {
      subCategoryIdColorMap[id] = bgColors[index % bgColors.length];
    });
  }
  const subCategoriesData = subCategoryIdsForAvg?.map((id, index) => ({
    id: id,
    name: subCategoryNames?.length > 0 ? subCategoryNames[index] : "",
    percentage:
      subCategoryPercentages?.length > 0 ? subCategoryPercentages[index] : 0,
    color: subCategoryIdColorMap[id],
  }));
  const dashboardAverage =
    averageData?.length > 0 ? averageData[1]?.dashboardAverage : undefined;
  const dashboardNames = dashboardAverage?.map(
    (dashboard) => dashboard.dashboard
  );
  const dashboardIdsForAvg = dashboardAverage?.map(
    (dashboard) => dashboard.dashboardId
  );
  const dashboardPercentages = dashboardAverage?.map(
    (dashboard) => dashboard.percentage
  );
  const dashboardIdColorMap = {};
  if (dashboardIdsForAvg?.length > 0) {
    dashboardIdsForAvg.flat().forEach((id, index) => {
      const presetColor = dashboardAverage?.filter(
        (item) => item.dashboardId === id
      );
      dashboardIdColorMap[id] = presetColor
        ? presetColor[0].dashboardColor
        : bgColors[index % bgColors.length];
    });
  }
  const dashboardsData = dashboardIdsForAvg?.map((id, index) => ({
    id: id,
    name: dashboardNames?.length > 0 ? dashboardNames[index] : "",
    percentage:
      dashboardPercentages?.length > 0 ? dashboardPercentages[index] : 0,
    color: dashboardIdColorMap[id],
  }));
  const chartTypes = [
    {
      id: 1,
      label: "By Groups",
      value: "C1",
    },
    {
      id: 2,
      label: "By Categories",
      value: "C2",
    },
    {
      id: 3,
      label: "By Time",
      value: "C3",
    },
  ];
  const getFacebookReplies = async (resReviews = []) => {
    const url = "dashboards/get_facebook_replies";
    const payload = {
      comment_ids: allReviews.map(({ DocId }) => {
        return DocId;
      }),
      firebase_id: DashboardReducer.dashboard.UserId,
    };
    const result = await apiRequest(url, "POST", payload, true);
    if (result.success) {
      setFacebookReplies(result.success);
    } else {
      notification.error({
        message: result.error,
      });
    }
  };
  const handleSearchWord = (tag) => {
    const selectedTags = DashboardReducer.searchState.chosenKeywords;
    const nextSelectedTags = tag ? [...selectedTags, tag] : selectedTags;
    setSearchWord("");
    setStartIndex(0);
    setNumOfDocs(10);
    dispatch({
      type: actions.UPDATE_SEARCH_STATE,
      payload: {
        chosenKeywords: nextSelectedTags,
      },
    });
  };
  const getGroupReviews = async (updateIndex, reset) => {
    setReviewsLoading(true);
    let triggerFacebookReplies = false;
    const platformId = DashboardReducer?.dashboard?.PlatformId;
    const isFacebookPlatform = platformId === 9 || platformId === 10;
    const payload = {
      group_ids: selectedGroupForReviews.id,
      topic_id: selectedGroupForReviews.topicId,
      filters: {
        date: [params.get("since"), params.get("until")],
        sentiment: JSON.parse(params.get("selected_sentiment")) || [],
        stream: JSON.parse(params.get("selected_platforms")) || [],
      },
    };
    if (
      DashboardReducer.searchState.chosenKeywords &&
      DashboardReducer.searchState.chosenKeywords.length > 0
    ) {
      payload.keyword = DashboardReducer.searchState.chosenKeywords;
    }
    if (selectedGroupForReviews.othersParent) {
      payload.others_parent = selectedGroupForReviews.othersParent;
    }
    payload.page_size = numOfDocs;
    payload.start_index = reset
      ? 0
      : updateIndex
      ? updateIndex > 1
        ? updateIndex
        : startIndex + updateIndex
      : 0;
    const url = "industry_analysis/group/get_reviews";
    const res = await apiRequest(url, "POST", payload, true);
    if (res && res.success) {
      setAllReviews(res.success.docs);
      setNumReviews(res.success.total_num_docs);
      setStartIndex(
        reset
          ? 0
          : updateIndex
          ? updateIndex > 1
            ? updateIndex
            : startIndex + updateIndex
          : startIndex
      );
      triggerFacebookReplies =
        isFacebookPlatform && ((res || {}).docs || []).length > 0;
      if (triggerFacebookReplies) {
        getFacebookReplies(res.docs);
      }
      setReviewsLoading(false);
    } else {
      notification.error({
        message: res?.error,
      });
      setReviewsLoading(false);
    }
  };
  function rgbToHex(rgb) {
    if (rgb.includes("#")) {
      return rgb;
    } else {
      const values = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      const hex = (x) => {
        const hexValue = parseInt(x)
          ?.toString(16)
          ?.toUpperCase();
        return hexValue.length === 1 ? "0" + hexValue : hexValue;
      };

      return "#" + hex(values[1]) + hex(values[2]) + hex(values[3]);
    }
  }
  useEffect(() => {
    if (!selectedGroupForReviews) {
      setStartIndex(0);
      setNumOfDocs(10);
    }
  }, [selectedGroupForReviews]);
  useEffect(() => {
    if (selectedGroupForReviews && selectedGroupForReviews.id) {
      if (stopInitSearchRef.current) {
        stopInitSearchRef.current = false;
        return;
      }
      getGroupReviews();
    } else if (!stopInitSearchRef.current) {
      stopInitSearchRef.current = true;
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          chosenKeywords: [],
        },
      });
    }
  }, [selectedGroupForReviews, DashboardReducer.searchState.chosenKeywords]);
  return (
    <div className={styles.AnalysisBoard}>
      <div className={styles.filterBox}>
        <div className={styles.filtersFlex}>
          {showDateFilterPopup && (
            <div ref={dateFilterRef} className={styles.dateFilter}>
              <DateFilterPopUp
                onChange={(dateString, daysRange) => {
                  dispatch({
                    type: actions.UPDATE_SEARCH_STATE,
                    payload: {
                      dateRange: dateString,
                      selectedDaysRange: daysRange,
                      chosenDate: "",
                    },
                  });
                  if (dateString?.length > 0) {
                    updateQParams("since", dateString[0]);
                    updateQParams("until", dateString[1]);
                  } else {
                    params.delete("since");
                    params.delete("until");
                    history.push({ search: params.toString() });
                  }
                  updateQParams("daysRange", daysRange);
                  setShowDateFilterPopup(false);
                  setSelectedDaysRange(daysRange);
                  setDateRange(dateString);
                  setShowDateFilterPopup(false);
                }}
                close={() => {
                  setShowDateFilterPopup(false);
                }}
                selectedDaysRange={params.get("daysRange")}
                defaultStartDate={
                  params.get("since") && params.get("until")
                    ? params.get("since")
                    : new Date()
                }
                defaultEndDate={
                  params.get("since") && params.get("until")
                    ? params.get("until")
                    : new Date()
                }
                searchPeriod={
                  params.get("since") && params.get("until")
                    ? [params.get("since"), params.get("until")]
                    : [new Date(), new Date()]
                }
              />
            </div>
          )}
          <div
            className={styles.filters}
            onClick={() => {
              setShowDateFilterPopup(!showDateFilterPopup);
            }}
          >
            <p className={styles.filterPlaceholder}>Date Range</p>
            <Button
              className={styles.DateFilterButton}
              onClick={() => setShowDateFilterPopup(!showDateFilterPopup)}
            >
              <CalendarFilled />
              {params.get("daysRange") === "custom"
                ? `${dayjs(params.get("since")).format(
                    "DD MMM, YYYY"
                  )} - ${dayjs(params.get("until")).format("DD MMM, YYYY")}`
                : rangePresets[`${params.get("daysRange")}`].label}
            </Button>
          </div>
          <MultiSelectDropdown
            isLoading={isLoading}
            reset={showMobileFilters ? mobileFiltersReset : resetFiltersClicked}
            placeholder="Sentiment"
            defaultSelectedOptions={
              JSON.parse(params.get("selected_sentiment"))?.length > 0
                ? JSON.parse(params.get("selected_sentiment"))
                : []
            }
            options={options}
            setSelectedOptions={(values) => {
              if (values?.length > 0) {
                updateQParams("selected_sentiment", JSON.stringify(values));
              } else {
                params.delete("selected_sentiment");
                history.push({ search: params.toString() });
              }
              setSelectedOptions(values);
            }}
          />
          <MultiSelectDropdown
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            reset={showMobileFilters ? mobileFiltersReset : resetFiltersClicked}
            defaultSelectedOptions={
              JSON.parse(params.get("selected_platforms"))?.length > 0
                ? JSON.parse(params.get("selected_platforms"))
                : []
            }
            placeholder="Data Stream"
            options={platforms}
            setSelectedOptions={(values) => {
              if (values?.length > 0) {
                updateQParams("selected_platforms", JSON.stringify(values));
              } else {
                params.delete("selected_platforms");
                history.push({ search: params.toString() });
              }
              setSelectedPlatforms(values);
            }}
          />
          <MultiSelectDropdown
            isLoading={isLoading}
            selectionLimit={5}
            defaultSelectedOptions={
              JSON.parse(params.get("selected_categories"))?.length > 0
                ? JSON.parse(params.get("selected_categories"))
                : defaultSelectedCategories?.map((item) => item.categoryId)
            }
            setIsLoading={setIsLoading}
            reset={showMobileFilters ? mobileFiltersReset : resetFiltersClicked}
            placeholder="Categories"
            options={categoriesForDropdown}
            setSelectedOptions={(values) => {
              if (values?.length > 0) {
                updateQParams("selected_categories", JSON.stringify(values));
              } else {
                params.delete("selected_categories");
                history.push({ search: params.toString() });
              }
              setSelectedCategories(values);
            }}
          />
          <DropdownSelect
            reset={showMobileFilters ? mobileFiltersReset : resetFiltersClicked}
            placeholder="Group Type"
            defaultValue={chartTypes[0]}
            selectedOptions={
              params.get("selected_chart_type")
                ? params.get("selected_chart_type")
                : selectedChartType
            }
            options={chartTypes}
            setSelectedOptions={(value) => {
              updateQParams("selected_chart_type", value);
              setSelectedChartType(value);
            }}
          />
          {params.has("selected_subCategory") && (
            <div
              className={styles.subcategoryCard}
              onClick={handleSubCategoryClosed}
              style={{ cursor: "pointer" }}
              title={JSON.parse(params.get("selected_subCategory"))?.label}
            >
              <span className={styles.subCatName}>
                {JSON.parse(params.get("selected_subCategory"))?.label}{" "}
              </span>
              <CloseOutlined />
            </div>
          )}
        </div>
        <div className={styles.mobileFilterWithSubCat}>
          <div
            className={styles.mobileFilter}
            onClick={() => setShowMobileFilters(true)}
          >
            <FilterOutlined />
          </div>
          {params.has("selected_subCategory") && (
            <div
              className={styles.subcategoryCard}
              onClick={handleSubCategoryClosed}
            >
              <span className={styles.subCatName}>
                {JSON.parse(params.get("selected_subCategory"))?.label}{" "}
              </span>
              <CloseOutlined />
            </div>
          )}
        </div>
        {showMobileFilters && (
          <Modal
            showModal={showMobileFilters}
            onClose={() => setShowMobileFilters(false)}
            showSaveButton={false}
            showCancelButton={false}
            hideCloseIcon={true}
            titleAsset={""}
            className={styles.mobileFilterPopup}
            widthofmodal="20rem"
            title={"Select Filters"}
          >
            <div className={styles.mobileFilterFlex}>
              {showDateFilterPopup && (
                <div ref={dateFilterRef} className={styles.dateFilter}>
                  <DateFilterPopUp
                    onChange={(dateString, daysRange) => {
                      dispatch({
                        type: actions.UPDATE_SEARCH_STATE,
                        payload: {
                          dateRange: dateString,
                          selectedDaysRange: daysRange,
                          chosenDate: "",
                        },
                      });
                      if (dateString?.length > 0) {
                        updateQParams("since", dateString[0]);
                        updateQParams("until", dateString[1]);
                      } else {
                        params.delete("since");
                        params.delete("until");
                        history.push({ search: params.toString() });
                      }
                      updateQParams("daysRange", daysRange);
                      setShowDateFilterPopup(false);
                      setSelectedDaysRange(daysRange);
                      setDateRange(dateString);
                      setShowDateFilterPopup(false);
                    }}
                    close={() => {
                      setShowDateFilterPopup(false);
                    }}
                    selectedDaysRange={params.get("daysRange")}
                    defaultStartDate={
                      params.get("since") && params.get("until")
                        ? params.get("since")
                        : new Date()
                    }
                    defaultEndDate={
                      params.get("since") && params.get("until")
                        ? params.get("until")
                        : new Date()
                    }
                    searchPeriod={
                      params.get("since") && params.get("until")
                        ? [params.get("since"), params.get("until")]
                        : [new Date(), new Date()]
                    }
                  />
                </div>
              )}
              <div className={styles.mobileFilters}>
                <p
                  className={styles.mobileFiltersReset}
                  onClick={() => {
                    setShowMobileFilters(false);
                    setMobileFiltersReset(true);
                    resetFilters();
                  }}
                >
                  Reset filters
                </p>
                <div className={styles.dropdowns}>
                  <div
                    className={styles.filters}
                    onClick={() => {
                      setShowDateFilterPopup(!showDateFilterPopup);
                    }}
                  >
                    <p className={styles.filterPlaceholder}>Date Range</p>
                    <Button
                      className={styles.DateFilterButton}
                      onClick={() =>
                        setShowDateFilterPopup(!showDateFilterPopup)
                      }
                    >
                      <CalendarFilled />
                      {params.get("daysRange") === "custom"
                        ? `${dayjs(params.get("since")).format(
                            "DD MMM, YYYY"
                          )} - ${dayjs(params.get("until")).format(
                            "DD MMM, YYYY"
                          )}`
                        : rangePresets[`${params.get("daysRange")}`].label}
                    </Button>
                  </div>
                  <MultiSelectDropdown
                    isLoading={isLoading}
                    reset={
                      showMobileFilters
                        ? mobileFiltersReset
                        : resetFiltersClicked
                    }
                    placeholder="Sentiment"
                    defaultSelectedOptions={
                      JSON.parse(params.get("selected_sentiment"))?.length > 0
                        ? JSON.parse(params.get("selected_sentiment"))
                        : []
                    }
                    options={options}
                    setSelectedOptions={(values) => {
                      if (values?.length > 0) {
                        updateQParams(
                          "selected_sentiment",
                          JSON.stringify(values)
                        );
                      } else {
                        params.delete("selected_sentiment");
                        history.push({ search: params.toString() });
                      }
                      setSelectedOptions(values);
                    }}
                  />
                  <MultiSelectDropdown
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    reset={
                      showMobileFilters
                        ? mobileFiltersReset
                        : resetFiltersClicked
                    }
                    defaultSelectedOptions={
                      JSON.parse(params.get("selected_platforms"))?.length > 0
                        ? JSON.parse(params.get("selected_platforms"))
                        : []
                    }
                    placeholder="Data Stream"
                    options={platforms}
                    setSelectedOptions={(values) => {
                      if (values?.length > 0) {
                        updateQParams(
                          "selected_platforms",
                          JSON.stringify(values)
                        );
                      } else {
                        params.delete("selected_platforms");
                        history.push({ search: params.toString() });
                      }
                      setSelectedPlatforms(values);
                    }}
                  />
                  <MultiSelectDropdown
                    isLoading={isLoading}
                    selectionLimit={5}
                    defaultSelectedOptions={
                      JSON.parse(params.get("selected_categories"))?.length > 0
                        ? JSON.parse(params.get("selected_categories"))
                        : defaultSelectedCategories?.map(
                            (item) => item.categoryId
                          )
                    }
                    setIsLoading={setIsLoading}
                    reset={
                      showMobileFilters
                        ? mobileFiltersReset
                        : resetFiltersClicked
                    }
                    placeholder="Categories"
                    options={categoriesForDropdown}
                    setSelectedOptions={(values) => {
                      if (values?.length > 0) {
                        updateQParams(
                          "selected_categories",
                          JSON.stringify(values)
                        );
                      } else {
                        params.delete("selected_categories");
                        history.push({ search: params.toString() });
                      }
                      setSelectedCategories(values);
                    }}
                  />
                  <DropdownSelect
                    reset={
                      showMobileFilters
                        ? mobileFiltersReset
                        : resetFiltersClicked
                    }
                    placeholder="Group Type"
                    defaultValue={chartTypes[0]}
                    selectedOptions={
                      params.get("selected_chart_type")
                        ? params.get("selected_chart_type")
                        : selectedChartType
                    }
                    options={chartTypes}
                    setSelectedOptions={(value) => {
                      updateQParams("selected_chart_type", value);
                      setSelectedChartType(value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <Button
                className={styles.footerBtn}
                onClick={() => setShowMobileFilters(false)}
              >
                Cancel
              </Button>
              <Button
                className={styles.footerBtn}
                type="primary"
                onClick={handleMobileFiltersApply}
              >
                Apply
              </Button>
            </div>
          </Modal>
        )}
        <div className={styles.rightFilters}>
          <ThreeDotsMenu
            options={threeDotsFilters}
            setSelectedFilter={setSelectedFilter}
          />
        </div>
      </div>
      <div className={styles.ComparisonChart}>
        {!params.has("selected_subCategory") ? (
          <div className={styles.ChartView}>
            <div className={styles.ChartHeader}>
              <div className={styles.NameAndIcon}>
                {!isEditMode ? (
                  <div className={styles.Header}>
                    <h5 className={styles.comparisonName}>
                      {comparisonName}{" "}
                      <EditOutlined
                        className={styles.EditIcon}
                        onClick={handleEditComparisonName}
                      />
                    </h5>
                    <div className={styles.comparisonType}>Categories</div>
                  </div>
                ) : (
                  <div className={styles.NameInput}>
                    <Input
                      type="text"
                      value={comparisonName}
                      onChange={handleComparisonNameChange}
                      className={styles.ComparisonNameInput}
                    />
                    <CheckOutlined
                      className={styles.CheckIcon}
                      onClick={handleSaveComparisonName}
                    />
                  </div>
                )}
              </div>
            </div>
            {chartData && (
              <ComparisonChart
                selectedCategories={
                  selectedCategories
                    ? selectedCategories
                    : categories?.map((category) => category.categoryId)
                }
                chartData={chartData}
                chartType={
                  params.get("selected_chart_type") && showMobileFilters
                    ? ""
                    : params.get("selected_chart_type")
                    ? params.get("selected_chart_type")
                    : "C2"
                }
                setSubCategoryClicked={(value) => setSubCategoryClicked(value)}
                setSelectedCategoryForSubcategories={(value) => {
                  updateQParams("selected_subCategory", JSON.stringify(value));
                  setSelectedCategoryForSubcategories(value);
                }}
                barColorMap={categoryIdColorMap}
                doughnutColorMap={dashboardIdColorMap}
                dateRange={
                  params.get("since") && params.get("until")
                    ? [params.get("since"), params.get("until")]
                    : []
                }
                setSelectedGroupForReviews={setSelectedGroupForReviews}
              />
            )}
          </div>
        ) : (
          <div>
            <div className={styles.ChartHeader}>
              <div className={styles.NameAndIcon}>
                <div className={styles.Header}>
                  <h5
                    className={styles.comparisonName}
                    onClick={handleSubCategoryClosed}
                  >
                    {comparisonName}
                    <RightOutlined className={styles.rightIcon} />
                    {JSON.parse(params.get("selected_subCategory"))?.label}
                  </h5>
                  <div className={styles.comparisonType}>Sub categories</div>
                </div>
              </div>
            </div>
            {chartData && (
              <SubCategoryChart
                selectedCategoryForSubcategories={JSON.parse(
                  params.get("selected_subCategory")
                )}
                chartData={chartData}
                chartType={selectedChartType ? selectedChartType : "C1"}
                barColorMap={subCategoryIdColorMap}
                doughnutColorMap={dashboardIdColorMap}
                dateRange={
                  params.get("since") && params.get("until")
                    ? [params.get("since"), params.get("until")]
                    : []
                }
                setSelectedGroupForReviews={setSelectedGroupForReviews}
              />
            )}
          </div>
        )}
        {selectedGroupForReviews && selectedGroupForReviews.id && (
          <Drawer
            title={
              <span className={styles.drawerTitleWrapper}>
                <span className={styles.groupTitleWrapper}>
                  <span
                    className={styles.groupTitle}
                    title={
                      selectedGroupForReviews.clickIsFrom === "groups"
                        ? selectedGroupForReviews.label
                        : selectedGroupForReviews.topicLabel
                    }
                  >
                    {selectedGroupForReviews.clickIsFrom === "groups" ? (
                      selectedGroupForReviews.label
                    ) : (
                      <span>{selectedGroupForReviews.topicLabel}</span>
                    )}
                  </span>
                </span>
                <span className={styles.extraFilters}>
                  {selectedGroupForReviews.clickIsFrom === "groups" ? (
                    <span
                      className={styles.topicLabel}
                      title={selectedGroupForReviews.topicLabel}
                      style={{
                        backgroundColor: rgbToHex("rgb(116, 45, 224)"),
                        color: "white",
                      }}
                    >
                      {selectedGroupForReviews.topicLabel}
                    </span>
                  ) : (
                    <MultiSelect
                      options={dashboardIds.map((id, index) => ({
                        id: id?.toString(),
                        label: dashboardLabels[index],
                        value: id?.toString(),
                      }))}
                      enableSelectAll={false}
                      defaultSelectedOptions={selectedGroupForReviews.id.map(
                        (id, index) => ({
                          id: id?.toString(),
                          label: selectedGroupForReviews.label[index],
                          value: id?.toString(),
                        })
                      )}
                      allowClear={false}
                      tagCount={"responsive"}
                      isLoading={reviewsLoading}
                      showSearch={true}
                      minSelection={1}
                      setSelectedOptions={(values) => {
                        setStartIndex(0);
                        setNumOfDocs(10);
                        setSelectedGroupForReviews((prev) => {
                          const updated = { ...prev };
                          updated.id = values.map((v) => v.id);
                          updated.label = values.map((v) => v.label);
                          return updated;
                        });
                      }}
                    />
                  )}
                </span>
              </span>
            }
            placement="right"
            closable={window.innerWidth < 500 ? true : false}
            closeIcon={
              <CloseOutlined
                style={{ color: theme === "dark" ? "white" : "black" }}
              />
            }
            maskClosable={!reviewsLoading}
            onClose={() => {
              setSelectedGroupForReviews(null);
              setAllReviews([]);
              setSearchWord("");
            }}
            width={
              window.innerWidth > 1300
                ? 650
                : window.innerWidth > 500 && window.innerWidth < 1300
                ? 450
                : 350
            }
            open={selectedGroupForReviews && selectedGroupForReviews.id}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Spin spinning={reviewsLoading}>
                <ReviewsList
                  reviewsLoading={reviewsLoading}
                  allReviews={allReviews}
                  numReviews={numReviews}
                  startIndex={startIndex}
                  setStartIndex={setStartIndex}
                  getAllReviews={getGroupReviews}
                  setNumOfDocs={setNumOfDocs}
                  numOfDocs={numOfDocs}
                  handleTagSelection={handleSearchWord}
                  user={user}
                  setReviewsLoading={setReviewsLoading}
                  shared={false}
                  facebookReplies={facebookReplies}
                  getFacebookReplies={getFacebookReplies}
                  resetReviews={resetReviews}
                  setResetReviews={setResetReviews}
                  searchWord={searchWord}
                  setSearchWord={setSearchWord}
                  handleSearchWord={handleSearchWord}
                  colorMap={dashboardIdColorMap}
                />
              </Spin>
            </div>
          </Drawer>
        )}
      </div>
      {!params.has("selected_subCategory") && selectedChartType === "C1" && (
        <div className={styles.OverallAverage}>
          <OverallAverage data={categoriesData} />
        </div>
      )}
      {!params.has("selected_subCategory") && selectedChartType === "C2" && (
        <div className={styles.OverallAverage}>
          <OverallAverage data={dashboardsData} />
        </div>
      )}
      {!params.has("selected_subCategory") && selectedChartType === "C3" && (
        <div className={styles.OverallAverage}>
          <OverallAverage data={dashboardsData} />
        </div>
      )}
      {params.has("selected_subCategory") && (
        <div className={styles.OverallAverage}>
          <OverallAverage data={subCategoriesData} />
        </div>
      )}
    </div>
  );
};

export default AnalysisBoard;
