import React from 'react';
import DropZoneWrapper from './dropZoneWrapper';
import styles from "./style.module.scss";

const Representation = ({
  handleFileChange,
  progress,
  spinning,
  fileName,
  setColumnNames,
}) => (
    <div className={styles.wrapper}>
      <DropZoneWrapper
        spinning={spinning}
        progress={progress}
        handleFileChange={handleFileChange}
        setColumnNames={setColumnNames}
      />
      {fileName &&
        <span className={styles.fileNamepan}>
          <i className="fa fa-check-circle" /> {fileName}
        </span>
      }
    </div>
  );

export default Representation;