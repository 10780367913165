import React, { useState, useEffect, useCallback } from "react";
import styles from "./style.module.scss";
import {
  Select,
  notification,
  Spin,
  Checkbox,
  Input,
  Menu,
  Dropdown,
  Button,
} from "antd";
import { useHistory } from "react-router-dom";
import { UNLOGGED } from "util/constants";
import SortPopup from "helperComponents/SortPopup";
import { apiRequest } from "util/services";
import moment from "moment";
import {
  FilePdfFilled,
  LoadingOutlined,
  DeleteFilled,
  ReloadOutlined,
} from "@ant-design/icons";
import { PlatformStatus } from "helperComponents/Status";
import { Modal } from "components/General/ModalFormComponents";
import RenameDeletePopup from "helperComponents/RenameDeletePopup";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const ReportsDownloads = ({ theme, user, authenticated }) => {
  const [switchTab, setSwitchTab] = useState("downloads");
  const [showSortPopup, setShowSortPopup] = useState(false);
  const [showRowItems, setShowRowItems] = useState(true);
  const [status, setStatus] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("newest");
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [reports, setReports] = useState([]);
  const [tempReports, setTempReports] = useState([]);
  const [downloads, setDownloads] = useState([]);
  const [tempDownloads, setTempDownloads] = useState([]);
  const [reportLoading, setReportLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [chooseInformationChecked, setChooseInformationChecked] = useState(
    true
  );
  const [aiChecked, setAIChecked] = useState(true);
  const [fixedChecked, setFixedChecked] = useState(false);
  const [customChecked, setCustomChecked] = useState(false);
  const [insightsChecked, setInsightsChecked] = useState(true);
  const [reviewsSwitch, setReviewsSwitch] = useState(false);
  const [dashboards, setDashboards] = useState([]);
  const [name, setName] = useState("");
  const [selectedDashboard, setSelectedDashboard] = useState();
  const [subscriptionStatus, setSubscriptionStatus] = useState();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [showRenameReport, setShowRenameReport] = useState(false);
  const [showDeleteReport, setShowDeleteReport] = useState(false);
  const [showDeleteDownload, setShowDeleteDownload] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [renameReportValue, setRenameReportValue] = useState("");
  const [reportID, setReportID] = useState(null);

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    if (authenticated === UNLOGGED) {
      history.push("/");
      return;
    }
  }, []);
  useEffect(() => {
    getReportsDownloads();
  }, [sortValue, switchTab]);
  const getReportsDownloads = async (searchValue) => {
    if (switchTab === "reports") {
      setReportLoading(true);
      const payload = {
        user_id: user.uid,
        sort: sortValue,
        keywords: searchValue,
      };
      const result = await apiRequest(
        "report/reports/list",
        "POST",
        payload,
        true
      );
      if (!result || result.error) {
        notification.error({
          message: result?.error,
        });
      } else {
        setReports(result);
        setTempReports(result);
      }
      setReportLoading(false);
    }
    if (switchTab === "downloads") {
      setDownloadLoading(true);
      const payload = {
        user_id: user.uid,
        sort: sortValue,
        keywords: searchValue,
      };
      const result = await apiRequest(
        "report/downloads/list",
        "POST",
        payload,
        true
      );
      if (!result.error) {
        setDownloads(result);
        setTempDownloads(result);
      } else {
        notification.error({
          message: result.error,
        });
      }
      setDownloadLoading(false);
    }
  };
  useEffect(() => {
    if (switchTab === "reports") {
      if (status === "all") {
        setTempReports(reports);
      } else {
        const filterReports = reports.filter(
          (item) => item.Status.toUpperCase() === status.toUpperCase()
        );
        setTempReports(filterReports);
      }
    }
    if (switchTab === "downloads") {
      if (status === "all") {
        setTempDownloads(downloads);
      } else {
        const filterDownloads = downloads.filter((item) => {
          return item.Status.toUpperCase() === status.toUpperCase();
        });
        setTempDownloads(filterDownloads);
      }
    }
  }, [status]);

  useEffect(() => {
    setSortValue("newest");
    setStatus("All");
    setSearchValue("");
    setShowSearchBox(false);
  }, [switchTab]);

  useEffect(() => {
    const payload = {
      start_index: 0,
      num_dashboards: 8,
      report: true,
    };
    if (showModal) {
      apiRequest("dashboards/all", "POST", payload, true).then((result) => {
        if (!result.error) {
          setDashboards(result?.dashboards);
        } else {
          notification.error({
            message: result.error,
          });
        }
      });
    }
  }, [showModal]);

  const handleClick = async () => {
    const payload = {
      dashboard_id: parseInt(selectedDashboard),
      user_id: user.uid,
      include_reviews: reviewsSwitch,
      ai_topics_bool: chooseInformationChecked && aiChecked,
      fixed_topics_bool: chooseInformationChecked && fixedChecked,
      custom_topics_bool: chooseInformationChecked && customChecked,
      insights_bool: chooseInformationChecked && insightsChecked,
      report_name: name,
    };
    if (!selectedDashboard) {
      notification.error({
        message: t("notifications.37"),
      });
    } else if (!name) {
      notification.error({
        message: t("notifications.38"),
      });
    } else {
      setReportLoading(true);
      const promise = apiRequest("report/create", "POST", payload, true);
      const timeout = new Promise((resolve) => setTimeout(resolve, 10000));
      const result = await Promise.race([promise, timeout]);
      if (result?.validation_error || result?.error) {
        notification.error({
          message: result?.error ? result.error : "Unable to create report",
        });
        setReportLoading(false);
      } else {
        notification.success({
          message: t("reports_downloads.25"),
        });
        setReportLoading(false);
      }
      getReportsDownloads();
      setShowModal(false);
    }
  };

  const handleReportDownload = (link) => {
    if (link) {
      window.open(link, "_blank");
    } else {
      notification.error({
        message: t("notifications.36"),
      });
    }
  };

  const menu = (id, reportName, pdfLink, status) => (
    <Menu
      style={{ width: "120px" }}
      theme="dark"
      onClick={() => setReportID(id)}
    >
      <Menu.Item
        key={1}
        onClick={() => {
          setShowRenameReport(true);
          setRenameReportValue(reportName);
        }}
      >
        {t("button.1")}
      </Menu.Item>
      <Menu.Item key={3} onClick={() => handleReportDownload(pdfLink)}>
        {t("reports_downloads.12")}
      </Menu.Item>
      {status !== "Progress" && (
        <Menu.Item key={2} onClick={() => setShowDeleteReport(true)}>
          {t("button.2")}
        </Menu.Item>
      )}
    </Menu>
  );

  const handleShowSearch = () => {
    if (window.innerWidth < 880) {
      setShowRowItems(false);
    }
    setShowSearchBox(!showSearchBox);
  };

  const ReportItem = ({
    title,
    status,
    dashboardName,
    createdAt,
    reportID,
    dashboardId,
    csvLink,
    pdfLink,
  }) => (
    <div className={styles.tableRow}>
      <p className={styles.nameRow}>{title}</p>
      <p className={styles.nameRow}>{dashboardName}</p>
      <div className={styles.statusContainer}>
        <PlatformStatus status={status} />
      </div>
      <p className={styles.createdAtRow}>
        {moment(createdAt).format("DD MMM YYYY")}
      </p>
      {/* <div className={styles.downloadRow}>
            {pdfLink && (status === "Ready" || status === "InProgress") &&
               <FilePdfFilled style={{ fontSize: "28px" }} onClick={() => window.open(pdfLink, '_blank')} />}
            {csvLink && (status === "Ready" || status === "InProgress") &&
               <a href={csvLink} target="_blank" rel="noopener noreferrer"><img width="30" src="/assets/csvFile.png" alt="" /></a>}
         </div> */}
      <div className={styles.moreRow}>
        {/* {deleteInProgress === dashboardId
               ? <LoadingOutlined spin style={{ fontSize: "28px" }} />
               : <DeleteFilled style={{ fontSize: "28px" }} onClick={() => deleteReport(dashboardId)} />} */}
        <Dropdown overlay={() => menu(reportID, title, pdfLink, status)}>
          <img
            src={
              theme === "dark"
                ? "/assets/more-vertical.svg"
                : "/assets/more-vertical-black.svg"
            }
            alt=""
          />
        </Dropdown>
      </div>
    </div>
  );

  const DownloadItem = ({ exportName, status, exportDate, csvLink, id }) => (
    <div className={`${styles.tableRow} ${styles.downloadTableRow}`}>
      <p className={styles.exportRow}>{exportName}</p>
      <div className={styles.statusRow}>
        <PlatformStatus status={status} />
      </div>
      <p className={styles.exportDateRow}>
        {moment(exportDate).format("DD MMM YYYY hh:mm A")}
      </p>
      <div className={styles.downloadRow}>
        {csvLink && (status === "Ready" || status === "InProgress") ? (
          <a href={csvLink} target="_blank" rel="noopener noreferrer">
            <img
              src={
                theme === "dark"
                  ? "/assets/download.svg"
                  : "/assets/download_black.svg"
              }
              alt=""
            />
          </a>
        ) : (
          <img
            src={
              theme === "dark" ? "/assets/trash.svg" : "/assets/trash_black.svg"
            }
            alt=""
            onClick={() => {
              setReportID(id);
              setShowDeleteDownload(true);
            }}
          />
        )}
      </div>
    </div>
  );

  const CustomDropDown = ({
    onChange,
    selectedOption,
    options,
    placeholder,
  }) => (
    <Select
      className={styles.dropdown}
      placeholder={placeholder}
      onChange={onChange}
      value={selectedOption}
      showSearch
      showArrow
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      <Select.Option style={{ display: "none" }}>{placeholder}</Select.Option>
      {options?.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </Select>
  );

  const handleReportDelete = async () => {
    setDisableButton(true);
    const payload = {
      report_id: reportID,
    };

    const response = await apiRequest("report/delete", "POST", payload, true);

    if (response.Success) {
      notification.success({
        message: t("notifications.39"),
      });
      setDisableButton(false);
      setShowDeleteReport(false);
      getReportsDownloads();
    } else {
      notification.error({
        message: response.error,
      });
      setShowDeleteReport(false);
      setDisableButton(false);
    }
  };

  const handleDownloadDelete = async () => {
    setDisableButton(true);
    const payload = {
      report_id: reportID,
    };

    const response = await apiRequest("report/delete", "POST", payload, true);

    if (response.Success) {
      notification.success({
        message: t("notifications.40"),
      });
      setDisableButton(false);
      setShowDeleteDownload(false);
      getReportsDownloads();
    } else {
      notification.error({
        message: response.error,
      });
      setShowDeleteDownload(false);
      setDisableButton(false);
    }
  };

  const handleReportRename = async () => {
    setDisableButton(true);
    const payload = {
      report_id: reportID,
      report_name: renameReportValue,
    };

    const response = await apiRequest("report/rename", "POST", payload, true);

    if (response.Success) {
      notification.success({
        message: t("notifications.41"),
      });
      setDisableButton(false);
      setShowRenameReport(false);
      getReportsDownloads();
    } else {
      notification.error({
        message: response.error,
      });
      setShowRenameReport(false);
      setDisableButton(false);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      getReportsDownloads(searchValue);
    }
  };
  return (
    <div
      className={styles.mainWindow}
      onClick={() => {
        setShowSortPopup(false);
      }}
    >
      <div className={styles.topicsLibraryContainer}>
        <div className={styles.header}>
          <p>{t("dashboard_sidebar.5")}</p>
        </div>
        <div className={styles.tabsContainer}>
          <div className={styles.topicsWrapper}>
            <span
              onClick={() => {
                setSwitchTab("downloads");
                // history.push('/console/topicLibrary/industry_topics')
              }}
              className={switchTab === "downloads" && styles.activeTopic}
            >
              {t("reports_downloads.2")}
            </span>
            <span
              onClick={() => {
                setSwitchTab("reports");
                // history.push('/console/topicLibrary/custom_topics')
              }}
              className={switchTab === "reports" && styles.activeTopic}
            >
              {t("reports_downloads.1")}
            </span>
          </div>

          {
            <div className={styles.topicContents}>
              {showRowItems && (
                <div className={styles.firstRow}>
                  <div className={styles.dropdowns}>
                    <Select
                      style={{ minWidth: 120, width: "max-content" }}
                      className={styles.languageDropdown}
                      value={status}
                      onChange={(value) => setStatus(value)}
                    >
                      <Option value={"all"} key={"all"}>
                        All
                      </Option>
                      <Option value={"progress"} key={"progress"}>
                        {t("reports_downloads.4")}
                      </Option>
                      <Option value={"ready"} key={"ready"}>
                        {t("reports_downloads.5")}
                      </Option>
                      <Option value={"expired"} key={"expired"}>
                        {t("reports_downloads.6")}
                      </Option>
                    </Select>
                  </div>

                  <div className={styles.actionBtnBlock}>
                    <button
                      className={styles.search}
                      onClick={handleShowSearch}
                    >
                      <img
                        src={
                          theme === "dark"
                            ? "/assets/search.svg"
                            : "/assets/search_black.svg"
                        }
                        alt=""
                      />
                    </button>

                    {switchTab === "reports" && (
                      <button
                        className={styles.newReport}
                        onClick={() => setShowModal(true)}
                      >
                        {" "}
                        <i class="fa-solid fa-plus"></i>{" "}
                        <span>{t("button.10")}</span>
                      </button>
                    )}
                    <span
                      className={styles.search}
                      onClick={() => {
                        setSearchValue();
                        getReportsDownloads("");
                      }}
                    >
                      <img
                        src={
                          theme === "dark"
                            ? "/assets/refresh.svg"
                            : "/assets/refresh_black.svg"
                        }
                        width={18}
                        height={18}
                      />
                    </span>
                    {window.innerWidth > 880 && (
                      <div
                        className={`${styles.searchBox} ${showSearchBox &&
                          styles.showSearch} `}
                      >
                        <img
                          src={
                            theme === "dark"
                              ? "/assets/search.svg"
                              : "/assets/search_black.svg"
                          }
                          alt=""
                        />
                        <input
                          type="text"
                          placeholder={
                            switchTab === "reports"
                              ? t("others.24")
                              : t("others.25")
                          }
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!showRowItems && (
                <div
                  className={`${styles.searchBoxSmall} ${showSearchBox &&
                    styles.showSearch} `}
                >
                  <i
                    onClick={() => {
                      setShowRowItems(true);
                      setSearchValue("");
                    }}
                    className="fa-solid fa-arrow-left"
                  ></i>
                  <input
                    type="text"
                    placeholder={
                      switchTab === "reports" ? t("others.24") : t("others.25")
                    }
                    onKeyDown={handleKeyDown}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
              )}

              {switchTab === "reports" ? (
                <Spin spinning={reportLoading}>
                  {tempReports?.length > 0 && !reportLoading ? (
                    <div className={styles.tableContent}>
                      <div className={styles.tableHeader}>
                        <p className={styles.nameRow}>
                          {t("reports_downloads.7")}
                        </p>
                        <p className={styles.nameRow}>
                          {t("reports_downloads.8")}
                        </p>
                        <p className={styles.statusContainer}>
                          {t("reports_downloads.10")}
                        </p>
                        <p className={styles.createdAtRow}>
                          {t("reports_downloads.9")}
                        </p>
                        {/* <p className={styles.downloadRow}>Download</p> */}
                        <p className={styles.moreRow}>
                          {t("reports_downloads.11")}
                        </p>
                      </div>
                      {tempReports?.map((elem) => (
                        <ReportItem
                          title={elem.Name}
                          status={elem.Status}
                          dashboardName={elem.DashboardName}
                          createdAt={elem.CreationDate}
                          pdfLink={elem.ReportLink}
                          csvLink={elem.CSVLink}
                          dashboardId={elem.DashboardId}
                          reportID={elem.report_id}
                        />
                      ))}
                    </div>
                  ) : (
                    !reportLoading && (
                      <div className={styles.noReport}>
                        <p className={styles.heading}>
                          {t("empty_states.13")}{" "}
                          <img
                            src={
                              theme === "dark"
                                ? "/assets/emptyStates/no_reports.svg"
                                : "/assets/emptyStates/no_reports_black.svg"
                            }
                            alt=""
                          />
                        </p>
                        <div className={styles.secondRow}>
                          <img
                            src={
                              theme === "dark"
                                ? "/assets/error_outline.svg"
                                : "/assets/error_outline_black.svg"
                            }
                            alt=""
                          />
                          {t("empty_states.14")}
                        </div>
                        <p className={styles.secondHeading}>
                          {t("empty_states.15")}{" "}
                          <img src={"/assets/double_down_arrow.svg"} alt="" />
                        </p>
                        <div className={styles.imgBlock}>
                          <div>
                            <img
                              src={
                                theme === "dark"
                                  ? "/assets/emptyStates/reports1.png"
                                  : theme === "light"
                                  ? "/assets/emptyStates/reports1_light.png"
                                  : "/assets/emptyStates/reports1_brand.png"
                              }
                              alt=""
                            />
                          </div>
                          <div>
                            <img
                              src={
                                theme === "dark"
                                  ? "/assets/emptyStates/reports2.png"
                                  : theme === "light"
                                  ? "/assets/emptyStates/reports2_light.png"
                                  : "/assets/emptyStates/reports2_brand.png"
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </Spin>
              ) : (
                <Spin spinning={downloadLoading}>
                  <div className={styles.tableContent}>
                    <div className={styles.tableHeader}>
                      <p className={styles.exportRow}>
                        {t("reports_downloads.13")}
                      </p>
                      <p className={styles.statusRow}>
                        {t("reports_downloads.10")}
                      </p>
                      <p className={styles.exportDateRow}>
                        {t("reports_downloads.14")}
                      </p>
                      <p className={styles.downloadRow}>
                        {t("reports_downloads.15")}
                      </p>
                    </div>
                    {tempDownloads?.map((elem) => (
                      <DownloadItem
                        exportName={elem.Name + " " + elem.DashboardName}
                        status={elem.Status}
                        exportDate={elem.CreationDate}
                        csvLink={elem.CSVLink}
                        id={elem.report_id}
                      />
                    ))}
                  </div>
                </Spin>
              )}
            </div>
          }
        </div>
      </div>
      {showModal && (
        <div className={styles.createReportModal}>
          <div className={styles.centerWrapper}>
            <div className={styles.contentWrapper}>
              <div className={styles.modalTitle}>
                <span>{t("reports_downloads.16")}</span>
                <span>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/help-circle.svg"
                        : "/assets/help-circle-black.svg"
                    }
                    alt=""
                  />
                  {t("topic_library.18")}
                </span>
              </div>
              <div className={styles.modalContent}>
                <div className={styles.modalRow}>
                  <p>{t("reports_downloads.17")}*</p>
                  <Input
                    placeholder={t("placeholder.11")}
                    onChange={(e) => setName(e.target.value)}
                    className={styles.textAreaInput}
                  />
                </div>
                <div className={styles.modalRow}>
                  <p>{t("reports_downloads.18")}</p>
                  <CustomDropDown
                    placeholder={t("placeholder.12")}
                    options={dashboards}
                    selectedOption={selectedDashboard}
                    onChange={(value) => setSelectedDashboard(value)}
                  />
                </div>
                <div className={styles.checkboxContainer}>
                  <div className={`${styles.checkboxRow}`}>
                    {/* <Checkbox checked={chooseInformationChecked} onChange={(e) => setChooseInformationChecked(e.target.checked)} /> */}
                    <p>{t("reports_downloads.19")}</p>
                  </div>
                  <div className={`${styles.checkboxRow} ${styles.marginLeft}`}>
                    <Checkbox
                      checked={insightsChecked}
                      disabled={!chooseInformationChecked}
                      onChange={(e) => setInsightsChecked(e.target.checked)}
                    />
                    <p
                      className={
                        !chooseInformationChecked && styles.disabledText
                      }
                    >
                      {t("reports_downloads.20")}
                    </p>
                  </div>
                  <div className={styles.verticalLine} />
                  <div className={`${styles.checkboxRow} ${styles.marginLeft}`}>
                    <Checkbox
                      checked={aiChecked}
                      disabled={!chooseInformationChecked}
                      onChange={(e) => setAIChecked(e.target.checked)}
                    />
                    <p
                      className={
                        !chooseInformationChecked && styles.disabledText
                      }
                    >
                      {t("reports_downloads.26")}
                    </p>
                  </div>
                  {/* <div className={styles.verticalLine} />
                              <div className={`${styles.checkboxRow} ${styles.marginLeft}`}>
                                 <Checkbox checked={fixedChecked} disabled={!chooseInformationChecked} onChange={(e) => setFixedChecked(e.target.checked)} />
                                 <p className={!chooseInformationChecked && styles.disabledText}>{t("selected_dashboard_sidebar.3")}</p>
                              </div> */}
                  <div className={styles.verticalLine} />
                  <div className={`${styles.checkboxRow} ${styles.marginLeft}`}>
                    <Checkbox
                      checked={customChecked}
                      disabled={!chooseInformationChecked}
                      onChange={(e) => setCustomChecked(e.target.checked)}
                    />
                    <p
                      className={
                        !chooseInformationChecked && styles.disabledText
                      }
                    >
                      {t("selected_dashboard_sidebar.4")}
                    </p>
                  </div>
                </div>
                <div className={styles.switchContainer}>
                  <div className={styles.dynamicBtn}>
                    <input
                      type="checkbox"
                      id="reviews-switch"
                      checked={reviewsSwitch}
                      disabled={
                        subscriptionStatus?.PackageId < 76 ||
                        subscriptionStatus?.PackageId == 80
                      }
                      onClick={(e) => setReviewsSwitch(e.target.checked)}
                    />
                    <label for="reviews-switch">Toggle</label>
                  </div>
                  <p className={styles.switchText}>
                    {t("reports_downloads.21")}
                  </p>
                </div>

                <div className={styles.btnContainer}>
                  <button onClick={() => setShowModal(false)}>
                    {t("button.3")}
                  </button>
                  <button onClick={handleClick}>{t("button.7")}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showRenameReport && (
        <RenameDeletePopup
          text={renameReportValue}
          setText={setRenameReportValue}
          label={t("reports_downloads.22")}
          handleAction={handleReportRename}
          closePopup={setShowRenameReport}
          disableBtn={disableButton}
          type="rename"
        />
      )}
      {showDeleteDownload && (
        <Modal
          showModal={showDeleteDownload}
          onClose={() => setShowDeleteDownload(false)}
          onSave={handleDownloadDelete}
          showSaveButton={true}
          showCancelButton={true}
          hideCloseIcon={true}
          textonButton={t("button.2")}
          titleAsset=""
          title={"Delete Download"}
          loadingSaveClick={disableButton}
          widthofmodal="450px"
          disabled={disableButton}
          isDeleteModal={true}
        >
          <p className={styles.deletetext}>
            {t("selected_dashboard_page.80")}{" "}
            <b>
              {tempDownloads?.find((item) => item.report_id === reportID)?.Name
                ? tempDownloads?.find((item) => item.report_id === reportID)
                    .Name
                : tempDownloads?.find((item) => item.report_id === reportID)
                    ?.DashboardName}
            </b>{" "}
            {t("selected_dashboard_page.87")}
          </p>
        </Modal>
      )}
      {showDeleteReport && (
        <Modal
          showModal={showDeleteReport}
          onClose={() => setShowDeleteReport(false)}
          onSave={handleReportDelete}
          showSaveButton={true}
          showCancelButton={true}
          hideCloseIcon={true}
          textonButton={t("button.2")}
          titleAsset=""
          title={"Delete Report"}
          loadingSaveClick={disableButton}
          widthofmodal="450px"
          disabled={disableButton}
          isDeleteModal={true}
        >
          <p className={styles.deletetext}>
            {t("selected_dashboard_page.80")}{" "}
            <b>
              {tempReports?.find((item) => item.report_id === reportID)?.Name
                ? tempReports?.find((item) => item.report_id === reportID).Name
                : tempReports?.find((item) => item.report_id === reportID)
                    ?.DashboardName}
            </b>{" "}
            {t("selected_dashboard_page.88")}
          </p>
        </Modal>
      )}
    </div>
  );
};

export default ReportsDownloads;
