import React, { useEffect, useState } from "react";
import { Radio, Collapse, Tag, Badge, Button, Spin } from "antd";
import { apiRequest } from "util/services";
import styles from "./style.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as actions from "redux/Dashboard/actions.js";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@material-ui/core";
import DropdownSelect from "helperComponents/CustomDropdown";

const TopTopics = ({
  data,
  disableTopTopicsBtn,
  selectedTopTopics,
  setSelectedTopTopics,
  topTopicsLoading,
  checkedTopicIds,
  setCheckedTopicIds,
  selectedTopicIds,
  setSelectedTopicIds,
  boardType,
  handleClearAll,
  handleFilterClear,
}) => {
  const [type, setType] = useState("custom");
  const [viewType, setViewType] = useState("category");
  const [words, setWords] = useState();
  const [startIndex, setStartIndex] = useState(0);
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const DashboardReducer = useSelector((state) => state?.DashboardReducer);
  const [searchValue, setSearchValue] = useState("");
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedFilterType, setSelectedFilterType] = useState(null);
  const parentBoxRef = React.useRef(null);
  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);
  const [queryFilters, setQueryFilters] = useState({});

  useEffect(() => {
    if (params) {
      const values = Array.from(params);
      const queryObj = values.reduce((acc, [key, val]) => {
        if (
          key === "keyword" ||
          key === "pivotFilter" ||
          key === "top_topics" ||
          key === "level1Selection" ||
          key === "level2Selection" ||
          key === "level1OfSelected" ||
          key === "level2OfSelected"
        ) {
          acc[key] = JSON.parse(val);
        } else {
          acc[key] = val;
        }
        return acc;
      }, {});
      setQueryFilters(queryObj);
    }
  }, [history, search]);

  const searchTopics = (topicsArr, id) => {
    for (let tpc of topicsArr) {
      if (tpc.id === parseInt(id, 10)) {
        return tpc;
      }
      if (tpc.child && tpc.child.length > 0) {
        const res = searchTopics(tpc.child, id);
        if (res) {
          return res;
        }
      }
    }
  };
  const getLibFromTopicId = (list, id) => {
    const lib = searchTopics(list, id);
    if (lib) {
      return lib;
    }
    return null;
  };
  useEffect(() => {
    if (
      !topTopicsLoading &&
      queryFilters?.top_topics &&
      queryFilters.top_topics.length > 0
    ) {
      const topics = queryFilters.top_topics.filter((item) => {
        if (
          item.type === "ai" ||
          (Object.keys(item).length === 3 && item.type === "custom")
        ) {
          return;
        } else {
          return item;
        }
      });
      const parentIds = topics
        .map((item) => item.parent_id)
        .filter((i) => {
          if (i) {
            return i;
          }
        });
      const f = topics.filter((item) => !parentIds.includes(item.id));
      f.map((item) => {
        const target = {
          id: item?.parent_id
            ? getLibFromTopicId(categoryList, item.id)?.lib_id?.toString()
            : item.id?.toString(),
          name: item.label?.toString(),
          value: item.parent_id?.toString(),
        };
        handleCheckboxChange({
          target: target,
        });
      });
    }
  }, [queryFilters.top_topics, topTopicsLoading]);
  const addTopics = (id, type, label) => {
    let filterID = queryFilters?.top_topics?.filter((item) => item.id === id);
    if (!filterID || filterID?.length === 0) {
      params.delete("top_topics");
      params.append(
        "top_topics",
        JSON.stringify(
          queryFilters?.top_topics?.length > 0
            ? [
                ...queryFilters?.top_topics,
                { id: id, type: type, label: label },
              ]
            : [{ id: id, type: type, label: label }]
        )
      );
      history.push({ search: params.toString() });
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          chosenTopTopic: [
            ...DashboardReducer.searchState.chosenTopTopic,
            { id: id, type: type, label: label },
          ],
        },
      });
    } else {
      params.delete("top_topics");
      params.append(
        "top_topics",
        JSON.stringify(
          queryFilters?.top_topics?.filter((item) => item.id !== id)
        )
      );
      history.push({ search: params.toString() });
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          chosenTopTopic: DashboardReducer.searchState.chosenTopTopic?.filter(
            (item) => item.id !== id
          ),
        },
      });
    }
  };

  useEffect(() => {
    setStartIndex(0);
    if (type === "all") {
      setWords(data?.list);
      setViewType("plain");
    } else if (type === "ai") {
      setWords(data?.list?.filter((item) => item.type === "ai"));
      setViewType("plain");
    } else if (type === "fixed") {
      setWords(data?.list?.filter((item) => item.type === "fixed"));
      setViewType("plain");
    } else if (type === "custom") {
      setWords(data?.list?.filter((item) => item.type === "custom"));
      setCategoryList(data?.tree);
      setViewType("category");
    }
  }, [type, data]);

  useEffect(() => {
    //loop through the selectedTopTopics and checking if a parent has all its children selected
    //if yes, then set the parent to checked
    //if no, then set the parent to indeterminate
    if (checkedTopicIds?.length > 0) {
      for (let i = 0; i < checkedTopicIds?.length; i++) {
        const current = checkedTopicIds[i];
        if (current.type === "parent") {
          if (selectionStatus(current.id) === "some") {
            if (document.getElementById(current.id)) {
              document.getElementById(current.id).indeterminate = true;
            }
          } else if (selectionStatus(current.id) === "all") {
            if (document.getElementById(current.id)) {
              document.getElementById(current.id).checked = true;
              document.getElementById(current.id).indeterminate = false;
            }
          }
        }
      }
    }
  }, [selectedTopTopics, trigger]);

  const numFormatter = (num) => {
    return Math.abs(num) > 999
      ? Math.sign(num) * Math.trunc(Math.abs(num) / 1000) + "K"
      : num;
  };

  const applyOptions = [
    {
      id: "1",
      label: "Intersection",
      value: "AND",
    },
    {
      id: "2",
      label: "Union",
      value: "OR",
    },
  ];
  useEffect(() => {
    if (selectedFilterType && selectedTopicIds.length > 0) {
      handleApplyFilter(selectedFilterType.value);
    }
  }, [selectedFilterType]);
  const visualWords = words?.map((word, index) => {
    if (index >= 10 * startIndex && index < 10 * (startIndex + 1)) {
      return (
        <Tag
          icon={
            <Badge
              count={numFormatter(word.count)}
              overflowCount={9999999999}
              style={{
                marginRight: "10px",
                background: "#FFFFFF",
                border: "none",
                color: "#4400AA",
              }}
            />
          }
          className={
            DashboardReducer?.searchState?.chosenTopTopic?.some(
              (item) => item.id === word.id
            )
              ? styles.activeTerm
              : ""
          }
          color={theme === "brand" ? "#4a4d4e" : "#4400AA"}
          onClick={() =>
            !disableTopTopicsBtn && addTopics(word.id, word.type, word.label)
          }
          title={word.label}
        >
          <span className={styles.wordLabel}>{word.label}</span>
        </Tag>
      );
    }
  });

  const updatePage = (increment) => {
    setStartIndex(startIndex + increment);
  };

  const getText = (type) => {
    if (type === "all") {
      return t("others.47");
    } else if (type === "ai") {
      return t("others.48");
    } else if (type === "fixed") {
      return t("others.49");
    } else if (type === "custom") {
      return t("others.50");
    }
  };

  const handleTypeChange = (e) => {
    setViewType(e.target.value);
  };

  const getSearchItem = (list, str) => {
    let arr = list.filter((item) => {
      if (item.name?.toLowerCase().includes(str)) {
        return item;
      }
    });
    setCategoryList(arr);
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      if (searchValue?.trim()) {
        getSearchItem(categoryList, searchValue?.toLowerCase());
      } else {
        setCategoryList(data?.tree);
      }
    }
  };
  const handleButtonClickSearch = () => {
    if (searchValue?.trim()) {
      getSearchItem(categoryList, searchValue?.toLowerCase());
    } else {
      setCategoryList(data?.tree);
    }
  };
  const handleShowSearch = () => {
    setShowSearchBox(true);
  };

  const selectionStatus = (id) => {
    //check if all the child of this parent are present in selectedTopicIds and if the parent is present in selectedTopicIds
    //return fullSelected if parent and all child of this parent are present in selectedTopicIds
    //return partialSelected if parent is present in selectedTopicIds and some of the child of this parent are present in selectedTopicIds
    //return noneSelected if parent is not present in selectedTopicIds and none of the child of this parent are present in selectedTopicIds
    let parent = categoryList?.find((item) => item?.lib_id === id);
    let fullSelected = true;
    let partialSelected = false;
    let noneSelected = true;
    for (let i = 0; i < parent?.child?.length; i++) {
      let c = parent?.child[i];
      if (c && c.count) {
        if (checkedTopicIds?.some((item) => item.id === c.lib_id)) {
          noneSelected = false;
          partialSelected = true;
        } else {
          fullSelected = false;
        }
      }
      //check if child has children
      if (c?.child?.length !== 0) {
        let status = selectionStatus(c.lib_id);
        if (status === "all") {
          noneSelected = false;
          partialSelected = true;
        } else if (status === "some") {
          noneSelected = false;
          partialSelected = true;
          fullSelected = false;
        } else {
          fullSelected = false;
        }
      }
    }

    if (fullSelected) return "all";
    if (partialSelected) return "some";
    if (noneSelected) return "none";
  };

  const selectNestedTopics = (parentId) => {
    let arr = [];
    let parent = categoryList?.find((item) => item?.lib_id === parentId);
    if (parent?.child?.length === 0) return [];
    for (let i = 0; i < parent?.child?.length; i++) {
      let c = parent?.child[i];
      if (c && c.count)
        arr.push({
          label: c.name,
          id: parseInt(c.lib_id, 10),
          type: "custom",
          parent_id: parentId,
        });
      //check if child has children
      if (c?.child?.length !== 0) {
        arr = arr.concat(selectNestedTopics(c.id));
      }
    }

    return arr;
  };

  const handleCheckboxChange = (e) => {
    const { id, name, value } = e.target;
    //[parent_id:25].filter.
    if (value) {
      if (
        selectedTopicIds?.filter((item) => item.id === parseInt(id, 10))
          ?.length !== 0
      ) {
        //removing the parent if all the children are removed
        const parent = selectedTopicIds.find(
          (item) =>
            item.id ===
            selectedTopicIds.find((item) => item.id === parseInt(id, 10))
              ?.parent_id
        );
        const newArr = selectedTopicIds.filter(
          (item) => item.id !== parseInt(id, 10)
        );
        if (
          parent &&
          newArr.filter((item) => item.parent_id === parent.id).length === 0
        ) {
          setSelectedTopicIds(newArr.filter((item) => item.id !== parent.id));
          setCheckedTopicIds(newArr.filter((item) => item.id !== parent.id));
          if (data.length > 0) {
            document.getElementById(parent.id).checked = false;
            document.getElementById(parent.id).indeterminate = false;
          }
        } else {
          setSelectedTopicIds(newArr);
          setCheckedTopicIds(newArr);
        }
        //if all the elements of the parent are removed then uncheck the parent
      } else {
        const newArr = [...selectedTopicIds];
        //checking if the parent is already selected
        const parent = selectedTopicIds.find(
          (item) => item.id === parseInt(value, 10)
        );
        if (parent) {
          newArr.push({
            label: name,
            id: parseInt(id, 10),
            type: "custom",
            parent_id: parseInt(value, 10),
          });
        } else {
          newArr.push({
            label: name,
            id: parseInt(id, 10),
            type: "custom",
            parent_id: parseInt(value, 10),
          });
          //finding the parent in the category list
          const parent = categoryList?.find(
            (item) => item.lib_id === parseInt(value, 10)
          );
          newArr.push({
            label: parent?.name,
            id: parseInt(value, 10),
            type: "parent",
          });
        }
        const unique = [
          ...new Map(newArr.map((item) => [item.id, item])).values(),
        ];
        setSelectedTopicIds(unique);
        setCheckedTopicIds(unique);
      }
    } else {
      if (
        selectedTopicIds?.filter((item) => item.id === parseInt(id, 10))
          ?.length !== 0
      ) {
        //removing all parent and child topics
        const toBeRemoved = selectNestedTopics(parseInt(id, 10));
        const topicArr = selectedTopicIds.filter(
          (item) => !toBeRemoved.find((i) => i.id === item.id)
        );
        const checkArr = checkedTopicIds.filter(
          (item) => !toBeRemoved.find((i) => i.id === item.id)
        );
        setSelectedTopicIds(
          topicArr.filter((item) => item.id !== parseInt(id, 10))
        );
        setCheckedTopicIds(
          checkArr.filter((item) => item.id !== parseInt(id, 10))
        );
        if (data.length > 0) {
          document.getElementById(id).checked = false;
          document.getElementById(id).indeterminate = false;
        }
      } else {
        const arr = selectedTopicIds;
        const checkedArr = checkedTopicIds;
        arr.push({ label: name, id: parseInt(id, 10), type: "parent" });
        checkedArr.push({ label: name, id: parseInt(id, 10), type: "parent" });
        //removing all duplicate topics
        const unique = [
          ...new Map(arr.map((item) => [item.id, item])).values(),
        ];
        checkedArr.push(...selectNestedTopics(parseInt(id, 10)));
        //removing all duplicate topics
        const uniqueChecked = [
          ...new Map(checkedArr.map((item) => [item.id, item])).values(),
        ];
        setCheckedTopicIds([...uniqueChecked]);
        setSelectedTopicIds([...unique]);
      }
    }
    setTrigger(!trigger);
  };

  const findNestedChild = (id) => {
    let element = false;
    //recursively find the child whose id is equal to the id passed
    const findChild = (id, arr) => {
      for (let i = 0; i < arr?.length; i++) {
        if (arr[i].lib_id === id) {
          element = arr[i];
          break;
        }
        if (arr[i].child?.length !== 0) {
          findChild(id, arr[i].child);
        }
      }
    };
    findChild(id, categoryList);

    return element;
  };

  const handleApplyFilter = (operator) => {
    const topicsArr = queryFilters?.top_topics || [];
    let newArr = topicsArr
      ?.filter((val) => !val.label)
      ?.concat(selectedTopicIds);
    //mapping ids to child_ids
    const mappedArr = newArr?.map((item) => {
      if (item.type === "custom") {
        const child = findNestedChild(item.id);
        return { ...item, id: child?.id };
      } else {
        return item;
      }
    });
    if (mappedArr) {
      params.delete("top_topics");
      params.delete("topics_operator");
      params.append("top_topics", JSON.stringify(mappedArr));
      params.append("topics_operator", operator);
      history.push({ search: params.toString() });
      dispatch({
        type: actions.UPDATE_SEARCH_STATE,
        payload: {
          chosenTopTopic: mappedArr,
          topicsOperator: operator,
        },
      });
    } else {
      params.delete("top_topics");
      params.delete("topics_operator");
    }
  };

  const getNestedChild = (item) => {
    return item?.map((it) => (
      <Collapse className={styles.collapse} bordered={false} accordion>
        <Collapse.Panel
          key={it.lib_id}
          showArrow={false}
          header={
            <div className={styles.topicHeader}>
              {
                <input
                  checked={
                    checkedTopicIds?.filter(
                      (item) => item.id === parseInt(it.lib_id, 10)
                    )?.length !== 0
                  }
                  id={it.lib_id}
                  className={`${styles.topicCheckBox} check-box-topic-for-filter`}
                  type="checkbox"
                  name={it.name}
                  onChange={handleCheckboxChange}
                  value={it.parent_id}
                  onClick={(e) => e.stopPropagation()}
                />
              }
              {it?.child?.length > 0 && (
                <i className="fa-solid fa-chevron-right"></i>
              )}
              <span>
                {it?.count >= 0 && (
                  <span className={styles.numbering}>{it?.count}</span>
                )}
                {it.name}
              </span>
            </div>
          }
          collapsible={it?.child?.length > 0 ? "header" : "disabled"}
        >
          {it.child?.length > 0 && getNestedChild(it.child)}
        </Collapse.Panel>
      </Collapse>
    ));
  };

  return (
    <div className={styles.HotWordsSection}>
      <div className={styles.radioContainer}>
        <Radio.Group
          defaultValue={"custom"}
          buttonStyle="solid"
          onChange={(e) => setType(e.target.value)}
        >
          <Radio.Button className={styles.item} value={"all"}>
            <img
              src={
                type === "all" && theme !== "dark"
                  ? "/assets/all_words_black.svg"
                  : "/assets/all_words.svg"
              }
              alt=""
            />
            {t("others.43")}
          </Radio.Button>
          <Radio.Button className={styles.item} value={"ai"}>
            <img
              src={
                type === "ai" && theme !== "dark"
                  ? "/assets/bubble_chart_black.svg"
                  : "/assets/bubble_chart.svg"
              }
              alt=""
            />
            {t("others.44")}
          </Radio.Button>
          {/* <Radio.Button className={styles.item} value={"fixed"}><img src={type === 'fixed' && theme !== 'dark' ? "/assets/industry_words_black.svg" : "/assets/industry_words.svg"} alt="" />{t("others.45")}</Radio.Button> */}
          <Radio.Button className={styles.item} value={"custom"}>
            <img
              src={
                type === "custom" && theme !== "dark"
                  ? "/assets/custom_words_black.svg"
                  : "/assets/custom_words.svg"
              }
              alt=""
            />
            {t("others.46")}
          </Radio.Button>
        </Radio.Group>
      </div>

      <div className={styles.description}>
        <span>{getText(type)}</span>
        {type === "custom" ? (
          <div className={styles.radioInput}>
            <div className={styles.item}>
              <input
                type="radio"
                id="plain"
                value="plain"
                name="view-type"
                checked={viewType === "plain"}
                onChange={handleTypeChange}
              />
              <label htmlFor="plain">Plain View</label>
            </div>
            <div className={styles.item}>
              <input
                type="radio"
                id="category"
                value="category"
                checked={viewType === "category"}
                name="view-type"
                onChange={handleTypeChange}
              />
              <label htmlFor="category">Hierarchical View</label>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={styles.visualTerms}>
        {words?.length === 0 && !topTopicsLoading && viewType === "plain" ? (
          <div className={styles.emptyState}>
            {" "}
            <div className={styles.title}>
              <img
                src={
                  theme === "dark"
                    ? "/assets/emptyStates/topic_library_icon.svg"
                    : "/assets/emptyStates/topic_library_black.svg"
                }
                alt=""
              />
              {t("notifications.80")}
            </div>
            <div className={styles.description}>
              To create custom topics, go{" "}
              <Link to="/console/topicLibrary">Topic Library</Link>
            </div>
          </div>
        ) : viewType === "plain" ? (
          <div className={styles.topTopicsPlain}>{visualWords}</div>
        ) : type === "custom" ? (
          <div className={styles.categoryBlock}>
            <div className={styles.btnRow}>
              {!showSearchBox && (
                <span
                  className={styles.clearAllTopics}
                  onClick={handleClearAll}
                >
                  Clear all
                </span>
              )}
              <span className={styles.searchNapply}>
                {!showSearchBox && (
                  <div className={styles.actionBtnBlock}>
                    <button
                      className={styles.search}
                      onClick={handleShowSearch}
                    >
                      <img
                        src={
                          theme === "dark"
                            ? "/assets/search.svg"
                            : "/assets/search_black.svg"
                        }
                        alt=""
                      />
                    </button>
                  </div>
                )}
                {showSearchBox && (
                  <div
                    className={`${styles.searchBoxSmall} ${showSearchBox &&
                      styles.showSearch} `}
                  >
                    <i
                      onClick={() => {
                        setShowSearchBox(false);
                        setSearchValue("");
                        setCategoryList(data?.tree);
                      }}
                      className="fa-solid fa-arrow-left"
                    ></i>
                    <input
                      type="text"
                      placeholder={t("placeholder.2")}
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      onKeyPress={handleSearch}
                    />
                    <button onClick={handleButtonClickSearch}>
                      {t("button.5")}
                    </button>
                  </div>
                )}
              </span>
            </div>
            {selectedTopicIds && selectedTopicIds.length > 0 && (
              <div className={styles.applyActions}>
                {selectedTopicIds.length === 1 ||
                (selectedTopicIds.length === 2 &&
                  selectedTopicIds.filter((item) =>
                    item.hasOwnProperty("parent_id")
                  ).length < 2 &&
                  selectedTopicIds.filter((item) => item.type === "parent")
                    .length < 2) ? (
                  <span
                    onClick={() => handleApplyFilter("OR")}
                    className={styles.applyFilterDropdown}
                  >
                    Apply
                  </span>
                ) : (
                  <DropdownSelect
                    options={applyOptions}
                    selectedOptions={selectedFilterType}
                    setSelectedOptions={setSelectedFilterType}
                    hideImg={true}
                    hiddenTriggerLabel={
                      <span className={styles.applyFilterDropdown}>Apply</span>
                    }
                    triggerEvent={"click"}
                    disableMenu={selectedTopicIds.length === 0}
                    placement={"bottomRight"}
                  />
                )}
              </div>
            )}
            <div className={styles.topicCategoryContainer}>
              <Spin spinning={false}>
                {categoryList?.length !== 0 ? (
                  <Collapse
                    className={styles.collapse}
                    bordered={false}
                    accordion
                  >
                    {categoryList?.map((item) => (
                      <Collapse.Panel
                        key={item.lib_id}
                        showArrow={false}
                        header={
                          <div className={styles.topicHeader}>
                            {
                              <input
                                ref={parentBoxRef}
                                className={`${styles.topicCheckBox} check-box-topic-for-filter`}
                                type="checkbox"
                                id={item.lib_id}
                                name={item.name}
                                onChange={handleCheckboxChange}
                                value={item.parent_id}
                                onClick={(e) => e.stopPropagation()}
                              />
                            }
                            {item?.child?.length > 0 && (
                              <i className="fa-solid fa-chevron-right"></i>
                            )}
                            <span>
                              {item?.count >= 0 && (
                                <span className={styles.numbering}>
                                  {item?.count}
                                </span>
                              )}
                              {item.name}
                            </span>
                          </div>
                        }
                        collapsible={
                          item?.child?.length > 0 ? "header" : "disabled"
                        }
                      >
                        {item.child?.length > 0 && getNestedChild(item.child)}
                      </Collapse.Panel>
                    ))}
                  </Collapse>
                ) : (
                  <p>{t("notifications.80")}</p>
                )}
              </Spin>
            </div>
          </div>
        ) : (
          type === "custom" && <p>{t("notifications.80")}</p>
        )}
      </div>
      {words?.length > 10 && viewType === "plain" ? (
        <div className={styles.footer}>
          <div className={styles.btn}>
            <Button
              className={styles.button}
              disabled={startIndex === 0}
              onClick={() => updatePage(-1)}
            >
              <i className="fas fa-chevron-left"></i>
            </Button>
          </div>
          <div className={styles.btn}>
            <Button
              className={styles.button}
              disabled={
                (words?.length % 10 === 0 &&
                  words?.length / 10 === startIndex + 1) ||
                (words?.length % 10 !== 0 &&
                  Math.trunc(words?.length / 10) + 1 === startIndex + 1)
              }
              onClick={() => updatePage(1)}
            >
              <i className="fas fa-chevron-right"></i>
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TopTopics;
