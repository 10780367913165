import React, { useState, useEffect } from "react";
import { Table, Checkbox, Spin } from "antd";

const GroupsSelection = ({
  groups,
  selectedGroups,
  setSelectedGroups,
  resetPopup,
  loading,
}) => {
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelectAll(selectedGroups?.length === groups?.length);
  }, [selectedGroups, groups]);

  useEffect(() => {
    if (resetPopup) {
      setSelectAll(false);
      setSelectedGroups([]);
    } else {
      setSelectAll(selectedGroups?.length === groups?.length);
    }
  }, [resetPopup]);
  useEffect(() => {
    setSelectAll(
      selectedGroups?.length === groups?.length ||
        (selectedGroups?.length > 0 && selectedGroups?.length < groups?.length)
    );
  }, [selectedGroups, groups]);

  const handleGroupsSelection = (selectedGroup) => {
    const updatedSelection = [...selectedGroups];

    const groupIndex = updatedSelection.findIndex(
      (group) => group?.groupId === selectedGroup?.groupId
    );

    if (groupIndex === -1) {
      updatedSelection.push(selectedGroup);
    } else {
      updatedSelection.splice(groupIndex, 1);
    }

    setSelectedGroups(updatedSelection);
  };

  const handleSelectAll = () => {
    const newSelectAll = !(
      selectedGroups?.length === groups?.length ||
      (selectedGroups?.length > 0 && selectedGroups?.length < groups?.length)
    );
    setSelectAll(newSelectAll);
    const selected = newSelectAll ? groups : [];
    setSelectedGroups(selected);
  };

  const columns = [
    {
      title: (
        <Checkbox
          checked={selectAll}
          indeterminate={
            selectedGroups?.length > 0 &&
            selectedGroups?.length < groups?.length
          }
          onChange={handleSelectAll}
        />
      ),
      key: "checkbox",
      render: (record) => (
        <Checkbox
          checked={
            Array.isArray(selectedGroups) &&
            selectedGroups.some((group) => group?.groupId === record?.groupId)
          }
          onChange={() => handleGroupsSelection(record)}
        />
      ),
    },
    {
      title: "Group Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "# of dashboards",
      dataIndex: "noOfDashboards",
      key: "noOfDashboards",
      align: "center",
    },
  ];

  const data = groups?.map((group, index) => ({
    groupId: group.groupId,
    name: group.name,
    noOfDashboards: group.noOfDashboards,
    lastUpdated: group.lastUpdated,
  }));

  return (
    <div>
      <Spin spinning={loading}>
        <Table columns={columns} dataSource={data} pagination={false} />
      </Spin>
    </div>
  );
};

export default GroupsSelection;
