import React from 'react';
import { Popover } from "antd";

const titles = {
  platform: "Choose the desired platform",
  allKeyword: "Get tweets containing (all) these keywords",
  anyKeyword: "Get tweets containing (any) of these keywords",
  languageCustomer: "Language of reviews that are uploaded",
  languagePlatform: "This is the language of tweets that will be collected",
  languagePlaystore: "Language of reviews that will be collected",
  startDate: "Select the crawling starting time",
  endDate: "Select the crawling ending time",
  algorithm: "Select the training algorithm to generate topics",
  numTopics: "Select the number of topics in the resulted Dashboard",
  chunkSize: "Select the chunk size in training",
  numIters: "Select the number of iterations in training",
  numPasses: "Select the number of passes in training",
  alpha: "Select the alpha value in training",
  beta: "Select the beta value of passes in training",
  appId: "Type the application url found at the store",
  customerFile: "Upload or choose one of your customer feedback files",
  feedbackcn: "Type the feedback column name in the uploaded/selected file",
  emailcn: "Type the email column name in the uploaded/selected file if any",
  phonecn: "Type the phone column name in the uploaded/selected file if any",
  customerIdcn: "Type the ID column name in the uploaded/selected file if any",
  datecn: "Type the date column name in the uploaded/selected file if any",
  ratecn: "Type the rate column name in the uploaded/selected file if any",
  selectMostPositive: "Select the topic with the most positive sentiment",
  selectMostNegative: "Select the topic with the most negative sentiment",
  selectMostComplaints: "Select the topic with the most complaints",
  selectLeastRating: "Select the topic with the least rating",
  selectTopic: "Select a topic from the listed ones",
  selectByKeyword: "Search the topics by keyword and find a match",
  countryPlaystore: "Filter reviews by countries",
  boardNameEdit: "Edit your Dashboard name",
  dynamicBoard: "Make the Dashboard reviews updated automatically"
}

export const getToolTip = (type) => {
  return (
    <Popover content={titles[type]} style={{ zIndex: 1000 }}>
      <i
        style={{ margin: "0 10px", fontSize: "20px" }}
        className="fa fa-info-circle"
      />
    </Popover>
  );
};
