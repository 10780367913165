import React, { useState, useEffect, useRef } from "react";
import styles from "./style.module.scss";
import { hexToRgbA } from "util/common";
import { notification, Button, Spin } from "antd";
import ReactReadMoreReadLess from "react-read-more-read-less";
import StarRatings from "react-star-ratings";
import { apiRequest } from "util/services";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";

const ReviewItem = ({
  text,
  title,
  date,
  sentiment,
  intents,
  engagements,
  checkbox,
  relatedTopics,
  rate,
  name,
  pic,
  userVerified,
  username,
  likes,
  restaurant,
  reviewId,
  facebookReplies,
  getFacebookReplies,
  reviewPlatformId,
  platformId,
  npsRating,
  showCustomTopicLabel,
  showAiTopicLabel,
  Gpid,
  showCustomTopicParentLabel,
  showPivotsInReviews,
  groupName,
  groupId,
  colorMap,
}) => {
  const [overallSentiment, setOverallSentiment] = useState(null);
  const [overallIntent, setOverallIntent] = useState(null);
  const [facebookReplyMessage, setFacebookReplyMessage] = useState("");
  const [
    isReplyFacebookModalVisible,
    setIsReplyFacebookModalVisible,
  ] = useState(false);
  const [
    isReplyFacebookModalLoading,
    setIsReplyFacebookModalLoading,
  ] = useState(false);
  const [
    isReplyFacebookReviewsVisible,
    setIsReplyFacebookReviewsVisible,
  ] = useState(false);
  const { t } = useTranslation();
  const dashboard_id = useSelector(
    (state) => state.DashboardReducer.dashboard?.ID
  );
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const language = useSelector(
    (state) => state.DashboardReducer.dashboard?.Language
  );
  const advancedAllowed = useSelector(
    (state) => state.userReducer.advancedAllowed
  );
  const userId = useSelector(
    (state) => state.DashboardReducer.dashboard?.UserId
  );
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < 950 ? true : false
  );
  const [collapseAssociations, setCollapseAssociations] = useState(true);
  const associationsRef = useRef();
  const [associationsRefHeight, setAssociationsRefHeight] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 950);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobileView]);
  const hexToRGBA = (hex, opacity) => {
    hex = hex.replace("#", "");
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };
  const replyFacebook = async (comment_id, message) => {
    if (isReplyFacebookModalLoading) {
      return;
    }
    if (!message) {
      notification.error({
        message: t("notifications.89"),
      });
      return;
    }
    setIsReplyFacebookModalLoading(true);
    const url = "dashboards/post_facebook_comment";
    const payload = {
      comment_id: comment_id,
      message: message,
      firebase_id: userId,
    };
    const result = await apiRequest(url, "POST", payload, true);
    if (!result.error) {
      getFacebookReplies();
      notification.success({
        message: result.success,
      });
    } else {
      notification.error({
        message: result.error,
      });
    }
    setIsReplyFacebookModalVisible(false);
    setIsReplyFacebookModalLoading(false);
  };
  /*const select = (topic) => {
    const payload = {
      selected_topic: topic.id,
    };
    if (boardDataSrc) {
      payload.trends = {};
    }
    dispatch({
      type: actions.SELECT_TOPIC,
      payload,
    });
    if (shared && currentDashboardHash) {
      if (topic.type === "ai") {
        history.push({
          pathname: `/share/Dashboard/${currentDashboardHash}`,
          state: {
            searchInsight: true,
            selected_topic: topic.id,
          }
        });
      }
      else if (topic.type === "custom") {
        history.push({
          pathname: `/console/customTopics/${currentDashboardHash}`,
          state: {
            searchInsight: true,
            selected_topic: topic.id,
          }
        });
      }
    } else {
      if (topic.type === "ai") {
        history.push({
          pathname: `/console/Dashboard/${dashboard_id}`,
          state: {
            searchInsight: true,
            selected_topic: topic.id,
          }
        });
      }
      else if (topic.type === "custom") {
        history.push({
          pathname: `/console/customTopics/${dashboard_id}`,
          state: {
            searchInsight: true,
            selected_topic: topic.id,
          }
        });
      }
    }
  };*/
  const removeParentFromChildren = (parent) => {
    const substrs = parent.split(">");
    substrs.splice(0, 1);
    return substrs.join(">");
  };
  const color = (topicType) => {
    if (topicType === "ai") {
      return [
        "rgba(215, 183, 124, 0.945)",
        theme === "dark" ? hexToRgbA("#754d03", 1) : hexToRgbA("#bd7b01", 1),
      ];
    } else if (topicType === "custom") {
      return [
        "rgba(185, 157, 255, 0.3)",
        theme === "dark" ? "rgb(185, 167, 230)" : "rgb(101, 77, 163)",
      ];
    }
  };

  const label = (Label, topicType) => {
    if (topicType === "ai") {
      return `${Label}`;
    } else if (topicType === "custom") {
      if (showCustomTopicParentLabel) {
        return `${Label}`;
      } else {
        let str = Label?.split(">").at(-1);
        str = str?.trim();
        if (str) {
          return `${str}`;
        } else return `${Label}`;
      }
    }
  };
  const mobileLabel = (label) => {
    if (label && label !== "") {
      const hasParent = label.includes(">");
      if (hasParent) {
        let parent = label.split(">")[0];
        const child = removeParentFromChildren(label);
        if (parent.length > 5) {
          return `${parent.slice(0, 4)}... > ${child}`;
        } else {
          return `${parent} > ${child}`;
        }
      } else {
        return label;
      }
    }
  };
  const handleUserText = (e) => {
    let element = document.getElementById("custom_text_input");
    if (element.scrollTop !== 0) {
      element.style.height = element.scrollHeight + "px";
    }
  };
  const intentOptions = [
    {
      id: "-1",
      value: "-1",
      label: t("feedback_dropdown_values.1"),
      img: "/assets/Unspecified.svg",
    },
    {
      id: "1",
      value: "1",
      label: t("feedback_dropdown_values.5"),
      img: "/assets/Appraisal.svg",
    },
    {
      id: "2",
      value: "2",
      label: t("feedback_dropdown_values.6"),
      img: "/assets/Complaint.svg",
    },
    {
      id: "3",
      value: "3",
      label: t("feedback_dropdown_values.7"),
      img: "/assets/Suggestion.svg",
    },
    {
      id: "4",
      value: "4",
      label: t("feedback_dropdown_values.8"),
      img: "/assets/Remark.svg",
    },
    {
      id: "5",
      value: "5",
      label: t("feedback_dropdown_values.9"),
      img: "/assets/Marketing & Spam.svg",
    },
    {
      id: "6",
      value: "6",
      label: t("feedback_dropdown_values.10"),
      img: "/assets/Information.svg",
    },
    {
      id: "7",
      value: "7",
      label: t("feedback_dropdown_values.11"),
      img: "/assets/Request.svg",
    },
    {
      id: "8",
      value: "8",
      label: t("feedback_dropdown_values.12"),
      img: "/assets/Comparison.svg",
    },
  ];
  const overallSentimentOptions = [
    {
      id: "1.0",
      value: "1.0",
      label: t("feedback_dropdown_values.2"),
      img: "/assets/Positive.svg",
    },
    {
      id: "0.5",
      value: "0.5",
      label: t("feedback_dropdown_values.3"),
      img: "/assets/Neutral.svg",
    },
    {
      id: "0.0",
      value: "0.0",
      label: t("feedback_dropdown_values.4"),
      img: "/assets/Highly_negative.svg",
    },
  ];
  const sentimentOptions = [
    {
      id: "-1",
      value: "-1",
      label: t("feedback_dropdown_values.1"),
      img: "/assets/Unspecified_Sentiment.svg",
    },
    {
      id: "1.0",
      value: "1.0",
      label: t("feedback_dropdown_values.2"),
      img: "/assets/Positive.svg",
    },
    {
      id: "0.5",
      value: "0.5",
      label: t("feedback_dropdown_values.3"),
      img: "/assets/Neutral.svg",
    },
    {
      id: "0.0",
      value: "0.0",
      label: t("feedback_dropdown_values.4"),
      img: "/assets/Negative.svg",
    },
  ];
  useEffect(() => {
    if (intents) {
      const i =
        intents !== "None"
          ? intentOptions.find((item) => item.id === intents)
          : intentOptions[0];
      if (i) {
        setOverallIntent(i);
      }
    }
    if (sentiment) {
      const s =
        sentiment !== "None"
          ? sentimentOptions.find((item) => item.id === sentiment)
          : sentimentOptions[0];
      if (s) {
        setOverallSentiment(s);
      }
    }
  }, [intents, sentiment]);

  useEffect(() => {
    if (associationsRef && associationsRef.current) {
      setAssociationsRefHeight(associationsRef.current.scrollHeight);
    }
  });
  function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
  const handleExtraResize = debounce(function() {
    setAssociationsRefHeight(associationsRef.current.scrollHeight);
    console.log("resizing…");
  });

  useEffect(() => {
    window.addEventListener("resize", handleExtraResize);

    return () => {
      window.removeEventListener("resize", handleExtraResize);
    };
  });

  const partOfAllReviews = (
    <>
      {title && title !== "None" && (
        <span className={styles.reviewTitle}>{title}</span>
      )}
      <div className={styles.reviewText}>
        <ReactReadMoreReadLess
          charLimit={200}
          readMoreText={
            <span className={styles.showMoreShowLess}>
              {" "}
              Show more... <CaretDownFilled />
            </span>
          }
          readLessText={
            <span className={styles.showMoreShowLess}>
              {" "}
              Show less... <CaretUpFilled />
            </span>
          }
        >
          {text && text !== "nan" ? text : ""}
        </ReactReadMoreReadLess>
      </div>

      <div className={styles.itemBottom}>
        <div className={styles.firstLine}>
          <div className={styles.engagements}>
            {reviewPlatformId === "1" && engagements && (
              <>
                <span className={styles.count}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/comments.svg"
                        : "/assets/comments_dark.svg"
                    }
                    width={16.25}
                    height={18}
                  />{" "}
                  {engagements["comments"]}
                </span>
                <span className={styles.count}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/retweet.svg"
                        : "/assets/retweet_dark.svg"
                    }
                    width={20.25}
                    height={22}
                  />{" "}
                  {engagements["retweets"]}
                </span>
                <span className={styles.count}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/likes.svg"
                        : "/assets/likes_dark.svg"
                    }
                    width={16.25}
                    height={18}
                  />{" "}
                  {engagements["likes"]}
                </span>
                <span className={styles.count}>
                  <img
                    src={
                      theme === "dark"
                        ? "/assets/views.svg"
                        : "/assets/views_dark.svg"
                    }
                    width={16.25}
                    height={18}
                  />{" "}
                  {engagements["views"]}
                </span>
              </>
            )}
          </div>
          {date && (
            <span className={styles.itemDate}>
              <span>
                <i style={{ marginRight: 8 }} className="fa fa-calendar-o" />
                {dayjs(date).format("DD MMM YYYY")}
              </span>
              <span>
                <i style={{ marginRight: 8 }} className="fa fa-clock-o" />
                {dayjs(date).format("HH:mm")}
              </span>
            </span>
          )}
        </div>
        <div className={styles.feedbackActions} style={{ display: "flex" }}>
          <div className={styles.hideOverflows}>
            <div
              className={styles.itemProps}
              ref={associationsRef}
              style={
                collapseAssociations
                  ? { maxHeight: "100px", overflow: "hidden" }
                  : {}
              }
            >
              {groupName && groupId && (
                <span
                  title={groupName}
                  className={styles.groupName}
                  style={{
                    backgroundColor: colorMap[groupId],
                    color:
                      colorMap[groupId] === "#D2B5F2" ||
                      colorMap[groupId] === "#56DEC8" ||
                      colorMap[groupId] === "#FFBFFF" ||
                      colorMap[groupId] === "#CCCCCC" ||
                      colorMap[groupId] === "#9BC3DE" ||
                      colorMap[groupId] === "#E7EDEF"
                        ? "#4400AA"
                        : "white",
                  }}
                >
                  <span className={styles.group}>{groupName}</span>
                </span>
              )}
              {sentiment && sentiment !== "None" && (
                <div
                  className={styles.itemIntent}
                  style={{
                    backgroundColor:
                      parseFloat(sentiment) > 0.5 &&
                      parseFloat(sentiment) <= 1.0
                        ? hexToRGBA("#369E3A", 0.1)
                        : parseFloat(sentiment) == 0.5
                        ? hexToRGBA("#FFC223", 0.1)
                        : parseFloat(sentiment) >= 0.0 &&
                          parseFloat(sentiment) < 0.5
                        ? hexToRGBA("#FF1D1D", 0.1)
                        : hexToRGBA("#858585", 0.1),
                    color:
                      parseFloat(sentiment) > 0.5 &&
                      parseFloat(sentiment) <= 1.0
                        ? hexToRGBA("#369E3A", 1)
                        : parseFloat(sentiment) == 0.5
                        ? hexToRGBA("#FFC223", 1)
                        : parseFloat(sentiment) >= 0.0 &&
                          parseFloat(sentiment) < 0.5
                        ? hexToRGBA("#FF1D1D", 1)
                        : hexToRGBA("#858585", 1),
                  }}
                >
                  <img src={overallSentiment?.img} width={20} height={20} />{" "}
                  {overallSentiment?.label}{" "}
                </div>
              )}
              {intents && intents !== "None" && (
                <div
                  className={styles.itemIntent}
                  style={{
                    backgroundColor: theme === "dark" ? "#1F2E5E" : "#3757BC",
                    color: theme === "dark" ? "#2F80ED" : "white",
                  }}
                >
                  <img src={overallIntent?.img} width={20} height={20} />{" "}
                  {overallIntent?.label}{" "}
                </div>
              )}
              {restaurant &&
                Object.keys(restaurant)?.length > 0 &&
                showPivotsInReviews && (
                  <>
                    {Object.keys(restaurant).map((key) => (
                      <span className={styles.pivotValues}>
                        {key}: {restaurant[key]}
                      </span>
                    ))}
                  </>
                )}
              {relatedTopics &&
                relatedTopics.length > 0 &&
                relatedTopics.map((topic) => {
                  return topic.type === "ai" && showAiTopicLabel ? (
                    <div
                      className={styles.itemRelatedTopics}
                      style={{
                        backgroundColor: color(topic.type)[0],
                        color: color(topic.type)[1],
                        cursor: "pointer",
                      }}
                    >
                      <span className={styles.topicLabel}>
                        <span className={styles.label} title={topic.label}>
                          {isMobileView
                            ? mobileLabel(topic.label)
                            : label(topic.label, "ai")}{" "}
                        </span>
                      </span>
                    </div>
                  ) : (
                    (!topic.type || topic.type === "custom") &&
                      showCustomTopicLabel && (
                        <div
                          className={styles.itemRelatedTopics}
                          style={{
                            backgroundColor: color("custom")[0],
                            color: color("custom")[1],
                            cursor: "pointer",
                          }}
                        >
                          <span className={styles.topicLabel}>
                            <span className={styles.label} title={topic.label}>
                              {isMobileView
                                ? mobileLabel(topic.label)
                                : label(topic.label, "custom")}{" "}
                            </span>
                          </span>
                        </div>
                      )
                  );
                })}
            </div>
            {associationsRefHeight && associationsRefHeight > 100 && (
              <span
                className={styles.showMoreAssociations}
                onClick={() => setCollapseAssociations(!collapseAssociations)}
              >
                {collapseAssociations ? (
                  <span>
                    Show More <CaretDownFilled />
                  </span>
                ) : (
                  <span>
                    Show Less <CaretUpFilled />
                  </span>
                )}
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );

  const FacebookReply = ({ item }) => {
    const formatDate = (dateString) => {
      if (dateString.length === 1) {
        dateString = "0" + dateString;
      }

      return dateString;
    };

    const ownerName = item?.from?.name;
    const dateObject = new Date(item?.created_time);
    const date =
      String(dateObject.getFullYear()) +
      "-" +
      formatDate(String(dateObject.getMonth() + 1)) +
      "-" +
      formatDate(String(dateObject.getDate())) +
      " " +
      formatDate(String(dateObject.getHours())) +
      ":" +
      formatDate(String(dateObject.getMinutes())) +
      ":" +
      formatDate(String(dateObject.getSeconds()));

    return (
      <>
        <div
          className={styles.itemContainerNoBorder}
          style={{
            padding: 15,
            backgroundColor: theme === "dark" ? "#100D31" : "#fff",
            borderRadius: 8,
            marginTop: 8,
          }}
        >
          <div className={styles.facebookComment}>
            <div className={styles.facebookCommentLeft}>
              {/* <div className={styles.checkbox}>{checkbox}</div> */}
              <div>
                <img
                  src="/assets/facebook_user.jpg"
                  className={styles.facebookCommentPic}
                />
              </div>
              <div className={styles.facebookCommentName}>
                <p style={{ color: ownerName ? "#1890ff" : "#fff" }}>
                  {ownerName || t("review.11")} ({t("review.10")})
                </p>
              </div>
            </div>
            <div className={styles.facebookCommentRight}>
              {/* <div>
                <i className="fa fa-thumbs-up fa-2x"></i>
                <p>{likes}</p>
              </div>
              <img src="/assets/google-play.svg"/> */}
            </div>
          </div>
          {/* <ShowMoreText
            lines={3}
            more="Show more"
            less="Show less"
            expanded={false}
            className="review-more"
            anchorclassName=''
          >
            {item.message}
          </ShowMoreText> */}
          <ReactReadMoreReadLess
            charLimit={200}
            readMoreText={`${t("review.6")} ▼`}
            readLessText={`${t("review.7")} ▲`}
          >
            {item.message}
          </ReactReadMoreReadLess>
          <div className={styles.itemBottom}>
            <div className={styles.itemProps}></div>
            <span className={styles.itemDate}>{date}</span>
          </div>
        </div>
      </>
    );
  };

  if (platformId == 2 && pic && (name || username)) {
    return (
      <div className={styles.itemContainer}>
        <div className={styles.tweet}>
          <div className={styles.tweetLeft}>
            <div className={styles.checkbox}>{checkbox}</div>
            <div>
              <img src={pic} className={styles.tweetPic} />
            </div>
            <div className={styles.tweetName}>
              <p>{name || username}</p>
              {rate && (
                <div className={styles.starRating}>
                  <StarRatings
                    rating={parseFloat(rate)}
                    starDimension="20px"
                    starSpacing="5px"
                    starRatedColor="#ffaf00"
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.tweetLikes}>
            <div>
              <i className="fa fa-thumbs-up fa-2x"></i>
              <p>{likes}</p>
            </div>
            <img src="/assets/google-play.svg" />
          </div>
        </div>
        {partOfAllReviews}
      </div>
    );
  }

  if (platformId == 11 && text !== t("notifications.86")) {
    return (
      <div className={styles.itemContainer}>
        <div className={styles.tweet}>
          <div className={styles.tweetLeft}>
            <div>
              <img
                src={pic || "/assets/facebook_user.jpg"}
                className={styles.tweetPic}
              />
            </div>
            <div className={styles.tweetName}>
              <p>{name || username}</p>
              {rate && (
                <div className={styles.starRating}>
                  <StarRatings
                    rating={parseFloat(rate)}
                    starDimension="20px"
                    starSpacing="5px"
                    starRatedColor="#ffaf00"
                  />
                </div>
              )}
            </div>
          </div>

          <div className={styles.tweetLikes}>
            {/* <div>
              <i className="fa fa-thumbs-up fa-2x"></i>
              <p>{likes}</p>
            </div> */}
            {reviewPlatformId === "2" ? (
              <i
                style={{
                  fontSize: 32,
                  marginRight: 15,
                  color: theme === "dark" ? "#fff" : "#000",
                }}
                className="fab fa-google-play"
              />
            ) : reviewPlatformId === "4" ? (
              <i
                style={{
                  fontSize: 32,
                  marginRight: 15,
                  color: theme === "dark" ? "#fff" : "#000",
                }}
                className="fab fa-app-store"
              />
            ) : null}
          </div>
        </div>
        {partOfAllReviews}
      </div>
    );
  }

  if (platformId == 12 && text !== t("notifications.86")) {
    return (
      <div className={styles.itemContainer}>
        <div className={styles.tweet}>
          <div className={styles.tweetLeft}>
            <div>
              <img
                src={
                  pic !== "None" && pic !== "nan"
                    ? pic
                    : "/assets/facebook_user.jpg"
                }
                className={styles.tweetPic}
              />
            </div>
            <div className={styles.tweetName}>
              <div className={styles.tweetNameVerified}>
                <h5>{name !== "None" && name !== "nan" ? name : ""}</h5>
                {userVerified && userVerified === "True" && (
                  <svg
                    viewBox="0 0 24 24"
                    aria-label="Verified account"
                    className="r-1fmj7o5 r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
                  >
                    <g>
                      <path d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z"></path>
                    </g>
                  </svg>
                )}
              </div>
              <p>
                {username !== "None" &&
                username !== "nan" &&
                typeof username !== "undefined"
                  ? "@" + username
                  : ""}
              </p>
            </div>
          </div>

          <div
            styles={{
              marginRight: 10,
            }}
            className={styles.tweetLikes}
          >
            {/* <div>
              <i className="fa fa-thumbs-up fa-2x"></i>
              <p>{likes}</p>
            </div> */}
            {reviewPlatformId === "1" ? (
              <img className={styles.twitter} src="/assets/twitter_new_.png" />
            ) : reviewPlatformId === "5" ? (
              <img
                style={{ fontSize: 32, marginRight: 15 }}
                src="/assets/sikayet_icon.svg"
                alt="sikayet"
              />
            ) : reviewPlatformId === "6" ? (
              <i
                style={{ fontSize: 32, marginRight: 15, color: "#674fbc" }}
                className="fa-brands fa-instagram"
              />
            ) : reviewPlatformId === "9" ? (
              <i
                style={{ fontSize: 32, marginRight: 15, color: "#4267B2" }}
                className="fa-brands fa-facebook-square"
              />
            ) : reviewPlatformId === "10" ? (
              <i
                style={{ fontSize: 32, marginRight: 15, color: "#4267B2" }}
                className="fa-brands fa-facebook-square"
              />
            ) : reviewPlatformId === "18" ? (
              <i
                style={{ fontSize: 32, marginRight: 15, color: "#ff4500" }}
                className="fa-brands fa-reddit"
              />
            ) : reviewPlatformId === "19" ? (
              <img
                style={{ objectFit: "contain", marginTop: 5, marginRight: 15 }}
                src="/assets/eksi_icon.svg"
                alt="eksi"
              />
            ) : reviewPlatformId === "27" ? (
              <i
                style={{ fontSize: 32, marginRight: 15, color: "#674fbc" }}
                className="fa-brands fa-instagram"
              />
            ) : null}
          </div>
        </div>
        {partOfAllReviews}
      </div>
    );
  }
  if ((platformId == 3 || platformId == 28) && text !== t("notifications.86")) {
    return (
      <div className={styles.itemContainer}>
        <div className={styles.tweet}>
          <div className={styles.tweetLeft}>
            <div className={styles.checkbox}>{checkbox}</div>
          </div>

          {!isNaN(npsRating) ? (
            <div className={styles.npsTag}>
              <span>
                {npsRating}
                <img src="/assets/Star.svg" alt="" />
              </span>
              <span
                style={{
                  backgroundColor:
                    npsRating < 7
                      ? "#FF6253"
                      : npsRating > 6 && npsRating < 9
                      ? "#FFA800"
                      : "#00B07B",
                }}
              >
                NPS
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
        {partOfAllReviews}
      </div>
    );
  }
  if ((platformId == 7 || platformId == 8) && text !== t("notifications.86")) {
    return (
      <div className={styles.itemContainer}>
        <div className={styles.facebookComment}>
          <div className={styles.facebookCommentLeft}>
            <div className={styles.checkbox}>{checkbox}</div>
            <div>
              <img
                src="/assets/facebook_user.jpg"
                className={styles.facebookCommentPic}
              />
            </div>
            <div className={styles.facebookCommentName}>
              <p> {name && name !== "None" ? name : t("review.11")} </p>
            </div>
          </div>
        </div>
        {partOfAllReviews}
      </div>
    );
  }
  if ((platformId == 9 || platformId == 10) && text !== t("notifications.86")) {
    return (
      <>
        <div className={styles.itemContainer}>
          <div className={styles.facebookComment}>
            <div className={styles.facebookCommentLeft}>
              <div className={styles.checkbox}>{checkbox}</div>
              <div>
                <img
                  src="/assets/facebook_user.jpg"
                  className={styles.facebookCommentPic}
                />
              </div>
              <div className={styles.facebookCommentName}>
                <p>{t("review.11")}</p>
              </div>
            </div>
            <div className={styles.facebookCommentRight}>
              <Button
                type="primary"
                onClick={() => {
                  setIsReplyFacebookReviewsVisible(
                    !isReplyFacebookReviewsVisible
                  );
                }}
                style={{
                  marginRight: 8,
                  backgroundColor: theme === "dark" ? "#100D31" : "#ffffff",
                  width: 150,
                }}
              >
                {((facebookReplies || {})[reviewId] || []).length > 0 &&
                  (isReplyFacebookReviewsVisible
                    ? t("review.12")
                    : `${t("review.13")} (` +
                      ((facebookReplies || {})[reviewId] || []).length +
                      ")")}
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setIsReplyFacebookModalVisible(true);
                }}
                style={{
                  backgroundColor: theme === "dark" ? "#100D31" : "#ffffff",
                }}
              >
                {t("review.10")}
              </Button>
              {/* <div>
                  <i className="fa fa-thumbs-up fa-2x"></i>
                  <p>{likes}</p>
                </div>
                <img src="/assets/google-play.svg"/> */}
            </div>
          </div>
          {partOfAllReviews}
          {isReplyFacebookReviewsVisible &&
            ((facebookReplies || {})[reviewId] || []).map((item) => (
              <FacebookReply item={item} />
            ))}
        </div>
        {isReplyFacebookModalVisible && (
          <div
            // onClose={() => {
            //   setFacebookReplyMessage("");
            //   setIsReplyFacebookModalVisible(false);
            // }}
            // onSave={() => {
            //   replyFacebook(reviewId, facebookReplyMessage);
            //   setFacebookReplyMessage("");
            // }}
            // showSaveButton={true}
            // textonButton="Ok"
            // titleAsset=""
            // title="Reply to comment"
            // widthofmodal="800px"
            className={styles.replyBoxModal}
          >
            <div className={styles.replyBoxModalContent}>
              <div className={styles.headingBox}>
                <div className={styles.titleBox}>
                  <p>{t("review.14")}</p>
                  <p>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1593_1271)">
                        <path
                          d="M7.00033 12.8337C10.222 12.8337 12.8337 10.222 12.8337 7.00033C12.8337 3.77866 10.222 1.16699 7.00033 1.16699C3.77866 1.16699 1.16699 3.77866 1.16699 7.00033C1.16699 10.222 3.77866 12.8337 7.00033 12.8337Z"
                          stroke={theme === "dark" ? "#D9D9D9" : "#222222"}
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7 9.33333V7"
                          stroke={theme === "dark" ? "#D9D9D9" : "#222222"}
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7 4.66699H7.00667"
                          stroke={theme === "dark" ? "#D9D9D9" : "#222222"}
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1593_1271">
                          <rect width="14" height="14" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {t("review.15")}
                  </p>
                </div>
                {theme === "dark" ? (
                  <img
                    src="/assets/x.svg"
                    alt=""
                    onClick={() => {
                      setFacebookReplyMessage("");
                      setIsReplyFacebookModalVisible(false);
                    }}
                  />
                ) : (
                  <img
                    src="/assets/x_black.svg"
                    alt=""
                    onClick={() => {
                      setFacebookReplyMessage("");
                      setIsReplyFacebookModalVisible(false);
                    }}
                  />
                )}
              </div>

              <Spin spinning={isReplyFacebookModalLoading}>
                <div className={styles.userNameDetails}>
                  <span>
                    <img src="/assets/facebook_user.jpg" alt="" />{" "}
                    {t("review.11")}
                  </span>
                  <p>{text}</p>
                </div>
                <div className={styles.replyBox}>
                  <textarea
                    onInput={handleUserText}
                    id="custom_text_input"
                    value={facebookReplyMessage}
                    rows={"3"}
                    placeholder={t("placeholder.17")}
                    onChange={(e) => setFacebookReplyMessage(e.target.value)}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      replyFacebook(reviewId, facebookReplyMessage);
                      setFacebookReplyMessage("");
                    }}
                  >
                    {t("review.10")}
                  </button>
                </div>
              </Spin>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <div className={styles.itemContainer}>
      {rate && (
        <div className={styles.starRating}>
          <div className={styles.checkbox}>{checkbox}</div>
          <StarRatings
            rating={parseFloat(rate)}
            starDimension="20px"
            starSpacing="5px"
            starRatedColor="#ffaf00"
          />
        </div>
      )}
      {platformId == 1 && pic && name && username && userVerified && (
        <div className={styles.tweet}>
          <div className={styles.tweetLeft}>
            <div>
              <img src={pic} className={styles.tweetPic} />
            </div>
            <div className={styles.tweetName}>
              <div className={styles.tweetNameVerified}>
                <h5>{name}</h5>
                {userVerified === "True" && (
                  <svg
                    viewBox="0 0 24 24"
                    aria-label="Verified account"
                    className="r-1fmj7o5 r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
                  >
                    <g>
                      <path d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z"></path>
                    </g>
                  </svg>
                )}
              </div>

              <p>@{username}</p>
            </div>
          </div>

          <div>
            <i className="fab fa-twitter fa-2x"></i>
          </div>
        </div>
      )}
      {partOfAllReviews}
    </div>
  );
};

export default ReviewItem;
