import React, { useEffect } from "react";
import styles from "./style.module.scss";
import { Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Representation = ({
  showSaveButton,
  description,
  showCancelButton,
  hideCloseIcon,
  hideHeader,
  showModal,
  titleAsset,
  title,
  onSave,
  onClose,
  children,
  textonButton,
  cancelButtonText,
  widthofmodal,
  disabled,
  loadingSaveClick,
  isDeleteModal,
  onDiscard,
}) => {
  const theme = useSelector((state) => state.DashboardReducer.theme);
  const { t } = useTranslation();

  useEffect(() => {
    if (showModal) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  }, [showModal]);

  return (
    <>
      {showModal && (
        <div className={styles.modalBackground}>
          <div className={styles.modal} style={{ width: `${widthofmodal}` }}>
            {!hideCloseIcon && (
              <button
                className={styles.closeButton}
                style={loadingSaveClick ? { cursor: "not-allowed" } : {}}
                onClick={() => onClose()}
                disabled={loadingSaveClick}
              >
                {theme === "dark" ? (
                  <img src="/assets/x.svg" alt="" />
                ) : (
                  <img src="/assets/x_black.svg" alt="" />
                )}
              </button>
            )}
            {!hideHeader && (
              <div className={styles.header}>
                <div className={styles.headerWrapper}>
                  <div className={styles.titleWrapper}>
                    {titleAsset === "" ? (
                      ""
                    ) : (
                      <img width="45" height="45" src={titleAsset} alt="" />
                    )}
                    <h3 className={styles.title}>{title}</h3>
                  </div>
                  <div className={styles.description}>{description}</div>
                </div>
              </div>
            )}

            <div className={styles.content}>{children}</div>
            {(showCancelButton || showSaveButton) && (
              <div className={styles.footer}>
                {showCancelButton && (
                  <span
                    className={`${styles.footerBtn} ${styles.cancelButton}`}
                    style={loadingSaveClick ? { cursor: "not-allowed" } : {}}
                    onClick={() => {
                      !loadingSaveClick &&
                        (onDiscard ? onDiscard() : onClose());
                    }}
                    disabled={loadingSaveClick}
                  >
                    {cancelButtonText ? cancelButtonText : "Cancel"}
                  </span>
                )}
                {showSaveButton && (
                  <span
                    className={
                      isDeleteModal
                        ? `${styles.footerBtn} ${styles.deleteButton}`
                        : `${styles.footerBtn} ${styles.saveButton}`
                    }
                    style={loadingSaveClick ? { cursor: "not-allowed" } : {}}
                    onClick={() => {
                      !disabled && onSave();
                    }}
                    disabled={disabled}
                  >
                    {loadingSaveClick ? (
                      <LoadingOutlined
                        color="white"
                        style={{ color: "white" }}
                      />
                    ) : textonButton ? (
                      textonButton
                    ) : (
                      t("button.7")
                    )}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Representation;
